import PropTypes from 'prop-types';
import React from 'react';

import DomainAuthority from 'components/DomainAuthority/DomainAuthority';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';

import SweetheartListJournalistProfile from './SweetheartListJournalistProfile';
import SweetheartListJournalistTier from './SweetheartListJournalistTier';
import SweetheartListJournalistTopics from './SweetheartListJournalistTopics';

const SweetheartListJournalist = ({ journalist = {}, index = 0 }) => {
  const isEvenRow = index % 2 === 0;
  return (
    <tr
      className={isEvenRow ? 'bg-gray-50' : 'bg-white'}
      data-test-id={`sweetheartList-tableView-${journalist.name}`}
    >
      <td className="px-4 py-2">
        <SweetheartListJournalistProfile
          name={journalist.name}
          ImageComponent={JournalistImage}
          bold
        />
      </td>

      <td className="px-4 py-2 text-center">
        <SweetheartListJournalistTier tier={journalist.tier} />
      </td>

      <td className="px-4 py-2 text-center">
        <DomainAuthority domainAuthority={journalist.domainAuthority} />
      </td>

      <td className="px-4 py-2 max-w-sm">
        <SweetheartListJournalistTopics interests={journalist.interests} />
      </td>

      <td className="px-4 py-2">
        <SweetheartListJournalistProfile
          name={journalist.publicationName}
          ImageComponent={PublicationImage}
        />
      </td>
    </tr>
  );
};

SweetheartListJournalist.propTypes = {
  journalist: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default SweetheartListJournalist;
