import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import { AnnotationIcon } from '@heroicons/react/solid';
import get from 'lodash/get';
import { Controller, useForm } from 'react-hook-form';

import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldFounders from 'components/form/inputFields/InputFieldFounders/InputFieldFounders';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import InputFieldToggle from 'components/form/inputFields/InputFieldToggle';
import { tagsTopics } from 'constants/tags';
import createOnChangeIsFutureDate from 'utils/date/createOnChangeIsFutureDate';
import { getDateRange, getDateRangeDefault } from 'utils/date/dateRange';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

const CompanyQuoteEditModal = ({
  open,
  quote = null,
  onClose,
  onSubmit,
  founders = [],
}) => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isValid },
    setError,
    clearErrors,
    getValues,
  } = useForm({ mode: 'onChange' });

  const defaultValues = quote || {};
  const defaultDate = quote ? getDateObjFromTimestamp(quote.date) : {};

  const onChangeDate = createOnChangeIsFutureDate({
    date: getValues('date'),
    onError: () =>
      setError('date', { message: 'Date cannot be in the future' }),
    onSuccess: () => clearErrors(),
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
            <div className="absolute top-4 right-4 z-10">
              <CloseButton onClick={onClose} />
            </div>
            <div className="px-6 py-6 pb-0">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                <AnnotationIcon className="w-6" />
              </div>
              <div className="mt-3 text-center">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="dialog-1-title"
                >
                  {quote ? 'Edit' : 'Add a'} quote
                </h3>
                {!quote && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Quotes provide journalists with content they can easily
                      add and attribute to your company in their articles.
                    </p>
                  </div>
                )}
              </div>

              <div className="flex justify-between items-baseline mt-5">
                <Label htmlFor="title">Title*</Label>
              </div>
              <InputFieldText
                name="title"
                ref={register({
                  required: 'Please put in a title',
                })}
                placeholder="Type in a quote title..."
                defaultValue={defaultValues?.title}
                data-test-id="company-quotes-editModal-titleInput"
              />
              {errors.title && (
                <ErrorMessage testId="company-quotes-editModal-titleInput-error">
                  {errors.title.message}
                </ErrorMessage>
              )}

              <div className="mt-3">
                <Label htmlFor="body">Body*</Label>
              </div>

              <InputFieldTextArea
                name="body"
                ref={register({
                  required: 'Please put in a body',
                })}
                placeholder="Type in a quote body..."
                defaultValue={defaultValues?.body}
                data-test-id="company-quotes-editModal-bodyInput"
              />
              {errors.body && (
                <ErrorMessage testId="company-quotes-editModal-bodyInput-error">
                  {errors.body.message}
                </ErrorMessage>
              )}

              <fieldset className="mt-3">
                <Label htmlFor="date">When did it happen?</Label>

                <div className="flex mt-2">
                  <Controller
                    name="date.day"
                    defaultValue={get(
                      defaultDate,
                      'day',
                      getDateRangeDefault('days')
                    )}
                    control={control}
                    render={({ onChange, value }) => (
                      <InputFieldDropdown
                        options={getDateRange('days')}
                        value={value}
                        onChange={onChangeDate(onChange)}
                        optionWidthClassName="w-16 sm:w-20"
                        wrapperClassName="mr-2"
                        testId="company-quotes-editModal-day"
                      />
                    )}
                  />

                  <Controller
                    name="date.month"
                    defaultValue={get(
                      defaultDate,
                      'month',
                      getDateRangeDefault('months')
                    )}
                    control={control}
                    render={({ onChange, value }) => (
                      <InputFieldDropdown
                        options={getDateRange('months')}
                        value={value}
                        onChange={onChangeDate(onChange)}
                        optionWidthClassName="w-36"
                        wrapperClassName="mr-2"
                        testId="company-quotes-editModal-month"
                      />
                    )}
                  />

                  <Controller
                    name="date.year"
                    defaultValue={get(
                      defaultDate,
                      'year',
                      getDateRangeDefault('years')
                    )}
                    control={control}
                    render={({ onChange, value }) => (
                      <InputFieldDropdown
                        options={getDateRange('years')}
                        value={value}
                        onChange={onChangeDate(onChange)}
                        optionWidthClassName="w-20 sm:w-28"
                        testId="company-quotes-editModal-year"
                      />
                    )}
                  />
                </div>

                {errors.date && (
                  <ErrorMessage>{errors.date.message}</ErrorMessage>
                )}
              </fieldset>

              <div className="mt-3">
                <Label htmlFor="tags">Tags</Label>
              </div>

              <div
                className="mt-2"
                data-test-id="company-quotes-editModal-tagsInput"
              >
                <Controller
                  name="tags"
                  control={control}
                  defaultValue={defaultValues?.tags || []}
                  render={({ onChange, value }) => (
                    <InputFieldTagsAutocomplete
                      customFieldName="tag"
                      suggestions={tagsTopics}
                      onChange={onChange}
                      value={value}
                      tagsIndex={tagsTopics}
                    />
                  )}
                />
              </div>

              <div className="mt-3">
                <Label htmlFor="users">Related founders</Label>
              </div>

              <div className="mt-2">
                <Controller
                  name="founder"
                  control={control}
                  defaultValue={
                    defaultValues.founder || defaultValues?.users?.[0] || null
                  }
                  render={({ onChange, value }) => (
                    <InputFieldFounders
                      founders={founders}
                      onChange={onChange}
                      value={value}
                      testId="company-quotes-editModal-founderInput"
                    />
                  )}
                />
              </div>

              {errors.founder && (
                <ErrorMessage>{errors.founder.message}</ErrorMessage>
              )}

              <div className="mt-3">
                <Label htmlFor="private">Private</Label>
              </div>

              <div className="mt-2">
                <Controller
                  name="private"
                  control={control}
                  defaultValue={!!defaultValues.private}
                  render={({ onChange, value }) => (
                    <InputFieldToggle
                      value={value}
                      onChange={onChange}
                      text="This quote should be private"
                      testId="company-quotes-editModal-privateInput"
                    />
                  )}
                />
              </div>
            </div>

            <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
              <Button
                type="secondary"
                onClick={() => onClose()}
                data-test-id="company-quotes-editModal-cancel"
              >
                Cancel
              </Button>
              <div className="ml-3">
                <Button
                  submit
                  disabled={!isValid}
                  eventType="pressPage_update"
                  eventProperties={{ action: 'quote_add' }}
                  data-test-id="company-quotes-editModal-submit"
                >
                  {quote ? 'Update' : 'Add'} quote
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

CompanyQuoteEditModal.propTypes = {
  open: PropTypes.bool,
  quote: PropTypes.object,
  founders: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyQuoteEditModal;
