import PropTypes from 'prop-types';
import React from 'react';

const SweetheartListJournalistTier = ({ tier = '' }) => (
  <div className="bg-white px-4 py-2 rounded-full border border-gray-200 flex items-center justify-center whitespace-nowrap text-teal-500">
    {tier || 'n/a'}
  </div>
);

SweetheartListJournalistTier.propTypes = {
  tier: PropTypes.string,
};

export default SweetheartListJournalistTier;
