import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

const CompanyInsightWrapper = ({
  hasAnswer = false,
  showOwnerView = false,
  onClick,
  children,
}) => {
  const className =
    'relative z-0 flex flex-1 flex-col justify-between items-start p-6 rounded-lg text-sm border-gray-200 bg-white border';

  if (showOwnerView) {
    return (
      <li className={classNames(className, { 'border-dashed': hasAnswer })}>
        {children}
      </li>
    );
  }

  return (
    <Link to="#" onClick={onClick} className="flex">
      <li
        className={classNames(
          className,
          'hover:border-teal-500 hover:shadow-md'
        )}
      >
        {children}
      </li>
    </Link>
  );
};

CompanyInsightWrapper.propTypes = {
  hasAnswer: PropTypes.bool,
  showOwnerView: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default CompanyInsightWrapper;
