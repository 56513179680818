import React from 'react';

import { useParams } from 'react-router-dom';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useRedirectOpportunities from 'hooks/useRedirectOpportunities';

import Opportunities from './Opportunities';
import OpportunitiesAdmin from './OpportunitiesAdmin';

const OpportunitiesPage = () => {
  const company = useCompanyData();
  const showOpportunities = useRedirectOpportunities();

  // Use the company slug to inspect companies as admin
  const { id: slug } = useParams('id');

  if (!showOpportunities) {
    return null;
  }

  if (slug) {
    return <OpportunitiesAdmin slug={slug} />;
  }

  if (company.companyData) {
    return <Opportunities company={company.companyData} />;
  }

  // if no company data is available, throw error

  return "Can't find any media opportunities";
};

export default OpportunitiesPage;
