import PropTypes from 'prop-types';
import React from 'react';

import { SearchIcon } from '@heroicons/react/outline';
import { Controller } from 'react-hook-form';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const AdminCompaniesCompanySearchField = ({ control }) => (
  <div className="pt-4 pb-2 w-full">
    <h3 className="text-gray-600 text-xs font-semibold uppercase mb-2">
      Search Companies
    </h3>
    <Controller
      name="activeCompanySearchInput"
      control={control}
      defaultValue=""
      render={({ onChange }) => (
        <InputFieldText
          name="activeCompanySearchInput"
          type="text"
          autoComplete="company"
          prefix={<SearchIcon className="w-4 h-4" />}
          onChange={onChange}
        />
      )}
    />
  </div>
);
AdminCompaniesCompanySearchField.propTypes = {
  control: PropTypes.object.isRequired,
};

export default AdminCompaniesCompanySearchField;
