import React from 'react';

import sortBy from 'lodash/sortBy';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_COLLECTION, TOPICS_MAPPINGS } from 'constants/firebasePaths';
import { tagsTopics } from 'constants/tags';
import getTagsClassNames from 'utils/color/getTagsClassNames';

import AdminWrapper from '../AdminWrapper';

// UI to map old and custom topics to default topics

const AdminMapTopicsPage = () => {
  const firestore = useFirestore();

  const topicsMappingsCollection = firestore.collection(TOPICS_MAPPINGS);
  const topicsMappings = useFirestoreCollectionData(
    topicsMappingsCollection,
    defaultOptions
  );

  const companiesCollection = firestore
    .collection(COMPANIES_COLLECTION)
    .where('slug', '!=', null);
  const companies = useFirestoreCollectionData(
    companiesCollection,
    defaultOptions
  );

  // get default topics from tags
  const defaultTopics = tagsTopics.filter((t) => !t.core);

  // get custom topics from company
  const customTopics = companies.data
    .map((company) =>
      company.topics
        ? company.topics.filter(
            (topic) =>
              typeof topic.id === 'string' && topic.id.startsWith('custom-')
          )
        : []
    )
    .filter((topics) => topics.length)
    .flat();
  const customTopicsSorted = sortBy(customTopics, 'name');

  const coreTopicsNames = tagsTopics
    .filter((topic) => !topic.uncategorised && !topic.mapsTo)
    .map((topic) => topic.name);

  // Save a new mapping, assigning new email categories/keywords to a company <topic />
  // If mapping already exists, overwrite with new values
  const onChange = async (sourceTopic, targetTopicName) => {
    let isSet = false;

    const mapsTo = tagsTopics.find(
      (coreTopic) => coreTopic.name === targetTopicName
    );

    const doc = {
      id: sourceTopic.id,
      name: sourceTopic.name,
      mapsTo: mapsTo?.id ?? null,
    };

    topicsMappings.data.forEach((mapping) => {
      if (mapping.id === doc.id) {
        topicsMappingsCollection.doc(mapping.uid).update(doc);
        isSet = true;
      }
    });

    if (!isSet) {
      // if not, create new one
      topicsMappingsCollection.add(doc);
    }
  };

  return (
    <AdminWrapper currentIndex={2} title="Core topics">
      <p className="mt-4 text-sm leading-normal">
        These are hardcoded and cannot be changed easily. These are also the
        topics we use for classifying opportunities. The training model on
        MonkeyLearn using the exact same topics to classify opportunities, and
        we use the topics as a way to match a company&apos;s interests (defind
        as topics) with relevant opportunities (where each opportunity can have
        one or more topics).
      </p>

      <ul className="mt-4">
        {coreTopicsNames.map((coreTopicName) => {
          const idx = tagsTopics.findIndex((t) => t.name === coreTopicName);
          let bgClass;
          let colorClass;
          [bgClass, colorClass] = getTagsClassNames(idx, coreTopicName.length);
          if (coreTopicName === 'Uncategorised') {
            bgClass = 'bg-black';
            colorClass = 'text-white';
          }
          return (
            <li
              key={coreTopicName}
              className={`inline-block mr-2 mb-2 px-2 py-1 rounded-md text-xs ${bgClass} ${colorClass}`}
            >
              {coreTopicName}
            </li>
          );
        })}
      </ul>

      <h1 className="text-2xl font-semibold mt-4">Map default topics</h1>
      <p className="mt-4 text-sm">
        Default topics are the ones we&apos;ve used until now (v1) in our
        onboarding process, so we can not easily replace them. Instead, each of
        them is still valid and can be entered in the onboarding process, but as
        of the media opportunities v2, under the hood we&apos;re mapping them to
        the new set of core topics.
      </p>

      <div className="flex flex-col mt-4">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Maps to
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {defaultTopics.map((topic, topicIdx) => {
                    const mapping = topicsMappings.data.find(
                      (m) => m.id === topic.id
                    );

                    const coreTopic = mapping
                      ? tagsTopics.find((t) => t.id === mapping.mapsTo)
                      : null;

                    return (
                      <tr
                        key={topic.id}
                        className={
                          topicIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {topic.name}{' '}
                          {topic.uncategorised && (
                            <span className="inline-block ml-1 px-2 py-1 bg-gray-100 text-gray-700 rounded-lg text-xs">
                              Uncategorised
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 relative flex">
                          <InputFieldDropdown
                            options={coreTopicsNames}
                            optionWidthClassName="w-48"
                            value={coreTopic?.name || 'Uncategorised'}
                            onChange={(val) => onChange(topic, val)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <h1 className="text-3xl font-semibold mt-8">Custom topics</h1>

      <p className="mt-4 text-sm">
        Next, you can see all of the custom topics that companies have added. We
        need to assign each of them to a core topic, because we&apos;re only
        using core topics to identify relevant opportunities. If a topic
        doesn&apos;t have a matching core topic, use &quot;Uncategorised&quot;.
        Uncategorised opportunities are less relevant and can still be assigned
        a topic in the future.
      </p>

      <div className="flex flex-col mt-8">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Maps to
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {customTopicsSorted.map((topic, topicIdx) => {
                    const mapping = topicsMappings.data.find(
                      (m) => m.id === topic.id
                    );

                    const coreTopic = mapping
                      ? tagsTopics.find((t) => t.id === mapping.mapsTo)
                      : null;

                    return (
                      <tr
                        key={topic.id}
                        className={
                          topicIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {topic.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 relative flex">
                          <InputFieldDropdown
                            options={coreTopicsNames}
                            optionWidthClassName="w-48"
                            value={coreTopic?.name || 'Uncategorised'}
                            onChange={(val) => onChange(topic, val)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminMapTopicsPage;
