import React from 'react';

import { Switch, Route, BrowserRouter } from 'react-router-dom';

import AnalyticsPageLogger from 'components/integrations/AnalyticsPageLogger/AnalyticsPageLogger';
import CoreLayout from 'components/layout/CoreLayout';
import PrivateRoute from 'components/router/PrivateRoute';
import CompanyProvider from 'contexts/CompanyContext/CompanyContext';
import useEnvironment from 'contexts/EnvironmentContext/hooks/useEnvironment';
import UserProvider from 'contexts/UserContext/UserContext';

import AnalyticsRoute from './Analytics';
import CompaniesRoute from './Companies';
import CompanyRoute from './Company';
import HomeRoute from './Home';
import NotFoundRoute from './NotFound';
import OnboardingRoute from './Onboarding';
import OpportunitiesRoute from './Opportunities';
import SettingsRoute from './Settings';
import SweetheartListRoute from './SweetheartList';
import AdminRoute from './admin';
import AdminCompaniesRoute from './admin/AdminCompanies';
import AdminCreateOpportunityRoute from './admin/AdminCreateOpportunity';
import AdminJournalistsRoute from './admin/AdminJournalists';
import AdminMapTopicsRoute from './admin/AdminMapTopics';
import AdminOpportunitiesRoute from './admin/AdminOpportunities';
import AdminOpportunitiesNotificationsRoute from './admin/AdminOpportunitiesNotifications';
import AdminPublicationsRoute from './admin/AdminPublications';
import AdminSweetheartListRoute from './admin/AdminSweetheartList';
import LoginRoute from './auth/Login';
import ResetPasswordRoute from './auth/ResetPassword';
import SignupVcRoute from './auth/SignupVc';

const HomeComponent = HomeRoute.component;

const Router = () => {
  const { isLocal, isEmulated } = useEnvironment();
  return (
    <BrowserRouter>
      <UserProvider>
        {(uid) => (
          <CompanyProvider uid={uid}>
            <CoreLayout>
              <Switch>
                <Route
                  exact
                  path={HomeRoute.path}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  component={() => <HomeComponent />}
                />
                {[
                  // auth routes
                  SignupVcRoute,
                  LoginRoute,
                  ResetPasswordRoute,

                  // onboarding route
                  OnboardingRoute,

                  // user area routes
                  CompanyRoute,
                  OpportunitiesRoute,
                  SettingsRoute,
                  AnalyticsRoute,
                  SweetheartListRoute,

                  // vc routes
                  CompaniesRoute,

                  // admin routes
                  AdminRoute,
                  AdminPublicationsRoute,
                  AdminJournalistsRoute,
                  AdminOpportunitiesRoute,
                  AdminOpportunitiesNotificationsRoute,
                  AdminCreateOpportunityRoute,
                  AdminMapTopicsRoute,
                  AdminCompaniesRoute,
                  AdminSweetheartListRoute,
                ].map((settings) => {
                  if (settings.authRequired) {
                    return (
                      <PrivateRoute
                        key={`Route-${settings.path}`}
                        {...settings}
                      />
                    );
                  }
                  return <Route key={`Route-${settings.path}`} {...settings} />;
                })}
                <Route component={NotFoundRoute.component} />
              </Switch>
              {!isEmulated && !isLocal && <AnalyticsPageLogger />}
            </CoreLayout>
          </CompanyProvider>
        )}
      </UserProvider>
    </BrowserRouter>
  );
};

export default Router;
