import PropTypes from 'prop-types';
import React, { useState } from 'react';

import 'chartjs-adapter-date-fns';
import { Helmet } from 'react-helmet';

import Container from 'components/Container';
import PageWrapper from 'components/PageWrapper';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';

import AnalyticsCompanyProfile from './AnalyticsCompanyProfile';
import AnalyticsDetails from './AnalyticsDetails';
import AnalyticsOverView from './AnalyticsOverview';
import AnalyticsPie from './AnalyticsPie';
import generateDaysArray from './helpers/generateDays';
import useReportRows from './hooks/useReportRows';

const Analytics = ({ slug }) => {
  const [period, setPeriod] = useState('Last week');

  // calculate number of days and days array
  const numberOfDays = period === 'Last week' ? 7 : 30;
  const daysArray = generateDaysArray(numberOfDays);

  // get report rows
  const reportRowsOverview = useReportRows({ slug, numberOfDays });
  const reportRowsDetails = useReportRows({
    slug,
    numberOfDays,
    getDetails: true,
  });

  return (
    <div className="-mt-4 sm:-mt-6">
      <Helmet>
        <title>Analytics | MVPR</title>
      </Helmet>
      <PageWrapper>
        <Container
          title="Overview"
          ComponentEnd={
            <div className="flex items-center space-x-2">
              <p className="text-sm text-gray-500 hidden sm:block">
                Show data for
              </p>
              <InputFieldDropdown
                optionWidthClassName="w-36"
                value={period}
                onChange={setPeriod}
                options={['Last month', 'Last week']}
              />
            </div>
          }
        >
          <div className="w-full grid grid-cols-12 gap-6 m-6">
            <AnalyticsOverView
              period={period}
              daysArray={daysArray}
              reportRows={reportRowsOverview}
            />
            <AnalyticsCompanyProfile
              reportRows={reportRowsDetails}
              title="Company information"
              action="companyProfile_view_copyToClipboard"
            />
            <AnalyticsPie
              reportRows={reportRowsDetails}
              title="Most viewed founders"
              action="founder_view"
              showPercentage
            />
            <AnalyticsDetails
              reportRows={reportRowsDetails}
              title="Most clicked achievements"
              action="achievement_open"
              showPercentage
            />
            <AnalyticsDetails
              reportRows={reportRowsDetails}
              title="Most clicked articles"
              action="article_open"
              showPercentage
            />
            <AnalyticsDetails
              reportRows={reportRowsDetails}
              title="Most viewed snapshots"
              action="snapshot_view"
              showPercentage
            />
            <AnalyticsDetails
              reportRows={reportRowsDetails}
              title="Most viewed quotes"
              action="quote_view"
              showPercentage
            />
            <AnalyticsDetails
              reportRows={reportRowsDetails}
              title="Most viewed press releases"
              action="pressRelease_view"
            />
            <AnalyticsDetails
              reportRows={reportRowsDetails}
              title="Most downloaded press releases"
              action="pressRelease_open_fix"
            />
            <AnalyticsDetails
              reportRows={reportRowsDetails}
              title="Most downloaded assets"
              action="download_open"
            />
          </div>
        </Container>
      </PageWrapper>
    </div>
  );
};

Analytics.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Analytics;
