import flow from 'lodash/flow';
import uniqBy from 'lodash/uniqBy';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { TOPICS_MAPPINGS } from 'constants/firebasePaths';
import { tagsTopics } from 'constants/tags';

// map topic to one of the three options, or null if none of them applies:
// - core topic, given that the core flag is enabled
// - mapping, if it can be found in the mappings
// - mappedTopic, if mapsTo is pointing to another topic
const mapTopic =
  (mappings = []) =>
  (topic = {}) => {
    const coreTopic = tagsTopics.find((t) => t.id === topic.id && t.core);
    if (coreTopic) {
      return coreTopic;
    }

    const mapping = mappings.find((m) => m.id === topic.id);
    if (!mapping) {
      return null;
    }

    const mappedTopic = tagsTopics.find((t) => t.id === mapping.mapsTo);
    if (mappedTopic) {
      return mappedTopic;
    }

    return null;
  };

// 1. map topics
// 2. filter out empty ones
// 3. filter out duplicates
const createTopicsMapper = (mappings) =>
  flow([
    (arr) => arr.map(mapTopic(mappings)),
    (arr) => arr.filter(Boolean),
    (arr) => uniqBy(arr, 'id'),
  ]);

// map topics to core topics
const useMapTopicsToCoreTopics = () => {
  const firestore = useFirestore();
  const collection = firestore.collection(TOPICS_MAPPINGS);

  const { data: mappings } = useFirestoreCollectionData(
    collection,
    defaultOptions
  );

  const mapTopics = createTopicsMapper(mappings);
  return (topics) => (topics ? mapTopics(topics) : []);
};

export default useMapTopicsToCoreTopics;
