import { useMemo } from 'react';

import { useStorage } from 'reactfire';

import { REGEX_IMAGE_FILETYPE } from 'constants/regex';

const IMAGE_SIZE_LOWRES = '110x110';
const IMAGE_SIZE_HIGHRES = '220x220';
const IMAGE_SIZE_SUPERRES = '330x330';

const logoSizes = [IMAGE_SIZE_LOWRES, IMAGE_SIZE_HIGHRES, IMAGE_SIZE_SUPERRES];

const useGetResponsiveStorageRefs = (path = '') => {
  const storage = useStorage();

  const responsiveStorageRefs = useMemo(
    () =>
      logoSizes.map((size) =>
        storage.ref(path.replace(REGEX_IMAGE_FILETYPE, `_${size}.$1`))
      ),
    [path, storage]
  );

  return responsiveStorageRefs;
};

export default useGetResponsiveStorageRefs;
