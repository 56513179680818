import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import {
  VC_COMPANIES_PATH,
  DEFAULT_ONBOARDING_PATH,
  LOGIN_PATH,
} from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import getCompanyRoute from 'utils/getCompanyRoute';

// redirect hook for homepage, redirecting the user to the correct path on load
const useRedirectHome = () => {
  const { isAnonymous, uid, claimsVc } = useUserData();
  const { companyData } = useCompanyData();
  const history = useHistory();
  const slug = companyData?.slug;
  const isLoggedIn = !isAnonymous && uid;

  useEffect(() => {
    if (claimsVc) {
      history.push(VC_COMPANIES_PATH);
      return;
    }

    if ((isLoggedIn || isAnonymous) && slug) {
      history.push(getCompanyRoute(slug));
      return;
    }

    if (isAnonymous || (isLoggedIn && !slug)) {
      history.push(DEFAULT_ONBOARDING_PATH);
      return;
    }

    // If none of the above, link to login
    history.push(LOGIN_PATH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useRedirectHome;
