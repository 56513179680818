import PropTypes from 'prop-types';
import React from 'react';

import CompaniesListCompany from './CompaniesListCompany';
import CompaniesListEmptyState from './CompaniesListEmptyState';

// Displays a list of companies with the company logo and name, with an arrow on the right indicating this is a link
const CompaniesList = ({ companies = [] }) =>
  companies && companies.length ? (
    companies.map((company) => (
      <CompaniesListCompany key={company.slug} company={company} />
    ))
  ) : (
    <CompaniesListEmptyState />
  );

CompaniesList.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      logo: PropTypes.object,
      name: PropTypes.string,
      owner: PropTypes.string,
    })
  ).isRequired,
};

export default CompaniesList;
