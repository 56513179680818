import PropTypes from 'prop-types';
import React, { memo } from 'react';

import AdminSweetheartListCompanyLink from './AdminSweetheartListCompanyLink';
import AdminSweetheartListCompanyNotifyButton from './AdminSweetheartListCompanyNotifyButton';
import AdminSweetheartListCompanyToggle from './AdminSweetheartListCompanyToggle';

const AdminSweetheartListCompany = ({
  company = {},
  toggleSweetheartList,
  notifySweetheartList,
  hasSweetheartList = false,
}) => {
  const { uid, isEnabled, isNotified } = company;

  return (
    <tr>
      <td className="px-4 py-2">
        <AdminSweetheartListCompanyLink company={company} />
      </td>
      <td className="px-4 py-2 max-w-xs">
        <div className="ml-auto flex flex-col w-5/6">
          <AdminSweetheartListCompanyToggle
            isEnabled={isEnabled}
            uid={uid}
            toggleSweetheartList={toggleSweetheartList}
            isDisabled={!hasSweetheartList}
          />
          {!hasSweetheartList && (
            <p className="mt-2 text-gray-400 text-xs">
              This company does not exist on Airtable or does not match the
              company slug.
            </p>
          )}
        </div>
      </td>
      <td className="px-4 py-2">
        <div className="m-auto w-fit">
          <AdminSweetheartListCompanyNotifyButton
            notifySweetheartList={notifySweetheartList}
            isNotified={isNotified}
            isDisabled={!hasSweetheartList}
          />
        </div>
      </td>
    </tr>
  );
};

AdminSweetheartListCompany.propTypes = {
  company: PropTypes.object.isRequired,
  hasSweetheartList: PropTypes.bool.isRequired,
  toggleSweetheartList: PropTypes.func.isRequired,
  notifySweetheartList: PropTypes.func.isRequired,
};

export default memo(AdminSweetheartListCompany, (prevProps, nextProps) => {
  const {
    company: { isNotified: prevIsNotified, isEnabled: prevIsEnabled },
    hasSweetheartList: prevHasSweetheartList,
  } = prevProps;
  const {
    company: { isNotified: nextIsNotified, isEnabled: nextIsEnabled },
    hasSweetheartList: nextHasSweetheartList,
  } = nextProps;

  return (
    prevIsNotified === nextIsNotified &&
    prevIsEnabled === nextIsEnabled &&
    prevHasSweetheartList === nextHasSweetheartList
  );
});
