import { VC_COMPANIES_PATH as path } from 'constants/paths';

import CompaniesPage from './CompaniesPage';

const CompanyRoute = {
  path,
  authRequired: true,
  component: CompaniesPage,
};

export default CompanyRoute;
