import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import subscriptionTiers from 'constants/subscriptionTiers';

const AdminCompaniesTierTabs = ({ activeTier = '', setActiveTier }) => (
  <div className="w-full border-b border-gray-200 mb-2 sm:mb-6">
    <nav
      className="-mb-px flex flex-col md:flex-row md:justify-around"
      aria-label="Tabs"
    >
      {subscriptionTiers.map((tier) => (
        <a
          key={tier.name}
          onClick={() => {
            setActiveTier(tier.name);
          }}
          className={classNames(
            activeTier === tier.name
              ? 'border-teal-500 text-teal-600'
              : 'border-transparent text-gray-400 hover:text-teal-500',
            'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
          )}
        >
          {tier.name.replace(' Package', '')}
        </a>
      ))}
    </nav>
  </div>
);
AdminCompaniesTierTabs.propTypes = {
  activeTier: PropTypes.string,
  setActiveTier: PropTypes.func,
};

export default AdminCompaniesTierTabs;
