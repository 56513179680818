import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import InputFieldTextPrefix from './InputFieldTextPrefix';

/**
 * Styled input field for text, should be used anywhere the user inputs one line of text
 * Can be used for email and password fields as well.
 */
const InputFieldText = React.forwardRef(
  (
    {
      name = '',
      type = 'text',
      defaultValue = '',
      placeholder = '',
      disabled = false,
      autoComplete = null,
      required = false,
      prefix = '',
      Suffix = null,
      ...rest
    },
    ref
  ) => (
    <div className="flex w-full mt-2 rounded-md shadow-sm">
      {prefix && <InputFieldTextPrefix prefix={prefix} />}
      <input
        id={name}
        ref={ref}
        name={name}
        type={type}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
        required={required}
        className={classNames(
          'px-3 py-2 flex-1 block w-full bg-white border-gray-200 focus:border-teal-500 focus:outline-none focus:ring-0 min-w-0 text-sm',
          {
            'rounded-md': !prefix && !Suffix,
            'rounded-r': prefix,
            'rounded-l': Suffix,
            'opacity-50': disabled,
          }
        )}
        {...rest}
      />
      {Suffix}
    </div>
  )
);

InputFieldText.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password', 'email']),
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  prefix: PropTypes.node,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  Suffix: PropTypes.node,
};

InputFieldText.displayName = 'InputFieldText';

export default InputFieldText;
