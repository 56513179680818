import PropTypes from 'prop-types';
import React from 'react';

import { Controller } from 'react-hook-form';

import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import { tagsTopics } from 'constants/tags';

const AdminCompaniesTopicFilter = ({ control }) => {
  const coreTopics = tagsTopics.filter((topic) => topic.core === true);

  return (
    <div className="pt-4 pb-2 w-full">
      <h3 className="text-gray-600 text-xs font-semibold uppercase mb-2">
        Filter by Core Topics
      </h3>
      <div className="w-full">
        <Controller
          name="topics"
          control={control}
          defaultValue={[]}
          render={({ onChange, value }) => (
            <InputFieldTagsAutocomplete
              customFieldName="topics"
              optionWidthClassName="w-full"
              placeholder="i.e. Fintech, Digital Transformation..."
              instructions={false}
              suggestions={coreTopics.filter((topic) => topic.name)}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
    </div>
  );
};

AdminCompaniesTopicFilter.propTypes = {
  control: PropTypes.object.isRequired,
};

export default AdminCompaniesTopicFilter;
