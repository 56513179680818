import PropTypes from 'prop-types';
import React from 'react';

import findIndex from 'lodash/findIndex';

import { tagsDownloads } from 'constants/tags';
import getTagsClassNames from 'utils/color/getTagsClassNames';

const CompanyDownloadTags = ({ tags = [], testId = '' }) => (
  <div className="flex flex-wrap items-center">
    {tags.map((tag) => {
      const tagIndex = findIndex(tagsDownloads, (t) => t.id === tag.id);
      const [bgColor, textColor] = getTagsClassNames(
        tagIndex,
        tag.name?.length
      );

      return (
        <span
          key={tag.name}
          className={`inline-flex mr-2 mb-2 p-2 py-1 text-xs rounded-md ${bgColor} ${textColor}`}
          data-test-id={`${testId}-${tag.name}`}
        >
          {tag.name}
        </span>
      );
    })}
  </div>
);

CompanyDownloadTags.propTypes = {
  tags: PropTypes.array,
  testId: PropTypes.string,
};

export default CompanyDownloadTags;
