import PropTypes from 'prop-types';
import React from 'react';

import { HeartIcon } from '@heroicons/react/outline';

import SweetheartListJournalist from './SweetheartListJournalist/SweetheartListJournalist';

// eslint-disable-next-line no-unused-vars
const SweetheartListJournalists = ({ journalists = [] }) => (
  <div className="py-6 px-4">
    <h1
      className="text-2xl font-semibold text-gray-600 mb-4 flex items-center"
      data-test-id="sweetheartList-title"
    >
      <HeartIcon className="w-6 h-6 mr-2" />
      Sweetheart List
    </h1>
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table
        className="min-w-full divide-y divide-gray-200"
        data-test-id="sweetheartList-tableView"
      >
        {/* Toggle to show/hide headings */}
        <thead className="bg-gray-50 text-xs uppercase text-gray-500">
          <tr className="text-left">
            <th scope="col" className="px-6 py-3 font-medium tracking-wider">
              Journalist
            </th>
            <th scope="col" className="px-6 py-3 font-medium text-center">
              Tier
            </th>
            <th scope="col" className="px-6 py-3 font-medium text-center">
              Domain Authority
            </th>
            <th scope="col" className="px-4 py-3 font-medium">
              Topics
            </th>
            <th scope="col" className="px-6 py-3 font-medium">
              Publication
            </th>
          </tr>
        </thead>
        <tbody className="text-sm text-gray-900">
          {/* map through journalists here */}

          {journalists.map((journalist, index) => (
            <SweetheartListJournalist
              key={journalist.id}
              journalist={journalist}
              index={index + 1}
            />
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

SweetheartListJournalists.propTypes = {
  journalists: PropTypes.array.isRequired,
};

export default SweetheartListJournalists;
