import { useFirestoreDocData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import useUserData from 'contexts/UserContext/hooks/useUserData';

const useCompanyDataByRef = (ref) => {
  const { uid, claimsVc, claimsAdmin } = useUserData();
  const { data, status } = useFirestoreDocData(ref, defaultOptions);

  if (status === 'loading') {
    return null;
  }

  const matchesOwner = data.owner === uid;
  const matchesVc =
    claimsVc && data?.vcs?.some((vc) => vc.slug && vc.slug === claimsVc);

  // user is owner, or company belongs to VC, or user is admin
  const isOwner = !!(matchesOwner || matchesVc || claimsAdmin);

  return { matchesOwner, isOwner, ...data };
};

export default useCompanyDataByRef;
