import { useEffect } from 'react';

import { useRouteMatch } from 'react-router-dom';

import {
  ANALYTICS_PATH,
  COMPANY_PATH,
  ONBOARDING_PATH,
  OPPORTUNITIES_PATH,
  SETTINGS_PATH,
  SWEETHEART_PATH,
} from 'constants/paths';
import getCompanyRoute from 'utils/getCompanyRoute';

// checks the current route and returns if we're on an onboarding route and if we should show the top nav
const useRouteMatchNav = (slug, setHomeRoute) => {
  const routeMatchOnboarding = useRouteMatch(ONBOARDING_PATH);
  const routeMatchCompany = useRouteMatch(COMPANY_PATH);
  const routeMatchOpportunities = useRouteMatch(OPPORTUNITIES_PATH);
  const routeMatchAnalytics = useRouteMatch(ANALYTICS_PATH);
  const routeMatchSettings = useRouteMatch(SETTINGS_PATH);
  const routeMatchSweetheartList = useRouteMatch(SWEETHEART_PATH);

  const isOnboardingRoute = routeMatchOnboarding;
  const showTopNav =
    slug &&
    (routeMatchCompany ||
      routeMatchOpportunities ||
      routeMatchAnalytics ||
      routeMatchSettings ||
      routeMatchSweetheartList);

  // Get the company slug for the home link
  useEffect(() => {
    if (slug) {
      setHomeRoute(getCompanyRoute(slug));
    }
  }, [setHomeRoute, slug]);

  return [isOnboardingRoute, showTopNav];
};

export default useRouteMatchNav;
