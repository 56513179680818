import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import { useAnalytics } from 'reactfire';

import { DEFAULT_ONBOARDING_PATH } from 'constants/paths';

const OnboardingLink = ({ signup = false, isAnonymous = false }) => {
  const analytics = useAnalytics();
  const onClick = () => {
    analytics.logEvent('create_company', {
      from: signup ? 'sign_up' : 'login',
    });
  };

  return (
    <div className="col-span-12 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <p className="text-sm font-medium text-gray-700 mb-2">
          Want to set up a company account on MVPR?
        </p>
        <Link
          onClick={onClick}
          to={DEFAULT_ONBOARDING_PATH}
          data-test-id="onboarding-link"
          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          {isAnonymous ? 'Continue creating company' : 'Create company now'}
        </Link>
      </div>
    </div>
  );
};

OnboardingLink.propTypes = {
  signup: PropTypes.bool,
  isAnonymous: PropTypes.bool,
};

export default OnboardingLink;
