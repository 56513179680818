import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import { OfficeBuildingIcon } from '@heroicons/react/solid';
import { useForm } from 'react-hook-form';

import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';

const CompanyMetaDescriptionEditModal = ({
  open,
  description,
  onClose,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
    watch,
  } = useForm({ mode: 'onChange' });

  const descriptionWatch = watch('description') || description;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative bg-white rounded-lg shadow-xl m-6 w-full max-w-xl">
            <div className="absolute top-4 right-4 z-10">
              <CloseButton onClick={onClose} />
            </div>
            <div className="px-6 py-6 pb-0">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                <OfficeBuildingIcon className="w-6" />
              </div>

              <div className="mt-3 text-center">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="dialog-1-title"
                >
                  {description ? 'Edit' : 'Add'} company information
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Explain what your company does in 2-3 sentences.
                  </p>
                </div>
              </div>

              <div className="mt-5 flex items-baseline justify-between">
                <Label htmlFor="date">Description*</Label>
                <p className="text-xs text-gray-400">
                  {descriptionWatch?.length ?? 0} characters
                </p>
              </div>

              <InputfieldTextArea
                name="description"
                ref={register({
                  required: 'Please put in a long description',
                })}
                defaultValue={description}
                placeholder="A longer description of your company (about 140 characters)..."
                data-test-id="company-meta-description-editModal-descriptionInput"
              />

              {errors.description && (
                <ErrorMessage testId="company-meta-description-editModal-descriptionInput-error">
                  {errors.description.message}
                </ErrorMessage>
              )}
            </div>
            <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
              <Button
                type="secondary"
                onClick={() => onClose()}
                data-test-id="company-meta-description-editModal-cancel"
              >
                Cancel
              </Button>
              <div className="ml-3">
                <Button
                  submit
                  disabled={!isValid}
                  eventType="pressPage_update"
                  eventProperties={{ action: 'companyProfile_add' }}
                  data-test-id="company-meta-description-editModal-update"
                >
                  {description ? 'Update' : 'Add'} description
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

CompanyMetaDescriptionEditModal.propTypes = {
  open: PropTypes.bool,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyMetaDescriptionEditModal;
