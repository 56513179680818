import PropTypes from 'prop-types';
import React from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import SocialLogin from 'components/SocialLogin';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { REGEX_EMAIL } from 'constants/regex';

const LoginForm = ({ onSubmit, signInWithGoogle }) => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    nativeValidation: false,
  });

  return (
    <div className="col-span-12 mt-16 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Label htmlFor="email">Email address</Label>
            <InputFieldText
              ref={register({
                pattern: {
                  value: REGEX_EMAIL,
                  message: 'Please provide a valid email address',
                },
              })}
              type="email"
              name="email"
              autoComplete="email"
              required
              data-test-id="loginForm-email"
            />
            {errors.email && (
              <ErrorMessage testId="loginForm-email-error">
                {errors.email.message}
              </ErrorMessage>
            )}
          </div>

          <div>
            <Label htmlFor="password">Password</Label>
            <InputFieldText
              ref={register({
                required: 'Please provide a password',
              })}
              name="password"
              type="password"
              autoComplete="current-password"
              required
              data-test-id="loginForm-password"
            />
            {errors.password && (
              <ErrorMessage testId="loginForm-password-error">
                {errors.password.message}
              </ErrorMessage>
            )}
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link
                to="/resetPassword"
                className="font-medium text-teal-500 hover:text-teal-500"
                data-test-id="loginForm-forgotPassword"
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <div>
            <Button
              size="large"
              submit
              full
              disabled={isSubmitting || !isValid}
              eventType="login"
              eventProperties={{ method: 'email', form: 'page' }}
              data-test-id="loginForm-submit"
            >
              {isSubmitting ? 'Loading' : 'Login'}
            </Button>
          </div>
        </form>

        <SocialLogin signInWithGoogle={signInWithGoogle} />
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  signInWithGoogle: PropTypes.func.isRequired,
};

export default LoginForm;
