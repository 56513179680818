import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { TrashIcon } from '@heroicons/react/solid';
import { useAnalytics } from 'reactfire';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';

import CompanyPressReleaseDeleteModal from '../CompanyPressReleaseDeleteModal';
import CompanyPressReleaseEditModal from '../CompanyPressReleaseEditModal';
import CompanyPressReleaseEmbargoDate from './CompanyPressReleaseEmbargoDate';
import CompanyPressReleaseFileIcons from './CompanyPressReleaseFileIcons';
import CompanyPressReleaseWrapper from './CompanyPressReleaseWrapper';

const CompanyPressRelease = ({
  pressRelease,
  ops = {},
  onView,
  showOwnerView,
  companyId,
}) => {
  const analytics = useAnalytics();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onSubmit = (data = {}) => {
    const { title, description, filePdf, fileDoc, image } = data;
    const date = createTimestampFromDateObj(data.date);
    ops.updateDoc(pressRelease.uid, {
      title,
      description,
      filePdf,
      fileDoc,
      image,
      date,
    });
    setIsEditModalOpen(false);
  };

  const onDelete = () => {
    ops.deleteDoc(pressRelease.uid);
    setIsDeleteModalOpen(false);
  };

  const onClick = (e) => {
    e.preventDefault();
    analytics.logEvent('pressPage_view', {
      action: 'pressRelease_view',
      title: pressRelease.title,
    });

    onView(pressRelease.uid);
  };

  return (
    <>
      <CompanyPressReleaseWrapper
        onClick={onClick}
        showOwnerView={showOwnerView}
      >
        {showOwnerView && (
          <div className="absolute top-4 right-4">
            <DotsMenu
              theme="medium"
              options={[
                {
                  label: `Edit press release`,
                  onClick: () => setIsEditModalOpen(true),
                  testId: `company-pressRelease-${pressRelease.title}-menu-edit`,
                },
                {
                  label: `Delete press release`,
                  icon: <TrashIcon className="w-4 h-4" />,
                  onClick: () => setIsDeleteModalOpen(true),
                  testId: `company-pressRelease-${pressRelease.title}-menu-delete`,
                },
              ]}
              testId={`company-pressRelease-${pressRelease.title}-menu`}
            />
          </div>
        )}

        <CompanyPressReleaseEmbargoDate
          date={pressRelease.date}
          testId={`company-pressRelease-${pressRelease.title}-embargo`}
        />

        <div>
          <p
            className="mt-2 text-xl font-medium leading-normal line-clamp-2"
            data-test-id={`company-pressRelease-${pressRelease.title}-title`}
          >
            {pressRelease.title}
          </p>

          <p
            className="flex-1 mt-2 text-sm text-gray-400 leading-normal line-clamp-5"
            data-test-id={`company-pressRelease-${pressRelease.title}-description`}
          >
            {pressRelease.description}
          </p>
        </div>

        {(pressRelease.filePdf ||
          pressRelease.fileDoc ||
          pressRelease.image) && (
          <div className="w-full flex flex-row justify-between items-center mt-4">
            <CompanyPressReleaseFileIcons
              title={pressRelease.title}
              filePdf={pressRelease.filePdf}
              fileDoc={pressRelease.fileDoc}
              image={pressRelease.image}
            />
          </div>
        )}
      </CompanyPressReleaseWrapper>

      {showOwnerView && (
        <CompanyPressReleaseEditModal
          open={isEditModalOpen}
          pressRelease={pressRelease}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onSubmit}
          uploadFolder={`companies/${companyId}/articles`}
        />
      )}

      {showOwnerView && isDeleteModalOpen && (
        <CompanyPressReleaseDeleteModal
          title={pressRelease.title}
          onDelete={onDelete}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}
    </>
  );
};

CompanyPressRelease.propTypes = {
  pressRelease: PropTypes.shape({
    uid: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    filePdf: PropTypes.object,
    fileDoc: PropTypes.object,
    image: PropTypes.object,
  }).isRequired,
  ops: PropTypes.object,
  onView: PropTypes.func.isRequired,
  showOwnerView: PropTypes.bool,
  companyId: PropTypes.string.isRequired,
};

export default CompanyPressRelease;
