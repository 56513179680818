import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import { DownloadIcon, DocumentIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';

import Spinner from 'components/Spinner';
import UniversalImage from 'components/UniversalImage/UniversalImage';
import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import TextCounter from 'components/form/TextCounter';
import InputFieldFounders from 'components/form/inputFields/InputFieldFounders/InputFieldFounders';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import { tagsDownloads } from 'constants/tags';
import { presetFile, presetImage } from 'constants/uploadPresets';

const CompanyDownloadsEditModal = ({
  download = null,
  open,
  onClose,
  onSubmit,
  founders = [],
  uploadFolder,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    formState: { isValid },
    watch,
  } = useForm({ mode: 'onChange' });

  const defaultValues = download || {};
  const title = watch('title');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
            <div className="absolute top-4 right-4 z-10">
              <CloseButton onClick={onClose} />
            </div>

            <div className="px-6 py-6 pb-0">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                <DownloadIcon className="w-6" />
              </div>
              <div className="mt-3 text-center">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="dialog-1-title"
                >
                  {download ? 'Edit' : 'Add a'} download
                </h3>
                {!download && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Journalists are more likely to cover your company if they
                      can also publish images or other assets that accompany
                      your content.
                    </p>
                  </div>
                )}
              </div>

              <div className="flex justify-between items-baseline mt-5">
                <Label htmlFor="title">Title*</Label>
                <TextCounter str={title || defaultValues.title} />
              </div>

              <div className="mt-2">
                <InputFieldText
                  name="title"
                  defaultValue={defaultValues.title}
                  ref={register({
                    required: 'Please put in a title',
                    maxLength: {
                      value: 140,
                      message: 'Please keep the title below 140 characters',
                    },
                  })}
                  placeholder="Type in what this download should be called..."
                  data-test-id="company-downloads-editModal-titleInput"
                />
              </div>

              {errors.title && (
                <ErrorMessage testId="company-downloads-editModal-titleInput-error">
                  {errors.title.message}
                </ErrorMessage>
              )}

              <div className="flex w-full justify-between mt-3">
                <Label htmlFor="link">Downloadable file</Label>
              </div>

              <div className="mt-2">
                <Controller
                  rules={{ required: 'Please upload a file' }}
                  name="file"
                  control={control}
                  defaultValue={defaultValues.file || null}
                  render={({ onChange, value }) => (
                    <InputFieldUpload
                      name="file"
                      folder={uploadFolder}
                      onChange={onChange}
                      value={value}
                      setError={setError}
                      preset={presetFile}
                      heightClassName="h-full"
                      emptyClassName="p-4 py-6"
                      testId="company-downloads-editModal-fileInput"
                      renderFile={(file, onChangeFile) => {
                        const isImage = presetImage.allowedFormats.includes(
                          download?.file?.type
                        );

                        return (
                          <div className="w-full flex flex-col">
                            <div className="rounded-lg overflow-hidden">
                              <div className="relative flex h-28 bg-gray-100 justify-center items-center text-teal-500">
                                {file.isUploading && <Spinner />}
                                {!file.isUploading && !isImage && (
                                  <DocumentIcon
                                    data-test-id="company-downloads-editModal-fileInput-icon"
                                    className="h-1/2"
                                  />
                                )}
                                {!file.isUploading && isImage && (
                                  <UniversalImage
                                    image={file}
                                    heightClass="h-24"
                                    objectFitClass="object-cover"
                                    alt="Preview of uploaded file"
                                  />
                                )}
                              </div>
                              <div className="bg-gray-50 p-3">
                                <p className="text-sm font-medium text-gray-700 truncate">
                                  {file.name}
                                </p>
                                <p className="text-sm text-gray-400">
                                  {Math.floor(file.size / 1024)}kB
                                </p>
                              </div>
                            </div>
                            <div className="absolute -top-3 -right-2">
                              <CloseButton onClick={() => onChangeFile(null)} />
                            </div>
                          </div>
                        );
                      }}
                    />
                  )}
                />
              </div>

              {errors.file && (
                <ErrorMessage testId="company-downloads-editModal-fileInput-error">
                  {errors.file.message}
                </ErrorMessage>
              )}

              <div className="flex w-full justify-between mt-3">
                <Label htmlFor="users">Related founders</Label>
              </div>

              <div className="mt-2">
                <Controller
                  name="founder"
                  control={control}
                  defaultValue={
                    defaultValues.founder || defaultValues?.users?.[0] || null
                  }
                  render={({ onChange, value }) => (
                    <InputFieldFounders
                      founders={founders}
                      onChange={onChange}
                      value={value}
                      testId="company-downloads-editModal-founderInput"
                    />
                  )}
                />
              </div>

              {errors.users && (
                <ErrorMessage>{errors.users.message}</ErrorMessage>
              )}

              <div className="mt-3">
                <Label htmlFor="tags">Tags</Label>
              </div>

              <div
                className="mt-2"
                data-test-id="company-downloads-editModal-tagsInput"
              >
                <Controller
                  name="tags"
                  control={control}
                  defaultValue={defaultValues.tags || []}
                  render={({ onChange, value }) => (
                    <InputFieldTagsAutocomplete
                      customFieldName="tag"
                      suggestions={tagsDownloads}
                      onChange={onChange}
                      value={value}
                      tagsIndex={tagsDownloads}
                    />
                  )}
                />
              </div>
            </div>

            <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
              <Button
                type="secondary"
                onClick={() => onClose()}
                data-test-id="company-downloads-editModal-cancel"
              >
                Cancel
              </Button>
              <div className="ml-3">
                <Button
                  submit
                  disabled={!isValid}
                  eventType="pressPage_update"
                  eventProperties={{ action: 'download_add' }}
                  data-test-id="company-downloads-editModal-submit"
                >
                  {download ? 'Update' : 'Add'} download
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

CompanyDownloadsEditModal.propTypes = {
  download: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  founders: PropTypes.array,
  uploadFolder: PropTypes.string.isRequired,
};

export default CompanyDownloadsEditModal;
