import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useAuth } from 'reactfire';

import {
  VC_COMPANIES_PATH,
  LOGIN_PATH,
  DEFAULT_ONBOARDING_PATH,
  ADMIN_PATH,
} from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useNotifications from 'modules/notification/useNotifications';
import getCompanyRoute from 'utils/getCompanyRoute';

// redirect hook for auth pages (login, signupVc), redirecting the user to the correct path on load
const useRedirectAuth = (isVcSignup = false) => {
  const auth = useAuth();
  const { isAnonymous, isAuthenticating, uid, claimsVc, claimsAdmin } =
    useUserData();
  const { companyData } = useCompanyData();
  const slug = companyData?.slug;
  const hasCompany = companyData && slug;

  const history = useHistory();
  const { showError } = useNotifications();

  const isLoggedIn = !isAnonymous && uid;
  const showAuth = !isLoggedIn;

  useEffect(() => {
    if (isAuthenticating) {
      return false;
    }

    if (claimsVc) {
      history.push(VC_COMPANIES_PATH);
      return false;
    }

    if (claimsAdmin) {
      history.push(ADMIN_PATH);
      return false;
    }

    if (isVcSignup) {
      return false;
    }

    // if user has company, redirect there
    if (isLoggedIn && slug) {
      history.push(getCompanyRoute(slug));
      return false;
    }

    const isGoogleProvider = auth?.currentUser?.providerData.some(
      (p) => p?.providerId === 'google.com'
    );

    // user is logged in via Google, but doesn't have company yet...
    if (isLoggedIn && isGoogleProvider && !hasCompany) {
      // ...delete user, then send back to login path and show error message
      auth.currentUser.delete().then(() => {
        history.push(LOGIN_PATH);
        showError('Please create a company before logging in.');
      });
      return false;
    }

    if (isLoggedIn && !hasCompany) {
      history.push(DEFAULT_ONBOARDING_PATH);
      return false;
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showAuth;
};

export default useRedirectAuth;
