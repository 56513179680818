import PropTypes from 'prop-types';
import React from 'react';

import { EyeOffIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import findIndex from 'lodash/findIndex';

import { tagsTopics } from 'constants/tags';
import getTagsClassNames from 'utils/color/getTagsClassNames';

const CompanyQuoteTags = ({ tags = [], isPrivate = false, testId = '' }) => (
  <div className="pr-4 flex flex-wrap items-center">
    {tags.map((tag) => (
      <span
        key={tag.name}
        className={classNames(
          'inline-flex mr-2 mb-2 p-2 py-1 text-xs rounded-full',
          getTagsClassNames(
            findIndex(tagsTopics, (t) => t.id === tag.id),
            tag.name?.length
          )
        )}
        data-test-id={`${testId}-${tag.name}`}
      >
        {tag.name}
      </span>
    ))}

    {isPrivate && (
      <span className="p-0 py-1.5 mr-2 mb-2" data-test-id={`${testId}-private`}>
        <EyeOffIcon className="w-4 h-4 text-gray-500" />
      </span>
    )}
  </div>
);

CompanyQuoteTags.propTypes = {
  tags: PropTypes.array,
  isPrivate: PropTypes.bool,
  testId: PropTypes.string,
};

export default CompanyQuoteTags;
