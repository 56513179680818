import PropTypes from 'prop-types';
import React from 'react';

import { findIndex } from 'lodash';
import { useHistory } from 'react-router-dom';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import SectionHeadline from 'components/company/SectionHeadline';
import { SETTINGS_COMPANY_PATH } from 'constants/paths';
import { tagsTopics } from 'constants/tags';
import getTagsClassNames from 'utils/color/getTagsClassNames';

const CompanyMetaTopics = ({ topics, showOwnerView }) => {
  const history = useHistory();

  return (
    <div className="mt-5 pt-5 pb-5 pr-6 border-t border-b border-gray-200">
      <div className="flex justify-between items-center">
        <SectionHeadline>Topics</SectionHeadline>
        {showOwnerView && (
          <DotsMenu
            options={[
              {
                label: 'Edit company targeting',
                onClick: () =>
                  history.push({
                    pathname: SETTINGS_COMPANY_PATH,
                    hash: 'targeting',
                  }),
                testId: 'company-meta-topics-menu-edit',
              },
            ]}
            theme="medium"
            testId="company-meta-topics-menu"
          />
        )}
      </div>
      <div className="mt-5">
        {topics.length ? (
          topics.map((topic) => {
            const tagIndex = findIndex(tagsTopics, (t) => t.id === topic.id);
            const [bgColor, textColor] = getTagsClassNames(
              tagIndex,
              topic.name?.length
            );

            return (
              <span
                key={topic.id}
                className={`inline-block mb-2 mr-2 p-2 py-1 text-xs rounded-md ${bgColor} ${textColor}`}
              >
                {topic.name}
              </span>
            );
          })
        ) : (
          <p className="text-gray-300 text-sm">No topics added yet</p>
        )}
      </div>
    </div>
  );
};

CompanyMetaTopics.propTypes = {
  topics: PropTypes.array,
  showOwnerView: PropTypes.bool,
};

export default CompanyMetaTopics;
