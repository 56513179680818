// returns several useful parameters based on a UTC deadline
const diff1Hour = 60 * 60 * 1000;

const getDeadline = (deadlineUTC = null) => {
  const deadlineUnits = ['d', 'h'];

  // assign 0 by default just to make sure this never crashes the app
  const deadline = deadlineUTC?.toDate()?.getTime() ?? 0;
  const deadlineDiff = deadline - Date.now();

  if (deadlineDiff < diff1Hour) {
    deadlineUnits.push('m');
  }

  return [deadlineDiff, deadlineUnits];
};

export default getDeadline;
