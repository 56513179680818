import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import StepDivider from './StepDivider';

const StepCompleted = ({ number, title, name }) => (
  <li className="flex align-center items-center">
    <Link
      to={name}
      className="text-sm font-medium text-gray-500"
      data-test-id={`onboarding-step-${name}`}
    >
      {title}
    </Link>

    <StepDivider number={number} />
  </li>
);
// add background color

StepCompleted.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
};

export default StepCompleted;
