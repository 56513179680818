import PropTypes from 'prop-types';
import React, { createContext } from 'react';

import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';

const defaultCompanyData = {
  companyData: null,
  isOwner: false,
};

export const CompanyContext = createContext(defaultCompanyData);
const { Provider } = CompanyContext;

const CompanyProvider = ({ uid = null, children }) => {
  const firestore = useFirestore();
  const query = firestore
    .collection(COMPANIES_COLLECTION)
    .where('owner', '==', uid);
  const { status, data } = useFirestoreCollectionData(query, defaultOptions);

  const companyData = data[0];
  const isOwner = companyData?.owner === uid;

  if (status === 'loading') {
    return null;
  }

  return <Provider value={{ companyData, isOwner }}>{children}</Provider>;
};

CompanyProvider.propTypes = {
  uid: PropTypes.string,
  children: PropTypes.node,
};

export default CompanyProvider;
