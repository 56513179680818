import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useStateMachine } from 'little-state-machine';
import {
  preloadAnalytics,
  preloadAuth,
  preloadFirestore,
  preloadFunctions,
  preloadStorage,
  preloadRemoteConfig,
} from 'reactfire';

import {
  AUTH_EMULATOR_HOST,
  FIRESTORE_EMULATOR_PORT,
  FUNCTIONS_EMULATOR_PORT,
  LOCALHOST,
  STORAGE_EMULATOR_PORT,
} from 'constants/emulatorConfig';
import useEnvironment from 'contexts/EnvironmentContext/hooks/useEnvironment';

/**
 * Preloads all of the Firebase modules we're using.
 * Makes them talk to the emulator if `isEmulated` is true
 */
const PreloadFirebase = ({ children = null }) => {
  const { isEmulated, firebaseApp } = useEnvironment();
  const [preloadComplete, setPreloadComplete] = useState(!isEmulated);

  const { state } = useStateMachine();

  useEffect(() => {
    (async () => {
      // set analytics collection to value in local storage (`null` by default to comply with GDPR)
      const analytics = await preloadAnalytics({
        firebaseApp,
      });
      analytics().setAnalyticsCollectionEnabled(!!state.cookiesEnabled);

      // remote config is only useful in non-emulated environments
      preloadRemoteConfig({
        firebaseApp,
        setup: (factory) => {
          const remoteConfig = factory();
          remoteConfig.settings = {
            minimumFetchIntervalMillis: 10000,
            fetchTimeoutMillis: 10000,
          };
          return remoteConfig.fetchAndActivate();
        },
      });

      await preloadAuth({
        firebaseApp,
        setup: async (factory) => {
          const auth = factory();
          if (isEmulated) {
            auth.useEmulator(AUTH_EMULATOR_HOST);
          }
          return auth;
        },
      });

      await preloadFirestore({
        firebaseApp,
        setup: (factory) => {
          const firestore = factory();
          if (isEmulated) {
            // for Cypress tests, we have to enable long polling
            firestore.settings({
              experimentalForceLongPolling: true,
            });
            firestore.useEmulator(LOCALHOST, FIRESTORE_EMULATOR_PORT);
          }
          return firestore;
        },
      });

      await preloadFunctions({
        firebaseApp,
        setup: (factory) => {
          const functions = factory();

          if (isEmulated) {
            functions.useEmulator(LOCALHOST, FUNCTIONS_EMULATOR_PORT);
          }

          return functions;
        },
      });

      await preloadStorage({
        firebaseApp,
        setup: (factory) => {
          const storage = factory();

          if (isEmulated) {
            storage.useEmulator(LOCALHOST, STORAGE_EMULATOR_PORT);
          }
        },
      });

      setPreloadComplete(true);
    })();
  }, [isEmulated, firebaseApp, state.cookiesEnabled]);

  if (!preloadComplete) {
    return null;
  }

  return children;
};

PreloadFirebase.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PreloadFirebase;
