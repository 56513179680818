import PropTypes from 'prop-types';
import React from 'react';

import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import noop from 'lodash/lodash';

import OpportunitiesFilterOption from './OpportunitiesFilterOption';
import getFilterByText from './helpers/getFilterByText';

// Opportunities filter dropdown: allows filtering opportunities by tier
const OpportunitiesFilter = ({
  filterOptions = [],
  filter = [],
  setFilter = noop,
  wrapperClassName = 'hidden lg:block',
  buttonWidthClassName = 'w-44',
}) => (
  <div className={classNames('relative', wrapperClassName)}>
    <Menu>
      <Menu.Button
        className={classNames(
          'flex justify-between text-teal-500 px-3 py-2 border border-gray-300 rounded-md text-sm cursor-pointer focus:outline-none',
          buttonWidthClassName
        )}
      >
        {!filter.length && <span className="text-gray-700">Filter by</span>}
        {getFilterByText(filter)}
        <ChevronDownIcon className="w-4 ml-2" />
      </Menu.Button>

      <Menu.Items
        className="origin-top-right absolute top-11 left-0 z-20 rounded-md shadow-lg py-1 bg-white focus:outline-none ring-1 ring-gray-200"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
      >
        {filterOptions.map((option) => {
          const isSelected = !!filter.find((f) => f.index === option.index);
          return (
            <OpportunitiesFilterOption
              key={option.index}
              option={option}
              isSelected={isSelected}
              onClick={() =>
                setFilter((current) =>
                  isSelected
                    ? current.filter((f) => f.index !== option.index)
                    : [...current, option]
                )
              }
            />
          );
        })}
      </Menu.Items>
    </Menu>
  </div>
);

OpportunitiesFilter.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  filter: PropTypes.array.isRequired,
  setFilter: PropTypes.func.isRequired,
  wrapperClassName: PropTypes.string,
  buttonWidthClassName: PropTypes.string,
};

export default OpportunitiesFilter;
