import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';

// retrive all the companies in the companies collection
const useCompanies = () => {
  const firestore = useFirestore();

  const query = firestore
    .collection(COMPANIES_COLLECTION)
    .where('slug', '!=', null);

  const companies = useFirestoreCollectionData(query, defaultOptions);

  if (!companies?.data) {
    return null;
  }

  return companies.data.map(
    ({ slug, name, topics, logo, owner, vcs, vcsOther, uid }) => ({
      uid,
      slug,
      name,
      topics,
      logo,
      owner,
      vcs,
      vcsOther,
    })
  );
};

export default useCompanies;
