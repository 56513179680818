const tealShades = [
  '#0F766E',
  '#0D9488',
  '#14B8A6',
  '#2DD4BF',
  '#5EEAD4',
  '#99F6E4',
  '#CCFBF1',
];

// get all teal shades as an array
const getTealShades = () => tealShades;
export default getTealShades;
