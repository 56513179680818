import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import { LightBulbIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';

import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import TextCounter from 'components/form/TextCounter';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import { REGEX_URL } from 'constants/regex';
import createOnChangeIsFutureDate from 'utils/date/createOnChangeIsFutureDate';
import { getDateRange, getDateRangeDefault } from 'utils/date/dateRange';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

import CompanySnapshotEditModalEmoji from './CompanySnapshotEditModalEmoji';
import emojis from './emojis';

const CompanySnapshotEditModal = ({
  open,
  snapshot = null,
  onClose,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    control,
    errors,
    formState: { isValid },
    watch,
    getValues,
    clearErrors,
    setError,
  } = useForm({ mode: 'onChange' });

  const title = watch('title');
  const onChangeDate = createOnChangeIsFutureDate({
    date: getValues('date'),
    onError: () =>
      setError('date', { message: 'Date cannot be in the future' }),
    onSuccess: () => clearErrors(),
  });

  const defaultDate = snapshot?.date
    ? getDateObjFromTimestamp(snapshot.date)
    : {};
  const defaultValues = snapshot || {};

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
            <div className="absolute top-4 right-4 z-10">
              <CloseButton onClick={onClose} />
            </div>

            <div className="px-6 py-6 pb-0">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                <LightBulbIcon className="w-6" />
              </div>

              <div className="mt-3 text-center">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="dialog-1-title"
                >
                  {snapshot ? 'Edit' : 'Add a'} milestone
                </h3>
                {!snapshot && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Add a milestone to your profile to show journalists you
                      are a credible company.
                    </p>
                  </div>
                )}
              </div>

              <div className="flex w-full justify-between items-baseline mt-5">
                <Label htmlFor="title">Choose an emoji*</Label>
              </div>

              <div className="flex flex-wrap justify-start mt-2 -mb-1">
                <Controller
                  name="emoji"
                  defaultValue={defaultValues?.emoji || emojis[0].char}
                  control={control}
                  render={({ onChange, value }) =>
                    emojis.map((emoji, idx) => (
                      <CompanySnapshotEditModalEmoji
                        key={emoji.char}
                        emoji={emoji}
                        onClick={(e) => {
                          e.preventDefault();
                          onChange(emoji.char);
                        }}
                        isSelected={value === emoji.char}
                        isLast={idx === emojis.length - 1}
                      />
                    ))
                  }
                />
              </div>

              <div className="flex w-full justify-between items-baseline mt-3">
                <Label htmlFor="title">Describe the milestone*</Label>
                <TextCounter
                  str={title || defaultValues.title}
                  maxLength={140}
                />
              </div>

              <InputfieldTextArea
                defaultValue={defaultValues.title || null}
                ref={register({
                  required: 'Please put in a title for this milestone',
                  maxLength: {
                    value: 140,
                    message: 'Please keep the title below 140 characters',
                  },
                })}
                name="title"
                placeholder="Share a milestone"
                data-test-id="company-snapshots-editModal-titleInput"
              />
              {errors.title && (
                <ErrorMessage testId="company-snapshots-editModal-titleInput-error">
                  {errors.title.message}
                </ErrorMessage>
              )}

              <fieldset className="w-full flex flex-col items-start mt-3">
                <Label htmlFor="date">When did it happen?</Label>

                <div className="flex mt-2">
                  <Controller
                    name="date.day"
                    defaultValue={
                      defaultDate.day || getDateRangeDefault('days')
                    }
                    control={control}
                    render={({ onChange, value }) => (
                      <InputFieldDropdown
                        options={getDateRange('days')}
                        value={value}
                        onChange={onChangeDate(onChange)}
                        optionWidthClassName="w-16 sm:w-20"
                        wrapperClassName="mr-2"
                        testId="company-snapshots-editModal-day"
                      />
                    )}
                  />

                  <Controller
                    name="date.month"
                    defaultValue={
                      defaultDate.month || getDateRangeDefault('months')
                    }
                    control={control}
                    render={({ onChange, value }) => (
                      <InputFieldDropdown
                        options={getDateRange('months')}
                        value={value}
                        onChange={onChangeDate(onChange)}
                        optionWidthClassName="w-36"
                        wrapperClassName="mr-2"
                        testId="company-snapshots-editModal-month"
                      />
                    )}
                  />

                  <Controller
                    name="date.year"
                    defaultValue={
                      defaultDate.year || getDateRangeDefault('years')
                    }
                    control={control}
                    render={({ onChange, value }) => (
                      <InputFieldDropdown
                        options={getDateRange('years')}
                        value={value}
                        onChange={onChangeDate(onChange)}
                        optionWidthClassName="w-20 sm:w-28"
                        testId="company-snapshots-editModal-year"
                      />
                    )}
                  />
                </div>
                {errors.date && (
                  <ErrorMessage>{errors.date.message}</ErrorMessage>
                )}
              </fieldset>

              <div className="w-full flex justify-between mt-3 text-left text-sm font-medium">
                <Label htmlFor="url">
                  Link to existing coverage about this milestone
                </Label>
                <p className="text-gray-400">Optional</p>
              </div>
              <InputFieldText
                defaultValue={defaultValues.url || null}
                ref={register({
                  pattern: {
                    value: REGEX_URL,
                    message: 'Please type in a valid URL',
                  },
                })}
                name="url"
                placeholder="https://"
                data-test-id="company-snapshots-editModal-urlInput"
              />
              {errors.url && (
                <ErrorMessage testId="company-snapshots-editModal-urlInput-error">
                  {errors.url.message}
                </ErrorMessage>
              )}
            </div>

            <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
              <Button
                type="secondary"
                onClick={() => onClose()}
                data-test-id="company-snapshots-editModal-cancel"
              >
                Cancel
              </Button>
              <div className="ml-3">
                <Button
                  submit
                  disabled={!isValid}
                  eventType="pressPage_update"
                  eventProperties={{ action: 'achievement_add' }}
                  data-test-id="company-snapshots-editModal-submit"
                >
                  {snapshot ? 'Update' : 'Add'} milestone
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ReactTooltip />
    </Dialog>
  );
};

CompanySnapshotEditModal.propTypes = {
  open: PropTypes.bool,
  snapshot: PropTypes.shape({
    emoji: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanySnapshotEditModal;
