import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import SnackbarProviderButton from './SnackbarProviderButton';

export const NotificationsContext = React.createContext({
  showSuccess: () => {},
  showError: () => {},
  showWarning: () => {},
});

const NotificationsProvider = ({ children }) => {
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const useStyles = makeStyles(() => ({
    variantSuccess: {
      backgroundColor: '#14B8A6 !important',
    },
    variantError: {
      backgroundColor: '#DC2626 !important',
    },
    variantWarning: {
      backgroundColor: '#F59E0B, !important',
    },
  }));

  const classes = useStyles();

  const contextValue = useMemo(
    () => ({
      showSuccess: (message) =>
        notistackRef.current.enqueueSnackbar(message, { variant: 'success' }),

      showError: (message) =>
        notistackRef.current.enqueueSnackbar(message, { variant: 'error' }),
      showWarning: (message) =>
        notistackRef.current.enqueueSnackbar(message, { variant: 'warning' }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <NotificationsContext.Provider value={contextValue}>
      <SnackbarProvider
        ref={notistackRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={classes}
        hideIconVariant
        action={(key) => (
          <SnackbarProviderButton onClick={() => onClickDismiss(key)} />
        )}
      >
        {children}
      </SnackbarProvider>
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NotificationsProvider;
