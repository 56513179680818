import { useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'reactfire';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import getPageTitle from './helpers/getPageTitle';

/**
 * Logs the current page by sending a `page_view` event to Analytics
 * Generates the current page title using a helper
 */
const AnalyticsPageLogger = () => {
  const { state } = useStateMachine();

  const analytics = useAnalytics();
  const location = useLocation();
  const user = useUserData();
  const company = useCompanyData();

  const companySlug = company?.companyData?.slug ?? 'undefined';

  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `pathname` changes
  useEffect(() => {
    if (!state.cookiesEnabled) {
      return;
    }

    analytics.logEvent('page_view', {
      path_name: location.pathname,
      // we're overriding the automatically generated 'page_title' here, see above why
      page_title: getPageTitle(location.pathname, companySlug),
    });
  }, [location.pathname, state.cookiesEnabled, analytics, companySlug]);

  if (!state.cookiesEnabled) {
    return null;
  }

  // track user properties
  const uid = user?.uid ?? 'undefined';
  const isAnonymous = user?.isAnonymous ?? 'undefined';

  analytics.setUserId(uid);
  analytics.setUserProperties({
    anonymous: isAnonymous,
    companySlug,
  });

  return null;
};

export default AnalyticsPageLogger;
