import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useAuth } from 'reactfire';

import { VC_COMPANIES_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import getCompanyRoute from 'utils/getCompanyRoute';

// redirect hook for onboarding pages, redirecting the user to the correct path on load
const useRedirectOnboarding = () => {
  const auth = useAuth();
  const history = useHistory();
  const { isAnonymous, uid, claimsVc } = useUserData();
  const { companyData } = useCompanyData();
  const slug = companyData?.slug;

  useEffect(() => {
    if (claimsVc) {
      history.push(VC_COMPANIES_PATH);
      return;
    }

    // if user has company, redirect there
    if (slug) {
      history.push(getCompanyRoute(slug));
      return;
    }

    // sign in anonymously if no UID assigned yet
    if (!isAnonymous && !uid) {
      auth.signInAnonymously();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showOnboarding = !slug;
  const showLoginButton = isAnonymous || !uid;

  return [showOnboarding, showLoginButton];
};

export default useRedirectOnboarding;
