import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { noop } from 'lodash/noop';

import { tagsTopics } from 'constants/tags';
import getTagsClassNames from 'utils/color/getTagsClassNames';

const AdminOpportunityTag = ({ topic = {}, onClick = noop }) => {
  const index = tagsTopics.findIndex((t) => topic.name === t.name);
  const length = topic.name?.length;
  const isCore = tagsTopics.find((t) => t.id === topic.id)?.core;
  const isUncategorised = topic.id === 1199;

  return (
    <span
      onClick={() => isCore && onClick()}
      key={topic.name}
      className={classNames(
        'float-left mr-2 mb-2 p-2 py-1 text-xs rounded-md whitespace-nowrap',
        !isUncategorised && getTagsClassNames(index, length),
        {
          'bg-black text-white': isUncategorised,
          'cursor-pointer': isCore,
          'opacity-50': !isCore,
        }
      )}
    >
      {topic.name}
    </span>
  );
};

AdminOpportunityTag.propTypes = {
  topic: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

export default AdminOpportunityTag;
