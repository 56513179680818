import PropTypes from 'prop-types';
import React from 'react';

const EmptyState = ({ currentField }) => (
  <div className="flex w-full min-h-screen justify-center items-center p-8 -mt-32 text-center text-gray-500">
    <p className="max-w-prose">
      {currentField === 'upcoming' && (
        <>
          There aren’t any live opportunities for your selection of topics at
          the moment,
          <br className="hidden lg:block" />
          but check back in a few hours to see if any more come in. We receive
          requests throughout the day. ⏳{' '}
        </>
      )}
      {currentField === 'responded' &&
        'You haven’t submitted any opportunities yet, respond to a request that you can speak credibly about to give yourself a chance of getting coverage in the press. 🚀'}
      {currentField === 'saved' &&
        'Opportunities that you have clicked to “Save for later” will appear in here. Use this when you don’t have time to respond, but want to keep it on your radar! 🔖'}
      {currentField === 'declined' &&
        'You haven’t declined any opportunities yet! If you think something isn’t right for you, hit decline and we’ll learn from your actions to improve what opportunities we share with you. ✨'}
    </p>
  </div>
);

EmptyState.propTypes = {
  currentField: PropTypes.string.isRequired,
};

export default EmptyState;
