import { useUser, useFirestore, useFirestoreDocData } from 'reactfire';

import { USERS_COLLECTION } from 'constants/firebasePaths';

// returns the user name of the currently logged in user
const useUserName = () => {
  const firestore = useFirestore();
  const { data: user } = useUser();

  const userRef = firestore.doc(`${USERS_COLLECTION}/${user?.uid}`);
  const { data: userDoc } = useFirestoreDocData(userRef);

  if (!userDoc) {
    return 'No username';
  }

  if (userDoc.firstName && userDoc.lastName) {
    return `${userDoc.firstName} ${userDoc.lastName}`;
  }

  if (userDoc.firstName) {
    return `${userDoc.firstName}`;
  }

  if (userDoc.lastName) {
    return `${userDoc.lastName}`;
  }

  return 'No username';
};

export default useUserName;
