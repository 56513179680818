/**
 * Get local file state and render a file component
 * based on the type of file (local or remote)
 */
const getFileComponent = ({
  value,
  isLocal,
  localFile,
  renderFile,
  onChangeFile,
}) => {
  let fileComponent = null;

  // local file available, render it
  if (isLocal) {
    fileComponent = renderFile(localFile, onChangeFile);
  }

  // no local file available, render file with `value`
  if (!isLocal && value) {
    fileComponent = renderFile(value, onChangeFile);
  }

  return fileComponent;
};

export default getFileComponent;
