import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { sanitizeUrl } from '@braintree/sanitize-url';
import { TrashIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/solid';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import Video from 'components/company/Video/Video';
import VideoDuration from 'components/company/Video/VideoDuration';
import VideoTag from 'components/company/Video/VideoTag';
import VideoTitle from 'components/company/Video/VideoTitle';

import CompanyVideoDeleteModal from './CompanyVideoDeleteModal';
import CompanyVideoEditModal from './CompanyVideoEditModal/CompanyVideoEditModal';

const CompanyVideo = ({ video = {}, showOwnerView = false, ops = {} }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onSubmit = (data = {}) => {
    const { url } = data;
    const sanitizedUrl = url ? sanitizeUrl(url) : null;
    ops.updateDoc(video.uid, { ...data, url: sanitizedUrl });
    setIsEditModalOpen(false);
  };

  const onDelete = () => {
    ops.deleteDoc(video.uid);
    setIsDeleteModalOpen(false);
  };

  const onFeature = () => {
    ops.updateDoc(video.uid, { featured: true });
    ops.updateOtherDocs(video.uid, { featured: false });
  };

  const onUnfeature = () => {
    ops.updateDoc(video.uid, { featured: false });
  };

  return (
    <>
      <li className="col-span-2 relative z-0 flex flex-col border rounded-lg text-sm border-gray-200 bg-white">
        <Video
          video={video}
          playIconSizeClassName="w-10 h-10 sm:w-20 sm:h-20 md:w-10 md:h-10 lg:w-20 lg:h-20"
          testId={`company-video-${video.title}-video`}
          eventType="pressPage_view"
          eventProperties={{
            action: 'video_play',
            title: video.title,
            url: video.url,
          }}
        >
          {(isPlaying) =>
            !isPlaying && (
              <>
                <div className="absolute bg-black inset-0 opacity-10 pointer-events-none" />

                <VideoDuration title={video.title} duration={video.duration} />

                <div className="absolute top-4 left-4 w-full">
                  <VideoTitle title={video.title} />
                  <VideoTag
                    tag={video.tag}
                    testId={`company-video-${video.title}-tag`}
                  />
                </div>

                {showOwnerView && (
                  <div className="absolute z-20 top-2 right-2">
                    <DotsMenu
                      theme="medium"
                      options={[
                        {
                          label: 'Edit video',
                          onClick: () => setIsEditModalOpen(true),
                          testId: `company-video-${video.title}-menu-edit`,
                        },
                        {
                          label: 'Delete video',
                          icon: <TrashIcon className="w-4 h-4" />,
                          onClick: () => setIsDeleteModalOpen(true),
                          testId: `company-video-${video.title}-menu-delete`,
                        },
                      ].concat(
                        video.featured
                          ? {
                              label: 'Unset as featured',
                              icon: <EyeOffIcon className="w-4 h-4" />,
                              onClick: () => onUnfeature(),
                              testId: `company-video-${video.title}-menu-unfeature`,
                            }
                          : {
                              label: 'Set as featured',
                              icon: <EyeIcon className="w-4 h-4" />,
                              onClick: () => onFeature(),
                              testId: `company-video-${video.title}-menu-feature`,
                            }
                      )}
                      testId={`company-video-${video.title}-menu`}
                    />
                  </div>
                )}
              </>
            )
          }
        </Video>
      </li>

      {showOwnerView && (
        <CompanyVideoEditModal
          open={isEditModalOpen}
          video={video}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onSubmit}
        />
      )}

      {isDeleteModalOpen && (
        <CompanyVideoDeleteModal
          title={video.title}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

CompanyVideo.propTypes = {
  video: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  ops: PropTypes.shape({
    deleteDoc: PropTypes.func,
    updateDoc: PropTypes.func,
  }).isRequired,
};

export default CompanyVideo;
