import React from 'react';

import EmptyState from 'components/EmptyState';

const CompanyNotFound = () => (
  <EmptyState
    helmetTitle="Company not found"
    testId="company-notFound"
    title="Oops, sorry!"
    subtitle=" Looks like this company doesn't exist."
  />
);

export default CompanyNotFound;
