// formats a timestamp
const formatTimestamp = (timestamp) =>
  new Date(timestamp).toLocaleDateString('en-GB', {
    day: '2-digit',
    year: 'numeric',
    month: 'short',
    timeZone: 'UTC',
  });

export default formatTimestamp;
