import React, { useState } from 'react';

import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { JOURNALISTS_COLLECTION } from 'constants/firebasePaths';

import AdminWrapper from '../AdminWrapper';
import AdminJournalistsJournalist from './AdminJournalistsJournalist';
import AdminJournalistsJournalistModal from './AdminJournalistsJournalistModal';
import useUniqueJournalistsNames from './hooks/useUniqueJournalistsNames';

const AdminJournalistsPage = () => {
  const firestore = useFirestore();

  // get journalists collection
  const journalistsCollection = firestore.collection(JOURNALISTS_COLLECTION);
  const journalists = useFirestoreCollectionData(
    journalistsCollection,
    defaultOptions
  );

  // get unique journalists names
  const journalistsNames = useUniqueJournalistsNames();
  const [currentJournalistName, setCurrentJournalistName] = useState(false);

  const onSaveJournalist = ({ image = null }) => {
    setCurrentJournalistName(null);

    // check if journalist already exists
    const journalist = journalists.data.find(
      ({ name }) => name === currentJournalistName
    );

    if (journalist) {
      journalistsCollection.doc(journalist.uid).set({ image }, { merge: true });
    }

    journalistsCollection.add({
      name: currentJournalistName,
      image,
    });
  };

  return (
    <>
      <AdminWrapper title="Journalists" currentIndex={8}>
        <div className="mt-4 p-4 bg-white border border-dashed border-gray-400 rounded-xl text-sm text-gray-400">
          <h3 className="font-semibold uppercase">Instructions</h3>

          <p className="mt-2">
            Below you can find a list of <strong>journalists</strong>. The ideal
            image size for <strong>journalists</strong> is 64x64 (16x16 times 4)
            so it looks really crisp on high-resolution displays.
          </p>
        </div>

        <div className="mt-4 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Journalist image
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {journalistsNames.map((journalistName, index) => (
                      <AdminJournalistsJournalist
                        key={journalistName}
                        name={journalistName}
                        journalist={journalists.data.find(
                          (j) => j.name === journalistName
                        )}
                        className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                        onEdit={() => setCurrentJournalistName(journalistName)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </AdminWrapper>

      {currentJournalistName && (
        <AdminJournalistsJournalistModal
          journalistName={currentJournalistName}
          defaultJournalist={journalists.data.find(
            (j) => j.name === currentJournalistName
          )}
          onClose={() => setCurrentJournalistName(false)}
          onSubmit={onSaveJournalist}
        />
      )}
    </>
  );
};

export default AdminJournalistsPage;
