import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import { Controller, useForm } from 'react-hook-form';

import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldTagsAutocomplete from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocomplete';
import InputFieldTagsDropdown from 'components/form/inputFields/InputFieldTagsDropdown/InputFieldTagsDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import {
  REGEX_DEADLINE_RAW,
  REGEX_DOMAIN_AUTHORITY,
  REGEX_URL,
} from 'constants/regex';
import { tagsTopics } from 'constants/tags';

const AdminAddOpportunityModal = ({
  open,
  onClose,
  onSubmit,
  companies = [],
}) => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      data-test-id="opportunityResponseModal"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <div className="flex items-center justify-center min-h-screen">
        <div className="relative bg-white rounded-lg shadow-xl m-4 sm:m-6">
          <div className="absolute top-4 right-4 z-10">
            <CloseButton
              onClick={onClose}
              testId="opportunityResponseModal-close"
            />
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-6 py-6 pb-0">
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="dialog-1-title"
                >
                  Add opportunity
                </h3>
              </div>

              <div className="flex justify-between items-baseline mt-5">
                <Label htmlFor="enquirySummary">Enquiry summary*</Label>
              </div>
              <InputFieldText
                name="enquirySummary"
                ref={register({
                  required: 'Please put in an enquiry summary',
                  maxLength: {
                    value: 140,
                    message: 'Please limit this to 140 characters',
                  },
                })}
                placeholder="Type in the enquiry summary..."
              />
              {errors.enquirySummary && (
                <ErrorMessage>{errors.enquirySummary.message}</ErrorMessage>
              )}

              <div className="flex justify-between items-baseline mt-3">
                <Label htmlFor="query">Query*</Label>
              </div>

              <InputfieldTextArea
                name="query"
                cols={48}
                ref={register({
                  required: 'Please put in a query',
                  minLength: {
                    value: 140,
                    message: 'Please put in at least 140 characters',
                  },
                })}
                placeholder="Type in the query..."
              />

              {errors.query && (
                <ErrorMessage>{errors.query.message}</ErrorMessage>
              )}

              <div className="flex justify-between items-baseline mt-3">
                <Label htmlFor="mediaOutlet">Media outlet</Label>
              </div>

              <InputFieldText
                name="mediaOutlet"
                ref={register({
                  required: 'Please put in a media outlet',
                  maxLength: {
                    value: 140,
                    message: 'Please limit this to 140 characters',
                  },
                })}
                placeholder="Type in the media outlet..."
              />
              {errors.mediaOutlet && (
                <ErrorMessage>{errors.mediaOutlet.message}</ErrorMessage>
              )}

              <div className="flex justify-between items-baseline mt-3">
                <Label htmlFor="mediaOutletWebsite">Media outlet website</Label>
              </div>

              <InputFieldText
                name="mediaOutletWebsite"
                ref={register({
                  required: 'Please put in a media outlet website',
                  pattern: {
                    value: REGEX_URL,
                    message: 'Please enter a valid URL (https://...)',
                  },
                })}
                placeholder="Type in the media outlet website..."
              />
              {errors.mediaOutletWebsite && (
                <ErrorMessage>{errors.mediaOutletWebsite.message}</ErrorMessage>
              )}

              <div className="flex justify-between items-baseline mt-3">
                <Label htmlFor="domainAuthority">Domain authority</Label>
              </div>

              <InputFieldText
                name="domainAuthority"
                ref={register({
                  pattern: {
                    value: REGEX_DOMAIN_AUTHORITY,
                    message: 'Please type in a number between 0 and 100',
                  },
                })}
                placeholder="Type in the domain authority..."
              />
              {errors.domainAuthority && (
                <ErrorMessage>{errors.domainAuthority.message}</ErrorMessage>
              )}

              <div className="flex justify-between items-baseline mt-3">
                <Label htmlFor="journalist">Journalist</Label>
              </div>

              <InputFieldText
                name="journalist"
                ref={register({
                  required: 'Please put in a journalist',
                  maxLength: {
                    value: 140,
                    message: 'Please limit this to 140 characters',
                  },
                })}
                placeholder="Type in the journalist..."
              />
              {errors.journalist && (
                <ErrorMessage>{errors.journalist.message}</ErrorMessage>
              )}

              <div className="flex justify-between items-baseline mt-3">
                <Label htmlFor="deadlineRaw">Deadline</Label>
              </div>

              <InputFieldText
                name="deadlineRaw"
                ref={register({
                  pattern: {
                    value: REGEX_DEADLINE_RAW,
                    message: 'Please put in a deadline',
                  },
                })}
                placeholder="28 Jun 2021 12:00"
              />
              {errors.deadlineRaw && (
                <ErrorMessage>{errors.deadlineRaw.message}</ErrorMessage>
              )}

              <div className="flex w-full justify-between mt-3 mb-2">
                <Label htmlFor="users">Companies</Label>
              </div>

              <div className="mb-2">
                <Controller
                  name="companies"
                  control={control}
                  defaultValue={[]}
                  render={({ onChange, value }) => (
                    <InputFieldTagsDropdown
                      disabled={companies.length === 0}
                      tags={companies.map((company) => ({
                        ...company,
                        id: company.uid,
                      }))}
                      onChange={onChange}
                      value={value}
                      widthClassName="w-full"
                    />
                  )}
                />
              </div>

              {errors.companies && (
                <ErrorMessage>{errors.companies.message}</ErrorMessage>
              )}

              <div className="flex w-full justify-between mt-3 mb-2">
                <Label htmlFor="topics">Topics</Label>
              </div>

              <div className="mb-2">
                <Controller
                  name="topics"
                  control={control}
                  defaultValue={[]}
                  render={({ onChange, value }) => (
                    <InputFieldTagsAutocomplete
                      customFieldName="topic"
                      suggestions={tagsTopics.filter((topic) => topic.core)}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
            </div>

            <div className="flex w-full justify-end bg-gray-50 mt-3 sm:mt-6 px-6 py-6 rounded-b-md">
              <Button type="secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <div className="ml-3">
                <Button submit disabled={!isValid}>
                  Add opportunity
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

AdminAddOpportunityModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  companies: PropTypes.array,
};

export default AdminAddOpportunityModal;
