import PropTypes from 'prop-types';
import React from 'react';

import InputFieldRadioStackedCardsCard from './InputFieldRadioStackedCardsCard';

// Renders radio-like cards. The user can select one item.
const InputFieldRadioStackedCards = React.forwardRef(
  ({ name = '', options = [], defaultValue = null, testId = '' }, ref) => (
    <fieldset className="w-full rounded-md flex flex-col space-y-4 bg-gray-50">
      {options.map((option, index) => (
        <InputFieldRadioStackedCardsCard
          key={option}
          inputRef={ref}
          name={name}
          option={option}
          index={index}
          isSelected={defaultValue === option}
          isFirst={index === 0}
          isLast={index === options.length - 1}
          testId={testId}
        />
      ))}
    </fieldset>
  )
);

InputFieldRadioStackedCards.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  testId: PropTypes.string,
};

InputFieldRadioStackedCards.displayName = 'InputFieldRadioStackedCards';

export default InputFieldRadioStackedCards;
