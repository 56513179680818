import PropTypes from 'prop-types';
import React from 'react';

import { XIcon } from '@heroicons/react/outline';
import { MenuIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import noop from 'lodash/noop';

// Hamburger icon to toggle the mobile nav overlay
const MobileNavToggle = ({
  isMobileMenuOpen = false,
  toggleMobileMenu = noop,
}) => (
  <div className="flex items-center transition-all ease-in-out duration-700">
    <button
      type="button"
      className={classNames({
        'opacity-0': isMobileMenuOpen,
        'opacity-100': !isMobileMenuOpen,
      })}
      aria-expanded={isMobileMenuOpen}
      onClick={() => toggleMobileMenu(true)}
    >
      <span className="sr-only">Open main menu</span>
      <MenuIcon className="w-6 h-6" />
    </button>
    <button
      type="button"
      className={classNames('absolute z-20', {
        'opacity-100': isMobileMenuOpen,
        'opacity-0 -left-5': !isMobileMenuOpen,
      })}
      onClick={() => toggleMobileMenu(false)}
    >
      <span className="sr-only">Close main menu</span>
      <XIcon className="w-6 h-6" />
    </button>
  </div>
);

MobileNavToggle.propTypes = {
  isMobileMenuOpen: PropTypes.bool.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
};

export default MobileNavToggle;
