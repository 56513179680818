import PropTypes from 'prop-types';
import React from 'react';

import { CheckIcon, MailIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import Button from 'components/buttons/Button';

const AdminSweetheartListCompanyNotifyButton = ({
  isNotified = false,
  notifySweetheartList = noop,
  isDisabled = false,
}) => (
  <Button
    type="primary"
    size="small"
    onClick={notifySweetheartList}
    disabled={isDisabled || isNotified}
  >
    {isNotified ? (
      <>
        <CheckIcon className="w-4 h-4 mr-2 text-teal-600" />
        <span className="text-teal-600 italic">Notified</span>
      </>
    ) : (
      <>
        <MailIcon className="w-4 h-4 mr-2" />
        <span>Notify</span>
      </>
    )}
  </Button>
);

AdminSweetheartListCompanyNotifyButton.propTypes = {
  isNotified: PropTypes.bool.isRequired,
  notifySweetheartList: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default AdminSweetheartListCompanyNotifyButton;
