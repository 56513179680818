import PropTypes from 'prop-types';
import React from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const OpportunityResponseModalStepper = ({
  currentCount,
  totalCount,
  onPrevious,
  onNext,
}) => {
  const isFirst = currentCount === 0;
  const isLast = currentCount === totalCount - 1;

  return (
    <div className="pt-4 pb-3 pl-5 w-full flex justify-center items-center space-x-4 text-sm  text-gray-500 border-b border-gray-300">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          if (isFirst) {
            return;
          }
          onPrevious();
        }}
        className={`inline-block p-1 ${
          isFirst ? 'opacity-50' : 'hover:bg-gray-50'
        } rounded-full`}
        data-test-id="opportunity-responseModal-stepper-prev"
      >
        <ChevronLeftIcon className="w-6 h-6" />
      </a>
      <p data-test-id="opportunity-responseModal-stepper-count">
        {currentCount + 1} of {totalCount} opportunities
      </p>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          if (isLast) {
            return;
          }
          onNext();
        }}
        className={`inline-block p-1 ${
          isLast ? 'opacity-50' : 'hover:bg-gray-50'
        } rounded-full`}
        data-test-id="opportunity-responseModal-stepper-next"
      >
        <ChevronRightIcon className="w-6 h-6" />
      </a>
    </div>
  );
};

OpportunityResponseModalStepper.propTypes = {
  currentCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default OpportunityResponseModalStepper;
