import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

import getTagsClassNames from 'utils/color/getTagsClassNames';

// Child tag inside of a list of tags, can be selected
const InputFieldTagsListTag = ({
  tag = {},
  index = 0,
  isSelected = false,
  onChange = noop,
  testId = '',
}) => (
  <button
    className={classNames(
      'inline-block mt-2 mr-2 p-2 py-1 text-xs rounded-md focus:outline-none focus:ring',
      {
        'opacity-100': isSelected,
        'opacity-40': !isSelected,
      },
      ...getTagsClassNames(index, tag.name?.length)
    )}
    type="button"
    onClick={() => onChange(tag)}
    data-test-id={`${testId}-${tag.name}`}
  >
    {tag.name}
  </button>
);

InputFieldTagsListTag.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

export default InputFieldTagsListTag;
