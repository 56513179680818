import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

// Flexible wrapper with shadow, rounded corners and some margin/padding
const Container = ({
  children = null,
  title = '',
  subtitle = '',
  subtitleAction = null,
  ComponentEnd = null,
  titleColorClassName = 'text-teal-500',
  borderDivideClassName = 'divide-y',
  orderClassName = 'order-none',
  colClassName = 'col-span-12',
}) => (
  <div
    className={classNames(
      colClassName,
      orderClassName,
      'mt-4 mr-0 sm:mt-6 sm:mr-4 md:mt-6 md:mr-12'
    )}
  >
    <section
      className={classNames(
        'flex flex-col bg-white rounded-md shadow',
        borderDivideClassName
      )}
    >
      <div className="flex flex-row justify-between items-center p-5">
        <div
          className={classNames('text-lg font-semibold', titleColorClassName)}
        >
          {title}
        </div>
        {subtitle && subtitleAction && (
          <button
            type="button"
            onClick={subtitleAction}
            className="text-teal-500 font-medium"
          >
            {subtitle}
          </button>
        )}
        {subtitle && !subtitleAction && (
          <p className="text-gray-300">{subtitle}</p>
        )}
        {ComponentEnd}
      </div>
      <div className="flex flex-1">{children}</div>
    </section>
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleAction: PropTypes.func,
  ComponentEnd: PropTypes.node,
  titleColorClassName: PropTypes.string,
  borderDivideClassName: PropTypes.oneOf(['divide-y', 'divide-none']),
  orderClassName: PropTypes.string,
  colClassName: PropTypes.oneOf(['col-span-12', 'col-span-12 md:col-span-6']),
};

export default Container;
