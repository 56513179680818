import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { PlusIcon } from '@heroicons/react/solid';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import EmptyState from 'components/company/EmptyState';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';

import CompanyPressRelease from './CompanyPressRelease/CompanyPressRelease';
import CompanyPressReleaseEditModal from './CompanyPressReleaseEditModal';
import CompanyPressReleaseViewModal from './CompanyPressReleaseViewModal';

const CompanyPressReleases = ({
  crud,
  showOwnerView = false,
  companyId,
  sectionRef,
}) => {
  const [, pressReleasesRaw, ops] = crud;
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [currentViewPressReleaseId, setCurrentViewPressReleaseId] =
    useState(null);

  const pressReleases = pressReleasesRaw.filter(
    (pressRelease) =>
      showOwnerView || new Date().toISOString() > pressRelease.date
  );
  const isEmpty = pressReleases.length < 1;
  const isEmptyAndOwnerview = showOwnerView && isEmpty;

  // If there are no insights yet, hide this section for journalists
  if (!showOwnerView && isEmpty) {
    return null;
  }

  const onSubmit = (pressRelease) => {
    const date = createTimestampFromDateObj(pressRelease.date);
    ops.createDoc({
      title: pressRelease.title,
      description: pressRelease.description,
      filePdf: pressRelease.filePdf || null,
      fileDoc: pressRelease.fileDoc || null,
      image: pressRelease.image || null,
      date,
    });
    setIsAddModalOpen(false);
  };

  const onView = (uid) => {
    setCurrentViewPressReleaseId(uid);
    setIsViewModalOpen(true);
  };

  return (
    <>
      <div
        data-test-id="company-pressReleases"
        id="press-releases"
        ref={sectionRef}
      >
        <SectionHeaderWrapper>
          <SectionHeadline
            light
            testId="company-pressReleases-headline"
            showEmptySectionHint={isEmptyAndOwnerview}
          >
            Press releases
          </SectionHeadline>

          {showOwnerView && (
            <DotsMenu
              options={[
                {
                  label: 'Add press release',
                  icon: <PlusIcon className="w-4 h-4" />,
                  onClick: () => setIsAddModalOpen(true),
                  testId: 'company-pressReleases-menu-add',
                },
              ]}
              theme="dark"
              testId="company-pressReleases-menu"
            />
          )}
        </SectionHeaderWrapper>

        <div className="w-full mt-4">
          <ul
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4 gap-6 items-stretch"
            data-test-id="company-quotes-list"
          >
            {pressReleases.length ? (
              pressReleases.map((pressRelease) => (
                <CompanyPressRelease
                  key={pressRelease.title}
                  pressRelease={pressRelease}
                  showOwnerView={showOwnerView}
                  ops={ops}
                  onView={onView}
                  companyId={companyId}
                />
              ))
            ) : (
              <EmptyState
                title="Add a press release"
                description="Uploading a press release helps journalists bring your company into their stories."
                onClick={() => setIsAddModalOpen(true)}
                testId="company-pressReleases-addFirst"
              />
            )}
          </ul>
        </div>
      </div>

      {showOwnerView && (
        <CompanyPressReleaseEditModal
          open={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          onSubmit={onSubmit}
          ops={ops}
          uploadFolder={`companies/${companyId}/articles`}
        />
      )}

      <CompanyPressReleaseViewModal
        open={isViewModalOpen}
        pressReleases={pressReleases}
        currentPressReleaseId={currentViewPressReleaseId}
        setCurrentPressReleaseId={setCurrentViewPressReleaseId}
        onClose={() => setIsViewModalOpen(false)}
      />
    </>
  );
};

CompanyPressReleases.propTypes = {
  crud: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanyPressReleases;
