import PropTypes from 'prop-types';
import React from 'react';

import { EyeIcon } from '@heroicons/react/solid';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

const RemovePhotoModal = ({ onClose, onSubmit }) => (
  <Modal onClose={onClose}>
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className="px-6 py-6 pb-0">
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
          <EyeIcon className="w-6" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3
            className="text-lg leading-6 font-medium text-gray-900"
            id="dialog-1-title"
          >
            Remove your profile photo
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to remove your profile photo?
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center bg-gray-50 mt-5 sm:mt-6 px-6 py-6">
        <Button type="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <div className="ml-3">
          <Button submit>Remove photo</Button>
        </div>
      </div>
    </form>
  </Modal>
);

RemovePhotoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RemovePhotoModal;
