import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

/**
 * Aside link displaying the icon ( and whether it's selected or not) and the name on hover
 * The pointer-events-none makes sure that the hoverable link name doesn't interfere with the current page
 */
const AsideLink = ({
  link = '',
  isSelected = false,
  linkName = '',
  children = null,
  testId = '',
}) => (
  <Link
    to={link}
    className="text-gray-900 hover:text-gray-900 group rounded-md px-3 py-2 flex items-center justify-center text-sm font-medium"
    data-test-id={testId}
  >
    <div
      className={classNames(
        { 'bg-teal-500 bg-opacity-20': isSelected, 'bg-white': !isSelected },
        'text-teal-500 p-2 rounded-sm group-hover:bg-teal-500 group-hover:bg-opacity-20 transition-colors duration-300 ease-in-out'
      )}
    >
      {children}
    </div>
    <div className="whitespace-nowrap absolute left-14 px-1 rounded-sm bg-teal-500 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out pointer-events-none">
      {linkName}
    </div>
  </Link>
);

AsideLink.propTypes = {
  link: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  linkName: PropTypes.string,
  children: PropTypes.element,
  testId: PropTypes.string,
};

export default AsideLink;
