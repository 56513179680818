import slugify from 'slugify';

// generates a unique id to avoid collisions between files
// https://gist.github.com/gordonbrander/2230317#gistcomment-2362659
const generateUniqueId = () => {
  const array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  let str = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array.length; i++) {
    str += (i < 2 || i > 5 ? '' : '-') + array[i].toString(16).slice(-4);
  }
  return str;
};

// splits up a filename into the name + extension
const getFileExtension = (filename) => {
  const parts = filename.split('.');
  const extension = parts.pop();
  const name = parts.join('');
  return [name, extension];
};

// create a unique file name by generating a unique ID
// and appending it to the base, leaving the initial extension intact
// e.g. "picture 123.jpg" will turn into "picture_123-uniqueID.jpg"
const getFileName = (file) => {
  const id = generateUniqueId();
  const [name, extension] = getFileExtension(file.name);
  const slug = slugify(name);
  return `${slug}-${id}.${extension}`;
};

export default getFileName;
