import { SLACK_WEBHOOK_URL } from './config';

const sendNotification = ({
  user = {},
  companyName = '',
  companySlug = '',
  opportunity = {},
  response = '',
}) => {
  let text = '';

  if (user && user.data && user.data.email) {
    text = `📩 New response by ${user.data.email}: \n`;
  }

  if (companyName) {
    text = `${text}*Company:* ${companyName}`;
    if (companySlug) {
      text = `${text} (https://app.mvpr.io/company/${companySlug})`;
    }
    text = `${text}\n`;
  }

  if (opportunity.mediaOutlet) {
    text = `${text}*Media outlet:* ${opportunity.mediaOutlet}`;
    if (opportunity.mediaOutletWebsite) {
      text = `${text} (${opportunity.mediaOutletWebsite})`;
    }
    text = `${text}\n`;
  }

  if (opportunity.enquirySummary) {
    text = `${text}*Opportunity:* ${opportunity.enquirySummary}\n`;
  }

  if (opportunity.journalist) {
    text = `${text}*Journalist:* ${opportunity.journalist}\n`;
  }

  if (opportunity.deadlineRaw) {
    text = `${text}*Deadline*: ${opportunity.deadlineRaw}\n`;
  }

  if (response) {
    text = `${text}*Response:* ${response}`;
  }

  if (opportunity.email) {
    text = `${text}\n*Reply-to email*: ${opportunity.email}`;
  }

  fetch(SLACK_WEBHOOK_URL, {
    method: 'POST',
    header: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      text,
    }),
  }).then(() => {
    // TODO-LOG: send to logger
  });
};

export default sendNotification;
