import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import findIndex from 'lodash/findIndex';
import noop from 'lodash/lodash';

import getTagsClassNames from 'utils/color/getTagsClassNames';

// Creates an input field tag where the tag classes are based on the tagsIndex array (if available) or tags array
const createInputFieldTagsAutocompleteTag = (tags = [], tagsIndex = null) => {
  const InputFieldTagsAutocompleteTag = ({
    tag = {},
    removeButtonText = '',
    onDelete: onClick = noop,
  }) => (
    <div
      className={classNames(
        'inline-block mr-2 mt-2 p-2 py-1 text-xs rounded-md',
        ...getTagsClassNames(
          findIndex(tagsIndex || tags, (t) => t.id === tag.id),
          tag.name?.length
        )
      )}
    >
      {tag.name}{' '}
      <span
        className="ml-1 cursor-pointer hover:opacity-50"
        title={removeButtonText}
        onClick={onClick}
      >
        &#x2715;
      </span>
    </div>
  );

  InputFieldTagsAutocompleteTag.propTypes = {
    tag: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
    removeButtonText: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
  };

  return InputFieldTagsAutocompleteTag;
};

export default createInputFieldTagsAutocompleteTag;
