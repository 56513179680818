import PropTypes from 'prop-types';
import React from 'react';

// Opportunity meta text (used for journalists and publications)
const OpportunityMeta = ({ text = '' }) => (
  <h4 className="font-medium text-sm text-gray-700 whitespace-nowrap truncate">
    {text}
  </h4>
);

OpportunityMeta.propTypes = {
  text: PropTypes.string.isRequired,
};

export default OpportunityMeta;
