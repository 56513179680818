import PropTypes from 'prop-types';
import React from 'react';

import {
  CalendarIcon,
  ClockIcon,
  CheckCircleIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { capitalize } from 'lodash';

import DomainAuthority from 'components/DomainAuthority/DomainAuthority';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';
import OpportunityMeta from 'components/opportunities/opportunity/OpportunityMeta';
import OpportunityQuery from 'components/opportunities/opportunity/OpportunityQuery';
import getDeadline from 'components/opportunities/opportunity/helpers/getDeadline';
import getTimeLeft from 'components/opportunities/opportunity/helpers/getTimeLeft';

const AdminOpportunitiesNotificationsOpportunity = ({
  opportunity,
  onToggleCompany,
  notificationsData,
}) => {
  const {
    custom,
    date,
    deadlineUTC,
    domainAuthority,
    enquirySummary,
    journalist,
    mediaOutlet,
    query,
  } = opportunity;

  const [deadlineDiff, deadlineUnits] = getDeadline(deadlineUTC);
  const timeLeft = getTimeLeft({
    diff: deadlineDiff,
    units: deadlineUnits,
    dateUc: deadlineUTC,
  });

  return (
    <div className="flex flex-row items-stretch mt-2 space-x-2">
      <div className="flex flex-none flex-col justify-start relative p-4 pt-2 md:rounded-md md:border border-white max-w-prose bg-white">
        <p className="mt-2 inline-flex self-start items-center text-xs space-x-2 text-gray-500 font-semibold">
          <CalendarIcon className="w-4" />
          <span>{date?.toDate().toLocaleString()}</span>
        </p>

        <div className="flex justify-between mt-2">
          <h3 className="text-2xl font-semibold">
            {capitalize(enquirySummary)}
          </h3>
        </div>

        <div className="mt-3 flex items-center space-x-2">
          <PublicationImage name={mediaOutlet} custom={custom} />
          <OpportunityMeta text={mediaOutlet} />
          <DomainAuthority domainAuthority={domainAuthority} />
          <div className="w-1" />
          <JournalistImage name={journalist} />
          <OpportunityMeta text={journalist} />
        </div>

        <OpportunityQuery query={query} collapsed />

        <p className="mt-4 inline-flex self-start items-center text-xs py-1 px-2 pr-3 space-x-2 text-gray-400 bg-opacity-40 font-semibold rounded-md bg-gray-200">
          <ClockIcon className="w-4" />
          <span>{timeLeft}</span>
        </p>
      </div>

      <div className="bg-white p-4 w-1/2">
        {opportunity.companies.map((company) => {
          const { companiesToNotify, companiesNotified, companiesVisited } =
            notificationsData || {};
          const isSelected = companiesToNotify?.includes?.(company.uid);
          const isNotified = companiesNotified?.includes?.(company.uid);
          const hasVisited = companiesVisited?.includes?.(company.uid);

          return (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (isNotified || hasVisited) {
                  return;
                }
                onToggleCompany(company.uid);
              }}
              key={company.slug}
              className={classNames(
                'float-left flex items-center space-x-1 mr-2 mb-2 p-2 py-1 text-xs rounded-md whitespace-nowrap',
                {
                  'bg-gray-100 hover:bg-gray-50': !isSelected,
                  'bg-amber-300 text-amber-700 hover:bg-amber-200':
                    isSelected && !isNotified && !hasVisited,
                  'bg-green-300 text-green-700 hover:bg-green-300 cursor-default':
                    isNotified && !hasVisited,
                  'bg-gray-700 text-white hover:bg-gray-700 cursor-default':
                    hasVisited,
                }
              )}
            >
              {hasVisited && (
                <CheckCircleIcon className="w-4 h-4 text-white-500" />
              )}
              <span>{company.name}</span>
            </a>
          );
        })}
      </div>
    </div>
  );
};

AdminOpportunitiesNotificationsOpportunity.propTypes = {
  opportunity: PropTypes.object.isRequired,
  onToggleCompany: PropTypes.func.isRequired,
  notificationsData: PropTypes.object,
};

export default AdminOpportunitiesNotificationsOpportunity;
