import PropTypes from 'prop-types';
import React from 'react';

import Spinner from 'components/Spinner';

const AnalyticsContainer = ({ title, children, loading, empty, subtitle }) => (
  <div className="flex flex-col col-span-12 lg:col-span-6 xl:col-span-4 p-4 pb-2 border rounded-md shadow-sm bg-gray-50">
    <h2 className="text-sm font-medium">
      {title}
      {subtitle && (
        <span className="text-sm font-medium text-gray-500"> - {subtitle}</span>
      )}
    </h2>
    <div className="mt-4 relative flex-auto">
      {!loading && !empty && children}
      {!loading && empty && (
        <p className="text-sm text-gray-400 italic mb-4">
          Not enough data yet...
        </p>
      )}
      {loading && (
        <div className="py-8">
          <Spinner />
        </div>
      )}
    </div>
  </div>
);

AnalyticsContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  empty: PropTypes.bool.isRequired,
  subtitle: PropTypes.string,
};

export default AnalyticsContainer;
