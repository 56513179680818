import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

// Wrapper component that adds consistent padding around pages
const PageWrapper = ({ children }) => {
  const { companyData } = useCompanyData();
  const slug = companyData?.slug;

  return (
    <main
      className={classNames(
        'grid grid-cols-12 px-0 pt-16 pb-4 sm:pt-20 sm:pb-4 md:pt-28 md:pb-12 bg-gray-5',
        // when user is logged in and has a company, we need to make space for the Aside
        { 'sm:pl-20 md:pl-28': slug, 'sm:pl-4 md:pl-12': !slug }
      )}
    >
      {children}
    </main>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node,
};

export default PageWrapper;
