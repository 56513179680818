import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import UniversalImage from 'components/UniversalImage/UniversalImage';
import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import ErrorMessage from 'components/form/ErrorMessage';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';

const UPLOAD_FOLDER = 'journalistImages';

const AdminJournalistsJournalistModal = ({
  journalistName = '',
  defaultJournalist = {},
  onClose = noop,
  onSubmit = noop,
}) => {
  const {
    handleSubmit,
    errors,
    setError,
    control,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const defaultImage = defaultJournalist?.image;

  return (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-6 py-6">
          <h2 className="text-md text-gray-700">
            Journalist <span className="font-semibold">{journalistName}</span>
          </h2>
          <div className="mt-5">
            <Controller
              name="image"
              control={control}
              defaultValue={defaultImage}
              render={({ onChange, value }) => (
                <InputFieldUpload
                  name="image"
                  folder={UPLOAD_FOLDER}
                  onChange={onChange}
                  value={value}
                  setError={setError}
                  renderFile={(image, onChangeImage) => (
                    <div className="relative h-40 w-40 bg-gray-100">
                      <UniversalImage
                        image={image}
                        alt={`Headshot of ${journalistName}`}
                        heightClass="w-40 h-40"
                        objectFitClass="object-cover"
                      />
                      <div className="absolute -top-2 -right-2">
                        <CloseButton onClick={() => onChangeImage(null)} />
                      </div>
                    </div>
                  )}
                />
              )}
            />
          </div>

          {errors.image && (
            <div className="mt-2">
              <ErrorMessage>{errors.image.message}</ErrorMessage>
            </div>
          )}
        </div>

        <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
          <Button type="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <div className="ml-3">
            <Button disabled={!isValid} submit>
              Assign image
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

AdminJournalistsJournalistModal.propTypes = {
  journalistName: PropTypes.string.isRequired,
  defaultJournalist: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AdminJournalistsJournalistModal;
