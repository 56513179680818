import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import findIndex from 'lodash/findIndex';

import getTagsClassNames from 'utils/color/getTagsClassNames';

/**
 * Child element for InputFieldSelect button with multiple options
 * Can be prepended with an additional element, such as an image
 */
const InputFieldTagsDropdownButton = ({
  tag = {},
  tagsIndex = [],
  prependButtonFn = null,
  testId = '',
}) => {
  const tagIndex = findIndex(tagsIndex, (t) => t.id === tag.id);
  const [bgColorClassName, textColorClassName] = getTagsClassNames(
    tagIndex,
    tag.name?.length
  );

  return (
    <span
      className={classNames(
        'mr-2 mb-2 p-2 py-1 text-xs rounded-md',
        {
          'inline-flex items-center': prependButtonFn,
          'inline-block': !prependButtonFn,
        },
        bgColorClassName,
        textColorClassName
      )}
      data-test-id={`${testId}-value-${tag.id}`}
    >
      {prependButtonFn && prependButtonFn(tag)}
      {tag.name}
    </span>
  );
};

InputFieldTagsDropdownButton.propTypes = {
  tag: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  tagsIndex: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  prependButtonFn: PropTypes.func,
  testId: PropTypes.string,
};

export default InputFieldTagsDropdownButton;
