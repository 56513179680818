import { ADMIN_SWEETHEART_LIST_PATH as path } from 'constants/paths';

import AdminSweetheartListPage from './AdminSweetheartListPage';

const AdminSweetheartListRoute = {
  path,
  authRequired: true,
  claimsRequired: { admin: true },
  component: AdminSweetheartListPage,
};

export default AdminSweetheartListRoute;
