import PropTypes from 'prop-types';
import React from 'react';

import { OfficeBuildingIcon, SearchIcon } from '@heroicons/react/outline';

import EmptyState from 'components/EmptyState';
import useUserData from 'contexts/UserContext/hooks/useUserData';

const SweetheartListPageNoCompanyFound = ({ slug = '' }) => {
  const user = useUserData();

  return (
    <EmptyState
      helmetTitle="Sweetheart List - Company Not Found"
      testId="sweetheartList-companyNotFound"
      title="Sorry, no company found for"
      subtitle={slug}
      Icon={OfficeBuildingIcon}
      IconSecondary={SearchIcon}
      instructions={
        user.claimsAdmin && (
          <div className="pt-4">
            <h3 className="text-md font-semibold mx-8 text-center">
              Troubleshooting:
            </h3>
            <ul className="list-disc pl-4 mx-8 sm:mx-24">
              <li>
                Check if there is a company with the slug{' '}
                <code className="underline">{slug}</code> on the admin/companies
                page.
              </li>
            </ul>
          </div>
        )
      }
    />
  );
};

SweetheartListPageNoCompanyFound.propTypes = {
  slug: PropTypes.string,
};

export default SweetheartListPageNoCompanyFound;
