import PropTypes from 'prop-types';
import React from 'react';

import ReactTooltip from 'react-tooltip';

import CompanyArticles from './CompanyArticles/CompanyArticles';
import CompanyDownloads from './CompanyDownloads/CompanyDownloads';
import CompanyInsights from './CompanyInsights/CompanyInsights';
import CompanyPressReleases from './CompanyPressReleases/CompanyPressReleases';
import CompanyQuotes from './CompanyQuotes/CompanyQuotes';
import CompanySnapshots from './CompanySnapshots/CompanySnapshots';
import CompanyVideos from './CompanyVideos/CompanyVideos';

const CompanySections = ({
  companyId,
  cruds,
  sectionRefs,
  founders,
  showOwnerView,
}) => (
  <div className="min-h-screen p-6 pt-0 md:ml-80 md:pl-6 border-l bg-gray-50 border-gray-200">
    <CompanySnapshots
      crud={cruds.achievements}
      sectionRef={sectionRefs.achievements}
      showOwnerView={showOwnerView}
    />
    <CompanyVideos
      crud={cruds.videos}
      sectionRef={sectionRefs.videos}
      showOwnerView={showOwnerView}
      sectionRefs={sectionRefs}
    />

    <CompanyArticles
      crud={cruds.articles}
      sectionRef={sectionRefs.articles}
      showOwnerView={showOwnerView}
      companyId={companyId}
    />

    <CompanyInsights
      crud={cruds.insights}
      founders={founders}
      sectionRef={sectionRefs.insights}
      showOwnerView={showOwnerView}
    />

    <CompanyQuotes
      crud={cruds.quotes}
      founders={founders}
      sectionRef={sectionRefs.quotes}
      showOwnerView={showOwnerView}
    />

    <CompanyPressReleases
      crud={cruds.pressReleases}
      sectionRef={sectionRefs.pressReleases}
      showOwnerView={showOwnerView}
      companyId={companyId}
    />

    <CompanyDownloads
      crud={cruds.downloads}
      sectionRef={sectionRefs.downloads}
      founders={founders}
      showOwnerView={showOwnerView}
      companyId={companyId}
    />

    <ReactTooltip place="right" id="tooltip" />
  </div>
);

CompanySections.propTypes = {
  companyId: PropTypes.string.isRequired,
  cruds: PropTypes.object.isRequired,
  sectionRefs: PropTypes.object.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  founders: PropTypes.array,
};

export default React.memo(CompanySections);
