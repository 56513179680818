import React from 'react';

import CompaniesList from 'components/company/CompaniesList/CompaniesList';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useCompaniesByVc from 'hooks/useCompaniesByVc';

const CompaniesPage = () => {
  const user = useUserData();
  const claimsVc = user && user.claimsVc;

  const companies = useCompaniesByVc(claimsVc);

  // ask user to login again
  if (!claimsVc) {
    return (
      <div className="min-h-screen pt-24 bg-gray-50 flex flex-col py-12 sm:px-6 lg:px-8">
        <p className="font-semibold">Oops.</p>
        <p>
          You don&apos;t seem to have signed up for a VC account yet. If you
          recently signed up as a VC, please log out and log in again to refresh
          your ID token.
        </p>
      </div>
    );
  }

  return (
    <div className="pt-24">
      <h1 className="text-2xl text-teal-500 font-semibold text-center">
        Welcome to MVPR!
      </h1>
      <h2 className="text-xl text-grey-500 text-center mt-4">
        Select a company to continue
      </h2>
      <div className="m-8 ml-10">
        <CompaniesList companies={companies} />
      </div>
    </div>
  );
};

export default CompaniesPage;
