import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useStateMachine } from 'little-state-machine';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useStorage } from 'reactfire';

import Container from 'components/Container';
import UniversalImage from 'components/UniversalImage/UniversalImage';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import useNotifications from 'modules/notification/useNotifications';
import defaultUserImageUrl from 'static/User.png';

import RemovePhotoModal from './RemovePhotoModal';
import UpdatePhotoModal from './UpdatePhotoModal';

const updatePhotoURL = (state, payload) => ({
  ...state,
  user: { ...state.user, photoURL: payload },
});

const ProfileForm = ({ user, userRef, userDoc }) => {
  const { showSuccess, showError } = useNotifications();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    nativeValidation: false,
    defaultValues: userDoc,
  });

  const { actions } = useStateMachine({ updatePhotoURL });
  const storage = useStorage();

  const [updatePhotoModalOpen, setUpdatePhotoModalOpen] = useState(false);
  const [removePhotoModalOpen, setRemovePhotoModalOpen] = useState(false);

  const onSubmit = async (userData) => {
    try {
      await userRef.set(userData, { merge: true });
      showSuccess('User settings updated successfully.');
    } catch (err) {
      console.error('Error updating profile', err); // eslint-disable-line no-console
      showError(`Error updating profile: ${err.message}`);
    }
  };

  const updatePhoto = async ({ photo }) => {
    try {
      let photoURL = null;
      if (photo && photo.name) {
        photoURL = await storage
          .ref(`users/${user.uid}`)
          .child(`${photo.name}`)
          .getDownloadURL();
      }

      await user.updateProfile({
        photoURL,
      });
      actions.updatePhotoURL(photoURL);
      setUpdatePhotoModalOpen(false);
      setRemovePhotoModalOpen(false);
    } catch (e) {
      // TODO-LOG: send this to logger
    }
  };

  return (
    <>
      <Container
        title="Profile"
        titleColorClassName="text-gray-900"
        borderDivideClassName="divide-none"
      >
        <div className="w-full -mt-8 p-5">
          <p className="mt-1 text-sm text-gray-500">
            This information will be displayed publicly so be careful what you
            share.
          </p>

          <div className="grid grid-cols-12 gap-6 mt-6">
            <div className="col-span-12">
              <Label htmlFor="photo">Photo</Label>
              <div className="mt-2 flex items-center">
                <UniversalImage
                  image={{ path: user.photoURL }}
                  widthClass="w-16 rounded-full"
                  heightClass="h-16"
                  alt="User profile photo"
                  placeholderImage={defaultUserImageUrl}
                />
                <div className="ml-4 flex">
                  <Button
                    type="secondary"
                    className="mr-2"
                    onClick={() => setUpdatePhotoModalOpen(true)}
                  >
                    <span>Change</span>
                    <span className="sr-only"> user photo</span>
                  </Button>

                  {user.photoURL && (
                    <Link
                      to="#"
                      className="text-sm font-medium text-gray-700 py-2 px-3"
                      onClick={() => setRemovePhotoModalOpen(true)}
                    >
                      Remove
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-6 mt-6">
              <div className="col-span-12 sm:col-span-6">
                <Label htmlFor="firstName">First name</Label>
                <InputFieldText
                  ref={register}
                  name="firstName"
                  defaultValue={userDoc.firstName}
                  placeholder="First name"
                />
              </div>

              <div className="col-span-12 sm:col-span-6">
                <Label htmlFor="lastName">Last name</Label>
                <InputFieldText
                  ref={register}
                  name="lastName"
                  defaultValue={userDoc.lastName}
                  placeholder="Last name"
                />
              </div>
            </div>
            <div className="mt-4 flex flex-row justify-end">
              <Button
                type="secondary"
                submit
                disabled={isSubmitting || !isValid}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Container>

      {updatePhotoModalOpen && (
        <UpdatePhotoModal
          uploadFolder={`users/${user.uid}`}
          onClose={() => setUpdatePhotoModalOpen(false)}
          onSubmit={updatePhoto}
        />
      )}

      {removePhotoModalOpen && (
        <RemovePhotoModal
          onClose={() => setRemovePhotoModalOpen(false)}
          onSubmit={() => updatePhoto({ photo: null })}
        />
      )}
    </>
  );
};

ProfileForm.propTypes = {
  user: PropTypes.object.isRequired,
  userRef: PropTypes.object.isRequired,
  userDoc: PropTypes.object.isRequired,
};

export default ProfileForm;
