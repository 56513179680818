import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';

// checks if a company contains any vc whose slug matches the vc passed in
const filterByVc =
  (vc = '') =>
  (company = { vcs: [] }) =>
    company.vcs.some((v) => v.slug === vc);

// retrieve all the companies that belong to a specific VC
const useCompaniesByVc = (vc) => {
  const firestore = useFirestore();

  const query = firestore
    .collection(COMPANIES_COLLECTION)
    .where('vcs', '!=', null);

  const companies = useFirestoreCollectionData(query, defaultOptions);

  // if no companies match or vc is falsy, opt out
  if (!companies?.data || !vc) {
    return null;
  }

  return companies.data.filter(filterByVc(vc));
};

export default useCompaniesByVc;
