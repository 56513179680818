// This is a bad solution to track the page title.
// The main reason for doing it like this is that the title update by React Helmet lags:
// https://github.com/nfl/react-helmet/issues/189
const getPageTitle = (pathname, companySlug) => {
  if (pathname.includes('/opportunities')) {
    return 'Media opportunities | MVPR';
  }
  if (pathname.includes('/company')) {
    return `Press page - ${companySlug} | MVPR`;
  }
  if (pathname.includes('/analytics')) {
    return 'Analytics | MVPR';
  }
  if (pathname.includes('/login')) {
    return 'Login | MVPR';
  }
  if (pathname.includes('/signup')) {
    return 'Signup | MVPR';
  }
  if (pathname.includes('/signupVc')) {
    return 'Signup as VC | MVPR';
  }
  if (pathname.includes('/resetPassword')) {
    return 'Reset password | MVPR';
  }
  if (pathname.includes('/onboarding/one')) {
    return 'Create a press page - step one | MVPR';
  }
  if (pathname.includes('/onboarding/two')) {
    return 'Create a press page - step two | MVPR';
  }
  if (pathname.includes('/onboarding/three')) {
    return 'Create a press page - step three | MVPR';
  }
  if (pathname.includes('/onboarding/four')) {
    return 'Create a press page - step four | MVPR';
  }
  if (pathname.includes('/settings/user')) {
    return 'User settings | MVPR';
  }
  if (pathname.includes('/settings/company')) {
    return 'Company settings | MVPR';
  }

  return '';
};

export default getPageTitle;
