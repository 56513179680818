import React from 'react';

import Container from 'components/Container';

import CompanyForm from './CompanyForm/CompanyForm';
import CompanyTargetForm from './CompanyTargetForm/CompanyTargetForm';

const CompanySettings = () => (
  <>
    <Container
      title="Company"
      titleColorClassName="text-gray-900"
      borderDivideClassName="divide-none"
    >
      <div className="w-full -mt-8 p-5">
        <p className="mt-1 text-sm text-gray-500">
          This information will be displayed publicly so be careful what you
          share.
        </p>

        <CompanyForm />
      </div>
    </Container>

    <Container
      title="Targeting"
      titleColorClassName="text-gray-900"
      borderDivideClassName="divide-none"
    >
      <div className="w-full -mt-8 p-5">
        <p className="mt-1 text-sm text-gray-500">
          These options let you adjust which opportunities you are able to see.
        </p>
        <CompanyTargetForm />
      </div>
    </Container>
  </>
);

export default CompanySettings;
