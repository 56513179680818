import PropTypes from 'prop-types';
import React from 'react';

import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import noop from 'lodash/lodash';

import IconDropdownArrow from 'components/icons/IconDropdownArrow';

import InputFieldDropdownOption from './InputFieldDropdownOption';

// Dropdown select field to choose one option from a list
const InputFieldDropdown = ({
  options = [],
  onChange = noop,
  value = '',
  wrapperClassName = '',
  optionWidthClassName = 'w-24',
  testId = '',
}) => (
  <Listbox
    as="div"
    value={value}
    onChange={onChange}
    className={wrapperClassName}
  >
    {({ open }) => {
      const buttonChildren = (
        <span className="block truncate" data-test-id={`${testId}-value`}>
          {value}
        </span>
      );

      return (
        <div className="relative flex flex-col">
          <span className="block w-full rounded-md">
            <Listbox.Button
              className={classNames(
                'cursor-default relative rounded-md border border-gray-300 bg-white text-left focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5',
                'px-3 py-2',
                optionWidthClassName
              )}
              data-test-id={testId}
            >
              {buttonChildren}
              <IconDropdownArrow />
            </Listbox.Button>
          </span>

          <Transition
            show={open}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="absolute z-10 mt-10 w-full rounded-md bg-white shadow-lg"
          >
            <Listbox.Options
              static
              className={`max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 ${optionWidthClassName}`}
            >
              {options.map((option) => (
                <Listbox.Option
                  key={option}
                  value={option}
                  data-test-id={`${testId}-${option}`}
                >
                  {({ selected, active }) => (
                    <InputFieldDropdownOption
                      option={option}
                      isSelected={selected}
                      isActive={active}
                    />
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      );
    }}
  </Listbox>
);

InputFieldDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  wrapperClassName: PropTypes.string,
  optionWidthClassName: PropTypes.string,
  testId: PropTypes.string,
};

export default InputFieldDropdown;
