// add and delete operations on a Firebase reference

import { useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';

// provides the read, create, update and delete operations for a firestore ref
const useCrud = (ref) => {
  const dateCreated = new Date().toISOString();

  // read
  const { status, data } = useFirestoreCollectionData(ref, defaultOptions);

  // create, update, delete
  const ops = {
    createDoc: (doc) =>
      ref.add({
        ...doc,
        dateCreated,
      }),
    updateDoc: (id, doc) => ref.doc(id).update({ ...doc, dateCreated }),

    // look for all other docs that don't match `id`, and update with `updatedDoc`
    updateOtherDocs: (id, updatedDoc) =>
      ref
        .get()
        .then((docs) =>
          docs.forEach(
            (doc) => doc.id !== id && ref.doc(doc.id).update(updatedDoc)
          )
        ),
    deleteDoc: (id) => ref.doc(id).delete(),
  };

  return [status, data, ops];
};

export default useCrud;
