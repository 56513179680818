import React from 'react';

import { Helmet } from 'react-helmet';
import { useAuth } from 'reactfire';

import PageWrapper from 'components/PageWrapper';
import useNotifications from 'modules/notification/useNotifications';

import ResetPasswordForm from './ResetPasswordForm';

const ResetPasswordPage = () => {
  const auth = useAuth();
  const { showError, showSuccess } = useNotifications();

  const resetPassword = async (formValues) => {
    try {
      await auth.sendPasswordResetEmail(formValues.email);
      showSuccess('Password reset link sent. Please check your inbox.');
    } catch (err) {
      showError(err.message);
    }
  };

  return (
    <PageWrapper>
      <Helmet>
        <title>Reset password | MVPR</title>
      </Helmet>
      <ResetPasswordForm onSubmit={resetPassword} />
    </PageWrapper>
  );
};

export default ResetPasswordPage;
