import React from 'react';

import { EmojiSadIcon } from '@heroicons/react/solid';

const CompanyPageEmptyState = () => (
  <div
    data-test-id="company-page-emptyState"
    className="bg-gray-50 h-96 md:min-h-screen flex flex-col space-y-2 justify-center items-center md:-mt-36 md:mb-36 md:ml-80 text-gray-400 p-6 pt-0 border-l border-gray-200"
  >
    <EmojiSadIcon className="w-24 h-24 text-gray-300" />
    <p className="text-xl">Oops, sorry!</p>
    <p className="text-md text-center">
      Looks like this company hasn&apos;t added any content yet
    </p>
  </div>
);

export default CompanyPageEmptyState;
