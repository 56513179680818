import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Dialog } from '@headlessui/react';
import noop from 'lodash/noop';
import { Link } from 'react-router-dom';

import Callout from 'components/Callout/Callout';
import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import { SETTINGS_COMPANY_PATH } from 'constants/paths';

import OpportunitiesTopicsModalTopic from './OpportunitiesTopicsModalTopic';

// Displays a list of topics in a modal to filter opportunities by topic
const OpportunitiesTopicsModal = ({
  open = false,
  topics = [],
  onClose = noop,
  selectedTopics = [],
  setSelectedTopics = noop,
}) => {
  const [updatedTopics, setUpdatedTopics] = useState(selectedTopics);

  const toggleTopic = (topic) => {
    if (updatedTopics.some((t) => t.id === topic.id)) {
      setUpdatedTopics((state) => state.filter((t) => t.id !== topic.id));
      return;
    }
    setUpdatedTopics((state) => [...state, topic]);
  };

  const updateTopics = () => setSelectedTopics(updatedTopics);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      data-test-id="opportunities-navBar-topicsModal"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <div className="flex items-center justify-center min-h-screen">
        <div className="relative bg-white rounded-lg shadow-xl m-4 sm:m-6 max-w-xl lg:max-w-2xl xl:max-w-3xl">
          <div className="absolute top-4 right-4 z-10">
            <CloseButton onClick={onClose} />
          </div>

          <div className="p-6">
            <h2 className="text-xl text-gray-700 font-semibold">Topics</h2>
            <div className="mt-2">
              {topics
                .filter((t) => t.id !== 1199)
                .map((topic) => (
                  <OpportunitiesTopicsModalTopic
                    key={topic.id}
                    topic={topic}
                    isSelected={updatedTopics.some((t) => t.id === topic.id)}
                    onClick={() => toggleTopic(topic)}
                  />
                ))}
            </div>

            <Callout>
              If you&apos;d like to edit the default topics of your company, go
              to the{' '}
              <Link
                className="underline hover:no-underline"
                to={{ pathname: SETTINGS_COMPANY_PATH, hash: 'targeting' }}
              >
                company settings
              </Link>{' '}
              page.
            </Callout>
          </div>
          <div className="flex w-full justify-end bg-gray-50 px-6 py-6 rounded-b-md">
            <Button
              type="secondary"
              onClick={onClose}
              data-test-id="opportunities-navBar-topicsModal-cancel"
            >
              Cancel
            </Button>
            <div className="ml-3">
              <Button
                submit
                onClick={() => {
                  updateTopics();
                  onClose();
                }}
                data-test-id="opportunities-navBar-topicsModal-update"
              >
                Update topics
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

OpportunitiesTopicsModal.propTypes = {
  open: PropTypes.bool,
  topics: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedTopics: PropTypes.array.isRequired,
  setSelectedTopics: PropTypes.func.isRequired,
};

export default OpportunitiesTopicsModal;
