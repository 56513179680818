const tagClassesTuples = [
  ['bg-purple-100', 'text-purple-500', 'border-purple-400'],
  ['bg-indigo-100', 'text-indigo-500', 'border-indigo-400'],
  ['bg-red-100', 'text-red-500', 'border-red-400'],
  ['bg-blue-100', 'text-blue-500', 'border-blue-400'],
  ['bg-green-100', 'text-green-500', 'border-green-400'],
  ['bg-yellow-100', 'text-yellow-500', 'border-yellow-400'],
  ['bg-pink-100', 'text-pink-500', 'border-pink-400'],
  ['bg-teal-100', 'text-teal-500', 'border-teal-400'],
  ['bg-orange-100', 'text-orange-500', 'border-orange-400'],
  ['bg-amber-100', 'text-amber-500', 'border-amber-400'],
  ['bg-lime-100', 'text-lime-500', 'border-lime-400'],
  ['bg-emerald-100', 'text-emerald-500', 'border-emerald-400'],
  ['bg-cyan-100', 'text-cyan-500', 'border-cyan-400'],
  ['bg-violet-100', 'text-violet-500', 'border-violet-400'],
  ['bg-fuchsia-100', 'text-fuchsia-500', 'border-fuchsia-400'],
  ['bg-rose-100', 'text-rose-500', 'border-rose-400'],
];

// get the bg/text/border className combination for a tag, based on its index or (if not available) its length
const getTagsClassNames = (index = 0, length = 0) => {
  const idx = index === -1 ? length : index;
  return tagClassesTuples[idx % tagClassesTuples.length];
};

export default getTagsClassNames;
