import { HOME_PATH as path } from 'constants/paths';

import HomePage from './HomePage';

const HomeRoute = {
  path,
  component: HomePage,
};

export default HomeRoute;
