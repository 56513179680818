import PropTypes from 'prop-types';
import React from 'react';

import { MailOpenIcon } from '@heroicons/react/solid';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const UpdateEmailModal = ({ onClose, onSubmit }) => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  return (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-6 py-6 pb-0">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
            <MailOpenIcon className="w-6" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="dialog-1-title"
            >
              Update your email address
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500" />
            </div>
          </div>

          <div className="flex w-full justify-between mt-3 mb-2">
            <Label htmlFor="link">Password</Label>
          </div>

          <InputFieldText
            name="password"
            type="password"
            ref={register({ required: 'Please type in your current password' })}
            placeholder="Your current password"
          />

          {errors.password && (
            <ErrorMessage>{errors.password.message}</ErrorMessage>
          )}

          <div className="flex w-full justify-between mt-3 mb-2">
            <Label htmlFor="link">New email</Label>
          </div>

          <InputFieldText
            name="newEmail"
            ref={register({ required: 'Please type in the new email' })}
            placeholder="Type your new email here..."
          />

          {errors.newEmail && (
            <ErrorMessage>{errors.newEmail.message}</ErrorMessage>
          )}

          <p className="mt-4 text-sm text-gray-400">
            After updating your email, we&apos;ll send you a confirmation email
            with a link to confirm this new email.
          </p>
        </div>
        <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
          <Button type="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <div className="ml-3">
            <Button submit disabled={!isValid}>
              Update email
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

UpdateEmailModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UpdateEmailModal;
