import React from 'react';

import { useUser, useFirestore, useFirestoreDocData } from 'reactfire';

import { USERS_COLLECTION } from 'constants/firebasePaths';

import AccountForm from './AccountForm/AccountForm';
import ProfileForm from './ProfileForm/ProfileForm';

const UserForm = () => {
  const firestore = useFirestore();
  const { data: user } = useUser();

  const userRef = firestore.doc(`${USERS_COLLECTION}/${user.uid}`);
  const { data: userDoc, status } = useFirestoreDocData(userRef);

  return (
    <>
      <AccountForm user={user} />

      {status === 'success' && (
        <ProfileForm user={user} userRef={userRef} userDoc={userDoc} />
      )}
    </>
  );
};

export default UserForm;
