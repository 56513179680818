import PropTypes from 'prop-types';
import React from 'react';

import capitalize from 'lodash/capitalize';

import DomainAuthority from 'components/DomainAuthority/DomainAuthority';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';
import OpportunityMeta from 'components/opportunities/opportunity/OpportunityMeta';
import OpportunityQuery from 'components/opportunities/opportunity/OpportunityQuery';
import OpportunityTimeLeft from 'components/opportunities/opportunity/OpportunityTimeLeft';
import OpportunityTopics from 'components/opportunities/opportunity/OpportunityTopics';

import OpportunityActions from './OpportunityActions/OpportunityActions';

const Opportunity = ({
  opportunity,
  opportunitiesOps,
  currentField,
  selectedTopics = [],
  onExpand,
  onSelect,
  isSelected,
}) => {
  const {
    custom,
    deadlineUTC,
    domainAuthority,
    enquirySummary,
    journalist,
    mediaOutlet,
    topics,
    query,
  } = opportunity;

  const expired = deadlineUTC.toDate() < new Date();

  return (
    <article
      className={`relative flex flex-col-reverse items-center lg:items-start lg:flex-row lg:justify-center group lg:mt-2 ${
        expired ? 'opacity-50' : ''
      }`}
      data-test-id={`opportunity-${opportunity.uid}`}
    >
      <OpportunityActions
        opportunitiesOps={opportunitiesOps}
        opportunity={opportunity}
        currentField={currentField}
        expired={expired}
        onSelect={() => onSelect(opportunity.uid)}
        isSelected={isSelected}
      />

      <a
        className="flex flex-1 flex-col justify-between relative p-4 pt-2 mb-4 md:rounded-md md:border border-white max-w-prose w-full shadow cursor-pointer hover:border-teal-500 hover:shadow-md bg-white"
        onClick={onExpand}
      >
        <OpportunityTopics
          custom={custom}
          topics={topics}
          selectedTopics={selectedTopics}
        />

        <div className="flex justify-between mt-2">
          <h3 className="text-2xl font-semibold">
            {capitalize(enquirySummary)}
          </h3>
        </div>

        <div className="mt-3 flex items-center space-x-2">
          <PublicationImage name={mediaOutlet} custom={custom} />
          <OpportunityMeta text={mediaOutlet} />

          <DomainAuthority domainAuthority={domainAuthority} />
          <div className="w-1" />
          <JournalistImage name={journalist} />
          <OpportunityMeta text={journalist} />
        </div>

        <OpportunityQuery query={query} collapsed />
      </a>

      <OpportunityTimeLeft
        deadlineUTC={deadlineUTC}
        showClockIcon
        expired={expired}
      />
    </article>
  );
};

Opportunity.propTypes = {
  opportunity: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    mediaOutlet: PropTypes.string.isRequired,
    mediaOutletWebsite: PropTypes.string,
    journalist: PropTypes.string.isRequired,
    deadlineUTC: PropTypes.object.isRequired,
    enquirySummary: PropTypes.string,
    query: PropTypes.array.isRequired,
    topics: PropTypes.array,
    custom: PropTypes.bool,
    domainAuthority: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  opportunitiesOps: PropTypes.shape({
    decline: PropTypes.func.isRequired,
    undecline: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    unsave: PropTypes.func.isRequired,
    respond: PropTypes.func.isRequired,
  }).isRequired,
  currentField: PropTypes.string.isRequired,
  selectedTopics: PropTypes.array,
  onExpand: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default Opportunity;
