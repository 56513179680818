import { useStateMachine } from 'little-state-machine';
import { useAuth } from 'reactfire';

import { defaultUser, defaultCompany } from 'constants/defaultData';
import { LOGIN_PATH } from 'constants/paths';
import useUserData from 'contexts/UserContext/hooks/useUserData';

// Clear state machine
const clearState = (state = {}) => ({
  ...state,
  user: defaultUser,
  company: defaultCompany,
});

// Logs out a user and redirects to the login path
const useHandleLogout = () => {
  const auth = useAuth();
  const userContext = useUserData();
  const { actions } = useStateMachine({ clearState });

  return (e) => {
    e.preventDefault();

    // Clear state
    actions.clearState({});

    // Stop any user context updates from occurring
    userContext.setIsAuthenticating(true);

    auth.signOut().then(() => {
      window.location = LOGIN_PATH;
    });
  };
};

export default useHandleLogout;
