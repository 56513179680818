import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useAnalytics, useStorage } from 'reactfire';

import ExtensionIcon from 'components/icons/ExtensionIcon/ExtensionIcon';

const CompanyPressReleaseFileIcons = ({ title, filePdf, fileDoc, image }) => {
  const analytics = useAnalytics();

  const storage = useStorage();
  const [pdfUrl, setPdfUrl] = useState('#');
  const [docUrl, setDocUrl] = useState('#');
  const [imgUrl, setImgUrl] = useState('#');

  // get download links
  useEffect(
    () =>
      (async () => {
        if (filePdf) {
          const urlPdf = await storage.ref(filePdf.path).getDownloadURL();
          setPdfUrl(urlPdf);
        }
        if (fileDoc) {
          const urlDoc = await storage.ref(fileDoc.path).getDownloadURL();
          setDocUrl(urlDoc);
        }
        if (image) {
          const urlImg = await storage.ref(image.path).getDownloadURL();
          setImgUrl(urlImg);
        }
      })(),
    [filePdf, fileDoc, storage, image]
  );

  const onClick = (type, url) => {
    analytics.logEvent('pressPage_view', {
      action: 'pressRelease_open_fix',
      title,
      type,
      url,
    });
  };

  return (
    <div className="flex space-x-2 text-teal-500">
      {filePdf && (
        <Link
          target="_blank"
          to={{ pathname: pdfUrl }}
          onClick={() => onClick('pdf', pdfUrl)}
          data-test-id={`company-pressRelease-${title}-pdf`}
        >
          <ExtensionIcon type="PDF" className="w-7 h-7" />
        </Link>
      )}
      {fileDoc && (
        <Link
          target="_blank"
          to={{ pathname: docUrl }}
          onClick={() => onClick('doc', docUrl)}
          data-test-id={`company-pressRelease-${title}-doc`}
        >
          <ExtensionIcon type="DOC" className="w-7 h-7" />
        </Link>
      )}
      {image && (
        <Link
          target="_blank"
          to={{ pathname: imgUrl }}
          onClick={() => onClick('img', imgUrl)}
          data-test-id={`company-pressRelease-${title}-image`}
        >
          {(image.type === 'image/jpeg' || image.type === 'image/jpg') && (
            <ExtensionIcon type="JPG" className="w-7 h-7" />
          )}
          {image.type === 'image/png' && (
            <ExtensionIcon type="PNG" className="w-7 h-7" />
          )}
          {image.type === 'image/gif' && (
            <ExtensionIcon type="GIF" className="w-7 h-7" />
          )}
        </Link>
      )}
    </div>
  );
};

CompanyPressReleaseFileIcons.propTypes = {
  title: PropTypes.string,
  filePdf: PropTypes.object,
  fileDoc: PropTypes.object,
  image: PropTypes.object,
};

export default CompanyPressReleaseFileIcons;
