const colorClassNames = [
  'bg-purple-100',
  'bg-indigo-100',
  'bg-red-100',
  'bg-blue-100',
  'bg-green-100',
  'bg-yellow-100',
  'bg-pink-100',
];

// get a color className for a specific number (index), usually used for tags
const getBgColorClassName = (length = 0) =>
  colorClassNames[length % colorClassNames.length];
export default getBgColorClassName;
