import { useRouteMatch } from 'react-router-dom';

import { COMPANY_PATH, LOGIN_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

// checks the current route and returns if we show the signup link or save link
const useRouteMatchAccountLink = () => {
  const { isAnonymous } = useUserData();
  const { companyData } = useCompanyData();
  const slug = companyData?.slug;

  const routeMatchCompany = useRouteMatch(COMPANY_PATH);
  const routeMatchLogin = useRouteMatch(LOGIN_PATH);

  const isOwner = routeMatchCompany?.params?.id === slug ?? false;
  const showSigninLink = !routeMatchLogin && (isAnonymous || !isOwner);

  return showSigninLink;
};

export default useRouteMatchAccountLink;
