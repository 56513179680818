import PropTypes from 'prop-types';
import React from 'react';

import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import { PUBLICATIONS_COLLECTION } from 'constants/firebasePaths';

import DomainAuthorityPill from './DomainAuthorityPill';

const DomainAuthorityFromPublication = ({ publicationName = null }) => {
  const firestore = useFirestore();
  const query = firestore
    .collection(PUBLICATIONS_COLLECTION)
    .where('name', '==', publicationName);
  const { data } = useFirestoreCollectionData(query);
  const domainAuthority = data?.[0]?.domainAuthority;
  if (!domainAuthority) {
    return null;
  }
  return <DomainAuthorityPill domainAuthority={domainAuthority} />;
};

DomainAuthorityFromPublication.propTypes = {
  publicationName: PropTypes.string,
};

export default DomainAuthorityFromPublication;
