import LINE_GRAPH_CONFIG from '../constants/lineGraphConfig';

/**
 * Returns the data needed for the `<Line />` graph component,
 * using the provided labels (x-axis, usually: days), a label and the data points (y-axis) for each label
 */
const createLineGraphData = ({ labels, label, data }) => ({
  labels,
  datasets: [
    {
      ...LINE_GRAPH_CONFIG,
      label,
      data,
    },
  ],
});

export default createLineGraphData;
