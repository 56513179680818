import PropTypes from 'prop-types';
import React from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

import OpportunitiesNavbarTabsItem from './OpportunitiesNavbarTabsItem';

const OpportunitiesNavbarTabs = ({
  tabs,
  opportunities,
  activeTab,
  setActiveTab,
}) => {
  const getCount = (field) =>
    opportunities[field] ? opportunities[field].length : 0;

  return (
    <>
      <div className="hidden xl:flex">
        {tabs.map((tab, index) => {
          const count = getCount(tab.field);
          return (
            <OpportunitiesNavbarTabsItem
              key={tab.field}
              tab={tab}
              count={count}
              active={activeTab === index}
              onClick={() => setActiveTab(index)}
            />
          );
        })}
      </div>
      <div className="xl:hidden py-2 ml-2 xl:ml-4 mt-1 mb-3 text-teal-500">
        <Listbox as="div" value={activeTab} onChange={setActiveTab}>
          {({ open }) => (
            <div className="relative flex flex-col">
              <span className="block w-full rounded-md">
                <Listbox.Button
                  className="flex items-center w-56 sm:w-64 space-x-2 cursor-default relative focus:outline-none font-medium"
                  data-test-id="opportunities-navBar-tabs"
                >
                  <OpportunitiesNavbarTabsItem
                    tab={tabs[activeTab]}
                    count={getCount(tabs[activeTab].field)}
                  />
                  <ChevronDownIcon className="w-4" />
                </Listbox.Button>
              </span>

              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute z-10 mt-12 w-full rounded-md bg-white shadow-lg"
              >
                <Listbox.Options
                  static
                  className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 w-56 sm:w-64"
                >
                  {tabs.map((tab, index) => (
                    <Listbox.Option
                      key={tab.field}
                      value={index}
                      data-test-id={`opportunities-navBar-tabs-${tab.field}`}
                    >
                      {({ active }) => (
                        <div
                          className={`text-gray-900 ${
                            active ? 'bg-gray-50' : ''
                          } cursor-default select-none relative py-2 pl-2`}
                        >
                          <OpportunitiesNavbarTabsItem
                            tab={tab}
                            count={getCount(tab.field)}
                          />
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          )}
        </Listbox>
      </div>
    </>
  );
};

OpportunitiesNavbarTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  opportunities: PropTypes.object.isRequired,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default OpportunitiesNavbarTabs;
