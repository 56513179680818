import PropTypes from 'prop-types';
import React from 'react';

import { OfficeBuildingIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import getCompanyRoute from 'utils/getCompanyRoute';

import AdminCompaniesCompanyTier from './AdminCompaniesCompanyTier';
import AdminCompaniesCompanyTopics from './AdminCompaniesCompanyTopics';
import AdminCompaniesCompanyVCInfo from './AdminCompaniesCompanyVCInfo';
import useTier from './hooks/useTier';

const AdminCompaniesCompany = ({
  company = {},
  activeTier = '',
  topics = [],
  activeCompanySearchInput = '',
}) => {
  // Note: Remapping old subscription names to updated ones
  // TODO: Drop .replace when tiers has been updated at DB level
  const companyTier = useTier(company.owner)
    .replace('Opportunities Package', 'Basic Package')
    .replace('Insights Package', 'Startup Support Package');

  const isSelectedTier = activeTier === companyTier;
  const showAllTiers = activeTier === 'All';

  // Opportunities package -> Basic package
  // Insights package -> Startup support package

  const hasNoFilter = topics.length === 0;
  const companyTopics = company?.topics;

  const matchesSelectedFilters = companyTopics
    ?.map((companyTopic) =>
      topics.some(
        (selectedFilterTopic) => companyTopic.name === selectedFilterTopic.name
      )
    )
    ?.includes(true);

  const matchActiveSearchString = company.name
    .slice(0, activeCompanySearchInput.length)
    .toLowerCase();

  const matchesCompanyName = matchActiveSearchString.includes(
    activeCompanySearchInput.toLowerCase()
  );

  const matchesCompanyStringPhrase =
    company.name[company.name.search(activeCompanySearchInput)] !== undefined;

  if (!isSelectedTier && !showAllTiers) {
    return null;
  }

  // return if not in selected filter
  if (!matchesSelectedFilters && !hasNoFilter) {
    return null;
  }

  // return if no companyName in search input or if string is included in first part of companyName
  if (!matchesCompanyName && !matchesCompanyStringPhrase) {
    return null;
  }

  return (
    <tr>
      <td className="px-4 py-2">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            {company.logo ? (
              <UniversalImage
                image={company.logo}
                alt={`Logo company ${company.name}`}
                widthClass="w-10"
                heightClass="h-10"
                objectFitClass="object-contain"
              />
            ) : (
              <OfficeBuildingIcon className="text-gray-300 w-12 h-12 p-2" />
            )}
          </div>
          <div className="ml-4">
            <Link
              to={getCompanyRoute(company.slug)}
              className="text-sm font-medium text-gray-900"
            >
              {company.name}
            </Link>
          </div>
        </div>
      </td>

      <td className="px-4 py-2 max-w-sm">
        {company.topics?.length > 0 && (
          <AdminCompaniesCompanyTopics topics={company.topics} />
        )}
      </td>

      <AdminCompaniesCompanyTier tier={companyTier} />

      <td className="px-4 py-2">
        {company.vcs?.length > 0 && (
          <AdminCompaniesCompanyVCInfo
            companyName={company.name}
            vcs={company.vcs}
            vcsOther={company.vcsOther}
          />
        )}
      </td>

      {/* 
        Adding additional components:
        Make sure to wrap with <td> to maintain table structure if component/data does not exist.
      */}
    </tr>
  );
};

AdminCompaniesCompany.propTypes = {
  company: PropTypes.object.isRequired,
  activeTier: PropTypes.string,
  topics: PropTypes.array,
  activeCompanySearchInput: PropTypes.string,
};

export default AdminCompaniesCompany;
