import PropTypes from 'prop-types';
import React from 'react';

import { useStateMachine } from 'little-state-machine';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { useUser } from 'reactfire';

import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import InputFieldRadioStackedCards from 'components/form/inputFields/InputFieldRadioStackedCards/InputFieldRadioStackedCards';

import TitleSubtitle from '../TitleSubtitle';
import { updateUser } from '../actions';

const optionsPrKnowledge = [
  'Almost nothing',
  'I know we need it, but not sure how it works',
  'I have worked in or with PR teams before',
  'I know about marketing more broadly',
  "I'm a PR professional",
];

const StepTwo = ({ history, availableSteps = [] }) => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
    getValues,
  } = useForm({ mode: 'onChange' });

  const { actions, state } = useStateMachine({ updateUser });

  const user = useUser();
  const hasUserData = user?.data;

  const onSubmit = () => history.push('/onboarding/three');

  const onChange = () => {
    actions.updateUser(getValues());
  };

  return (
    <form
      className="relative z-10 flex flex-col items-center mx-4"
      onSubmit={handleSubmit(onSubmit)}
      onChange={onChange}
      data-test-id="onboarding-prKnowledge-form"
    >
      <div className="flex flex-col items-center max-w-lg mt-12 mb-8 w-full">
        <TitleSubtitle
          title="How much do you know about PR already?"
          subtitle="This helps us know how much help to give."
        />

        <InputFieldRadioStackedCards
          ref={register({ validate: (value) => value && value.length })}
          name="prKnowledge"
          options={optionsPrKnowledge}
          defaultValue={state.user.prKnowledge}
          testId="onboarding-prKnowledge"
        />
        {errors.prGoals && (
          <ErrorMessage>{errors.prKnowledge.message}</ErrorMessage>
        )}
      </div>
      <div className="mb-8 flex items-center">
        <Button
          size="large"
          submit
          disabled={
            !isValid || !hasUserData || !availableSteps.includes('three')
          }
          data-test-id="onboarding-prKnowledge-submit"
        >
          Next question
        </Button>
      </div>
    </form>
  );
};

StepTwo.propTypes = {
  history: PropTypes.object,
  availableSteps: PropTypes.array,
};

export default withRouter(StepTwo);
