import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import { PhotographIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import ErrorMessage from 'components/form/ErrorMessage';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import { SETTINGS_COMPANY_PATH } from 'constants/paths';
import useUserData from 'contexts/UserContext/hooks/useUserData';

const CompanyHeaderModal = ({ open, uploadFolder, onClose, onSubmit }) => {
  const {
    control,
    setError,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const { isAnonymous } = useUserData();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
            <div className="absolute top-4 right-4 z-10">
              <CloseButton onClick={onClose} />
            </div>
            <div className="px-6 py-6 pb-0">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                <PhotographIcon className="w-6" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="dialog-1-title"
                >
                  Upload a cover photo
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    A nice header image makes a good first impression to
                    visitors.
                  </p>
                </div>
              </div>

              <div className="mt-3">
                <Controller
                  name="image"
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: 'Please upload a header image',
                  }}
                  render={({ onChange, value }) => (
                    <InputFieldUpload
                      name="image"
                      folder={uploadFolder}
                      onChange={onChange}
                      value={value}
                      setError={setError}
                      renderFile={(image, onChangeImage) => (
                        <div className="relative w-full h-40 bg-gray-100">
                          <UniversalImage
                            image={image}
                            alt="Header preview"
                            heightClass="h-40"
                            objectFitClass="object-cover"
                            testId="company-header-modal-image"
                          />
                          <div className="absolute -top-2 -right-2">
                            <CloseButton onClick={() => onChangeImage(null)} />
                          </div>
                        </div>
                      )}
                      testId="company-header-modal-imageInput"
                    />
                  )}
                />
              </div>

              <p className="mt-2 text-xs text-gray-400">
                {' '}
                Upload an image with an aspect ratio of 16x9 or wider
                (approximately 1500x400 or similar).
              </p>

              {errors.image && (
                <ErrorMessage>{errors.image.message}</ErrorMessage>
              )}

              {!isAnonymous && (
                <p className="mt-4 text-sm text-gray-500">
                  To update your company&apos;s name, website, location and
                  logo, please go to the{' '}
                  <Link className="underline" to={SETTINGS_COMPANY_PATH}>
                    company settings page
                  </Link>
                  .
                </p>
              )}
            </div>
            <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
              <Button
                type="secondary"
                onClick={() => onClose()}
                data-test-id="company-header-modal-cancel"
              >
                Cancel
              </Button>
              <div className="ml-3">
                <Button
                  submit
                  disabled={!isValid}
                  eventType="pressPage_update"
                  eventProperties={{ action: 'header_add' }}
                  data-test-id="company-header-modal-update"
                >
                  Update image
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

CompanyHeaderModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadFolder: PropTypes.string.isRequired,
};

export default CompanyHeaderModal;
