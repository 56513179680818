import PropTypes from 'prop-types';
import React from 'react';

import { DuplicateIcon } from '@heroicons/react/solid';
import { useAnalytics } from 'reactfire';

import useNotifications from 'modules/notification/useNotifications';
import copyToClipboard from 'utils/copyToClipboard';

// Button used for copying text to the OS clipboard
// TODO-LOG: Extract the logging logic to a separate `onClickWithAnalytics` hook
const CopyToClipboardButton = ({
  copyText = '',
  moduleName = '',
  eventType = '',
  eventProperties = {},
}) => {
  const { showSuccess } = useNotifications();
  const analytics = useAnalytics();

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    copyToClipboard(copyText);
    showSuccess(
      <span className="flex items-center">
        <DuplicateIcon className="h-6 w-6 mr-2" />{' '}
        <p>
          Copied <strong> {moduleName} </strong> to clipboard.
        </p>
      </span>
    );
    analytics.logEvent(eventType, eventProperties);
  };

  return (
    <button
      type="button"
      className="p-2 border border-gray-300 rounded-full hover:bg-gray-50 focus:outline-none focus:ring"
      onClick={onClick}
    >
      <DuplicateIcon className="w-4 h-4 text-teal-500" />
    </button>
  );
};

CopyToClipboardButton.propTypes = {
  copyText: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  eventType: PropTypes.string,
  eventProperties: PropTypes.object,
};

export default CopyToClipboardButton;
