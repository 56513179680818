import React from 'react';

import { ArrowRightIcon } from '@heroicons/react/outline';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from 'reactfire';

import Button from 'components/buttons/Button';
import HomeLogo from 'components/layout/Navbar/HomeLogo';
import { LOGIN_PATH } from 'constants/paths';
import useRedirectOnboarding from 'hooks/useRedirectOnboarding';
import { ReactComponent as BgDotsGrey } from 'static/bg/bg--texture-dots-gray.svg';

import StepOne from './01-StepOne/StepOne';
import StepTwo from './02-StepTwo/StepTwo';
import StepThree from './03-StepThree/StepThree';
import StepFour from './04-StepFour/StepFour';
import Progress from './Progress';
import useStepValidation from './hooks/useStepValidation';

const steps = [
  {
    number: 1,
    title: 'Step 1',
    name: 'one',
    component: StepOne,
  },
  {
    number: 2,
    title: 'Step 2',
    name: 'two',
    component: StepTwo,
  },
  {
    number: 3,
    title: 'Step 3',
    name: 'three',
    component: StepThree,
  },
  {
    number: 4,
    title: 'Step 4',
    name: 'four',
    component: StepFour,
  },
];

const OnboardingPage = () => {
  const auth = useAuth();
  const history = useHistory();
  const { step: stepName } = useParams();
  const availableSteps = useStepValidation();

  const [showOnboarding, showLoginButton] = useRedirectOnboarding();
  if (!showOnboarding) {
    return null;
  }
  const { component: StepComponent, number: currentStep } = steps.find(
    (step) => step.name === stepName
  );

  return (
    <div className="relative bg-gray-50 min-h-screen">
      <Helmet>
        <title>Create a press page - step {stepName} | MVPR</title>
      </Helmet>
      <div className="absolute z-0 right-0 top-0">
        <BgDotsGrey />
      </div>
      <div className="absolute z-0 left-0 bottom-0">
        <BgDotsGrey />
      </div>

      <nav className="relative z-10 min-w-full flex items-center md:px-12 lg:px-24 justify-center md:justify-between">
        <HomeLogo className="hidden md:block" />

        {/* this should go into the Navbar component */}
        <Progress
          steps={steps}
          currentStep={currentStep}
          availableSteps={availableSteps}
        />

        {/* Signin button */}
        <div className="hidden md:block">
          {showLoginButton ? (
            <Button
              type="secondary"
              onClick={() => history.push(LOGIN_PATH)}
              data-test-id="onboarding-login"
            >
              <div className="flex justify-center items-center">
                <div className="mr-2">Log in to MVPR</div>
                <ArrowRightIcon className="w-4" />
              </div>
            </Button>
          ) : (
            <Button
              type="secondary"
              onClick={() => {
                auth.signOut().then(() => {
                  // we cannot use history.push here because the /login route will
                  // use the old state of the UserContext and immediately redirect here
                  window.location = LOGIN_PATH;
                });
              }}
              data-test-id="onboarding-login"
            >
              Log out
            </Button>
          )}
        </div>
      </nav>

      <StepComponent availableSteps={availableSteps} />
    </div>
  );
};

export default OnboardingPage;
