import { useState } from 'react';

import { useFirebaseApp } from 'reactfire';

// redirects user to the Stripe portal, given that they're logged in
const useRedirectToStripePortal = () => {
  const functions = useFirebaseApp().functions('europe-west2');

  const portalFunction = functions.httpsCallable(
    // TODO: move this to a constants file
    'ext-firestore-stripe-payments-createPortalLink'
  );

  const [isRedirecting, setIsRedirecting] = useState(false);

  const redirectToStripePortal = async (e) => {
    e.preventDefault();
    setIsRedirecting(true);
    let url;
    try {
      const { data } = await portalFunction({
        // TODO: turn this into a function
        returnUrl: `${window.location.origin}${window.location.pathname}`,
        locale: 'auto',
      });
      if (data) {
        url = data.url;
      }
    } catch (err) {
      // TODO-LOG: this should send an error to the logger (which we don't have yet)
      setIsRedirecting(false);
    }
    if (url) {
      window.location.assign(url);
    }
  };

  return { redirectToStripePortal, isRedirecting };
};

export default useRedirectToStripePortal;
