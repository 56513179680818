import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const OpportunitiesNavbarTabsItem = ({ tab, count, active, onClick }) => (
  <a
    key={tab.field}
    className={classNames(
      'block pl-2 xl:py-3 xl:pb-4 xl:px-4 cursor-pointer w-auto space-x-1 text-sm hover:text-teal-500',
      { 'border-b-2 border-teal-500 font-medium text-teal-500': active }
    )}
    onClick={onClick}
    data-test-id={`tab-${tab.field}`}
  >
    <span className="hidden sm:inline xl:hidden 2xl:inline">{tab.emoji}</span>{' '}
    <span>{tab.title}</span>
    <span className="inline-block bg-teal-100 py-0.5 px-2 xl:px-3 rounded-full text-sm text-teal-600">
      {count}
    </span>
  </a>
);

OpportunitiesNavbarTabsItem.propTypes = {
  tab: PropTypes.shape({
    field: PropTypes.string,
    emoji: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  count: PropTypes.number.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default OpportunitiesNavbarTabsItem;
