import { useEffect } from 'react';

import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import {
  COMPANIES_COLLECTION,
  CUSTOMERS_COLLECTION,
} from 'constants/firebasePaths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

// verifies that a payment has indeed be collected from a user and sets the company's `signupStatus` to 2 as a result
const useCheckPayment = () => {
  const { uid } = useUserData();
  const { companyData } = useCompanyData();

  const firestore = useFirestore();
  const subscriptionsRef = firestore.collection(
    `${CUSTOMERS_COLLECTION}/${uid}/subscriptions`
  );

  const { data } = useFirestoreCollectionData(subscriptionsRef);

  useEffect(() => {
    (async () => {
      const paymentConfirmed = data.find(
        (subscription) => subscription.status === 'active'
      );

      if (!paymentConfirmed) {
        return;
      }

      await firestore
        .doc(`${COMPANIES_COLLECTION}/${companyData.uid}`)
        .update({ signupStatus: 2 });
    })();
  }, [companyData.slug, companyData.uid, data, firestore, uid]);
};

export default useCheckPayment;
