import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import { REGEX_HANDLE_TWITTER, REGEX_HANDLE_LINKEDIN } from 'constants/regex';

const CompanyMetaFoundersEditModal = ({
  open,
  uploadFolder,
  onClose,
  onSubmit,
  founder,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
            <div className="absolute top-4 right-4 z-10">
              <CloseButton onClick={onClose} />
            </div>
            <div className="px-6 py-6 pb-0">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                <UserIcon className="w-6" />
              </div>
              <div className="mt-3 text-center">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="dialog-1-title"
                >
                  {founder ? 'Edit' : 'Add a'} founder profile
                </h3>
                {!founder && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Journalists love reading about founders and use this as a
                      way of getting to know a company. Add yours to improve
                      journalist engagement with your page.
                    </p>
                  </div>
                )}
              </div>

              <div className="mt-5 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6 justify-between items-start">
                <div className="w-full sm:w-1/2">
                  <Label htmlFor="date">Founder name*</Label>
                  <InputFieldText
                    name="name"
                    ref={register({
                      required: 'Please put in a name',
                      maxLength: {
                        value: 140,
                        message: 'Please keep the name below 140 characters',
                      },
                    })}
                    placeholder="Type in the name here..."
                    defaultValue={founder?.name ?? null}
                    data-test-id="company-meta-founders-editModal-name"
                  />
                  {errors.name && (
                    <ErrorMessage testId="company-meta-founders-editModal-name-error">
                      {errors.name.message}
                    </ErrorMessage>
                  )}
                </div>

                <div className="w-full sm:w-1/2">
                  <Label htmlFor="position">Position*</Label>
                  <InputFieldText
                    name="position"
                    ref={register({
                      required: 'Please put in a position',
                      maxLength: {
                        value: 50,
                        message: 'Please keep the position below 50 characters',
                      },
                    })}
                    placeholder="E.g. CEO, COO, CTO..."
                    defaultValue={founder?.position ?? null}
                    data-test-id="company-meta-founders-editModal-position"
                  />
                  {errors.position && (
                    <ErrorMessage testId="company-meta-founders-editModal-position-error">
                      {errors.position.message}
                    </ErrorMessage>
                  )}
                </div>
              </div>

              <div className="mt-3">
                <Label htmlFor="link">Profile picture </Label>
              </div>

              <div className="mt-2">
                <Controller
                  name="image"
                  control={control}
                  defaultValue={founder?.image ?? null}
                  render={({ onChange, value }) => (
                    <InputFieldUpload
                      name="image"
                      folder={uploadFolder}
                      onChange={onChange}
                      value={value}
                      setError={setError}
                      renderFile={(image, onChangeImage) => (
                        <div className="relative h-40 bg-gray-100">
                          <UniversalImage
                            image={image}
                            alt="Headshot preview of founder"
                            heightClass="h-40"
                            objectFitClass="object-contain"
                            testId="company-meta-founders-editModal-image"
                          />
                          <div className="absolute -top-2 -right-2">
                            <CloseButton onClick={() => onChangeImage(null)} />
                          </div>
                        </div>
                      )}
                      data-test-id="company-meta-founders-editModal-imageInput"
                    />
                  )}
                />
              </div>
              <p className="mt-2 text-xs text-gray-400">
                Square images work best
              </p>
              {errors.image && (
                <ErrorMessage>{errors.image.message}</ErrorMessage>
              )}

              <div className="mt-3 flex flex-col space-y-4 sm:flex-row sm:space-y-0 justify-between items-start">
                <div className="w-full sm:w-2/3 sm:mr-4">
                  <Label htmlFor="linkedinHandle">LinkedIn URL</Label>
                  <InputFieldText
                    name="linkedinHandle"
                    ref={register({
                      pattern: {
                        value: REGEX_HANDLE_LINKEDIN,
                        message: 'Please use a valid handle',
                      },
                    })}
                    placeholder="reidhoffman"
                    defaultValue={founder?.linkedinHandle ?? null}
                    prefix="linkedin.com/in/"
                    data-test-id="company-meta-founders-editModal-linkedinHandle"
                  />

                  {errors.linkedinHandle && (
                    <ErrorMessage testId="company-meta-founders-editModal-linkedinHandle-error">
                      {errors.linkedinHandle.message}
                    </ErrorMessage>
                  )}
                </div>
                <div className="w-full sm:w-auto">
                  <Label htmlFor="twitterHandle">Twitter handle</Label>

                  <InputFieldText
                    name="twitterHandle"
                    ref={register({
                      pattern: {
                        value: REGEX_HANDLE_TWITTER,
                        message: 'Please use a valid handle',
                      },
                    })}
                    placeholder="jackdorsey"
                    defaultValue={founder?.twitterHandle ?? null}
                    prefix="@"
                    data-test-id="company-meta-founders-editModal-twitterHandle"
                  />

                  {errors.twitterHandle && (
                    <ErrorMessage testId="company-meta-founders-editModal-twitterHandle-error">
                      {errors.twitterHandle.message}
                    </ErrorMessage>
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
              <Button
                type="secondary"
                onClick={() => onClose()}
                data-test-id="company-meta-founders-editModal-cancel"
              >
                Cancel
              </Button>
              <div className="ml-3">
                <Button
                  submit
                  disabled={!isValid}
                  eventType="pressPage_update"
                  eventProperties={{ action: 'founder_add' }}
                  data-test-id="company-meta-founders-editModal-submit"
                >
                  {founder ? 'Update' : 'Add'} founder
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

CompanyMetaFoundersEditModal.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  uploadFolder: PropTypes.string.isRequired,
  founder: PropTypes.shape({
    name: PropTypes.string,
    position: PropTypes.string,
    image: PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
    }),
    linkedinHandle: PropTypes.string,
    twitterHandle: PropTypes.string,
  }),
};

export default CompanyMetaFoundersEditModal;
