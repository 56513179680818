import { withRouter } from 'react-router-dom';

import useRedirectHome from 'hooks/useRedirectHome';

const HomePage = () => {
  useRedirectHome();
  return null;
};

export default withRouter(HomePage);
