import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

const CompanyPressReleaseWrapper = ({
  showOwnerView = false,
  onClick,
  children,
}) => {
  const className =
    'relative z-0 flex flex-1 flex-col justify-between items-start p-6 rounded-lg text-sm border-gray-200 bg-white border';

  if (showOwnerView) {
    return <li className={className}>{children}</li>;
  }

  return (
    <Link to="#" onClick={onClick} className="flex">
      <li className={`${className} hover:border-teal-500 hover:shadow-md`}>
        {children}
      </li>
    </Link>
  );
};

CompanyPressReleaseWrapper.propTypes = {
  showOwnerView: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default CompanyPressReleaseWrapper;
