import uniq from 'lodash/uniq';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import {
  CLASSIFIED_OPPORTUNITIES_COLLECTION,
  COMPANIES_SWEETHEARTS_COLLECTION,
} from 'constants/firebasePaths';

// retrieve a list of unique journalists names from collections
// `opportunities` and `companies_sweethearts`
const useUniqueJournalistsNames = () => {
  const firestore = useFirestore();

  // get opportunities collection
  const opportunitiesCollection = firestore.collection(
    CLASSIFIED_OPPORTUNITIES_COLLECTION
  );
  const opportunities = useFirestoreCollectionData(
    opportunitiesCollection,
    defaultOptions
  );
  // get journalists' names from opportunities
  const opportunitiesJournalistsNames = opportunities.data
    .map(({ journalist }) => journalist || null)
    .sort()
    .filter(Boolean);

  // get sweetheart lists collection
  const companiesSweetheartsCollection = firestore.collection(
    COMPANIES_SWEETHEARTS_COLLECTION
  );
  const companiesSweethearts = useFirestoreCollectionData(
    companiesSweetheartsCollection,
    defaultOptions
  );
  // get journalists' names from companies sweethearts lists
  const companiesSweetheartsJournalistsNames = companiesSweethearts.data
    .flatMap((c) => c?.journalists?.map((j) => j.name))
    .filter(Boolean);

  // concat journalists names
  const journalistsNames = opportunitiesJournalistsNames.concat(
    companiesSweetheartsJournalistsNames
  );
  // return array without duplicates
  return uniq(journalistsNames).sort();
};

export default useUniqueJournalistsNames;
