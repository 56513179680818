import { useEffect, useMemo } from 'react';

import { useStateMachine } from 'little-state-machine';
import { useParams, useHistory, matchPath } from 'react-router-dom';

import { ONBOARDING_PATH } from 'constants/paths';

// Custom hook that returns available steps for a given form,
// and redirects to the last valid step if the user tries to go to an invalid step

const useStepValidation = () => {
  const { step: stepName } = useParams();
  const history = useHistory();
  const { state } = useStateMachine();

  // We create an array of all available steps
  const availableSteps = useMemo(() => {
    const steps = ['one'];
    const {
      user: { prGoals, prKnowledge, prGoalsOther },
      company: { topics, audiences },
    } = state;

    // If the user has filled previous forms we add the next step
    if (prGoals.length || prGoalsOther) {
      steps.push('two');
    }
    if (prKnowledge && steps.includes('two')) {
      steps.push('three');
    }
    if (topics.length && audiences.length && steps.includes('three')) {
      steps.push('four');
    }

    return steps;
  }, [state]);

  useEffect(() => {
    const goToLastValidStep = () =>
      history.replace(availableSteps[availableSteps.length - 1]);

    const isOnboarding = matchPath(history.location.pathname, {
      path: ONBOARDING_PATH,
      exact: true,
    });

    // If the user tries to go to a step that is not available we redirect him to the last available step
    if (!availableSteps.includes(stepName) && isOnboarding) {
      goToLastValidStep();
    }
  }, [stepName, availableSteps, history]);

  return availableSteps;
};

export default useStepValidation;
