import PropTypes from 'prop-types';
import React from 'react';

import useUserData from 'contexts/UserContext/hooks/useUserData';

import Analytics from './Analytics';

const AnalyticsAdmin = ({ slug }) => {
  const user = useUserData();

  if (!user.claimsAdmin) {
    return "You're not authorised to view this page";
  }

  return <Analytics slug={slug} />;
};

AnalyticsAdmin.propTypes = {
  slug: PropTypes.string,
};

export default AnalyticsAdmin;
