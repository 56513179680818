import { useEffect } from 'react';

import { sanitizeUrl } from '@braintree/sanitize-url';
import debounce from 'lodash/debounce';

import isUrl from 'utils/validate/isUrl';

import usePrevious from './usePrevious';

const OPENGRAPH_API_ENDPOINT = 'https://opengraph.io/api/1.1/site';
const OPENGRAPH_APP_ID = process.env.REACT_APP_OPENGRAPH_app_id;
const DEBOUNCE_DELAY = 1500;

const createFetchUrl = (url = '') =>
  `${OPENGRAPH_API_ENDPOINT}/${encodeURIComponent(
    url
  )}?app_id=${OPENGRAPH_APP_ID}`;

// fetch open graph data, parse the string and return the json
const createFetchDebounceFn = (url = '') =>
  debounce(
    (cb) =>
      fetch(createFetchUrl(url)).then((res) =>
        res.json().then((json) => cb(json))
      ),
    DEBOUNCE_DELAY
  );

// fetches the open graph data for a URL and calls a callback with the parsed result
const useOpenGraphApi = ({ url, defaultUrl, setIsLoading, callback }) => {
  // save the default value in case watchableUrl is undefined.
  // Or it'll trigger an update when watchableUrl turns from `undefined` to a string
  const previousUrl = usePrevious(url || defaultUrl);

  useEffect(() => {
    let debounceFn;
    (async () => {
      if (!url) {
        return;
      }

      if (previousUrl === url) {
        return;
      }

      const sanitizedUrl = sanitizeUrl(url);

      if (!isUrl(sanitizedUrl)) {
        return;
      }

      setIsLoading(true);

      // configure the fetch function with the URL
      debounceFn = createFetchDebounceFn(sanitizedUrl);

      // call the debounce with the evaluating callback
      debounceFn((res) => {
        setIsLoading(false);
        if (!res?.error && res?.hybridGraph) {
          callback(false, res.hybridGraph);
        }

        if (res?.error) {
          callback(true, res.error);
          // TODO-LOG: send this to the logger
        }
      });
    })();

    return () => {
      setIsLoading(false);
      debounceFn?.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, setIsLoading]);
};

export default useOpenGraphApi;
