import { useFirestore, useFirestoreCollection } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';

// get a company ref by its slug
const useCompanyDataRefBySlug = (slug) => {
  const firestore = useFirestore();

  let query = firestore.collection(COMPANIES_COLLECTION);

  if (slug) {
    query = query.where('slug', '==', slug);
  }

  // get ref from collection
  const companyCollection = useFirestoreCollection(query, defaultOptions);
  return companyCollection?.data?.docs?.[0]?.ref ?? null;
};

export default useCompanyDataRefBySlug;
