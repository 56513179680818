import PropTypes from 'prop-types';
import React from 'react';

import { sum } from 'lodash';
import { Line } from 'react-chartjs-2';

import Spinner from 'components/Spinner';

import CHART_OPTIONS from './constants/chartOptions';
import createLineGraphData from './helpers/createLineGraphData';
import generatePageData from './helpers/generatePageData';

const AnalyticsOverView = ({ daysArray, reportRows, period }) => {
  const { pageTimesData, pageViewsData } = generatePageData(
    reportRows,
    daysArray
  );

  const pageViews = createLineGraphData({
    labels: daysArray,
    label: 'Views',
    data: pageViewsData,
  });

  const pageTimes = createLineGraphData({
    labels: daysArray,
    label: 'Seconds on average',
    data: pageTimesData,
  });

  const pageViewsSum = sum(pageViewsData.map((num) => parseInt(num, 10)));
  const pageTimesSum = (
    sum(pageTimesData.map((num) => parseInt(num, 10))) / pageTimesData.length
  ).toFixed(0);

  return (
    <>
      <div className="col-span-12 lg:col-span-6 p-4 border rounded-md shadow-sm bg-gray-50 relative">
        <div className="pb-16">
          <h2 className="text-sm font-medium">Press Page views</h2>
          {reportRows && (
            <div className="absolute mt-2 flex items-baseline">
              <p className="text-4xl font-medium">{pageViewsSum}</p>
              <p className="text-sm text-gray-400 ml-2">
                in the {period.toLowerCase()}
              </p>
            </div>
          )}
        </div>
        {pageViews && (
          <Line
            data={pageViews}
            options={CHART_OPTIONS}
            className={`${!reportRows && 'opacity-0'}`}
          />
        )}
        {!reportRows && <Spinner />}
      </div>
      <div className="col-span-12 lg:col-span-6 p-4 border rounded-md shadow-sm bg-gray-50 relative">
        <div className="pb-16">
          <h2 className="text-sm font-medium">
            Average time on Press Page (s)
          </h2>
          {reportRows && (
            <div className="absolute mt-2 flex items-baseline">
              <p className="text-4xl font-medium">{pageTimesSum}s</p>
              <p className="text-sm text-gray-400 ml-2">
                in the {period.toLowerCase()}
              </p>
            </div>
          )}
        </div>
        {pageTimes && (
          <Line
            data={pageTimes}
            options={CHART_OPTIONS}
            className={`${!reportRows && 'opacity-0'}`}
          />
        )}
        {!reportRows && <Spinner />}
      </div>
    </>
  );
};

AnalyticsOverView.propTypes = {
  period: PropTypes.string,
  daysArray: PropTypes.array.isRequired,
  reportRows: PropTypes.array,
};

export default AnalyticsOverView;
