import createTimestampFromDateObj from './createTimestampFromDateObj';

// curried functions that calls onChange, then takes the changed date, checks if it is in the future and calls the corresponding success/error callbacks
const createOnChangeIsFutureDate =
  ({ date, onError, onSuccess }) =>
  (onChange) =>
  (val) => {
    onChange(val);
    const newDate = createTimestampFromDateObj(date, true);

    if (newDate > new Date()) {
      // setError(field, { message: 'Date cannot be in the future' });
      onError();
    } else {
      // clearErrors(field);
      onSuccess();
    }
  };

export default createOnChangeIsFutureDate;
