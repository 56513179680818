import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/lodash';
import { Link } from 'react-router-dom';

// Navigation Link in the mobile nav overlay
const MobileNavLink = ({
  link = '',
  onClick = noop,
  isSelected = false,
  children = null,
}) => (
  <li className="px-3">
    <Link
      onClick={onClick}
      to={link}
      className={classNames(
        'flex items-center py-2 px-3 rounded-md text-white hover:bg-white hover:bg-opacity-25 focus:outline-none',
        { 'bg-white bg-opacity-25': isSelected }
      )}
      role="menuitem"
    >
      {children}
    </Link>
  </li>
);

MobileNavLink.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

export default MobileNavLink;
