import padStart from 'lodash/padStart';

// Returns the hour and minute from a timestamp
const getTimeFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const hour = padStart(date.getUTCHours(), '2', '0');
  const min = padStart(date.getUTCMinutes(), 2, '0');

  return `${hour}:${min}`;
};

export default getTimeFromTimestamp;
