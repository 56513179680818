import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ShareIcon } from '@heroicons/react/outline';
import { useHistory, Link } from 'react-router-dom';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import { SETTINGS_COMPANY_PATH } from 'constants/paths';
import stripProtocol from 'utils/data/stripProtocol';
import stripTrailingSlash from 'utils/data/stripTrailingSlash';

import CompanyMetaDescription from './CompanyMetaDescription/CompanyMetaDescription';
import CompanyMetaFounders from './CompanyMetaFounders/CompanyMetaFounders';
import CompanyMetaPlace from './CompanyMetaPlace';
import CompanyMetaShareModal from './CompanyMetaShareModal';
import CompanyMetaTopics from './CompanyMetaTopics';
import CompanyMetaVideo from './CompanyMetaVideo';

const CompanyMeta = ({
  slug,
  name,
  website: websiteRaw,
  foundedDate,
  location,
  description,
  topics,
  companyRef,
  showOwnerView,
  foundersCrud,
  videosRef,
}) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState();
  const history = useHistory();

  const website = stripTrailingSlash(stripProtocol(websiteRaw));

  return (
    <>
      <div
        className={`relative md:absolute ${
          showOwnerView ? 'md:left-16' : ''
        } w-full flex-none md:min-h-screen p-6 pr-0 pt-12 md:pt-20 md:w-80 bg-white shadow md:shadow-none`}
      >
        <div className="flex justify-between items-center pr-6">
          <h2
            className="text-2xl font-medium break-words"
            data-test-id="company-meta-title"
          >
            {name}
          </h2>
          {showOwnerView && (
            <DotsMenu
              options={[
                {
                  label: 'Edit company settings',
                  onClick: () => history.push(SETTINGS_COMPANY_PATH),
                  testId: 'company-meta-title-menu-edit',
                },
              ]}
              theme="medium"
              testId="company-meta-title-menu"
            />
          )}
        </div>
        <Link
          to="#"
          className="absolute top-32 mt-1.5 md:mt-0 right-6 md:top-4 md:right-4 md:bottom-auto border border-gray-200 rounded-full p-2 hover:bg-gray-50 cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            setIsShareModalOpen(true);
          }}
        >
          <ShareIcon className="w-5 h-5 -mt-px text-teal-500" />
        </Link>
        <CompanyMetaPlace
          website={website}
          location={location}
          foundedDate={foundedDate}
          showOwnerView={showOwnerView}
        />

        <CompanyMetaVideo videosRef={videosRef} />

        {/* Description */}
        <CompanyMetaDescription
          description={description}
          companyRef={companyRef}
          showOwnerView={showOwnerView}
        />

        {/* Founders */}
        <CompanyMetaFounders
          crud={foundersCrud}
          showOwnerView={showOwnerView}
          companyId={companyRef.id}
        />

        {/* Topics */}
        <CompanyMetaTopics topics={topics} showOwnerView={showOwnerView} />
      </div>

      {/* Share modal */}
      {isShareModalOpen && (
        <CompanyMetaShareModal
          slug={slug}
          onClose={() => setIsShareModalOpen(false)}
        />
      )}
    </>
  );
};

CompanyMeta.propTypes = {
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.string,
  website: PropTypes.string,
  foundedDate: PropTypes.shape({
    month: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  description: PropTypes.string,
  topics: PropTypes.array,
  showOwnerView: PropTypes.bool.isRequired,
  companyRef: PropTypes.shape({
    id: PropTypes.string,
    collection: PropTypes.func.isRequired,
  }).isRequired,
  foundersCrud: PropTypes.array.isRequired,
  videosRef: PropTypes.object.isRequired,
};

export default CompanyMeta;
