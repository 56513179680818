import PropTypes from 'prop-types';
import React from 'react';

import { BookmarkIcon, ThumbDownIcon } from '@heroicons/react/solid';

import Button from 'components/buttons/Button';
import useLogOpportunityInteraction from 'routes/Opportunities/hooks/useLogOpportunityInteraction';

const OpportunityResponseModalActions = ({
  showResponseForm,
  setShowResponseForm,
  currentField,
  onClose,
  opportunitiesOps,
  opportunity,
}) => {
  const logOpportunityInteraction = useLogOpportunityInteraction();

  const showActions = !['responded', 'declined'].includes(currentField);

  if (showResponseForm || currentField === 'responded') {
    return null;
  }

  return (
    <div className="flex w-full justify-between bg-gray-50 p-6 rounded-b-lg space-x-2">
      {showActions && (
        <>
          <div className="flex space-x-2">
            <Button
              size="small"
              type="secondary"
              className="space-x-1"
              onClick={() => {
                opportunitiesOps.decline(opportunity);
                onClose();
              }}
            >
              <ThumbDownIcon className="w-4" /> <span>Decline</span>
            </Button>

            {currentField !== 'saved' && (
              <Button
                size="small"
                type="secondary"
                className="space-x-1"
                onClick={() => {
                  opportunitiesOps.save(opportunity);
                  onClose();
                }}
              >
                <BookmarkIcon className="w-4" /> <span>Save for later</span>
              </Button>
            )}

            {currentField === 'saved' && (
              <Button
                size="small"
                type="secondary"
                className="space-x-1"
                onClick={() => {
                  opportunitiesOps.unsave(opportunity);
                  onClose();
                }}
              >
                <BookmarkIcon className="w-4" /> <span>Unsave</span>
              </Button>
            )}
          </div>
          {currentField !== 'declined' && (
            <Button
              size="small"
              onClick={() => {
                logOpportunityInteraction('respond', opportunity);
                setShowResponseForm(true);
              }}
              data-test-id="opportunity-responseModal-actions-respond"
            >
              Respond
            </Button>
          )}
        </>
      )}

      {currentField === 'declined' && (
        <Button
          size="small"
          type="secondary"
          className="space-x-1"
          onClick={() => {
            opportunitiesOps.undecline(opportunity);
            onClose();
          }}
        >
          <BookmarkIcon className="w-4" /> <span>Reactivate</span>
        </Button>
      )}
    </div>
  );
};

OpportunityResponseModalActions.propTypes = {
  showResponseForm: PropTypes.bool.isRequired,
  setShowResponseForm: PropTypes.func.isRequired,
  currentField: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  opportunitiesOps: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
};

export default OpportunityResponseModalActions;
