// Returns the comma-separated tiers
// e.g. => Tier 1,2, Other
const getTiers = (filter) =>
  filter
    .map((f) => (f?.index < 4 ? f?.index : f?.label))
    .sort()
    .join(', ');

const getFilterByText = (filter = []) => {
  if (filter.length >= 1 && filter[0]?.index !== 4) {
    return `Tier ${getTiers(filter)}`;
  }
  if (filter.length === 1 && filter[0].index === 4) return `Other`;
  return '';
};

export default getFilterByText;
