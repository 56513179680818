import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';

const useTier = (owner) => {
  const firestore = useFirestore();
  const subscriptionsCollection = firestore.collection(
    `customers/${owner}/subscriptions`
  );
  const subscriptions = useFirestoreCollectionData(
    subscriptionsCollection,
    defaultOptions
  );

  return (
    subscriptions?.data?.[0]?.items?.[0]?.price?.product?.name ??
    'No Subscription'
  );
};
export default useTier;
