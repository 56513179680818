import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

import Button from 'components/buttons/Button';
import { ReactComponent as IconGoogle } from 'static/icon-google.svg';

// Contains additional signup methods. For now, just Google.
const SocialLogin = ({
  signInWithGoogle = noop,
  signup = false,
  disabled = false,
}) => (
  <div className="mt-6">
    <div className="relative">
      <div className="absolute inset-0 flex items-center">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center text-sm">
        <span className="px-2 bg-white text-gray-500">Or sign in with</span>
      </div>
    </div>

    <div className="mt-6 grid grid-cols-1 gap-3">
      <Button
        disabled={disabled}
        type="secondary"
        onClick={(e) => {
          e.preventDefault();
          signInWithGoogle();
        }}
        eventType={signup ? 'sign_up' : 'login'}
        eventProperties={{ method: 'google' }}
      >
        <span className="sr-only">Sign in with Google</span>
        <div
          className={classNames('w-5 h-5', {
            'text-gray-500': !disabled,
            'text-gray-100': disabled,
          })}
        >
          <IconGoogle />
        </div>
      </Button>
    </div>
  </div>
);

SocialLogin.propTypes = {
  signInWithGoogle: PropTypes.func.isRequired,
  signup: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SocialLogin;
