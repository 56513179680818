import { useStateMachine } from 'little-state-machine';
import { useAuth } from 'reactfire';

import defaultUserImageUrl from 'static/User.png';

// return the photo URL of the currently logged-in user
const usePhotoUrl = () => {
  const { state } = useStateMachine();
  const auth = useAuth();

  if (state.user && state.user.photoURL) {
    return state.user.photoURL;
  }

  if (auth.currentUser && auth.currentUser.photoURL) {
    return auth.currentUser.photoURL;
  }

  return defaultUserImageUrl;
};

export default usePhotoUrl;
