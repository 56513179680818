import PropTypes from 'prop-types';
import React from 'react';

import AnalyticsContainer from './AnalyticsContainer';
import { getRowsForAction } from './helpers/reportRowsHelpers';

const AnalyticsCompanyProfile = ({ reportRows, action, title }) => {
  const rows = getRowsForAction(reportRows, action);

  const reportRow = rows.length
    ? rows.map((row) => ({
        count: row.metricValues[0].value,
      }))
    : 0;

  const count = reportRow && reportRow[0] && reportRow[0].count;

  return (
    <AnalyticsContainer title={title} loading={!reportRows} empty={count === 0}>
      <div className="flex min-h-full items-center justify-start md:justify-center text-sm  space-x-2">
        <div className="flex items-center justify-center w-12 h-12 rounded-full text-xl text-white font-medium bg-teal-500">
          {count}
        </div>
        <p>{count === '1' ? 'time' : 'times'} copied to clipboard</p>
      </div>
    </AnalyticsContainer>
  );
};

AnalyticsCompanyProfile.propTypes = {
  reportRows: PropTypes.array,
  action: PropTypes.string,
  title: PropTypes.string,
};

export default AnalyticsCompanyProfile;
