import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { ReactComponent as Icon } from 'static/mvpr-icon.svg';
import { ReactComponent as Logo } from 'static/mvpr-logo.svg';

// Displays the MVPR logo linking back to the home route
const HomeLogo = ({
  homeRoute = '/',
  className = '',
  testId = '',
  white = false,
  iconOnly = false,
}) => (
  <h1
    className={classNames('flex items-center', {
      'text-teal-500': !white,
      'text-white': white,
    })}
  >
    {iconOnly ? (
      <Icon
        className={classNames('w-10/12 m-auto', className)}
        alt="MVPR logo"
      />
    ) : (
      <Link to={homeRoute} data-test-id={testId}>
        <Logo
          className={classNames('h-auto w-24 -mb-2', className)}
          alt="MVPR logo"
        />
      </Link>
    )}
    <span className="hidden">MVPR</span>
  </h1>
);

HomeLogo.propTypes = {
  homeRoute: PropTypes.string,
  className: PropTypes.string,
  testId: PropTypes.string,
  white: PropTypes.bool,
  iconOnly: PropTypes.bool,
};

export default HomeLogo;
