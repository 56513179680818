import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash';

const SnackbarProviderButton = ({ onClick = noop }) => (
  <button
    type="button"
    aria-label="Close"
    onClick={onClick}
    className="rounded-md inline-flex text-white"
  >
    <span className="sr-only">Close</span>

    <svg
      className="h-5 w-5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  </button>
);

SnackbarProviderButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default React.memo(SnackbarProviderButton);
