import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import { DocumentTextIcon, DocumentIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';

import Spinner from 'components/Spinner';
import UniversalImage from 'components/UniversalImage/UniversalImage';
import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import TextCounter from 'components/form/TextCounter';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import { presetPdf, presetDoc } from 'constants/uploadPresets';
import { getDateRange, getDateRangeDefault } from 'utils/date/dateRange';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

const CompanyPressReleaseEditModal = ({
  open,
  pressRelease,
  uploadFolder,
  onClose,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    formState: { isValid },
    watch,
  } = useForm({ mode: 'onChange' });

  const titleWatch = watch('title');
  const descriptionWatch = watch('description');

  const defaultValues = pressRelease || {};
  const defaultDate = pressRelease
    ? getDateObjFromTimestamp(pressRelease.date)
    : {};

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <div className="flex items-center justify-center min-h-screen">
        <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
          <div className="absolute top-4 right-4 z-10">
            <CloseButton onClick={onClose} />
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-6 py-6 pb-0">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                <DocumentTextIcon className="w-4" />
              </div>
              <div className="mt-3 text-center">
                <Dialog.Title className="text-lg leading-6 font-medium text-gray-900">
                  {pressRelease ? 'Edit' : 'Add a'} press release
                </Dialog.Title>
                {!pressRelease && (
                  <Dialog.Description className="mt-2 text-sm text-gray-500">
                    Press releases are used to communicate details about company
                    announcements, like fundraise, product release,
                    partnerships, etc...
                  </Dialog.Description>
                )}
              </div>

              <div className="flex justify-between items-baseline mt-5">
                <Label htmlFor="title">Title*</Label>
                <TextCounter str={titleWatch || defaultValues.title} />
              </div>

              <InputFieldText
                name="title"
                defaultValue={defaultValues.title || null}
                ref={register({
                  required: 'Please put in a title',
                  maxLength: {
                    value: 140,
                    message: 'Please keep the title below 140 characters',
                  },
                })}
                placeholder="The title of the press release..."
                data-test-id="company-pressReleases-editModal-titleInput"
              />

              {errors.title && (
                <ErrorMessage testId="company-pressReleases-editModal-titleInput-error">
                  {errors.title.message}
                </ErrorMessage>
              )}

              <div className="flex justify-between items-baseline mt-3">
                <Label htmlFor="description">Description*</Label>
                <TextCounter
                  str={descriptionWatch || defaultValues.description}
                />
              </div>

              <InputFieldTextArea
                name="description"
                defaultValue={defaultValues.description || null}
                ref={register({
                  required: 'Please put in a description',
                })}
                placeholder="The description of the press release..."
                data-test-id="company-pressReleases-editModal-descriptionInput"
              />

              {errors.description && (
                <ErrorMessage testId="company-pressReleases-editModal-descriptionInput-error">
                  {errors.description.message}
                </ErrorMessage>
              )}

              <div className="flex mt-3">
                <div className="pr-2 w-1/2 flex-initial">
                  <Label htmlFor="filePdf">File - PDF</Label>

                  <div className="mt-2">
                    <Controller
                      name="filePdf"
                      control={control}
                      defaultValue={defaultValues.filePdf || null}
                      render={({ onChange, value }) => (
                        <InputFieldUpload
                          name="filePdf"
                          folder={uploadFolder}
                          onChange={onChange}
                          value={value}
                          setError={setError}
                          testId="company-pressReleases-editModal-pdfInput"
                          renderFile={(file, onChangeFile) => (
                            <div className="w-full flex flex-col">
                              <div className="relative flex h-28 bg-gray-100 justify-center items-center text-teal-500">
                                {file.isUploading ? (
                                  <Spinner />
                                ) : (
                                  <DocumentIcon
                                    className="w-12"
                                    data-test-id="company-pressReleases-editModal-pdfInput-icon"
                                  />
                                )}
                              </div>
                              <div className="bg-gray-50 p-3">
                                <p className="text-sm font-medium text-gray-700 truncate">
                                  {file.name}
                                </p>
                                <p className="text-sm text-gray-400">
                                  {Math.floor(file.size / 1024)}kB
                                </p>
                              </div>
                              <div className="absolute -top-3 -right-2">
                                <CloseButton
                                  onClick={() => onChangeFile(null)}
                                />
                              </div>
                            </div>
                          )}
                          preset={presetPdf}
                        />
                      )}
                    />
                  </div>

                  {errors.filePdf && (
                    <ErrorMessage>{errors.filePdf.message}</ErrorMessage>
                  )}
                </div>
                <div className="pl-2 w-1/2 flex-initial">
                  <Label htmlFor="fileDocx">File - DOCX</Label>

                  <div className="mt-2">
                    <Controller
                      name="fileDoc"
                      control={control}
                      defaultValue={defaultValues.fileDoc || null}
                      render={({ onChange, value }) => (
                        <InputFieldUpload
                          name="fileDoc"
                          folder={uploadFolder}
                          onChange={onChange}
                          value={value}
                          setError={setError}
                          testId="company-pressReleases-editModal-docInput"
                          renderFile={(file, onChangeFile) => (
                            <div className="w-full flex flex-col">
                              <div className="relative flex h-28 bg-gray-100 justify-center items-center text-teal-500">
                                {file.isUploading ? (
                                  <Spinner />
                                ) : (
                                  <DocumentIcon
                                    className="w-12"
                                    data-test-id="company-pressReleases-editModal-docInput-icon"
                                  />
                                )}
                              </div>
                              <div className="bg-gray-50 p-3">
                                <p className="text-sm font-medium text-gray-700 truncate">
                                  {file.name}
                                </p>
                                <p className="text-sm text-gray-400">
                                  {Math.floor(file.size / 1024)}kB
                                </p>
                              </div>
                              <div className="absolute -top-3 -right-2">
                                <CloseButton
                                  onClick={() => onChangeFile(null)}
                                />
                              </div>
                            </div>
                          )}
                          preset={presetDoc}
                        />
                      )}
                    />
                  </div>

                  {errors.fileDoc && (
                    <ErrorMessage>{errors.fileDoc.message}</ErrorMessage>
                  )}
                </div>
              </div>

              <fieldset className="mt-3 grid grid-cols-1 gap-1 sm:flex">
                <div>
                  <Label htmlFor="date">Embargo date</Label>
                  <div className="flex mt-2">
                    <Controller
                      name="date.day"
                      defaultValue={
                        defaultDate.day || getDateRangeDefault('days')
                      }
                      control={control}
                      render={({ onChange, value }) => (
                        <InputFieldDropdown
                          options={getDateRange('days')}
                          value={value}
                          onChange={onChange}
                          optionWidthClassName="w-16 sm:w-20"
                          wrapperClassName="mr-2"
                          testId="company-pressReleases-editModal-dayInput"
                        />
                      )}
                    />

                    <Controller
                      name="date.month"
                      defaultValue={
                        defaultDate.month || getDateRangeDefault('months')
                      }
                      control={control}
                      render={({ onChange, value }) => (
                        <InputFieldDropdown
                          options={getDateRange('months')}
                          value={value}
                          onChange={onChange}
                          optionWidthClassName="w-36"
                          wrapperClassName="mr-2"
                          testId="company-pressReleases-editModal-monthInput"
                        />
                      )}
                    />

                    <Controller
                      name="date.year"
                      defaultValue={
                        defaultDate.year || getDateRangeDefault('years')
                      }
                      control={control}
                      render={({ onChange, value }) => (
                        <InputFieldDropdown
                          options={getDateRange('years')}
                          value={value}
                          onChange={onChange}
                          optionWidthClassName="w-20 sm:w-28"
                          wrapperClassName="mr-2"
                          testId="company-pressReleases-editModal-yearInput"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="mt-3 sm:mt-0">
                  <Label htmlFor="date">Hour</Label>

                  <div className="flex mt-2">
                    <Controller
                      name="date.hour"
                      defaultValue={
                        defaultDate.hour || getDateRangeDefault('hours')
                      }
                      control={control}
                      render={({ onChange, value }) => (
                        <InputFieldDropdown
                          options={getDateRange('hours')}
                          value={value}
                          onChange={onChange}
                          optionWidthClassName="w-24 sm:w-28"
                          testId="company-pressReleases-editModal-hourInput"
                        />
                      )}
                    />
                  </div>
                </div>
              </fieldset>

              <div className="flex w-full justify-between mt-3">
                <Label htmlFor="image">Image</Label>
              </div>

              <div className="mt-2">
                <Controller
                  name="image"
                  control={control}
                  defaultValue={defaultValues.image || null}
                  render={({ onChange, value }) => (
                    <InputFieldUpload
                      name="image"
                      folder={uploadFolder}
                      onChange={onChange}
                      value={value}
                      setError={setError}
                      testId="company-pressReleases-editModal-imageInput"
                      renderFile={(image, onChangeImage) => (
                        <div className="relative h-40 bg-gray-100">
                          <UniversalImage
                            image={image}
                            alt="Press release preview"
                            heightClass="h-40"
                            objectFitClass="object-cover"
                            testId="company-pressReleases-editModal-imageInput-image"
                          />
                          <div className="absolute -top-2 -right-2">
                            <CloseButton onClick={() => onChangeImage(null)} />
                          </div>
                        </div>
                      )}
                    />
                  )}
                />
              </div>

              {errors.image && (
                <ErrorMessage>{errors.image.message}</ErrorMessage>
              )}
            </div>
            <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
              <Button
                type="secondary"
                onClick={() => onClose()}
                data-test-id="company-pressReleases-editModal-cancel"
              >
                Cancel
              </Button>
              <div className="ml-3">
                <Button
                  submit
                  disabled={!isValid}
                  eventType="pressPage_update"
                  eventProperties={{ action: 'pressRelease_add' }}
                  data-test-id="company-pressReleases-editModal-submit"
                >
                  {pressRelease ? 'Update' : 'Add'} press release
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

CompanyPressReleaseEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  pressRelease: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    filePdf: PropTypes.object,
    fileDoc: PropTypes.object,
    date: PropTypes.string,
    image: PropTypes.object,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadFolder: PropTypes.string.isRequired,
};

export default CompanyPressReleaseEditModal;
