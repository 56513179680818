import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { PlusIcon } from '@heroicons/react/solid';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';

import CompanyInsight from './CompanyInsight/CompanyInsight';
import CompanyInsightEditModal from './CompanyInsightEditModal';
import CompanyInsightViewModal from './CompanyInsightViewModal';
import useInsights from './hooks/useInsights';

const CompanyInsights = ({ crud, founders, showOwnerView, sectionRef }) => {
  const [, insights, userInsights, ops] = useInsights(crud);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [currentViewInsightId, setCurrentViewInsightId] = useState(null);

  const isEmpty = userInsights.length < 1;
  const isEmptyAndOwnerview = showOwnerView && isEmpty;

  // If there are no insights yet, hide this section for journalists
  if (!showOwnerView && isEmpty) {
    return null;
  }

  const onSubmit = (data = {}) => {
    const { question, answer, tag, founder } = data;
    ops.createDoc({
      question,
      answer,
      tag,
      founder: founder?.name ? { name: founder.name } : null,
    });
    setIsAddModalOpen(false);
  };

  const onView = (uid) => {
    setCurrentViewInsightId(uid);
    setIsViewModalOpen(true);
  };

  return (
    <>
      <div data-test-id="company-insights" id="insights" ref={sectionRef}>
        <SectionHeaderWrapper>
          <SectionHeadline
            light
            testId="company-insights-headline"
            showEmptySectionHint={isEmptyAndOwnerview}
          >
            Insights
          </SectionHeadline>
          {showOwnerView && (
            <DotsMenu
              options={[
                {
                  label: 'Add custom insight',
                  icon: <PlusIcon className="w-4 h-4" />,
                  onClick: () => setIsAddModalOpen(true),
                  testId: 'company-insights-menu-add',
                },
              ]}
              theme="dark"
              testId="company-insights-menu"
            />
          )}
        </SectionHeaderWrapper>

        <div className="w-full mt-4">
          {showOwnerView || userInsights.length ? (
            <ul
              className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-6"
              data-test-id="company-insights-list"
            >
              {insights.map((insight) => (
                <CompanyInsight
                  key={insight.question}
                  insight={insight}
                  showOwnerView={showOwnerView}
                  ops={ops}
                  founders={founders}
                  onView={onView}
                />
              ))}
            </ul>
          ) : null}
        </div>
      </div>

      {showOwnerView && (
        <CompanyInsightEditModal
          open={isAddModalOpen}
          founders={founders}
          onClose={() => setIsAddModalOpen(false)}
          onSubmit={onSubmit}
        />
      )}

      <CompanyInsightViewModal
        open={isViewModalOpen}
        insights={userInsights}
        founders={founders}
        currentInsightId={currentViewInsightId}
        setCurrentInsightId={setCurrentViewInsightId}
        onClose={() => setIsViewModalOpen(false)}
      />
    </>
  );
};

CompanyInsights.propTypes = {
  crud: PropTypes.array.isRequired,
  founders: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanyInsights;
