import PropTypes from 'prop-types';
import React from 'react';

import { HomeIcon } from '@heroicons/react/outline';
import {
  AdjustmentsIcon,
  BookmarkIcon,
  OfficeBuildingIcon,
  StarIcon,
  BellIcon,
  HeartIcon,
  NewspaperIcon,
  UsersIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import {
  ADMIN_COMPANIES_PATH,
  ADMIN_CREATE_OPPORTUNITY_PATH,
  ADMIN_MAP_TOPICS_PATH,
  ADMIN_OPPORTUNITIES_NOTIFICATIONS_PATH,
  ADMIN_OPPORTUNITIES_PATH,
  ADMIN_PATH,
  ADMIN_SWEETHEART_LIST_PATH,
  ADMIN_PUBLICATIONS_PATH,
  ADMIN_JOURNALISTS_PATH,
} from 'constants/paths';

const navigation = [
  { name: 'Dashboard', to: ADMIN_PATH, icon: HomeIcon },
  {
    name: 'Companies list',
    to: ADMIN_COMPANIES_PATH,
    icon: OfficeBuildingIcon,
  },
  {
    name: 'Map core topics',
    to: ADMIN_MAP_TOPICS_PATH,
    icon: BookmarkIcon,
  },
  {
    name: 'Fine-tune opportunities',
    to: ADMIN_OPPORTUNITIES_PATH,
    icon: AdjustmentsIcon,
  },
  {
    name: 'Custom opportunities',
    to: ADMIN_CREATE_OPPORTUNITY_PATH,
    icon: StarIcon,
  },
  {
    name: 'Opportunities notifications',
    to: ADMIN_OPPORTUNITIES_NOTIFICATIONS_PATH,
    icon: BellIcon,
  },
  {
    name: 'Sweetheart list',
    to: ADMIN_SWEETHEART_LIST_PATH,
    icon: HeartIcon,
  },
  {
    name: 'Publications',
    to: ADMIN_PUBLICATIONS_PATH,
    icon: NewspaperIcon,
  },
  {
    name: 'Journalists',
    to: ADMIN_JOURNALISTS_PATH,
    icon: UsersIcon,
  },
];

const AdminWrapper = ({ children, title, currentIndex }) => (
  <>
    <div className="hidden md:flex md:fixed md:inset-y-0 md:flex-col md:w-64">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex overflow-y-auto flex-col flex-grow pt-5 bg-white border-r border-gray-200">
        <div className="flex items-center px-4 shrink-0">
          <img
            className="w-auto h-8"
            src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
            alt="Workflow"
          />
        </div>
        <div className="flex flex-col flex-grow mt-5">
          <nav className="flex-1 px-2 pb-4 space-y-1">
            {navigation.map((item, index) => (
              <Link
                key={item.name}
                to={item.to}
                className={classNames(
                  {
                    'bg-gray-100 text-gray-900': index === currentIndex,
                    'text-gray-600 hover:bg-gray-50 hover:text-gray-900':
                      index !== currentIndex,
                  },
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                )}
              >
                <item.icon
                  className={classNames(
                    {
                      'text-gray-500': index === currentIndex,
                      'text-gray-400 group-hover:text-gray-500':
                        index !== currentIndex,
                    },
                    'mr-3 shrink-0 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                {item.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>

    <main className="flex-1 pt-16 sm:pl-30 md:pl-64">
      <div className="py-6">
        <div className="px-4 sm:px-6 md:px-8 mx-auto max-w-7xl">
          <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
        </div>
        <div className="px-4 sm:px-6 md:px-8 mx-auto max-w-7xl">{children}</div>
      </div>
    </main>
  </>
);

AdminWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  currentIndex: PropTypes.number,
};

export default AdminWrapper;
