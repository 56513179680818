import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Dialog } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import {
  EyeIcon,
  ChartSquareBarIcon,
  NewspaperIcon,
  StarIcon,
} from '@heroicons/react/solid';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useFirestore } from 'reactfire';

import SocialLogin from 'components/SocialLogin';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import {
  COMPANIES_COLLECTION,
  USERS_COLLECTION,
} from 'constants/firebasePaths';
import { LOGIN_PATH } from 'constants/paths';
import { REGEX_EMAIL } from 'constants/regex';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useSignIn from 'hooks/useSignIn';
import useSignup from 'hooks/useSignup';

// Modal to sign up to MVPR. Also includes the a checkbox for confirming the T&Cs and opt in to marketing
const OnboardingWrapperSignupModal = ({ open = false }) => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    nativeValidation: false,
  });

  const termsChecked = watch('termsAndConditions');
  const marketingOptInChecked = watch('marketingOptIn');

  const { companyData } = useCompanyData();
  const { uid } = useUserData();

  const firestore = useFirestore();
  const companyRef = firestore.doc(
    `${COMPANIES_COLLECTION}/${companyData.uid}`
  );
  const userRef = firestore.collection(USERS_COLLECTION).doc(uid);

  const signUpWithEmail = useSignup();
  const { signInWithGoogle } = useSignIn(true);

  const updateCompany = () => companyRef.update({ signupStatus: 1 });
  const updateUser = (email) =>
    userRef.update({
      email,
      marketingOptIn: marketingOptInChecked,
      signupConfirmationSent: 0,
    });

  const signupWithEmailFlow = async (formValues, e) => {
    const result = await signUpWithEmail(formValues, e);
    if (result) {
      await updateUser(formValues.email);
      await updateCompany();
    }
  };

  const signInWithGoogleFlow = async () => {
    const result = await signInWithGoogle();
    if (result) {
      await updateUser(result.user.email);
      await updateCompany();
    }
  };

  useEffect(() => ReactTooltip.rebuild());

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      data-test-id=""
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
      <div className="flex items-center justify-center min-h-screen sm:max-w-lg md:max-w-3xl lg:max-w-5xl m-auto">
        <div className="relative bg-white rounded-lg shadow-xl m-6">
          <div className="px-6 py-12 flex flex-col md:flex-row justify-between md:divide-x">
            <div className="px-8 flex-1 hidden md:block">
              <h3 className="text-xl font-semibold">
                Why should you join MVPR?
              </h3>

              <ul className="mt-6 space-y-6">
                <li className="flex text-md">
                  <div className="mr-3 mt-0.5">
                    <ChartSquareBarIcon className="text-teal-500 w-5 h-5" />
                  </div>
                  <p className="text-gray-500">
                    Learn what journalists want to write about and see data on
                    how they engage with your content and your company
                  </p>
                </li>
                <li className="flex text-md">
                  <div className="mr-3 mt-0.5">
                    <NewspaperIcon className="text-teal-500 w-5 h-5" />
                  </div>
                  <p className="text-gray-500">
                    Be guided on how to share visual, detailed information with
                    journalists in the right way to increase your chances of
                    getting coverage
                  </p>
                </li>
                <li className="flex text-md">
                  <div className="mr-3 mt-0.5">
                    <EyeIcon className="text-teal-500 w-5 h-5" />
                  </div>
                  <p className="text-gray-500">
                    Get in front of readers that are interested in your company
                    and your product
                  </p>
                </li>
                <li className="flex text-md">
                  <div className="mr-3 mt-0.5">
                    <StarIcon className="text-teal-500 w-5 h-5" />
                  </div>
                  <p className="text-gray-500">
                    See speaking and award opportunities that are relevant for
                    your company and its founders as they arise
                  </p>
                </li>
              </ul>
            </div>

            <div className="px-8 flex-1 mt-4 sm:mt-0">
              <h3 className="text-xl font-semibold text-teal-500">
                Save your press page
              </h3>
              <p className="mt-1 text-gray-500">
                Create an account to get started or{' '}
                <Link to={LOGIN_PATH} className="underline hover:no-underline">
                  log in
                </Link>
              </p>

              <form
                className="space-y-4 mt-6"
                onSubmit={handleSubmit(signupWithEmailFlow)}
                data-test-id="signupModal-form"
              >
                <div>
                  <Label htmlFor="email">
                    Email address
                    <InformationCircleIcon
                      className="inline w-5 h-5 sm:w-4 sm:h-4 ml-2 sm:ml-1 text-gray-400 hover:text-gray-500"
                      data-tip="We'll need this email to create your account, handle payments and share invoices."
                      data-class="w-full max-w-[240px] sm:max-w-[320px]"
                      data-for="tooltip"
                    />
                  </Label>

                  <InputFieldText
                    ref={register({
                      pattern: {
                        value: REGEX_EMAIL,
                        message: 'Please provide a valid email address',
                      },
                    })}
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="steve@apple.com"
                    required
                    data-test-id="signupModal-form-email"
                  />

                  {errors.email && (
                    <ErrorMessage>{errors.email.message}</ErrorMessage>
                  )}
                </div>

                <div>
                  <Label htmlFor="password">Create a password</Label>
                  <InputFieldText
                    ref={register({
                      required: 'Please provide a password',
                    })}
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    placeholder="Your password"
                    required
                    data-test-id="signupModal-form-password"
                  />
                  {errors.password && (
                    <ErrorMessage>{errors.password.message}</ErrorMessage>
                  )}
                </div>

                <div className="flex items-center pt-1">
                  <input
                    ref={register({ required: true })}
                    id="termsAndConditions"
                    name="termsAndConditions"
                    type="checkbox"
                    className="h-4 w-4 mr-2 text-teal-500 focus:ring-teal-500 border-gray-300 rounded cursor-pointer"
                    data-test-id="signupModal-form-terms"
                  />
                  <Label htmlFor="termsAndConditions">
                    I agree to the{' '}
                    <a
                      href="https://www.iubenda.com/terms-and-conditions/17443214"
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    >
                      Terms &amp; Conditions
                    </a>
                  </Label>
                </div>
                <div className="flex items-center cursor-pointer">
                  <input
                    ref={register}
                    id="marketingOptIn"
                    name="marketingOptIn"
                    type="checkbox"
                    className="h-4 w-4 mr-2 text-teal-600 focus:ring-teal-500 border-gray-300 rounded cursor-pointer"
                  />
                  <Label htmlFor="marketingOptIn">
                    I would like to be contacted for marketing
                  </Label>
                </div>

                <div className="pt-1">
                  <Button
                    size="large"
                    submit
                    full
                    disabled={isSubmitting || !isValid}
                    eventType="sign_up"
                    eventProperties={{ method: 'email', form: 'modal' }}
                    data-test-id="signupModal-form-submit"
                  >
                    {isSubmitting ? 'Creating account...' : 'Create an account'}
                  </Button>
                </div>
              </form>

              <SocialLogin
                signInWithGoogle={signInWithGoogleFlow}
                signup
                disabled={!termsChecked}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

OnboardingWrapperSignupModal.propTypes = {
  open: PropTypes.bool,
};

export default OnboardingWrapperSignupModal;
