import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const SweetheartListJournalistProfile = ({
  name = '',
  ImageComponent = null,
  bold = false,
}) => (
  <div className="flex items-center">
    <div className="flex-shrink-0 w-8 h-8 relative">
      <ImageComponent name={name} />
    </div>
    <div className={classNames('ml-2 text-sm', { 'font-medium': bold })}>
      {name}
    </div>
  </div>
);

SweetheartListJournalistProfile.propTypes = {
  name: PropTypes.string.isRequired,
  ImageComponent: PropTypes.elementType,
  bold: PropTypes.bool,
};

export default SweetheartListJournalistProfile;

// TODO Refactor as UniversalImageAndText and pass params for text, url, layout, alignment
