import PropTypes from 'prop-types';
import React from 'react';

import StepDivider from './StepDivider';

const StepUpcoming = ({ number = 1, title = '' }) => (
  <li className="flex align-center items-center">
    <span className="text-sm font-medium text-gray-300 cursor-not-allowed">
      {title}
    </span>

    <StepDivider number={number} />
  </li>
);

StepUpcoming.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
};

export default StepUpcoming;
