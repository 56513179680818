import { useAnalytics } from 'reactfire';

const useLogOpportunityInteraction = () => {
  const analytics = useAnalytics();

  return (action = '', opportunity = {}) =>
    analytics.logEvent('opportunity_interaction', {
      action,
      id: opportunity.uid,
      title: opportunity.enquirySummary,
      journalist: opportunity.journalist,
      mediaOutlet: opportunity.mediaOutlet,
    });
};

export default useLogOpportunityInteraction;
