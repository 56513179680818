import PropTypes from 'prop-types';
import React from 'react';

import { Helmet } from 'react-helmet';

import OnboardingWrapper from 'components/OnboardingWrapper/OnboardingWrapper';
import Spinner from 'components/Spinner';

import SweetheartListJournalists from './SweetheartListJournalists';
import SweetheartListPageBeingGenerated from './SweetheartListViewStates/SweetheartListPageBeingGenerated';
import SweetheartListPageJournalistsError from './SweetheartListViewStates/SweetheartListPageJournalistsError';
import useCompanySweetheart from './hooks/useCompanySweetheart';

// if user.claimsAdmin - display content
const SweetheartList = ({ company = {} }) => {
  const [{ isEnabled, journalists }, loading] = useCompanySweetheart(company);

  return (
    <OnboardingWrapper>
      <Helmet>
        <title>Sweetheart List | mvpr</title>
      </Helmet>

      <div className="mt-16 sm:ml-16">
        {isEnabled && (
          <div>
            {/* --- LOADING STATE --- */}
            {loading && <Spinner />}

            {/* --- IF LIST DOES NOT EXIST IN AIRTABLE --- */}
            {!journalists && !loading && (
              <SweetheartListPageJournalistsError companyName={company.name} />
            )}

            {/* --- JOURNALISTS --- */}
            {journalists && (
              <div className="sm:px-4 mt-4 lg:mt-6">
                <div className="w-full">
                  <SweetheartListJournalists journalists={journalists} />
                </div>
              </div>
            )}
          </div>
        )}

        {!isEnabled && <SweetheartListPageBeingGenerated />}
      </div>
    </OnboardingWrapper>
  );
};

SweetheartList.propTypes = {
  company: PropTypes.object.isRequired,
};

export default SweetheartList;
