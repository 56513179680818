// line graph options
const chartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  // animation: false,
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: 'dd/MM',
        },
        round: 'day',
        stepSize: 2,
        tooltipFormat: 'dd/MM/yyyy',
      },
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      // min: 0,
      beginAtZero: true,
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 10,
      },
    },
  },
};

export default chartOptions;
