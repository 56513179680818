import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';
import getTopics from 'routes/Opportunities/hooks/helpers/getTopics';

import useMapTopicsToCoreTopics from './useMapTopicsToCoreTopics';

const useMergeCompaniesIntoOpportunities = (opportunities = []) => {
  const firestore = useFirestore();

  // only use companies that have topics assigned
  const companiesCollection = firestore
    .collection(COMPANIES_COLLECTION)
    .where('topics', '!=', null);

  const { data: companiesData } = useFirestoreCollectionData(
    companiesCollection,
    defaultOptions
  );

  const mapTopicsToCoreTopics = useMapTopicsToCoreTopics();
  const companiesWithCoreTopics = companiesData
    .filter((c) => c.topics.length)
    .filter((c) => c.slug)
    .map((c) => ({
      ...c,
      topics: mapTopicsToCoreTopics(c.topics),
    }));

  // find companies for opportunity topics
  return opportunities
    .filter((o) => !o.topics.find((t) => t.id === 1199))
    .map((o) => {
      // if opportunity topics contain "uncategorised", discard
      const coreTopics = mapTopicsToCoreTopics(o.topics);

      const companies = companiesWithCoreTopics.filter((c) => {
        const matchingTopics = getTopics(coreTopics, c.topics);
        return matchingTopics.length;
      });

      return { ...o, companies };
    });
};

export default useMergeCompaniesIntoOpportunities;
