import PropTypes from 'prop-types';
import React, { createRef, useMemo } from 'react';

const CompanySectionRefs = ({ showOwnerView, cruds, children }) => {
  const sectionRefs = useMemo(() => {
    const asArray = [];
    const byKey = {};

    // remove section if empty and in journalist view
    let keys = Object.keys(cruds);
    const isNotEmpty = (key) => cruds[key]?.[1]?.length > 0;

    // Check if there are public quotes
    const hasPublicQuotes = cruds.quotes[1].some((quote) => !quote.private);
    const removeQuotes = (key) => key !== 'quotes';

    if (!showOwnerView) {
      keys = hasPublicQuotes
        ? keys.filter(isNotEmpty)
        : keys.filter(isNotEmpty).filter(removeQuotes);
    }

    keys.forEach((key) => {
      const ref = createRef();
      asArray.push(ref);
      byKey[key] = ref;
    });

    return { asArray, byKey };
  }, [cruds, showOwnerView]);

  return <div>{children(sectionRefs.asArray, sectionRefs.byKey)}</div>;
};

CompanySectionRefs.propTypes = {
  showOwnerView: PropTypes.bool.isRequired,
  cruds: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
};

export default CompanySectionRefs;
