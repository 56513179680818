import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import noop from 'lodash/noop';
import ReactTags from 'react-tag-autocomplete';
import { v4 as uuidv4 } from 'uuid';

import InputFieldTagsAutocompleteInstructions from './InputFieldTagsAutocompleteInstructions';
import reactTagsClassnames from './config/reactTagsClassnames';
import createInputFieldTagsAutocompleteTag from './createInputFieldTagsAutocompleteTag';

/**
 * Input field for tags.
 * Accepts a list of predefined suggestions that will show up in a dropdown,
 * but also allows inputting custom fields, for which we need to provide a `customFieldName`.
 */
const InputFieldTagsAutocomplete = ({
  customFieldName = '',
  onChange = noop,
  value = [],
  suggestions = [],
  tagsIndex = [],
  placeholder = '',
  instructions = true,
}) => {
  const hasTag = (tag = {}) => value?.find((t = {}) => t.name === tag.name);

  const onDelete = (i = 0) => {
    const arr = value.slice(0);
    arr.splice(i, 1);
    onChange(arr);
  };

  const onAdd = (tag = {}) => {
    if (hasTag(tag)) {
      return;
    }
    onChange(
      value.concat({
        ...tag,
        id: tag.id || `custom-${customFieldName}-${uuidv4()}`,
      })
    );
  };

  const ref = useRef();

  const placeholderText = placeholder.length > 0 ? placeholder : 'Add new tag';

  return (
    <>
      <ReactTags
        minQueryLength={1}
        classNames={reactTagsClassnames}
        ref={ref}
        tags={value}
        suggestions={suggestions.filter((suggestion) => !hasTag(suggestion))}
        onDelete={onDelete}
        onAddition={onAdd}
        tagComponent={createInputFieldTagsAutocompleteTag(
          suggestions,
          tagsIndex
        )}
        allowNew
        placeholderText={placeholderText}
        data-test-id="tags"
      />
      {instructions ? (
        <div className="mt-2 w-full">
          <InputFieldTagsAutocompleteInstructions />
        </div>
      ) : null}
    </>
  );
};

InputFieldTagsAutocomplete.propTypes = {
  customFieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  suggestions: PropTypes.array,
  tagsIndex: PropTypes.array,
  placeholder: PropTypes.string,
  instructions: PropTypes.bool,
};

export default InputFieldTagsAutocomplete;
