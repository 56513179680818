import PropTypes from 'prop-types';
import React from 'react';

import { useHistory } from 'react-router-dom';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useQuery from 'hooks/useQuery';

import OnboardingWrapperPaymentStatusFailure from './OnboardingWrapperPaymentStatusFailure';
import OnboardingWrapperPaymentStatusSuccess from './OnboardingWrapperPaymentStatusSuccess/OnboardingWrapperPaymentStatusSuccess';
import OnboardingWrapperPlansModal from './OnboardingWrapperPlansModal';
import OnboardingWrapperSignupModal from './OnboardingWrapperSignupModal';

/**
 * Displays the signup and plans modal depending on a company's `signupStatus`
 * Handles the payment success/failure callback from Stripe
 */
const OnboardingWrapper = ({ children = null, matchesOwner = true }) => {
  const query = useQuery();
  const hasPaid = !!query.get('success');
  const hasError = !!query.get('error');
  const history = useHistory();

  const user = useUserData();
  const isAdmin = user && (user.claimsVc || user.claimsAdmin);

  const { companyData } = useCompanyData();
  const signupStatus = companyData?.signupStatus;

  // if user is not owner or is admin, don't show onboarding
  if (!matchesOwner || isAdmin) {
    return children;
  }

  // if sign up status doesn't exist (legacy onboarding), don't show onboarding
  if (signupStatus === undefined) {
    return children;
  }

  const showSignupModal = signupStatus === 0;
  const showPlansModal = signupStatus === 1;

  const showModals = !hasPaid && !hasError;

  return (
    <>
      {children}
      {showModals && (
        <>
          <OnboardingWrapperSignupModal open={showSignupModal} />
          {showPlansModal && (
            <OnboardingWrapperPlansModal open={showPlansModal} />
          )}
        </>
      )}
      {hasPaid && (
        <OnboardingWrapperPaymentStatusSuccess
          open={hasPaid}
          onClose={() => {
            // this removes the query parameter, so that `success` and `error` on the query evaluate to null
            history.replace({
              search: null,
            });
          }}
        />
      )}
      {hasError && (
        <OnboardingWrapperPaymentStatusFailure
          open={hasError}
          onClose={() => history.push('/')}
        />
      )}
    </>
  );
};

OnboardingWrapper.propTypes = {
  children: PropTypes.node,
  matchesOwner: PropTypes.bool,
};

export default OnboardingWrapper;
