import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';

import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldFounders from 'components/form/inputFields/InputFieldFounders/InputFieldFounders';
import InputFieldTagsList from 'components/form/inputFields/InputFieldTagsList/InputFieldTagsList';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import { tagsInsights } from 'constants/tags';

const CompanyInsightEditModal = ({
  open,
  insight = null,
  founders = [],
  onClose,
  onSubmit,
}) => {
  const {
    control,
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const defaultValues = insight || {};

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
            <div className="absolute top-4 right-4 z-10">
              <CloseButton onClick={onClose} />
            </div>

            <div className="px-6 py-6 pb-0">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                <QuestionMarkCircleIcon className="w-6" />
              </div>
              <div className="mt-3 text-center">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="dialog-1-title"
                >
                  {insight ? 'Edit' : 'Add a'} company insight
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    These help journalists understand where your company fits
                    into the startup ecosystem.
                  </p>
                </div>
              </div>

              <div className="flex justify-between items-baseline mt-5">
                <Label htmlFor="date">Question*</Label>
              </div>

              <InputFieldText
                name="question"
                ref={register({
                  required: 'Please put in a question',
                })}
                defaultValue={defaultValues.question}
                placeholder="Please type a question here..."
                data-test-id="company-insights-editModal-questionInput"
              />

              {errors.question && (
                <ErrorMessage testId="company-insights-editModal-questionInput-error">
                  {errors.question.message}
                </ErrorMessage>
              )}

              <div className="flex justify-between items-baseline mt-3">
                <Label htmlFor="date">Answer*</Label>
              </div>

              <InputfieldTextArea
                name="answer"
                ref={register({
                  required: 'Please put in an answer',
                })}
                placeholder="Please type your answer here..."
                defaultValue={defaultValues.answer}
                data-test-id="company-insights-editModal-answerInput"
              />

              {insight?.question?.includes('competitor') && (
                <p className="mt-2 text-xs text-gray-400">
                  E.g.: Acme Studios (acme-studios.com), Alpha Studios
                  (alphastudios.com),...
                </p>
              )}

              {errors.answer && (
                <ErrorMessage testId="company-insights-editModal-answerInput-error">
                  {errors.answer.message}
                </ErrorMessage>
              )}

              <div className="flex justify-between items-baseline mt-3">
                <Label htmlFor="date">Tag</Label>
              </div>

              <Controller
                name="tag"
                control={control}
                defaultValue={defaultValues.tag || tagsInsights[0]}
                render={({ onChange, value }) => (
                  <InputFieldTagsList
                    tags={tagsInsights}
                    onChange={onChange}
                    value={value}
                    testId="company-insights-editModal-tagsInput"
                  />
                )}
              />

              <div className="mt-3">
                <Label htmlFor="users">Related founders</Label>
              </div>

              <div className="mt-2">
                <Controller
                  name="founder"
                  control={control}
                  defaultValue={defaultValues.founder || {}}
                  render={({ onChange, value }) => (
                    <InputFieldFounders
                      founders={founders}
                      onChange={onChange}
                      value={value}
                      testId="company-insights-editModal-founderInput"
                    />
                  )}
                />
              </div>

              {errors.founder && (
                <ErrorMessage>{errors.founder.message}</ErrorMessage>
              )}
            </div>

            <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-lg">
              <Button
                type="secondary"
                onClick={() => onClose()}
                data-test-id="company-insights-editModal-cancel"
              >
                Cancel
              </Button>
              <div className="ml-3">
                <Button
                  submit
                  disabled={!isValid}
                  eventType="pressPage_update"
                  eventProperties={{ action: 'snapshot_add' }}
                  data-test-id="company-insights-editModal-submit"
                >
                  {insight?.answer ? 'Update' : 'Add'} answer
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

CompanyInsightEditModal.propTypes = {
  open: PropTypes.bool,
  insight: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string,
  }),
  founders: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyInsightEditModal;
