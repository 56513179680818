export const HOME_PATH = '/';
export const ONBOARDING_PATH = '/onboarding/:step';
export const SETTINGS_PATH = '/settings/:section';
export const SETTINGS_USER_PATH = '/settings/user';
export const SETTINGS_COMPANY_PATH = '/settings/company';
export const LOGIN_PATH = '/login';
export const PASSWORD_RESET = '/resetPassword';
export const SIGNUP_VC_PATH = '/signupVc';
export const USERS_PATH = '/users';

// admins are allowed to see this (custom "admin" claim required)
export const COMPANY_PATH = '/company/:id';

// admins are allowed to use a specific company ID (custom "admin" claim required)
export const OPPORTUNITIES_PATH = '/opportunities/:id?';

// admins are allowed to use a specific company ID (custom "admin" claim required)
export const ANALYTICS_PATH = '/analytics/:id?';

// admins are allowed to use a specific company ID (custom "admin" claim required)
export const SWEETHEART_PATH = '/sweetheart-list/:id?';

// for VCs
export const VC_COMPANIES_PATH = '/companies';

export const DEFAULT_SETTINGS_PATH = SETTINGS_USER_PATH;
export const DEFAULT_ONBOARDING_PATH = '/onboarding/one';

// Admin paths
export const ADMIN_PATH = '/admin';
export const ADMIN_COMPANIES_PATH = '/admin/companies';
export const ADMIN_OPPORTUNITIES_PATH = '/admin/opportunities';
export const ADMIN_OPPORTUNITIES_NOTIFICATIONS_PATH =
  '/admin/opportunitiesNotifications';
export const ADMIN_CREATE_OPPORTUNITY_PATH = '/admin/createOpportunity';
export const ADMIN_MAP_TOPICS_PATH = '/admin/mapTopics';
export const ADMIN_SWEETHEART_LIST_PATH = '/admin/sweetheartList';
export const ADMIN_PUBLICATIONS_PATH = '/admin/publications';
export const ADMIN_JOURNALISTS_PATH = '/admin/journalists';
