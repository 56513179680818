import PropTypes from 'prop-types';
import React from 'react';

import StepCompleted from './StepCompleted';
import StepCurrent from './StepCurrent';
import StepUpcoming from './StepUpcoming';

const Progress = ({ steps = [], currentStep = '', availableSteps = [] }) => (
  <nav aria-label="Progress" className="p-4">
    <ol className="bg-white rounded-md shadow px-6 flex space-x-4">
      {steps.map((step, index) => {
        let Component = StepCompleted;
        if (currentStep === index + 1) {
          Component = StepCurrent;
        }
        if (!availableSteps.includes(step.name)) {
          Component = StepUpcoming;
        }
        return (
          <Component
            number={step.number}
            key={step.number}
            title={step.title}
            name={step.name}
          />
        );
      })}
    </ol>
  </nav>
);

Progress.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
  availableSteps: PropTypes.array.isRequired,
};

export default Progress;
