import { SIGNUP_VC_PATH as path } from 'constants/paths';

import SignupVcPage from './SignupVcPage';

const SignupVcRoute = {
  path,
  component: SignupVcPage,
};

export default SignupVcRoute;
