import { getDateRange } from './dateRange';

// turns a date object with year/month/day/hour into a JS date object
const createTimestampFromDateObj = (
  dateObj = {},
  skipTimestampConversion = false
) => {
  const date = new Date();

  if (dateObj.hour) {
    date.setUTCHours(parseInt(dateObj.hour.split(':')[0], 10));
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);
    date.setUTCMilliseconds(0);
  }

  if (dateObj.day) {
    date.setUTCDate(dateObj.day);
  }

  date.setUTCMonth(getDateRange('months').indexOf(dateObj.month));
  date.setUTCFullYear(parseInt(dateObj.year, 10));

  // TODO: check if date is still the same, to make sure day exists in month

  if (skipTimestampConversion) {
    return date;
  }

  return date.toISOString();
};

export default createTimestampFromDateObj;
