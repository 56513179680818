import { generatePath, useRouteMatch } from 'react-router-dom';

import {
  COMPANY_PATH,
  OPPORTUNITIES_PATH,
  ANALYTICS_PATH,
  SWEETHEART_PATH,
} from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

/**
 * Generates path to navigation links of currently logged in user
 * MUST NOT BE USED in places where user might not be logged in (because `slug` being null will crash the app)
 */
const useNavigationLinks = () => {
  const { companyData } = useCompanyData();

  // admin is able to operate on those pages, so we need to get the slug from the URL instead of the company context
  const routeMatchCompany = useRouteMatch(COMPANY_PATH);
  const routeMatchOpportunities = useRouteMatch(OPPORTUNITIES_PATH);
  const routeMatchAnalytics = useRouteMatch(ANALYTICS_PATH);

  const userData = useUserData();
  const isAdmin = userData?.claimsAdmin;

  // needed for routes that work with admin privileges,
  // because admin should be able to navigate between pages without typing in slug manually
  let urlSlug = null;
  if (isAdmin) {
    urlSlug =
      routeMatchCompany?.params?.id ||
      routeMatchOpportunities?.params?.id ||
      routeMatchAnalytics?.params?.id;
  }

  // set to URL-based slug if admin, otherwise use companyData
  const slug = isAdmin && urlSlug ? urlSlug : companyData?.slug;
  const slugId = isAdmin ? { id: slug } : null;

  const pressPageLink = generatePath(COMPANY_PATH, { id: slug });
  const opportunitiesLink = generatePath(OPPORTUNITIES_PATH, slugId);
  const analyticsLink = generatePath(ANALYTICS_PATH, slugId);
  const sweetheartListLink = generatePath(SWEETHEART_PATH, slugId);

  return [pressPageLink, opportunitiesLink, analyticsLink, sweetheartListLink];
};

export default useNavigationLinks;
