import firebase from 'firebase/app';
import { useAuth } from 'reactfire';

import useUserData from 'contexts/UserContext/hooks/useUserData';
import useNotifications from 'modules/notification/useNotifications';

// sign in a user with email/password or via Google
// when signup is true, don't try to log in credentials on error
const useSignIn = (signup = false) => {
  const auth = useAuth();
  const { showError } = useNotifications();
  const userContext = useUserData();

  const signInWithEmail = async (formValues, e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const res = await auth.signInWithEmailAndPassword(
        formValues.email,
        formValues.password
      );
      return res;
    } catch (err) {
      showError(err.message);
      userContext.setIsAuthenticating(false);
    }
    return false;
  };

  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    let res = null;
    try {
      if (userContext.isAnonymous) {
        res = await auth.currentUser.linkWithPopup(provider);
      } else {
        res = await firebase.auth().signInWithPopup(provider);
      }
    } catch (err) {
      // user already exists, override session with logged in user
      if (err.code === 'auth/credential-already-in-use') {
        if (signup) {
          showError(err.message);
        } else {
          res = await firebase.auth().signInWithCredential(err.credential);
        }
      }
    }

    return res;
  };

  return {
    signInWithEmail,
    signInWithGoogle,
  };
};

export default useSignIn;
