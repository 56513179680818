import React from 'react';

import { ShieldExclamationIcon } from '@heroicons/react/outline';

import EmptyState from 'components/EmptyState';

const SweetheartListPageNotAuthorized = () => (
  <EmptyState
    helmetTitle="Sweetheart List Not Authorized"
    testId="sweetheartList-NotAuthorized"
    title="Oops, sorry!"
    subtitle="You're not authorised to view this page"
    Icon={ShieldExclamationIcon}
  />
);

export default SweetheartListPageNotAuthorized;
