import PropTypes from 'prop-types';
import React from 'react';

import { EyeIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import UniversalImage from 'components/UniversalImage/UniversalImage';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';

const UpdatePhotoModal = ({ uploadFolder, onClose, onSubmit }) => {
  const {
    control,
    handleSubmit,
    errors,
    setError,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  return (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-6 py-6 pb-0">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
            <EyeIcon className="w-6" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="dialog-1-title"
            >
              Update your profile photo
            </h3>
          </div>

          <div className="mt-4">
            <Controller
              name="photo"
              control={control}
              render={({ value, onChange }) => (
                <InputFieldUpload
                  name="photo"
                  folder={uploadFolder}
                  onChange={onChange}
                  value={value}
                  setError={setError}
                  heightClassName="h-48"
                  renderFile={(image) => (
                    <UniversalImage
                      image={image}
                      alt="Photo preview"
                      heightClass="h-48"
                      objectFitClass="object-contain"
                    />
                  )}
                />
              )}
            />
          </div>

          {errors.photo && <ErrorMessage>{errors.photo.message}</ErrorMessage>}
        </div>
        <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
          <Button type="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <div className="ml-3">
            <Button submit disabled={!isValid}>
              Update photo
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

UpdatePhotoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadFolder: PropTypes.string.isRequired,
};

export default UpdatePhotoModal;
