import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';

import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';

import CompanyPressReleaseEmbargoDate from './CompanyPressRelease/CompanyPressReleaseEmbargoDate';
import CompanyPressReleaseFileIcons from './CompanyPressRelease/CompanyPressReleaseFileIcons';

const CompanyPressReleaseViewModal = ({
  open,
  pressReleases = [],
  currentPressReleaseId = null,
  setCurrentPressReleaseId,
  onClose,
}) => {
  const pressRelease = pressReleases.find(
    (p) => p.uid === currentPressReleaseId
  );

  if (!currentPressReleaseId || pressReleases.length === 0) {
    return null;
  }

  if (!pressRelease) {
    return null;
  }

  const currentIndex = pressReleases.indexOf(pressRelease);
  const hasNext = currentIndex + 1 < pressReleases.length;
  const hasPrevious = currentIndex > 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <div className="flex items-center justify-center min-h-screen">
        <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
          <div className="absolute top-4 right-4 z-10">
            <CloseButton onClick={onClose} />
          </div>

          <div className="px-6 py-6 pb-0">
            <p className="mt-2 text-xl font-medium leading-normal">
              {pressRelease.title}
            </p>

            <p className="flex-1 mt-2 text-sm text-gray-400 leading-normal">
              {pressRelease.description}
            </p>

            <div className="flex flex-col space-y-1.5 justify-between items-start mt-4">
              <span className="font-medium text-sm">Embargo date</span>
              <CompanyPressReleaseEmbargoDate
                date={pressRelease.date}
                textSizeClass="text-sm"
              />
            </div>

            {(pressRelease.filePdf ||
              pressRelease.fileDoc ||
              pressRelease.image) && (
              <div className="flex flex-col space-y-2 justify-start items-start mt-4">
                <span className="font-medium text-sm">Press release files</span>
                <CompanyPressReleaseFileIcons
                  title={pressRelease.title}
                  filePdf={pressRelease.filePdf}
                  fileDoc={pressRelease.fileDoc}
                  image={pressRelease.image}
                />
              </div>
            )}
          </div>
          <div className="flex w-full justify-between items-center bg-gray-50 mt-5 sm:mt-6 px-6 py-6">
            <Button
              type="secondary"
              onClick={() =>
                setCurrentPressReleaseId(pressReleases[currentIndex - 1].uid)
              }
              disabled={!hasPrevious}
              eventType="pressPage_view"
              eventProperties={{
                action: 'pressRelease_view',
                title: pressReleases[currentIndex - 1]?.title,
              }}
            >
              Previous
            </Button>

            <p className="text-center font-normal text-gray-400 text-sm sm:text-md">
              {currentIndex + 1} of {pressReleases.length} Team <br />
              {pressReleases.length === 1 ? 'Press Release' : 'Press Releases'}
            </p>

            <Button
              type="secondary"
              onClick={() =>
                setCurrentPressReleaseId(pressReleases[currentIndex + 1].uid)
              }
              disabled={!hasNext}
              eventType="pressPage_view"
              eventProperties={{
                action: 'pressRelease_view',
                title: pressReleases[currentIndex + 1]?.title,
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

CompanyPressReleaseViewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  pressReleases: PropTypes.array,
  currentPressReleaseId: PropTypes.string,
  setCurrentPressReleaseId: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompanyPressReleaseViewModal;
