import PropTypes from 'prop-types';
import React from 'react';

import { EyeIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import UniversalImage from 'components/UniversalImage/UniversalImage';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';

const UpdateLogoModal = ({ uploadFolder, onClose, onSubmit }) => {
  const {
    control,
    handleSubmit,
    errors,
    setError,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  return (
    <Modal onClose={onClose} testId="updateLogoModal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-6 py-6 pb-0">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
            <EyeIcon className="w-6" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="dialog-1-title"
            >
              Update your company logo
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Make your press page more unique with your own logo
            </p>
          </div>

          <div className="mt-4">
            <Controller
              name="logo"
              control={control}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <InputFieldUpload
                  name="logo"
                  folder={uploadFolder}
                  onChange={onChange}
                  value={value}
                  setError={setError}
                  heightClassName="h-48"
                  testId="updateLogoModal-upload"
                  renderFile={(image) => (
                    <UniversalImage
                      image={image}
                      alt="Logo preview"
                      heightClass="h-48"
                      objectFitClass="object-contain"
                    />
                  )}
                />
              )}
            />
          </div>
          <p className="mt-2 text-xs text-gray-400">
            Square images work best (e.g. 500x500 or bigger)
          </p>
          {errors.logo && <ErrorMessage>{errors.logo.message}</ErrorMessage>}
        </div>
        <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
          <Button
            type="secondary"
            onClick={() => onClose()}
            data-test-id="updateLogoModal-cancel"
          >
            Cancel
          </Button>
          <div className="ml-3">
            <Button
              submit
              disabled={!isValid}
              data-test-id="updateLogoModal-submit"
            >
              Update logo
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

UpdateLogoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadFolder: PropTypes.string.isRequired,
};

export default UpdateLogoModal;
