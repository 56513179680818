import { ANALYTICS_PATH as path } from 'constants/paths';

import AnalyticsPage from './AnalyticsPage';

const AnalyticsRoute = {
  path,
  authRequired: true,
  component: AnalyticsPage,
};

export default AnalyticsRoute;
