import { useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';
import { useAnalytics } from 'reactfire';

import useEnvironment from 'contexts/EnvironmentContext/hooks/useEnvironment';

import createIubendaConfiguration from './helpers/createIubendaConfiguration';
import setCookiesEnabled from './helpers/setCookiesEnabled';

// Displays the Iubenda Cookie Modal, stores this in the user's local storage
// Also sends an event to Analytics if cookies have been accepted and tracking been allowed
const IubendaCookieConsentModal = () => {
  const { actions } = useStateMachine({ setCookiesEnabled });
  const { isEmulated } = useEnvironment();
  const analytics = useAnalytics();

  // this method is deprecated, but onConsentRead doesn't provide any information
  // on whether the consent has been accepted or rejected in its callback
  const onConsentGiven = () => {
    // enable cookies in state machine, so PageLogger can start logging
    actions.setCookiesEnabled(true);
    // enable tracking in analytics so GA starts sending XHR requests
    analytics.setAnalyticsCollectionEnabled(true);
  };

  useEffect(() => {
    if (isEmulated) {
      actions.setCookiesEnabled(true);
    }
  }, [actions, isEmulated]);

  if (isEmulated) {
    return null;
  }

  createIubendaConfiguration(onConsentGiven);

  const scripts = [
    '//cdn.iubenda.com/cs/tcf/stub-v2.js',
    '//cdn.iubenda.com/cs/iubenda_cs.js',
  ];

  scripts.forEach((src, index) => {
    const id = `iubenda${index}`;
    const existingScript = document.getElementById(id);
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      document.body.appendChild(script);
    }
  });

  return null;
};

export default IubendaCookieConsentModal;
