import React from 'react';

import 'firebase/auth';
import { Helmet } from 'react-helmet';

import PageWrapper from 'components/PageWrapper';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useRedirectAuth from 'hooks/useRedirectAuth';
import useSignIn from 'hooks/useSignIn';

import OnboardingLink from '../OnboardingLink';
import LoginForm from './LoginForm';

const LoginPage = () => {
  const { isAnonymous } = useUserData();
  const showAuth = useRedirectAuth();
  const { signInWithEmail, signInWithGoogle } = useSignIn();

  // Don't render anything because the user should be redirected
  if (!showAuth) {
    return null;
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>Login | MVPR</title>
      </Helmet>
      <LoginForm
        onSubmit={signInWithEmail}
        signInWithGoogle={signInWithGoogle}
      />
      <OnboardingLink isAnonymous={isAnonymous} />
    </PageWrapper>
  );
};

export default LoginPage;
