import PropTypes from 'prop-types';
import React from 'react';

import getTealBgClassName from 'utils/color/getTealBgClassName';

import AnalyticsContainer from './AnalyticsContainer';
import { prepareRowsForPresentation } from './helpers/reportRowsHelpers';

const AnalyticsDetails = ({
  reportRows,
  action,
  title,
  showPercentage = false,
}) => {
  const rows = prepareRowsForPresentation({
    reportRows,
    action,
    showPercentage,
    sortByFields: ['count', 'title'],
  });
  return (
    <AnalyticsContainer
      title={title}
      subtitle="Top 6"
      loading={!reportRows}
      empty={!rows.length}
    >
      <ul>
        {rows.map((detail, idx) => {
          const colorClass = getTealBgClassName(idx);
          return (
            <div
              key={`${detail.title}-${detail.type}`}
              className="text-sm flex items-center space-x-2 mb-2"
            >
              <div
                className={`flex-none flex items-center justify-center ${
                  showPercentage ? 'w-16' : 'w-8'
                } px-1 h-6 rounded-sm text-white ${colorClass}`}
              >
                {detail.count.toFixed(showPercentage ? 1 : 0)}{' '}
                {showPercentage && '%'}
              </div>
              <p className="flex items-center text-ellipsis">
                <span className="line-clamp-1">{detail.title} </span>
                {detail.type && (
                  <span className="ml-1 text-gray-400">
                    ({detail.type.toUpperCase()})
                  </span>
                )}
              </p>
            </div>
          );
        })}
      </ul>
    </AnalyticsContainer>
  );
};

AnalyticsDetails.propTypes = {
  reportRows: PropTypes.array,
  action: PropTypes.string,
  title: PropTypes.string,
  showPercentage: PropTypes.bool,
};

export default AnalyticsDetails;
