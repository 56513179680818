import PropTypes from 'prop-types';
import React from 'react';

import { Scrollspy } from '@makotot/ghostui';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import OnboardingWrapper from 'components/OnboardingWrapper/OnboardingWrapper';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useQuery from 'hooks/useQuery';

import CompanyHeader from './CompanyHeader/CompanyHeader';
import CompanyMeta from './CompanyMeta/CompanyMeta';
import CompanyNavbar from './CompanyNavbar/CompanyNavbar';
import CompanyPageEmptyState from './CompanyPageEmptyState';
import CompanyScrollHandler from './CompanyScrollHandler';
import CompanySectionRefs from './CompanySectionRefs/CompanySectionRefs';
import CompanySections from './CompanySections';
import { COMPANY_NAVBAR_OFFSET } from './companyNavbarOffset';
import useCompanyCruds from './hooks/useCompanyCruds';
import useCompanyDataByRef from './hooks/useCompanyDataByRef';

const CompanyPageContent = ({ companyRef }) => {
  const location = useLocation();

  const query = useQuery();
  const isPreview = !!query.get('preview');

  const { companyData: userCompanyData } = useCompanyData();

  const companyData = useCompanyDataByRef(companyRef);
  const cruds = useCompanyCruds(companyRef);

  if (
    !companyRef ||
    companyData === null ||
    companyData?.isOwner === null ||
    !cruds
  ) {
    return null;
  }

  const { founders: foundersCrud, ...sectionCruds } = cruds;
  const showOwnerView = !!(companyData.isOwner && !isPreview);
  return (
    <OnboardingWrapper matchesOwner={companyData.matchesOwner}>
      <Helmet>
        <title>Press page - {companyData.name} | MVPR</title>
        <meta name="robots" content="noindex" />
      </Helmet>

      <div
        className={classNames('pt-16 bg-white', {
          'sm:pl-16': userCompanyData?.slug,
        })}
      >
        <CompanyHeader
          companyRef={companyRef}
          companyData={companyData}
          showOwnerView={showOwnerView}
          isPreview={isPreview}
        />
        <CompanyMeta
          {...companyData}
          companyRef={companyRef}
          showOwnerView={showOwnerView}
          foundersCrud={foundersCrud}
          videosRef={companyRef.collection('videos')}
        />

        <CompanySectionRefs showOwnerView={showOwnerView} cruds={sectionCruds}>
          {(sectionRefsArray, sectionRefs) =>
            !sectionRefsArray.length && !showOwnerView ? (
              <CompanyPageEmptyState />
            ) : (
              <CompanyScrollHandler
                hash={location.hash}
                sectionRefs={sectionRefs}
              >
                <Scrollspy
                  sectionRefs={sectionRefsArray}
                  offset={COMPANY_NAVBAR_OFFSET}
                >
                  {({ currentElementIndexInViewport }) => (
                    <>
                      <CompanyNavbar
                        currentLocation={location}
                        sectionRefs={sectionRefs}
                        showOwnerView={showOwnerView}
                        currentIndex={currentElementIndexInViewport}
                      />

                      <CompanySections
                        companyId={companyRef.id}
                        cruds={cruds}
                        founders={foundersCrud[1]}
                        sectionRefs={sectionRefs}
                        showOwnerView={showOwnerView}
                      />
                    </>
                  )}
                </Scrollspy>
              </CompanyScrollHandler>
            )
          }
        </CompanySectionRefs>
      </div>
    </OnboardingWrapper>
  );
};

CompanyPageContent.propTypes = {
  companyRef: PropTypes.object,
};

export default CompanyPageContent;
