import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Dialog } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';
import { useAnalytics } from 'reactfire';

import Button from 'components/buttons/Button';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useHandleLogout from 'hooks/useHandleLogout';

// The failure message presented after a failed payment
const OnboardingWrapperPaymentStatusFailure = ({
  open = false,
  onClose = noop,
}) => {
  const handleLogout = useHandleLogout();

  const { companyData } = useCompanyData();
  const analytics = useAnalytics();

  useEffect(() => {
    if (companyData?.slug && open) {
      analytics.logEvent('payment_update', {
        action: 'payment_failed',
        slug: companyData?.slug,
      });
    }
  }, [analytics, companyData?.slug, open]);

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <div className="flex items-center justify-center min-h-screen">
        <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
          <div className="flex items-center px-6 py-6 space-x-4">
            <div className="flex flex-col items-center sm:flex-row sm:items-start">
              <div className="shrink-0 mb-2 sm:mb-0">
                <XCircleIcon
                  className="h-10 w-10 text-red-500"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 space-y-3">
                <h3 className="text-2xl mt-1 font-medium text-red-500">
                  We&apos;re sorry!
                </h3>
                <p className="text-gray-700 text-md leading-relaxed">
                  Looks like there was an issue with the payment.
                  <br /> What would you like to do?
                </p>
              </div>
            </div>
          </div>
          <div className="flex w-full justify-between bg-gray-50 px-6 py-6 rounded-b-md">
            <Button type="secondary" onClick={handleLogout}>
              Logout
            </Button>
            <div className="ml-3">
              <Button onClick={onClose}>Try again</Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

OnboardingWrapperPaymentStatusFailure.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default OnboardingWrapperPaymentStatusFailure;
