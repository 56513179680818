import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import InputFieldToggle from 'components/form/inputFields/InputFieldToggle';

const AdminSweetheartListCompanyToggle = ({
  isEnabled = false,
  uid = '',
  toggleSweetheartList = noop,
  isDisabled = false,
}) => (
  <InputFieldToggle
    id={uid}
    value={isEnabled}
    onChange={toggleSweetheartList}
    text={isEnabled ? 'Feature Enabled' : 'Feature Disabled'}
    disabled={isDisabled}
  />
);

AdminSweetheartListCompanyToggle.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  uid: PropTypes.string.isRequired,
  toggleSweetheartList: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default AdminSweetheartListCompanyToggle;
