import React, { useState } from 'react';

import { CalendarIcon } from '@heroicons/react/solid';
import uniqBy from 'lodash/uniqBy';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import OpportunitiesFilter from 'components/opportunities/OpportunitiesFilter/OpportunitiesFilter';
import OpportunitiesSort from 'components/opportunities/OpportunitiesSort/OpportunitiesSort';
import OpportunitiesTopicsModal from 'components/opportunities/OpportunitiesTopicModal/OpportunitiesTopicsModal';
import filterOpportunities from 'components/opportunities/helpers/filterOpportunities';
import sortOpportunities from 'components/opportunities/helpers/sortOpportunities';
import defaultOptions from 'constants/defaultOptions';
import { CLASSIFIED_OPPORTUNITIES_COLLECTION } from 'constants/firebasePaths';
import { filterOptions, sortOptions } from 'constants/opportunitiesOptions';
import { tagsTopics } from 'constants/tags';

import AdminWrapper from '../AdminWrapper';
import AdminOpportunity from './AdminOpportunity/AdminOpportunity';

const date = new Date();

const sortOptionsNone = sortOptions.slice(2, 3);
const sortOptionsMostRecent = {
  name: 'mostRecent',
  label: 'Most recent',
  Icon: CalendarIcon,
};
const sortOptionsAdmin = sortOptions
  .slice(0, 2)
  .concat(sortOptionsMostRecent, sortOptionsNone);

const AdminOpportunitiesPage = () => {
  const firestore = useFirestore();

  // topics
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [isTopicsModalOpen, setIsTopicsModalOpen] = useState(false);

  // filter, sort
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState(sortOptionsMostRecent);
  const [sortDir, setSortDir] = useState(-1);

  // get classified opportunities
  const opportunitiesCollection = firestore
    .collection(CLASSIFIED_OPPORTUNITIES_COLLECTION)
    .where('deadlineUTC', '>', date);

  let { data: opportunities } = useFirestoreCollectionData(
    opportunitiesCollection,
    defaultOptions
  );

  if (filter.length) {
    opportunities = filterOpportunities(opportunities, filter);
  }

  if (sort !== null) {
    opportunities = sortOpportunities(opportunities, { sort, sortDir });
  }

  const opportunitiesCounts = filterOptions.map(
    (f) =>
      opportunities.filter(
        (o) => o?.domainAuthority >= f.min && o?.domainAuthority <= f.max
      ).length
  );

  // only show core and custom topics
  const allTopics = uniqBy(
    tagsTopics.filter((t) => t.core),
    'id'
  );

  return (
    <AdminWrapper title="Fine-tune opportunities" currentIndex={3}>
      <p className="mt-2">
        The second column lists the currently assigned topics, the third column
        the core topics. By clicking on an assigned topic, you can remove it. By
        clicking on a core topic, add it to the opportunity .
      </p>

      <div className="flex mt-4 justify-between">
        <div className="flex space-x-2">
          <OpportunitiesFilter
            filterOptions={filterOptions}
            setFilter={setFilter}
            filter={filter}
          />
          <OpportunitiesSort
            sortOptions={sortOptionsAdmin}
            setSort={setSort}
            sort={sort}
            sortDir={sortDir}
            setSortDir={setSortDir}
          />
        </div>
        <div className="flex space-x-2">
          {opportunitiesCounts.map((count, index) => (
            <p
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="flex items-center space-x-2 p-2 bg-gray-100 rounded-md text-sm"
            >
              <span className="text-gray-400">Tier {index + 1}</span>
              <span className="px-2 py-0.5 bg-teal-500 text-white rounded-lg">
                {count}
              </span>
            </p>
          ))}
        </div>
      </div>

      <div className="mt-4">
        {opportunities.map((opportunity) => (
          <AdminOpportunity
            key={opportunity.id}
            opportunity={opportunity}
            firestore={firestore}
          />
        ))}
      </div>

      <OpportunitiesTopicsModal
        open={isTopicsModalOpen}
        topics={allTopics}
        selectedTopics={selectedTopics}
        setSelectedTopics={setSelectedTopics}
        onClose={() => setIsTopicsModalOpen(false)}
      />
    </AdminWrapper>
  );
};

export default AdminOpportunitiesPage;
