import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { sanitizeUrl } from '@braintree/sanitize-url';
import { PlusIcon } from '@heroicons/react/solid';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import EmptyState from 'components/company/EmptyState';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';

import CompanyArticle from './CompanyArticle';
import CompanyArticlesEditModal from './CompanyArticleEditModal';

const CompanyArticles = ({ crud, showOwnerView, companyId, sectionRef }) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const closeModal = () => setIsAddModalOpen(false);

  const [, articles, ops] = crud;
  const isEmpty = articles.length < 1;
  const isEmptyAndOwnerview = showOwnerView && isEmpty;

  const onSubmit = (data = {}) => {
    const { date, url, image, title, author, publication } = data;
    const sanitizedUrl = sanitizeUrl(url);
    ops.createDoc({
      date: createTimestampFromDateObj(date),
      url: sanitizedUrl,
      image,
      title,
      author,
      publication,
    });
    closeModal();
  };

  // If there are no videos yet, hide this section for journalists
  if (!showOwnerView && isEmpty) {
    return null;
  }

  return (
    <div data-test-id="company-recentNews" id="recent-news" ref={sectionRef}>
      <SectionHeaderWrapper>
        <SectionHeadline
          light
          testId="company-recent-news-headline"
          showEmptySectionHint={isEmptyAndOwnerview}
        >
          Recent news
        </SectionHeadline>
        {showOwnerView && (
          <DotsMenu
            options={[
              {
                label: 'Add article',
                icon: <PlusIcon className="w-4 h-4" />,
                onClick: () => setIsAddModalOpen(true),
              },
            ]}
            theme="dark"
          />
        )}
      </SectionHeaderWrapper>

      <div className="w-full mt-4">
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-6">
          {articles.length ? (
            articles.map((article) => (
              <CompanyArticle
                key={article.uid}
                article={article}
                ops={ops}
                showOwnerView={showOwnerView}
                companyId={companyId}
              />
            ))
          ) : (
            <EmptyState
              title="Add an article"
              description="Adding recent news gives your company credibility."
              onClick={() => setIsAddModalOpen(true)}
              testId="company-articles-addFirst"
            />
          )}
        </ul>
      </div>

      {showOwnerView && (
        <CompanyArticlesEditModal
          open={isAddModalOpen}
          onClose={() => closeModal()}
          onSubmit={onSubmit}
          uploadFolder={`companies/${companyId}/articles`}
        />
      )}
    </div>
  );
};

CompanyArticles.propTypes = {
  crud: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanyArticles;
