import PropTypes from 'prop-types';
import React from 'react';

import { LightningBoltIcon } from '@heroicons/react/solid';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import HomeLogo from 'components/layout/Navbar/HomeLogo';
import { PUBLICATIONS_COLLECTION } from 'constants/firebasePaths';

const className =
  'flex-none bg-gray-100 flex justify-center items-center w-8 h-8 rounded-full overflow-hidden border border-gray-200';

// Displays the publication's image as a rounded avatar, or the MVPR logo for custom opportunities
const PublicationImage = ({ name = '', custom = false }) => {
  const firestore = useFirestore();
  const query = firestore
    .collection(PUBLICATIONS_COLLECTION)
    .where('name', '==', name);
  const { data } = useFirestoreCollectionData(query);

  if (custom) {
    return (
      <div className={className}>
        <HomeLogo iconOnly />
      </div>
    );
  }

  const image = data?.[0]?.image;

  return (
    <div className={className}>
      {image && (
        <UniversalImage
          image={image}
          alt={`Logo of publication ${name}`}
          widthClass="w-8"
          heightClass="h-8"
        />
      )}
      {!image && <LightningBoltIcon className="text-teal-500" />}
    </div>
  );
};

PublicationImage.propTypes = {
  name: PropTypes.string,
  custom: PropTypes.bool,
};

export default PublicationImage;
