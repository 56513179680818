import PropTypes from 'prop-types';
import React, { useState } from 'react';

import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { useAuth } from 'reactfire';

import Container from 'components/Container';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import useNotifications from 'modules/notification/useNotifications';

import UpdateEmailModal from './UpdateEmailModal';
import UpdatePasswordModal from './UpdatePasswordModal';

const AccountForm = ({ user }) => {
  const { showSuccess, showError } = useNotifications();

  const auth = useAuth;
  const { credential } = auth.EmailAuthProvider;

  const [updateEmailModalOpen, setUpdateEmailModalOpen] = useState(false);
  const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = useState(false);

  const isGoogleAccount =
    get(user, 'providerData.0.providerId') === 'google.com';

  const updateEmail = async ({ password, newEmail }) => {
    try {
      const credentials = credential(user.email, password);
      await user.reauthenticateWithCredential(credentials);
      await user.verifyBeforeUpdateEmail(newEmail);

      setUpdateEmailModalOpen(false);
      showSuccess('Email address has been updated. Please check your inbox.');
    } catch (e) {
      setUpdateEmailModalOpen(false);
      showError('Something has gone wrong. Please try again!');
    }
  };

  const updatePassword = async ({ password, newPassword }) => {
    try {
      const credentials = credential(user.email, password);
      await user.reauthenticateWithCredential(credentials);
      await user.updatePassword(newPassword);

      setUpdatePasswordModalOpen(false);
      showSuccess('Your password has been updated.');
    } catch (e) {
      setUpdatePasswordModalOpen(false);
      showError('Something has gone wrong. Please try again!');
    }
  };

  return (
    <>
      <Container
        title="Account data"
        titleColorClassName="text-gray-900"
        borderDivideClassName="divide-none"
      >
        <div className="w-full -mt-4 mb-6 mx-5">
          <Label htmlFor="email">Email</Label>
          <InputFieldText name="email" defaultValue={user.email} disabled />
          {!isGoogleAccount && (
            <div className="mt-2">
              <Button
                href="#"
                className="mr-2"
                type="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  setUpdateEmailModalOpen(true);
                }}
              >
                Change email
              </Button>
              <Link
                to="#"
                className="font-medium text-sm text-gray-700 hover:text-gray-500 py-2 px-3"
                onClick={(e) => {
                  e.preventDefault();
                  setUpdatePasswordModalOpen(true);
                }}
              >
                Reset password
              </Link>
            </div>
          )}
        </div>
      </Container>

      {updateEmailModalOpen && (
        <UpdateEmailModal
          onClose={() => setUpdateEmailModalOpen(false)}
          onSubmit={updateEmail}
        />
      )}

      {updatePasswordModalOpen && (
        <UpdatePasswordModal
          onClose={() => setUpdatePasswordModalOpen(false)}
          onSubmit={updatePassword}
        />
      )}
    </>
  );
};

AccountForm.propTypes = {
  user: PropTypes.object.isRequired,
};

export default AccountForm;
