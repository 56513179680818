export const REGEX_URL =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,16}\b([-a-zA-Z0-9()@*!:%_+.,~#?&//=]*)$/;
export const REGEX_URL_NO_PROTOCOL =
  /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,16}\b([-a-zA-Z0-9()@*!:%_+.,~#?&//=]*)$/;
export const REGEX_DATA_URL =
  /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;
export const REGEX_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i;
export const REGEX_DEADLINE_RAW =
  /^[0-9]{2}\s[A-Z]{1}[a-z]{2}\s[0-9]{4}\s[0-9]{2}:[0-9]{2}$/;
export const REGEX_DOMAIN_AUTHORITY = /^[1-9][0-9]?$|^100$/;
export const REGEX_PHONE = /^[0-9]*$/;
export const REGEX_HANDLE_TWITTER = /^[a-zA-Z_]{1}[a-zA-Z0-9_]{0,14}$/;
export const REGEX_HANDLE_LINKEDIN = /^[a-zA-Z_]{1}[a-zA-Z0-9-]{2,99}$/;
export const REGEX_IMAGE_FILETYPE = /\.(png|jpg|jpeg|gif|webp)$/;
