import PropTypes from 'prop-types';
import React from 'react';

import { CalendarIcon, ClockIcon } from '@heroicons/react/outline';

import formatTimestamp from 'utils/date/formatTimestamp';
import getTimeFromTimestamp from 'utils/date/getTimeFromTimestamp';

const CompanyPressReleaseEmbargoDate = ({
  date,
  textSizeClass = 'text-xs',
  testId = '',
}) => (
  <div className={`flex justify-start items-center space-x-2 ${textSizeClass}`}>
    <div className="flex justify-start items-center  space-x-1">
      <CalendarIcon className="w-4 h-4 text-gray-400" />
      <span className="text-gray-400" data-test-id={`${testId}-date`}>
        {formatTimestamp(new Date(date))}
      </span>
    </div>

    <div className="flex justify-start items-center space-x-1">
      <ClockIcon className="w-4 h-4 text-gray-400" />
      <span className="text-gray-400" data-test-id={`${testId}-time`}>
        {getTimeFromTimestamp(date)}
      </span>
    </div>
  </div>
);

CompanyPressReleaseEmbargoDate.propTypes = {
  date: PropTypes.string,
  textSizeClass: PropTypes.string,
  testId: PropTypes.string,
};

export default CompanyPressReleaseEmbargoDate;
