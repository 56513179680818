import PropTypes from 'prop-types';
import { useEffect } from 'react';

import pressPageSections from 'constants/pressPageSections';

const CompanyScrollHandler = ({ sectionRefs, hash, children }) => {
  // navigate to anchor on page load, if anchor is present in URL
  useEffect(() => {
    if (!hash) {
      return false;
    }
    const section = pressPageSections.find((s) => s.hash === hash);

    const timeout = setTimeout(() => {
      const yCoordinate =
        parseInt(
          sectionRefs?.[section?.name]?.current?.getBoundingClientRect()?.top,
          10
        ) + window.pageYOffset;

      if (!yCoordinate) {
        return;
      }
      const yOffset = -119;
      window.scrollTo({
        top: yCoordinate + yOffset,
        behavior: 'smooth',
      });
    }, 500);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

CompanyScrollHandler.propTypes = {
  sectionRefs: PropTypes.object,
  hash: PropTypes.string,
  children: PropTypes.node,
};

export default CompanyScrollHandler;
