// function to get topics from a list of matching topics (an array of IDs)
import sortBy from 'lodash/sortBy';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import filterOpportunities from 'components/opportunities/helpers/filterOpportunities';
import sortOpportunities from 'components/opportunities/helpers/sortOpportunities';
import defaultOptions from 'constants/defaultOptions';
import {
  CUSTOM_OPPORTUNITIES_COLLECTION,
  CLASSIFIED_OPPORTUNITIES_COLLECTION,
} from 'constants/firebasePaths';
import useMapTopicsToCoreTopics from 'hooks/useMapTopicsToCoreTopics';

import compareUid from './helpers/compareUid';
import getTopics from './helpers/getTopics';
import useCompanyTopics from './useCompanyTopics';

// current date
const date = new Date();

const useOpportunities = ({
  selectedTopics,
  topics,
  uid,
  companyOpportunities = [],
  filter = [],
  sort = null,
  sortDir = null,
}) => {
  const firestore = useFirestore();

  // map topics to core topics
  const mapTopicsToCoreTopics = useMapTopicsToCoreTopics();

  // map topics to company topics
  const companyTopics = useCompanyTopics(topics);

  // get classified opportunities
  const classifiedOpportunitiesCollection = firestore
    .collection(CLASSIFIED_OPPORTUNITIES_COLLECTION)
    .where('deadlineUTC', '>', date);

  const classifiedOpportunities = useFirestoreCollectionData(
    classifiedOpportunitiesCollection,
    defaultOptions
  );

  // get custom opportunities where company UID is included in `companies`
  const customOpportunitiesCollection = firestore
    .collection(CUSTOM_OPPORTUNITIES_COLLECTION)
    .where('deadlineUTC', '>', date)
    .where('companies', 'array-contains', uid);
  const { data: customOpportunitiesData } = useFirestoreCollectionData(
    customOpportunitiesCollection,
    defaultOptions
  );

  // map topics to selected topics, append custom ones
  const matchingOpportunities = classifiedOpportunities.data
    .concat(customOpportunitiesData)
    .map((opportunity) => ({
      ...opportunity,
      selectedTopics: getTopics(
        mapTopicsToCoreTopics(opportunity.topics),
        mapTopicsToCoreTopics(selectedTopics)
      ),
      topics: getTopics(
        companyTopics,
        mapTopicsToCoreTopics(opportunity.topics)
      ),
      uid: opportunity.uid,
    }));

  const matchingOpportunitiesSorted = sortBy(
    matchingOpportunities,
    (o) => o.custom
  );

  // empty array fallback in case any of these are undefined
  let declined = companyOpportunities.declined || [];
  let responded = companyOpportunities.responded || [];

  let saved = companyOpportunities.saved
    ? companyOpportunities.saved.filter(
        (opportunity) =>
          !declined.some(compareUid(opportunity.uid)) &&
          !responded.some(compareUid(opportunity.uid))
      )
    : [];

  // filter out from opportunities within deadline those stored in other tabs
  let upcoming = matchingOpportunitiesSorted.filter(
    (o) =>
      !declined.some(compareUid(o.uid)) &&
      !saved.some(compareUid(o.uid)) &&
      !responded.some(compareUid(o.uid))
  );

  // only show upcoming opportunities that contain selected topics or are custom
  if (selectedTopics?.length > 0) {
    upcoming = upcoming.filter((o) => o.custom || o.selectedTopics?.length > 0);
  }

  // remove uncategorised
  upcoming = upcoming.filter((o) => !o.topics?.find((t) => t.id === 1199));

  if (filter.length) {
    upcoming = filterOpportunities(upcoming, filter);
    declined = filterOpportunities(declined, filter);
    saved = filterOpportunities(saved, filter);
    responded = filterOpportunities(responded, filter);
  }

  upcoming = sortOpportunities(upcoming, { sort, sortDir });
  declined = sortOpportunities(declined, { sort, sortDir });
  saved = sortOpportunities(saved, { sort, sortDir });
  responded = sortOpportunities(responded, { sort, sortDir });

  // create object with all opportunities
  return {
    upcoming,
    declined,
    saved,
    responded,
  };
};

export default useOpportunities;
