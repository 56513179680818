/* eslint-disable no-unused-vars */
import React from 'react';

import { useParams } from 'react-router-dom';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import SweetheartList from './SweetheartList';
import SweetheartListPageAdmin from './SweetheartListPageAdmin';
import SweetheartListPageBeingGenerated from './SweetheartListViewStates/SweetheartListPageBeingGenerated';

// if user.claimsAdmin - display content
const SweetheartListPage = () => {
  const company = useCompanyData();

  // Use the company slug to inspect companies as admin
  const { id: slug } = useParams('id');

  // For Admin
  if (slug) {
    return <SweetheartListPageAdmin slug={slug} />;
  }

  // For Users

  if (company.companyData) {
    return <SweetheartList company={company.companyData} />;
  }

  return <SweetheartListPageBeingGenerated />;
};

export default SweetheartListPage;
