import PropTypes from 'prop-types';
import React from 'react';

const InputFieldTextPrefix = ({ prefix = '' }) => (
  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
    {prefix}
  </span>
);

InputFieldTextPrefix.propTypes = {
  prefix: PropTypes.node.isRequired,
};

export default InputFieldTextPrefix;
