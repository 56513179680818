import PropTypes from 'prop-types';
import React from 'react';

import { useStateMachine } from 'little-state-machine';
import { Controller, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { useUser } from 'reactfire';

import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import SeparatorTextLine from 'components/form/SeparatorTextLine';
import InputFieldTagsDropdown from 'components/form/inputFields/InputFieldTagsDropdown/InputFieldTagsDropdown';
import InputFieldTextOther from 'components/form/inputFields/InputFieldTextOther/InputFieldTextOther';
import { tagsAudiences, tagsTopics } from 'constants/tags';
import filterCustomOptions from 'utils/data/filterCustomOptions';

import TitleSubtitle from '../TitleSubtitle';
import { updateCompany } from '../actions';

const StepThree = ({ history, availableSteps = [] }) => {
  const {
    control,
    register,
    handleSubmit,
    errors,
    formState: { isValid },
    getValues,
    setValue,
  } = useForm({ mode: 'onChange' });

  const { actions, state } = useStateMachine({ updateCompany });
  const {
    company: { topics, audiences, topicsOther, audiencesOther },
  } = state;

  const user = useUser();
  const hasUserData = user?.data;

  const customTopics = filterCustomOptions(topics);
  const customAudiences = filterCustomOptions(audiences);
  const coreTopics = tagsTopics.filter((topic) => topic.core);

  const onSubmit = () => history.push('/onboarding/four');

  const onFormChange = () => {
    actions.updateCompany(getValues());
  };

  return (
    <form
      className="relative z-10 flex flex-col items-center"
      onSubmit={handleSubmit(onSubmit)}
      onChange={onFormChange}
      data-test-id="onboarding-user-form"
    >
      <div className="flex flex-col items-center w-10/12 sm:w-8/12 md:w-6/12 lg:w-4/12 mt-12 mb-8">
        <TitleSubtitle
          title="Now a little bit about your audiences…"
          subtitle="We’ll use this to match your company with journalists that will be interested in you."
        />

        {/* Topics */}
        <fieldset className="w-full mt-5">
          <Label htmlFor="topics">
            What topics are you keen to talk about with the media?
          </Label>

          {/* Can we have this alphabetical */}
          {/* put alphabetical fuction into InputFieldTagsDropdown */}
          <div className="mt-2">
            <Controller
              name="topics"
              control={control}
              defaultValue={topics}
              render={({ onChange, value }) => (
                <InputFieldTagsDropdown
                  tags={coreTopics.concat(customTopics)}
                  value={value}
                  optionWidthClassName="w-full"
                  placeholder="Select as many as you would like"
                  onChange={(val) => {
                    onChange(val);
                    onFormChange();
                  }}
                  testId="onboarding-company-topics"
                />
              )}
            />
          </div>

          {errors.topics && (
            <ErrorMessage>{errors.topics.message}</ErrorMessage>
          )}

          <SeparatorTextLine />

          <InputFieldTextOther
            ref={register}
            setValue={setValue}
            onFormChange={onFormChange}
            defaultFieldName="topics"
            defaultValue={topicsOther}
            watchedValue={topics}
            options={coreTopics}
            placeholder="Add custom topics here..."
            data-test-id="onboarding-company-topics-other"
          />
        </fieldset>

        {/* Audiences */}
        <fieldset className="w-full mt-10">
          <Label htmlFor="audiences">
            Who are the people you want to reach with your views?
          </Label>
          <div className="mt-2">
            <Controller
              name="audiences"
              control={control}
              defaultValue={audiences}
              render={({ onChange, value }) => (
                <InputFieldTagsDropdown
                  tags={tagsAudiences.concat(customAudiences)}
                  value={value}
                  optionWidthClassName="w-full"
                  placeholder="Select as many as you would like"
                  onChange={(val) => {
                    onChange(val);
                    onFormChange();
                  }}
                  testId="onboarding-company-audiences"
                />
              )}
            />
          </div>

          {errors.audiences && (
            <ErrorMessage>{errors.audiences.message}</ErrorMessage>
          )}

          <SeparatorTextLine />

          <InputFieldTextOther
            ref={register}
            setValue={setValue}
            onFormChange={onFormChange}
            defaultFieldName="audiences"
            defaultValue={audiencesOther}
            watchedValue={audiences}
            options={tagsAudiences}
            placeholder="Add custom audiences here..."
            data-test-id="onboarding-company-audiences-other"
          />
        </fieldset>
      </div>

      <div className="mb-8 flex items-center">
        <Button
          size="large"
          submit
          disabled={
            !isValid || !hasUserData || !availableSteps.includes('four')
          }
          data-test-id="onboarding-user-submit"
        >
          Next question
        </Button>
      </div>
    </form>
  );
};

StepThree.propTypes = {
  history: PropTypes.object,
  availableSteps: PropTypes.array,
};

export default withRouter(StepThree);
