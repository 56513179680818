import PropTypes from 'prop-types';
import React from 'react';

import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import { JOURNALISTS_COLLECTION } from 'constants/firebasePaths';
import DefaultAvatar from 'static/User.png';

// Displays the journalist's image as a rounded avatar
const JournalistImage = ({ name = '' }) => {
  const firestore = useFirestore();
  const query = firestore
    .collection(JOURNALISTS_COLLECTION)
    .where('name', '==', name);
  const { data } = useFirestoreCollectionData(query);
  const image = data?.[0]?.image;

  return (
    <div className="flex-none bg-gray-100 flex justify-center items-center w-8 h-8 rounded-full overflow-hidden border border-gray-200">
      {image ? (
        <UniversalImage
          image={image}
          alt={`Headshot of journalist ${name}`}
          widthClass="w-8"
          heightClass="h-8"
        />
      ) : (
        <img src={DefaultAvatar} className="w-8 h-8" alt="Journalist default" />
      )}
    </div>
  );
};

JournalistImage.propTypes = {
  name: PropTypes.string,
};

export default JournalistImage;
