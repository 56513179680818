export const USERS_COLLECTION = 'users';
export const COMPANIES_COLLECTION = 'companies';
export const COMPANIES_OPPORTUNITIES_COLLECTION = 'companies_opportunities';
export const RESPONSE_SOURCE_EMAILS_COLLECTION = 'response_source_emails';
export const TOPICS_KEYWORDS_MAPPINGS = 'topics_keywords_mappings';
export const TOPICS_MAPPINGS = 'topics_mappings';
export const CUSTOM_OPPORTUNITIES_COLLECTION = 'opportunities';
export const CLASSIFIED_OPPORTUNITIES_COLLECTION = 'opportunities_classified';
export const OPPORTUNITIES_NOTIFICATIONS = 'opportunities_notifications';
export const JOURNALISTS_COLLECTION = 'journalists';
export const COMPANIES_SWEETHEARTS_COLLECTION = 'companies_sweethearts';
export const PUBLICATIONS_COLLECTION = 'publications';
// stripe
export const CUSTOMERS_COLLECTION = 'customers';
