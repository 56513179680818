import { useCallback } from 'react';

import noop from 'lodash/noop';
import { useStorage } from 'reactfire';

import createFileFromMetadata from 'utils/file/createFileFromMetadata';
import createFilename from 'utils/file/createFilename';

/**
 * Callback that fires when user uploads a file
 * Analyses things like file size and type based on a specific preset,
 * then uploads it to the provided Firebase folder
 */
const useOnDrop = ({
  onChange = noop,
  setError = noop,
  setLocalFile = noop,
  preset = {},
  folder = '',
}) => {
  const storage = useStorage();
  return useCallback(
    (acceptedFiles) => {
      // grab the first available file
      // TODO-SH: implement multiple file upload: https://app.clubhouse.io/mvpr/story/581/enable-multiple-file-uploads
      const tempFile = acceptedFiles[0];

      // check if file is bigger than allowed
      if (tempFile.size > preset.maxFilesize) {
        setError(preset.maxFilesizeErrorText);
        return;
      }

      // check if file has one of the allowed formats
      if (!preset.allowedFormats.includes(tempFile.type)) {
        setError(preset.allowedFormatsErrorText);
        return;
      }

      // save local file to immediately show something
      setLocalFile((state) => ({
        ...state,
        ...createFileFromMetadata(tempFile),
        path: window.URL.createObjectURL(tempFile),
        isUploading: true,
      }));

      // prepare upload
      const filename = createFilename(tempFile);
      const uploadRef = storage.ref(folder).child(filename);
      const uploadTask = uploadRef.put(tempFile);

      uploadTask
        .then((upload) => {
          if (upload.metadata) {
            const file = {
              ...createFileFromMetadata(upload.metadata),
              path: upload.metadata.fullPath,
            };
            onChange(file);

            // set upload of local file to done
            setLocalFile((state) => ({
              ...state,
              isUploading: false,
            }));
          }
        })
        .catch(() => {
          // TODO-LOG: send `err` to logger
        });
    },
    [storage, folder, setError, setLocalFile, onChange, preset]
  );
};

export default useOnDrop;
