import PropTypes from 'prop-types';
import React, { useState } from 'react';

import uniqBy from 'lodash/uniqBy';
import { Helmet } from 'react-helmet';

import OnboardingWrapper from 'components/OnboardingWrapper/OnboardingWrapper';
import OpportunitiesTopicsModal from 'components/opportunities/OpportunitiesTopicModal/OpportunitiesTopicsModal';
import {
  filterOptions,
  sortOptions,
  tabs,
} from 'constants/opportunitiesOptions';
import { tagsTopics } from 'constants/tags';
import useQuery from 'hooks/useQuery';

import OpportunitiesList from './OpportunitiesList/OpportunitiesList';
import OpportunitiesNavbar from './OpportunitiesNavbar/OpportunitiesNavbar';
import useCompaniesOpportunites from './hooks/useCompaniesOpportunities';
import useOpportunities from './hooks/useOpportunities';
import useOpportunitiesOps from './hooks/useOpportunitiesOps';

const Opportunities = ({ company = {} }) => {
  const { uid, topics } = company;

  const defaultActiveTab = parseInt(useQuery().get('tab'), 10) || 0;

  // topics
  const [selectedTopics, setSelectedTopics] = useState(topics);
  const [isTopicsModalOpen, setIsTopicsModalOpen] = useState(false);

  // tabs, filter, sort
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState(null);
  const [sortDir, setSortDir] = useState(1);

  // tags operations
  const viewAllTopics = () => setIsTopicsModalOpen(true);

  const [companiesOpportunitiesData, companiesOpportunitiesDoc] =
    useCompaniesOpportunites(uid);

  // media opportunities: upcoming, responded, declined, saved
  const opportunities = useOpportunities({
    selectedTopics,
    topics,
    uid,
    companyOpportunities: companiesOpportunitiesData,
    filter,
    sort,
    sortDir,
  });

  const opportunitiesOps = useOpportunitiesOps({
    opportunities: companiesOpportunitiesData,
    doc: companiesOpportunitiesDoc,
    company,
  });

  // only show core and custom topics
  const allTopics = uniqBy(
    tagsTopics.filter((t) => t.core).concat(topics),
    'id'
  );

  return (
    <OnboardingWrapper>
      <Helmet>
        <title>Media opportunities | MVPR</title>
      </Helmet>

      <div className="mt-16 sm:ml-16">
        <OpportunitiesNavbar
          tabs={tabs}
          filterOptions={filterOptions}
          sortOptions={sortOptions}
          opportunities={opportunities}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          filter={filter}
          setFilter={setFilter}
          sort={sort}
          sortDir={sortDir}
          setSort={setSort}
          setSortDir={setSortDir}
          viewAllTopics={viewAllTopics}
        />

        {/* Separator: needs to be pulled up a bit to cover the "active" border of the active item */}
        <div className="border-t-2 -mt-0.5" />

        <OpportunitiesList
          opportunities={opportunities}
          opportunitiesOps={opportunitiesOps}
          currentField={tabs[activeTab].field}
          selectedTopics={selectedTopics}
        />
      </div>

      <OpportunitiesTopicsModal
        open={isTopicsModalOpen}
        topics={allTopics}
        selectedTopics={selectedTopics}
        setSelectedTopics={setSelectedTopics}
        onClose={() => setIsTopicsModalOpen(false)}
      />
    </OnboardingWrapper>
  );
};

Opportunities.propTypes = {
  company: PropTypes.object.isRequired,
};

export default Opportunities;
