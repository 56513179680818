import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { PlusIcon } from '@heroicons/react/solid';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import EmptyState from 'components/company/EmptyState';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';

import CompanyDownload from './CompanyDownload/CompanyDownload';
import CompanyDownloadsEditModal from './CompanyDownloadsEditModal';

const CompanyDownloads = ({
  crud,
  founders,
  showOwnerView = false,
  companyId,
  sectionRef,
}) => {
  const [, downloadsRaw, ops] = crud;
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const downloads = downloadsRaw.map((download) => ({
    ...download,
    founder: download?.users?.[0]
      ? { name: download?.users?.[0].name }
      : download.founder,
  }));
  const isEmpty = downloads.length < 1;
  const isEmptyAndOwnerview = showOwnerView && isEmpty;

  // If there are no insights yet, hide this section for journalists
  if (!showOwnerView && isEmpty) {
    return null;
  }

  const onSubmit = (download) => {
    ops.createDoc({
      title: download.title || null,
      file: download.file || null,
      founder: download.founder || null,
      tags: download.tags || null,
    });
    setIsAddModalOpen(false);
  };

  return (
    <>
      <div data-test-id="company-downloads" id="downloads" ref={sectionRef}>
        <SectionHeaderWrapper>
          <SectionHeadline
            light
            testId="company-downloads-headline"
            showEmptySectionHint={isEmptyAndOwnerview}
          >
            Downloads
          </SectionHeadline>

          {showOwnerView && (
            <DotsMenu
              options={[
                {
                  label: 'Add download',
                  icon: <PlusIcon className="w-4 h-4" />,
                  onClick: () => setIsAddModalOpen(true),
                  testId: 'company-downloads-menu-add',
                },
              ]}
              theme="dark"
              testId="company-downloads-menu"
            />
          )}
        </SectionHeaderWrapper>

        <div className="w-full mt-4 mb-20">
          <ul
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4 gap-6 items-stretch"
            data-test-id="company-downloads-list"
          >
            {downloads.length ? (
              downloads.map((download) => (
                <CompanyDownload
                  key={download.title}
                  download={download}
                  founders={founders}
                  showOwnerView={showOwnerView}
                  ops={ops}
                  companyId={companyId}
                />
              ))
            ) : (
              <EmptyState
                title="Add a download"
                description="Adding attachments will allow journalists to use your assets in their press mentions."
                onClick={() => setIsAddModalOpen(true)}
                testId="company-downloads-addFirst"
              />
            )}
          </ul>
        </div>
      </div>
      {showOwnerView && isAddModalOpen && (
        <CompanyDownloadsEditModal
          open={isAddModalOpen}
          founders={founders}
          onClose={() => setIsAddModalOpen(false)}
          onSubmit={onSubmit}
          uploadFolder={`companies/${companyId}/articles`}
        />
      )}
    </>
  );
};

CompanyDownloads.propTypes = {
  crud: PropTypes.array.isRequired,
  founders: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanyDownloads;
