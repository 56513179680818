import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Dialog, Switch } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { CheckIcon, LockClosedIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { useAnalytics, useRemoteConfigString } from 'reactfire';

import Callout from 'components/Callout/Callout';
import StripeRedirectModal from 'components/StripeRedirectModal';
import Button from 'components/buttons/Button';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useRedirectToStripe from 'hooks/useRedirectToStripe';

// Modal to select one of the available payment plans
const OnboardingWrapperPlansModal = ({ open = false }) => {
  const { uid } = useUserData();

  const { data } = useRemoteConfigString('tiers');
  const { tiers } = JSON.parse(data);

  const { redirectToStripe, isRedirecting } = useRedirectToStripe();

  const analytics = useAnalytics();

  const onClick = async (priceId, index) => {
    analytics.logEvent('choose_plan', {
      index: index + 1,
      priceId,
    });
    const redirectUrl = await redirectToStripe({
      uid,
      priceId,
      successUrl: `${window.location.origin}${window.location.pathname}?success=true`,
      cancelUrl: `${window.location.origin}${window.location.pathname}?error=true`,
    });
    if (redirectUrl) {
      window.location.assign(redirectUrl);
    }
  };

  const [successFeesToggled, setSuccessFeesToggled] = useState(false);

  useEffect(() => ReactTooltip.rebuild());

  return (
    <>
      {isRedirecting && <StripeRedirectModal isRedirecting={isRedirecting} />}
      <Dialog
        open={open && !isRedirecting}
        onClose={() => {}}
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        data-test-id="onboardingWrapper-plansModal"
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div className="flex items-center justify-center min-h-screen sm:max-w-lg md:max-w-3xl lg:max-w-6xl m-auto">
          <div className="relative bg-white rounded-lg shadow-xl m-6">
            <div className="py-8 px-4 sm:px-8 flex-1 md:block">
              <h3 className="text-xl font-semibold">Please choose a plan</h3>
              <Callout size="sm">
                If you don&apos;t know which plan to choose or don&apos;t have
                the payment details available,{' '}
                <b className="font-semibold">
                  you can come back here later by logging in with your account.
                </b>
              </Callout>

              <Switch.Group as="div" className="flex mt-6 items-center">
                <Switch
                  data-test-id="onboardingWrapper-plansModal-successFeesToggle"
                  checked={successFeesToggled}
                  onChange={setSuccessFeesToggled}
                  className={classNames(
                    successFeesToggled ? 'bg-teal-500' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 border-gray-300'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      successFeesToggled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>

                <Switch.Label
                  as="span"
                  className="ml-3 text-sm font-medium text-gray-900 flex flex-wrap"
                  passive
                >
                  I agree to paying the
                  <a
                    className="underline hover:no-underline text-teal-500 mx-1"
                    href="https://www.mvpr.io/success-fees"
                    target="_blank"
                    rel="noreferrer"
                  >
                    success fees
                  </a>
                  associated with media coverage
                  <InformationCircleIcon
                    data-tip="In the event that mvpr shares a press release with journalists on behalf of a client, mvpr will be entitled to success fees associated with coverage for the release up to a cap of £1,000 + VAT per release."
                    className="w-4 h-4 mb-1 mr-1 text-gray-500 shrink-0"
                  />{' '}
                  and media opportunities
                  <InformationCircleIcon
                    data-tip="In the event that the company or its employees are named in the media as a result of responding to an opportunity sourced by mvpr (marked 'Sourced by mvpr'). For full details click the link to Success Fees"
                    className="w-4 h-4 mb-1 mr-1 text-gray-500 shrink-0"
                  />
                  <ReactTooltip
                    className="max-w-sm"
                    // eslint-disable-next-line max-params
                    overridePosition={({ left, top }, _e, _t, node) => ({
                      top,
                      left: typeof node === 'string' ? left : Math.max(left, 0),
                    })}
                  />
                  <p className="text-sm text-gray-500 md:w-full">
                    Accepting success fees will unlock all plans below.
                    We&apos;ll only ever charge you in case of a successful
                    placement.
                  </p>
                </Switch.Label>
              </Switch.Group>

              <div className="mt-4 sm:mt-6 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-6 lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
                {tiers.map((tier, index) => (
                  <div
                    key={tier.name}
                    className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
                  >
                    <div className="p-6">
                      <h2 className="text-lg leading-6 font-medium text-gray-900">
                        {tier.name}
                      </h2>
                      <p className="mt-4 text-sm text-gray-500">
                        {tier.description}
                      </p>
                      <p className="mt-8">
                        <span className="text-4xl font-extrabold text-gray-900">
                          £{tier.priceMonthly}
                        </span>{' '}
                        <span className="text-base font-medium text-gray-500">
                          /mo
                        </span>
                      </p>

                      <Button
                        disabled={!successFeesToggled}
                        className="mt-8 block w-full"
                        onClick={(e) => {
                          e.preventDefault();
                          onClick(tier.priceId, index);
                        }}
                        data-test-id={`onboardingWrapper-plansModal-button-${tier.name}`}
                      >
                        Buy {tier.name}
                      </Button>
                    </div>
                    <div className="pt-6 pb-8 px-6">
                      <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                        What&apos;s included
                      </h3>
                      <ul className="mt-6 space-y-4">
                        {tier.includedFeatures.map((feature) => (
                          <li key={feature} className="flex space-x-3">
                            <CheckIcon
                              className="shrink-0 h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                            <span className="text-sm text-gray-500">
                              {feature}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-gray-500 flex text-sm mt-4 items-center md:justify-center space-x-2">
                <LockClosedIcon className="h-6 w-6 shrink-0" />
                <p>
                  mvpr does not process any card details on its own servers.
                  mvpr uses third party payment processor{' '}
                  <a
                    className="underline hover:no-underline text-teal-500"
                    href="https://stripe.com/gb"
                    target="_blank"
                    alt-text="link to Stripe"
                    rel="noreferrer"
                  >
                    Stripe
                  </a>{' '}
                  to process card payments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

OnboardingWrapperPlansModal.propTypes = {
  open: PropTypes.bool,
};

export default OnboardingWrapperPlansModal;
