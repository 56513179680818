import PropTypes from 'prop-types';
import React from 'react';

import { ArrowRightIcon } from '@heroicons/react/outline';
import { OfficeBuildingIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import getCompanyRoute from 'utils/getCompanyRoute';

const CompaniesListCompany = ({ company = {} }) => (
  <div
    key={company.slug}
    className="bg-white hover:bg-gray-50 border border-gray-200 rounded-md m-2 ml-0"
  >
    <Link
      to={getCompanyRoute(company.slug)}
      className="flex justify-between items-center"
    >
      <div className="relative flex items-center">
        <div className="p-2 mr-2">
          {company.logo ? (
            <UniversalImage
              image={company.logo}
              alt={`Logo company ${company.name}`}
              widthClass="w-12"
              heightClass="h-12"
              objectFitClass="object-contain"
            />
          ) : (
            <OfficeBuildingIcon className="text-gray-300 w-12 h-12 p-2" />
          )}
        </div>
        {company.name}
      </div>

      <div className="mr-2">
        <ArrowRightIcon className="w-4 mr-2" />
      </div>
    </Link>
  </div>
);

CompaniesListCompany.propTypes = {
  company: PropTypes.shape({
    slug: PropTypes.string,
    logo: PropTypes.object,
    name: PropTypes.string,
  }).isRequired,
};

export default CompaniesListCompany;
