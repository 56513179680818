import React, { useState } from 'react';

import { LightningBoltIcon } from '@heroicons/react/solid';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import Button from 'components/buttons/Button';
import defaultOptions from 'constants/defaultOptions';
import {
  COMPANIES_COLLECTION,
  CUSTOM_OPPORTUNITIES_COLLECTION,
} from 'constants/firebasePaths';
import getBgColorClassName from 'utils/color/getBgColorClassName';

import AdminWrapper from '../AdminWrapper';
import AddOpportunityModal from './AdminAddOpportunityModal';

const AdminCreateOpportunity = () => {
  const firestore = useFirestore();
  const opportunitiesCollection = firestore.collection(
    CUSTOM_OPPORTUNITIES_COLLECTION
  );
  const opportunities = useFirestoreCollectionData(
    opportunitiesCollection,
    defaultOptions
  );

  const companiesCollection = firestore.collection(COMPANIES_COLLECTION);
  const companies = useFirestoreCollectionData(
    companiesCollection,
    defaultOptions
  );

  const [showAddOpportunityModal, setShowAddOpportunityModal] = useState(false);

  const onSubmit = async (data) => {
    // create date in UTC time
    const dateUtc = new Date(data.deadlineRaw).getTime();

    // Add offset (London/UK)
    const offset = 3600000 * 1;
    const deadlineUTC = new Date(dateUtc + offset);

    const opportunity = {
      deadlineRaw: data.deadlineRaw,
      deadlineUTC,
      domainAuthority: data.domainAuthority,
      journalist: data.journalist,
      mediaOutlet: data.mediaOutlet,
      mediaOutletWebsite: data.mediaOutletWebsite,
      enquirySummary: data.enquirySummary,
      query: data.query.split('\n'),
      companies: data.companies.map((company) => company.uid),
      topics: data.topics,
      custom: true,
    };

    setShowAddOpportunityModal(false);

    try {
      await opportunitiesCollection.add(opportunity);
    } catch (err) {
      // TODO-LOG: send to logger
    }
  };

  return (
    <AdminWrapper currentIndex={4} title="Custom opportunities">
      <p className="mt-2">
        Below you can find a list of <strong>custom opportunities</strong>
      </p>

      <div className="flex flex-col mt-8">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Enquiry summary / Query
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Topics
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Media outlet
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <LightningBoltIcon className="w-4" />
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Journalist
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Deadline
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Companies
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {opportunities.data.map((opportunity) => {
                    const associatedCompanies = companies.data.filter(
                      (company) => opportunity.companies.includes(company.uid)
                    );

                    return (
                      <tr key={opportunity.uid}>
                        <td className="p-4">
                          <p className="text-sm text-gray-900 font-medium">
                            {opportunity.enquirySummary}
                          </p>
                          <p
                            className="text-sm text-gray-900 w-48 mt-2"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: opportunity.query
                                ?.join('<br />')
                                .substr(0, 255),
                            }}
                          />
                        </td>

                        <td className="p-4">
                          <p className="flex flex-col items-start">
                            {opportunity.topics?.map((topic) => {
                              const colorClass = getBgColorClassName(
                                topic.name.length
                              );
                              return (
                                <span
                                  key={topic.id}
                                  className={`inline-block mt-2 mr-2 px-2 py-1 text-xs rounded-md bg-gray-100 hover:opacity-75 focus:opacity-75 whitespace-nowrap ${colorClass}`}
                                >
                                  {topic.name}
                                </span>
                              );
                            })}
                          </p>
                        </td>

                        <td className="p-4">
                          <p className="text-sm text-gray-900">
                            <a
                              className="underline"
                              rel="noreferrer"
                              href={opportunity.mediaOutletWebsite}
                              target="_blank"
                            >
                              {opportunity.mediaOutlet}
                            </a>
                          </p>
                        </td>

                        <td className="p-4">
                          <p className="text-sm text-gray-900">
                            {opportunity.domainAuthority}
                          </p>
                        </td>
                        <td className="p-4">
                          <p className="text-sm text-gray-90">
                            {opportunity.journalist}
                          </p>
                        </td>
                        <td className="p-4">
                          <p className="text-sm text-gray-900">
                            {opportunity.deadlineRaw}
                          </p>
                        </td>
                        <td className="p-4">
                          <div className="flex flex-col items-start justify-center space-y-2">
                            {associatedCompanies.map((company) => {
                              const colorClass = getBgColorClassName(
                                company.name.length
                              );
                              return (
                                <span
                                  key={company.name}
                                  className={`bg-gray-100 px-2 py-1 rounded-md mr-2 whitespace-nowrap text-xs ${colorClass}`}
                                >
                                  {company.name}
                                </span>
                              );
                            })}
                          </div>
                        </td>
                        <td>
                          <Button
                            type="secondary"
                            onClick={() =>
                              opportunitiesCollection
                                .doc(opportunity.uid)
                                .delete()
                            }
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="mt-4 flex justify-end">
          <Button size="large" onClick={() => setShowAddOpportunityModal(true)}>
            Add opportunity
          </Button>
        </div>
      </div>

      <AddOpportunityModal
        open={showAddOpportunityModal}
        onClose={() => setShowAddOpportunityModal(false)}
        onSubmit={onSubmit}
        companies={companies.data}
      />
    </AdminWrapper>
  );
};

export default AdminCreateOpportunity;
