const today = Date.now();
const dayInMs = 24 * 60 * 60 * 1000;

/**
 * Generate an array of days from the current date backward,
 * using the unix timestamp and subtracting the "amount of ms in a day times i" for each iteration
 */
const generateDays = (numberOfDays = 1) => {
  const days = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= numberOfDays; i++) {
    days.push(today - i * dayInMs);
  }
  return days;
};

export default generateDays;
