import PropTypes from 'prop-types';
import React from 'react';

import { ClockIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import getDeadline from './helpers/getDeadline';
import getTimeLeft from './helpers/getTimeLeft';

// Displays the time left for an opportunity, can be expired as well (red background in this case) and without the clock icon
const OpportunityTimeLeft = ({
  deadlineUTC = {},
  expired = false,
  showClockIcon = false,
}) => {
  const [diff, units] = getDeadline(deadlineUTC);
  const timeLeft = getTimeLeft({ diff, units, dateUtc: deadlineUTC, expired });

  return (
    <div className="flex flex-col items-end lg:items-start mt-px w-full max-w-prose lg:w-40">
      <div
        className={classNames(
          'flex items-center text-xs py-1 px-2 lg:mt-2 lg:pr-3 space-x-2 text-gray-400  bg-opacity-40 mr-4 rounded-t-md lg:mr-0 lg:rounded-l-none lg:rounded-r-md truncate whitespace-nowrap font-semibold',
          { 'bg-red-200': expired, 'bg-gray-200': !expired }
        )}
      >
        {showClockIcon && <ClockIcon className="w-4" />}
        <p>
          <span className="text-gray-400">{timeLeft}</span>{' '}
        </p>
      </div>
    </div>
  );
};

OpportunityTimeLeft.propTypes = {
  deadlineUTC: PropTypes.object.isRequired,
  expired: PropTypes.bool,
  showClockIcon: PropTypes.bool,
};

export default OpportunityTimeLeft;
