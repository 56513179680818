import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import { useAnalytics } from 'reactfire';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserFlow from 'hooks/useUserFlow';

import useCheckPayment from './hooks/useCheckPayment';

// The success message presented after a successful payment
const OnboardingWrapperPaymentStatusSuccess = ({ open = false }) => {
  useCheckPayment();
  useUserFlow();

  const { companyData } = useCompanyData();
  const analytics = useAnalytics();

  useEffect(() => {
    if (companyData?.slug && open) {
      analytics.logEvent('payment_update', {
        action: 'payment_succeeded',
        slug: companyData?.slug,
      });
    }
  }, [analytics, companyData?.slug, open]);

  return null;
};

OnboardingWrapperPaymentStatusSuccess.propTypes = {
  history: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withRouter(OnboardingWrapperPaymentStatusSuccess);
