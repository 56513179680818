import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import InputFieldFoundersEmptyState from './InputFieldFoundersEmptyState';
import InputFieldFoundersFounder from './InputFieldFoundersFounder';

// Input field that displays the available founders, or an empty state if not
const InputFieldFounders = ({
  founders = [],
  value = {},
  onChange = noop,
  testId = '',
}) =>
  founders.length ? (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {founders.map((founder) => (
        <InputFieldFoundersFounder
          key={founder.uid}
          founder={founders.find((f) => f.name === founder.name)}
          isSelected={founder.name === value?.name}
          onChange={onChange}
          testId={testId}
        />
      ))}
    </div>
  ) : (
    <InputFieldFoundersEmptyState />
  );

InputFieldFounders.propTypes = {
  founders: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.object,
      position: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.object,
    position: PropTypes.string,
  }),
  testId: PropTypes.string,
};

export default InputFieldFounders;
