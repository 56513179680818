import PropTypes from 'prop-types';
import React from 'react';

import { ExternalLinkIcon, OfficeBuildingIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import getCompanyRoute from 'utils/getCompanyRoute';

const AdminSweetheartListCompanyLink = ({ company }) => {
  const companyRoute = getCompanyRoute(company.slug);

  return (
    <Link to={companyRoute} className="w-full relative block">
      <div className="flex items-center hover:bg-gray-100 rounded-md peer pr-6">
        <div className="flex-shrink-0 h-10 w-10 rounded-full overflow-hidden">
          {company.logo ? (
            <UniversalImage
              image={company.logo}
              alt={`Logo company ${company.name}`}
              widthClass="w-10"
              heightClass="h-10"
              objectFitClass="object-contain"
            />
          ) : (
            <OfficeBuildingIcon className="text-gray-300 w-10 h-10" />
          )}
        </div>
        <span className="ml-4 w-fit break-words text-sm font-medium text-gray-900">
          {company.name}
        </span>
      </div>
      <ExternalLinkIcon className="w-4 h-4 hidden absolute top-1/2 pointer-events-none -translate-y-1/2  right-2 peer-hover:block" />
    </Link>
  );
};

AdminSweetheartListCompanyLink.propTypes = {
  company: PropTypes.object.isRequired,
};

export default AdminSweetheartListCompanyLink;
