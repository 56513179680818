import PropTypes from 'prop-types';
import React from 'react';

import { ExclamationCircleIcon, TableIcon } from '@heroicons/react/outline';

import EmptyState from 'components/EmptyState';
import useUserData from 'contexts/UserContext/hooks/useUserData';

const SweetheartListPageJournalistsError = ({ companyName = '' }) => {
  const user = useUserData();

  return (
    <EmptyState
      helmetTitle="Sweetheart List Error"
      testId="sweetheartList-ListError"
      title="There's been an error fetching the Sweetheart list."
      subtitle="Please get in touch with tom@mvpr.io to resolve this issue."
      Icon={TableIcon}
      IconSecondary={ExclamationCircleIcon}
      instructions={
        user.claimsAdmin && (
          <div className="pt-4">
            <h3 className="text-md font-semibold mx-8 text-center">
              Troubleshooting:
            </h3>
            <ul className="list-disc pl-4 mx-8 sm:mx-24">
              <li>Check if there is a list for the company on Airtable.</li>
              <li>
                Check that the company name matches the slug:{' '}
                <code className="underline">{companyName}</code> on Airtable.
              </li>
            </ul>
          </div>
        )
      }
    />
  );
};

SweetheartListPageJournalistsError.propTypes = {
  companyName: PropTypes.string,
};

export default SweetheartListPageJournalistsError;
