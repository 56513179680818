import { defaultUser, defaultCompany } from 'constants/defaultData';

export const updateUser = (state, payload) => ({
  ...state,
  user: { ...state.user, ...payload },
});

export const updateCompany = (state, payload) => ({
  ...state,
  company: { ...state.company, ...payload },
});

// reset company data
// reset user data except email because that can be used for auto-filling email field on signup
export const resetCompanyAndUser = (state) => ({
  ...state,
  user: defaultUser,
  company: defaultCompany,
});
