import { useEffect } from 'react';

import { useUser } from 'reactfire';
import userflow from 'userflow.js';

import useEnvironment from 'contexts/EnvironmentContext/hooks/useEnvironment';

const USERFLOW_API_KEY_TESTING = 'ct_rvjc22npojce3i4nm5hd3hld2y';
const USERFLOW_API_KEY_PRODUCTION = 'ct_mmqi6riovbebhc5oulk462247a';
const USERFLOW_FLOW_ID = '1c55fa6f-fb2b-49c4-a9c1-ac032ab464d9';

const useUserFlow = () => {
  const { isProduction } = useEnvironment();
  const apiKey = isProduction
    ? USERFLOW_API_KEY_PRODUCTION
    : USERFLOW_API_KEY_TESTING;
  const { email, uid, metadata } = useUser().data;

  useEffect(() => {
    // don't run user flow on mobile devices (yet)
    if (window.innerWidth >= 640) {
      userflow.init(apiKey);
      userflow.identify(uid, {
        email,
        signed_up_at: new Date(metadata.creationTime).toISOString(),
      });
      userflow.start(USERFLOW_FLOW_ID);
    }
  }, [apiKey, email, metadata.creationTime, uid]);
};

export default useUserFlow;
