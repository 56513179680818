import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import useUserData from 'contexts/UserContext/hooks/useUserData';
import useCompanyRefBySlug from 'hooks/useCompanyRefBySlug';

import SweetheartList from './SweetheartList';
import SweetheartListPageNoCompanyFound from './SweetheartListViewStates/SweetheartListPageNoCompanyFound';
import SweetheartListPageNotAuthorized from './SweetheartListViewStates/SweetheartListPageNotAuthorized';

const SweetheartListPageAdmin = ({ slug = '' }) => {
  const user = useUserData();
  const [companyData, setCompanyData] = useState({
    uid: null,
    name: null,
  });

  const companyRef = useCompanyRefBySlug(slug);

  useEffect(
    () =>
      (async () => {
        if (!companyRef) {
          return false;
        }
        const result = await companyRef.get();
        const uid = result.id;
        const data = result.data();
        const { name } = data;
        if (!companyData.uid && name) {
          return setCompanyData({ uid, name });
        }
        return false;
      })(),
    [companyRef, companyData.uid, user.claimsAdmin]
  );

  if (!user.claimsAdmin || !companyData.uid) {
    return (
      <div className="mt-16 sm:ml-16">
        {!companyData.uid && <SweetheartListPageNoCompanyFound slug={slug} />}

        {!user.claimsAdmin && <SweetheartListPageNotAuthorized />}
      </div>
    );
  }

  return <SweetheartList company={companyData} />;
};

SweetheartListPageAdmin.propTypes = {
  slug: PropTypes.string,
};
export default SweetheartListPageAdmin;
