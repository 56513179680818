import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import { tagsTopics } from 'constants/tags';
import getTagsClassNames from 'utils/color/getTagsClassNames';

const AdminCompaniesCompanyTopics = ({ topics = [] }) => {
  const length = (topic) => topic.name?.length;
  const index = (topic) => tagsTopics.findIndex((t) => topic.name === t.name);
  const isUncategorised = (topic) => topic.id === 1199;

  return topics.map((topic) => (
    <span
      key={topic.name}
      className={classNames(
        'float-left mr-2 mb-2 p-2 py-1 text-xs rounded-md whitespace-nowrap',
        !isUncategorised(topic) &&
          getTagsClassNames(index(topic), length(topic)),
        {
          'bg-black text-white': isUncategorised(topic),
        }
      )}
    >
      {topic.name}
    </span>
  ));
};

AdminCompaniesCompanyTopics.propTypes = {
  topics: PropTypes.array,
};

export default AdminCompaniesCompanyTopics;
