import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useForm } from 'react-hook-form';

import useMapTopicsToCoreTopics from 'hooks/useMapTopicsToCoreTopics';

import AdminCompaniesCompany from './AdminCompaniesCompany';
import AdminCompaniesCompanySearchField from './AdminCompaniesCompanySearchField';
import AdminCompaniesTierTabs from './AdminCompaniesTierTabs';
import AdminCompaniesTopicFilter from './AdminCompaniesTopicFilter';

const AdminCompanies = ({ companies = [] }) => {
  const { control, watch } = useForm({ mode: 'onChange' });
  const mapTopicsToCoreTopics = useMapTopicsToCoreTopics();

  // tabs, filter, company Input
  const [activeTier, setActiveTier] = useState('All');

  const activeCompanySearchInput = watch('activeCompanySearchInput', '');
  const topics = watch('topics', []);

  // Map company topics to core topics
  const companiesMapped = companies.map((company) => ({
    ...company,
    topics: mapTopicsToCoreTopics(company.topics),
  }));

  return (
    <div className="flex flex-row flex-wrap mt-4">
      <AdminCompaniesTierTabs
        activeTier={activeTier}
        setActiveTier={setActiveTier}
      />

      <AdminCompaniesCompanySearchField control={control} />

      <AdminCompaniesTopicFilter control={control} />

      <div className="w-full">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 text-gray-500">
            <thead className="bg-gray-50 text-xs font-medium uppercase">
              <tr className="text-left tracking-wider">
                <th scope="col" className="px-4 py-2">
                  Company
                </th>
                <th scope="col" className="px-4 py-2 max-w-sm">
                  Topics
                </th>
                <th scope="col" className="px-4 py-2">
                  Subscription Status
                </th>
                <th scope="col" className="px-4 py-2">
                  VC Info
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 text-sm">
              {companiesMapped.map((company) => (
                <AdminCompaniesCompany
                  activeTier={activeTier}
                  activeCompanySearchInput={activeCompanySearchInput}
                  topics={topics}
                  key={company.slug}
                  company={company}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

AdminCompanies.propTypes = {
  companies: PropTypes.array,
};

export default AdminCompanies;
