import PropTypes from 'prop-types';
import React from 'react';

import DomainAuthorityFromPublication from './DomainAuthorityFromPublication';
import DomainAuthorityPill from './DomainAuthorityPill';

const DomainAuthority = ({ publicationName = '', domainAuthority = null }) => {
  if (domainAuthority) {
    return <DomainAuthorityPill domainAuthority={domainAuthority} />;
  }
  return (
    <DomainAuthorityFromPublication publicationName={publicationName}>
      {(domainAuthorityFromPublication) => (
        <DomainAuthorityPill domainAuthority={domainAuthorityFromPublication} />
      )}
    </DomainAuthorityFromPublication>
  );
};

DomainAuthority.propTypes = {
  publicationName: PropTypes.string,
  domainAuthority: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default DomainAuthority;
