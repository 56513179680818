import PropTypes from 'prop-types';
import React from 'react';

import { Menu } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

import DotsMenuOption from './DotsMenuOption';
import dotsMenuThemes from './config/dotsMenuThemes';

// 3-dots button that reveals a dropdown menu with configurable options and theme
const DotsMenu = ({ options = [], theme = 'light', testId = '' }) => {
  // if theme doesn't exist, don't render
  if (!Object.keys(dotsMenuThemes).includes(theme)) {
    return null;
  }

  const { dotsClassName, focusClassName } = dotsMenuThemes[theme];

  if (!Array.isArray(options) || !options.length) {
    return null;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className={classNames(
          'flex items-center focus:outline-none focus:bg-opacity-20 p-2 py-0.5 rounded-full',
          focusClassName,
          dotsClassName
        )}
        data-test-id={testId}
      >
        <span className="sr-only">Open options</span>
        <DotsHorizontalIcon className="w-5" />
      </Menu.Button>

      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
        <div className="py-1">
          {options.map((option) => (
            <DotsMenuOption key={option.label} option={option} />
          ))}
        </div>
      </Menu.Items>
    </Menu>
  );
};

DotsMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      icon: PropTypes.node,
      testId: PropTypes.string,
    })
  ),
  theme: PropTypes.oneOf(['light', 'medium', 'dark']),
  testId: PropTypes.string,
};

export default DotsMenu;
