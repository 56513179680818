import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  ClipboardCheckIcon,
  ClipboardIcon,
  ShareIcon,
} from '@heroicons/react/outline';
import { useAnalytics } from 'reactfire';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import copyToClipboard from 'utils/copyToClipboard';

const CompanyMetaShareModal = ({ slug, onClose }) => {
  const analytics = useAnalytics();

  const url = `https://app.mvpr.io/company/${slug}`;

  const [copied, setCopied] = useState(false);
  const Icon = copied ? ClipboardCheckIcon : ClipboardIcon;

  const onClick = () => {
    setCopied(true);
    copyToClipboard(url);
    analytics.logEvent('pressPage_view', {
      action: 'company_url_copyToClipboard',
      url,
    });
  };

  return (
    <Modal onClose={onClose}>
      <>
        <div className="px-6 py-6 pb-0">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
            <ShareIcon className="w-6" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="dialog-1-title"
            >
              Share press page
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Use the following URL to share this press page:
              </p>
              <div className="mt-5">
                <InputFieldText
                  name="pressPageUrl"
                  disabled
                  defaultValue={url}
                  Suffix={
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center space-x-1 px-2 pr-3 py-2 border border-gray-200 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                      onClick={onClick}
                    >
                      <Icon className="w-4" />
                      <span>Copy to clipboard</span>
                    </button>
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
          <div className="ml-3">
            <Button onClick={onClose}>I&apos;m done</Button>
          </div>
        </div>
      </>
    </Modal>
  );
};

CompanyMetaShareModal.propTypes = {
  slug: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompanyMetaShareModal;
