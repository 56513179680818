import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import StepDivider from './StepDivider';

const StepCurrent = ({ number, name, title }) => (
  <li className="flex align-center items-center">
    <Link to={name} className="text-sm font-medium text-teal-500">
      {title}
    </Link>

    <StepDivider number={number} />
  </li>
);

StepCurrent.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
};

export default StepCurrent;
