import PropTypes from 'prop-types';
import React from 'react';

const AdminCompaniesCompanyTier = ({ tier = '' }) => (
  <td className="px-4 py-2">{tier}</td>
);

AdminCompaniesCompanyTier.propTypes = {
  tier: PropTypes.string,
};

export default AdminCompaniesCompanyTier;
