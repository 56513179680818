import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Dialog } from '@headlessui/react';
import { ClockIcon } from '@heroicons/react/solid';
import capitalize from 'lodash/capitalize';

import DomainAuthority from 'components/DomainAuthority/DomainAuthority';
import CloseButton from 'components/buttons/CloseButton';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';
import OpportunityMeta from 'components/opportunities/opportunity/OpportunityMeta';
import OpportunityQuery from 'components/opportunities/opportunity/OpportunityQuery';
import OpportunityTopics from 'components/opportunities/opportunity/OpportunityTopics';
import getDeadline from 'components/opportunities/opportunity/helpers/getDeadline';
import getTimeLeft from 'components/opportunities/opportunity/helpers/getTimeLeft';

import OpportunityResponseModalActions from './OpportunityResponseModalActions';
import OpportunityResponseModalForm from './OpportunityResponseModalForm';
import OpportunityResponseModalResponse from './OpportunityResponseModalResponse';
import OpportunityResponseModalStepper from './OpportunityResponseModalStepper';
import OpportunityResponseModalTrackVisit from './OpportunityResponseModalTrackVisit';

// eslint-disable-next-line no-unused-vars
const OpportunityResponseModal = ({
  open,
  onClose,
  onSubmit,
  opportunity,
  opportunitiesOps,
  selectedTopics,
  currentField,
  currentCount,
  totalCount,
  onPrevious,
  onNext,
}) => {
  const [showResponseForm, setShowResponseForm] = useState(false);

  if (!opportunity) {
    return null;
  }

  const {
    custom,
    deadlineUTC,
    domainAuthority,
    enquirySummary,
    journalist,
    mediaOutlet,
    mediaOutletWebsite,
    topics,
    query,
    response,
  } = opportunity;

  const expired = deadlineUTC.toDate() < new Date();

  const [diff, units] = getDeadline(deadlineUTC);
  const timeLeft = getTimeLeft({
    diff,
    units,
    dateUtc: deadlineUTC,
    expired,
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        setShowResponseForm(false);
        onClose();
      }}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      data-test-id="opportunity-responseModal"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <div className="flex items-center justify-center min-h-screen">
        <div className="relative bg-white rounded-lg shadow-xl m-4 sm:m-6 max-w-xl lg:max-w-2xl xl:max-w-3xl">
          <div className="absolute top-5 right-4 z-10">
            <CloseButton
              onClick={onClose}
              testId="opportunity-responseModal-close"
            />
          </div>
          <OpportunityResponseModalStepper
            currentCount={currentCount}
            totalCount={totalCount}
            onPrevious={onPrevious}
            onNext={onNext}
          />
          <div className="p-6 pt-4 pb-6">
            <div className="pr-2">
              <OpportunityTopics
                custom={custom}
                topics={topics}
                selectedTopics={selectedTopics}
              />
            </div>
            <div className="flex justify-between mt-2.5">
              <h3 className="text-2xl font-semibold">
                {capitalize(enquirySummary)}
              </h3>
            </div>

            <div className="mt-4 mb-4 flex flex-col sm:flex-row items-start sm:items-center sm:space-x-6 space-y-3 sm:space-y-0">
              <div className="flex items-center space-x-3">
                <PublicationImage name={mediaOutlet} custom={custom} />
                <a
                  href={mediaOutletWebsite}
                  className="hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  <OpportunityMeta text={mediaOutlet} />
                </a>
                <DomainAuthority domainAuthority={domainAuthority} />
              </div>
              <div className="flex items-center justify-start space-x-3">
                <JournalistImage name={journalist} />
                <OpportunityMeta text={journalist} />
              </div>
            </div>

            <OpportunityQuery query={query} />

            <p
              className={`mt-4 inline-flex items-center text-xs py-1 px-2 pr-3 space-x-2 text-gray-400 bg-opacity-40 font-semibold rounded-md ${
                expired ? 'bg-red-200' : 'bg-gray-200'
              }`}
            >
              <ClockIcon className="w-4" />
              <span>{timeLeft}</span>
            </p>

            <OpportunityResponseModalResponse response={response} />
          </div>

          {!expired && (
            <OpportunityResponseModalActions
              currentField={currentField}
              opportunity={opportunity}
              opportunitiesOps={opportunitiesOps}
              onClose={onClose}
              showResponseForm={showResponseForm}
              setShowResponseForm={setShowResponseForm}
            />
          )}
          {showResponseForm && (
            <OpportunityResponseModalForm
              setShowResponseForm={setShowResponseForm}
              onSubmit={onSubmit}
              journalist={opportunity.journalist}
            />
          )}
        </div>
      </div>
      <OpportunityResponseModalTrackVisit uid={opportunity.uid} />
    </Dialog>
  );
};

OpportunityResponseModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opportunity: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    mediaOutlet: PropTypes.string.isRequired,
    mediaOutletWebsite: PropTypes.string,
    journalist: PropTypes.string.isRequired,
    deadlineUTC: PropTypes.object.isRequired,
    enquirySummary: PropTypes.string,
    query: PropTypes.array.isRequired,
    topics: PropTypes.array,
    custom: PropTypes.bool,
    domainAuthority: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    publicationImage: PropTypes.object,
    journalistImage: PropTypes.object,
    response: PropTypes.string,
  }),
  opportunitiesOps: PropTypes.object.isRequired,
  currentField: PropTypes.string.isRequired,
  selectedTopics: PropTypes.array,
  currentCount: PropTypes.number,
  totalCount: PropTypes.number,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default OpportunityResponseModal;
