import React from 'react';

import useCompanies from 'hooks/useCompanies';

import AdminWrapper from '../AdminWrapper';
import AdminCompanies from './AdminCompanies';

const AdminCompaniesPage = () => {
  const companies = useCompanies();

  return (
    <AdminWrapper title="Companies" currentIndex={1}>
      <AdminCompanies companies={companies} />
    </AdminWrapper>
  );
};

export default AdminCompaniesPage;
