import { ADMIN_MAP_TOPICS_PATH as path } from 'constants/paths';

import AdminMapTopicsPage from './AdminMapTopicsPage';

const AdminMapTopicsRoute = {
  path,
  authRequired: true,
  claimsRequired: { admin: true },
  component: AdminMapTopicsPage,
};

export default AdminMapTopicsRoute;
