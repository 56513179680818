import PropTypes from 'prop-types';
import React from 'react';

// Note this should be refactored into a core Step Component

const StepDivider = ({ number }) => {
  if (number !== 4) {
    return (
      <svg
        className="shrink-0 w-6 h-full text-gray-200"
        viewBox="0 0 24 44"
        preserveAspectRatio="none"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
      </svg>
    );
  }
  return null;
};

StepDivider.propTypes = {
  number: PropTypes.number.isRequired,
};

export default StepDivider;
