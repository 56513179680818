import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import { tagsTopics } from 'constants/tags';
import getTagsClassNames from 'utils/color/getTagsClassNames';

const SweetheartListJournalistTopics = ({ interests = [] }) => {
  const interestsUnique = [...new Set(interests)];
  const interestsAsTopicTagsArray = interestsUnique.map((interest) => ({
    name: interest,
  }));

  const length = (topic) => topic.name?.length;
  const index = (topic) => tagsTopics.findIndex((t) => topic.name === t.name);
  const isUncategorised = (topic) => topic.id === 1199;

  return (
    <div className="flex items-center flex-wrap w-full">
      {interestsAsTopicTagsArray.map((topic) => (
        <span
          key={topic.name}
          className={classNames(
            'float-left mr-2 my-1 p-2 py-1 text-xs rounded-md whitespace-nowrap',
            !isUncategorised(topic) &&
              getTagsClassNames(index(topic), length(topic)),
            {
              'bg-black text-white': isUncategorised(topic),
            }
          )}
        >
          {topic.name}
        </span>
      ))}
    </div>
  );
};

SweetheartListJournalistTopics.propTypes = {
  interests: PropTypes.array,
};

export default SweetheartListJournalistTopics;

// TODO: Refactor as TopicTags in components because it's the same as AdminCompanniesCompanyTopics
