const getDateForRow = (dateStr) => [
  parseInt(dateStr.slice(0, 4), 10),
  parseInt(dateStr.slice(4, 6), 10),
  parseInt(dateStr.slice(6, 8), 10),
];

const getDateForDay = (date) => [
  date.getUTCFullYear(),
  date.getUTCMonth() + 1,
  date.getUTCDate(),
];

/**
 * Generates page data (views and average view times) for a given array of report rows and days
 * The days determine the length of the report (e.g. 7 items result in a 7 day report) and the report rows
 * get matched against the date in the days array to return the right data point for each date
 */
const generatePageData = (reportRows, daysArray) => {
  const pageViewsData = [];
  const pageTimesData = [];

  daysArray.forEach((day) => {
    const [dayYear, dayMonth, dayDate] = getDateForDay(new Date(day));

    let pageViewValue = 0;
    let pageTimeValue = 0;

    if (reportRows) {
      reportRows.forEach((row) => {
        const { value } = row.dimensionValues[1];
        const [year, month, date] = getDateForRow(value);

        const metrics = row.metricValues;

        if (year === dayYear && month === dayMonth && date === dayDate) {
          // metrics[0] contains the number of page views
          if (metrics[0].value) {
            pageViewValue = metrics[0].value;
          }

          if (metrics[2].value && metrics[1].value) {
            // metrics[2] contains the total duration, metrics[1] the amount of users on that date
            pageTimeValue = metrics[2].value / metrics[1].value;
          }
        }
      });
    }
    pageViewsData.push(pageViewValue);
    pageTimesData.push(pageTimeValue);
  });

  return {
    pageViewsData,
    pageTimesData,
  };
};

export default generatePageData;
