import PropTypes from 'prop-types';
import React from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { LOGIN_PATH } from 'constants/paths';
import { REGEX_EMAIL } from 'constants/regex';

const ResetPasswordForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    nativeValidation: false,
  });

  return (
    <div className="col-span-12 mt-16 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Label htmlFor="email">Email address</Label>
            <InputFieldText
              ref={register({
                pattern: {
                  value: REGEX_EMAIL,
                  message: 'Please provide a valid email address',
                },
              })}
              name="email"
              type="email"
              autoComplete="email"
              required
            />
          </div>

          <div>
            <Button
              size="large"
              submit
              full
              disabled={isSubmitting || !isValid}
              eventType="reset_password"
            >
              {isSubmitting ? 'Loading' : 'Reset password'}
            </Button>
          </div>
        </form>
      </div>
      <div className="py-4 flex justify-center">
        <Link
          to={LOGIN_PATH}
          className="font-medium text-teal-500 hover:text-teal-500"
          data-test-id="forgotPassword-signUpLink"
        >
          Or sign in to an existing account
        </Link>
      </div>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
