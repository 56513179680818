import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import noop from 'lodash/lodash';

import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';

import OpportunitiesFilter from './OpportunitiesFilter/OpportunitiesFilter';
import OpportunitiesSort from './OpportunitiesSort/OpportunitiesSort';

// Opportunities filter/sort modal: allows filtering and sorting opportunities
const OpportunitiesFilterSortModal = ({
  open = false,
  onClose = noop,
  filterOptions = [],
  filter = [],
  setFilter = noop,
  sortOptions = [],
  sort = {},
  setSort = noop,
  sortDir = 1,
  setSortDir = noop,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    as="div"
    className="fixed inset-0 z-50 overflow-y-auto"
    data-test-id=""
  >
    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

    <div className="flex items-center justify-center min-h-screen">
      <div className="relative bg-white rounded-lg shadow-xl m-6">
        <div className="absolute top-4 right-4 z-10">
          <CloseButton onClick={onClose} />
        </div>

        <div className="p-6">
          <h2 className="mt-4 text-sm text-gray-700 font-semibold">
            Filter by
          </h2>

          <div className="mt-2">
            <OpportunitiesFilter
              filterOptions={filterOptions}
              filter={filter}
              setFilter={setFilter}
              wrapperClassName="block"
              buttonWidthClassName="w-full"
            />
          </div>

          <h2 className="mt-4 text-sm text-gray-700 font-semibold">Sort by</h2>

          <div className="mt-2">
            <OpportunitiesSort
              sortOptions={sortOptions}
              sort={sort}
              setSort={setSort}
              sortDir={sortDir}
              setSortDir={setSortDir}
              wrapperClassName="flex"
              widthClassName="w-56"
            />
          </div>
        </div>
        <div className="flex w-full justify-end bg-gray-50 px-6 py-6 rounded-b-md">
          <Button onClick={() => onClose()} data-test-id="">
            Done
          </Button>
        </div>
      </div>
    </div>
  </Dialog>
);

OpportunitiesFilterSortModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  filterOptions: PropTypes.array.isRequired,
  filter: PropTypes.array.isRequired,
  setFilter: PropTypes.func.isRequired,
  sortOptions: PropTypes.array.isRequired,
  sort: PropTypes.object,
  sortDir: PropTypes.number.isRequired,
  setSort: PropTypes.func.isRequired,
  setSortDir: PropTypes.func.isRequired,
};

export default OpportunitiesFilterSortModal;
