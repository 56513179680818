import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useLocation } from 'react-router-dom';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import AccountLink from './AccountLink';
import AccountMenu from './AccountMenu';
import Aside from './Aside/Aside';
import HomeLogo from './HomeLogo';
import MobileNav from './MobileNav/MobileNav';
import useRouteMatchNav from './hooks/useRouteMatchNav';

/**
 * Renders the top navbar with the following components:
 * - logo on the left
 * - account menu or account link on the right, hamburger icon on mobile
 * - mobile menu as an overlay on mobile
 * - aside with nav options (press page, opportunities,...) for logged-in users on the left
 */
const Navbar = ({ children = null }) => {
  const current = useLocation();
  const [homeRoute, setHomeRoute] = useState('/');

  const { isAnonymous, uid, claimsAdmin } = useUserData();
  const { companyData } = useCompanyData();
  const slug = companyData?.slug;

  // nav-specific logic
  const [isOnboardingRoute, showTopNav] = useRouteMatchNav(slug, setHomeRoute);

  // if we're on an onboarding route, don't render any navigation
  if (isOnboardingRoute) {
    return null;
  }

  // only show logged in user if NOT anonymous and has UID
  const authExists = !isAnonymous && uid;

  return (
    <nav className="fixed top-0 w-full z-20 bg-teal-500" data-test-id="navbar">
      <div className="relative flex justify-between px-2 sm:px-4 h-16">
        <HomeLogo
          homeRoute={homeRoute}
          testId="homeLogo"
          className="ml-2 sm:ml-0"
          white
        />
        {children}
        {authExists ? (
          <div className="flex items-center sm:ml-6">
            <AccountMenu claimsAdmin={claimsAdmin} />
            {showTopNav && <MobileNav current={current.pathname} slug={slug} />}
          </div>
        ) : (
          <AccountLink />
        )}
        {showTopNav && <Aside current={current.pathname} slug={slug} />}
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  children: PropTypes.node,
};

export default Navbar;
