import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DotsHorizontalIcon } from '@heroicons/react/outline';
import { OfficeBuildingIcon, XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import UpdateLogoModal from 'components/company/UpdateLogoModal';
import CompanyHeaderImage from 'static/company-header.jpg';

import CompanyHeaderDeleteModal from './CompanyHeaderDeleteModal';
import CompanyHeaderModal from './CompanyHeaderModal';

const CompanyHeader = ({
  companyRef,
  companyData,
  showOwnerView,
  isPreview,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditLogoModalOpen, setIsEditLogoModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const isOwnerNotPreview = companyData.isOwner && !isPreview;

  const onUpdate = (obj) => {
    companyRef.update({
      headerImage: obj.image,
    });
    setIsEditModalOpen(false);
  };

  const onDelete = () => {
    companyRef.update({
      headerImage: null,
    });
    setIsDeleteModalOpen(false);
  };

  const updateLogo = async ({ logo }) => {
    companyRef.update({ logo }, { merge: true });
    setIsEditLogoModalOpen(false);
  };
  return (
    <>
      <div className="relative">
        <div className="relative h-52 overflow-hidden">
          <UniversalImage
            image={companyData.headerImage || { path: CompanyHeaderImage }}
            alt={`Company header of ${companyData.name}`}
            heightClass="h-52"
            objectFitClass="object-cover"
            testId="company-header-image"
          />
          <div
            className="absolute inset-0 bg-gray-500 bg-opacity-50"
            aria-hidden="true"
          />
        </div>

        {companyData.isOwner && (
          <div className="absolute top-4 right-4">
            <Link
              to={(location) => ({
                ...location,
                search: isPreview ? '' : 'preview=true',
                hash: null,
              })}
              className="inline-flex justify-center py-2 px-4 border shadow-sm font-medium text-sm rounded-md whitespace-nowrap text-gray-100 bg-transparent hover:opacity-80 focus:border-gray-100 focus:outline-none focus:ring"
              data-test-id="company-header-preview-toggle"
            >
              {isPreview ? 'Back to edit mode' : 'Preview press page'}
            </Link>
          </div>
        )}

        {isOwnerNotPreview && (
          <div className="absolute bottom-3 right-4">
            <DotsMenu
              options={[
                {
                  label: 'Update header',
                  onClick: () => setIsEditModalOpen(true),
                  testId: 'company-header-menu-update',
                },
              ].concat(
                companyData.headerImage
                  ? {
                      label: 'Delete header',
                      onClick: () => setIsDeleteModalOpen(true),
                      icon: <XIcon className="w-4 h-4" />,
                      testId: 'company-header-menu-delete',
                    }
                  : []
              )}
              testId="company-header-menu"
            />
          </div>
        )}

        <div className="flex flex-none justify-center items-center absolute z-10 shadow rounded bg-white h-28 w-28 -bottom-8 left-6 md:-bottom-14 md:left-8">
          <div
            onClick={() => isOwnerNotPreview && setIsEditLogoModalOpen(true)}
            data-test-id="company-header-logo"
            className={classNames({
              'cursor-pointer w-full h-full border-solid border-2 border-transparent hover:border-teal-500 group rounded flex justify-center items-center':
                isOwnerNotPreview,
            })}
          >
            {companyData.logo ? (
              <UniversalImage
                image={companyData.logo}
                alt={`${companyData.name} logo`}
                heightClass="h-20"
                objectFitClass="object-contain"
                isResponsive
              />
            ) : (
              <OfficeBuildingIcon className="w-12 h-12 object-contain object-center text-gray-300" />
            )}
            {isOwnerNotPreview && (
              <div className="absolute bottom-0 right-0 bg-transparent w-8 flex justify-center rounded-tl rounded-br group-hover:bg-teal-500">
                <span className="sr-only">Edit Logo</span>
                <DotsHorizontalIcon className="text-transparent group-hover:text-gray-100 h-5" />
              </div>
            )}
          </div>
        </div>
      </div>

      {isEditLogoModalOpen && (
        <UpdateLogoModal
          uploadFolder={`companies/${companyData.uid}/logo`}
          onSubmit={updateLogo}
          onClose={() => setIsEditLogoModalOpen(false)}
        />
      )}

      {showOwnerView && (
        <CompanyHeaderModal
          open={isEditModalOpen}
          uploadFolder={`companies/${companyData.uid}/header`}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onUpdate}
        />
      )}

      {isDeleteModalOpen && (
        <CompanyHeaderDeleteModal
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

CompanyHeader.propTypes = {
  companyRef: PropTypes.object.isRequired,
  companyData: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
    logo: PropTypes.object,
    headerImage: PropTypes.object,
    isOwner: PropTypes.bool,
  }).isRequired,
  showOwnerView: PropTypes.bool,
  isPreview: PropTypes.bool.isRequired,
};

export default CompanyHeader;
