import PropTypes from 'prop-types';
import React from 'react';

import { useStateMachine } from 'little-state-machine';
import { Controller, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { useUser } from 'reactfire';

import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import SeparatorTextLine from 'components/form/SeparatorTextLine';
import InputFieldCheckboxesCards from 'components/form/inputFields/InputFieldCheckboxesCards/InputFieldCheckboxesCards';
import InputFieldTextArea from 'components/form/inputFields/InputFieldTextArea';

import TitleSubtitle from '../TitleSubtitle';
import { updateUser } from '../actions';

const optionsPrGoals = [
  {
    emoji: '📰',
    label: 'Get regular press coverage',
  },
  {
    emoji: '🗣',
    label: 'Share news about a current or upcoming announcement',
  },
  {
    emoji: '🛠',
    label: "Build up your company's brand name",
  },
  {
    emoji: '🎙',
    label: 'Grow your own voice with the industry',
  },
  {
    emoji: '🏆',
    label: 'Convince investors of your credibility',
  },
];

const StepOne = ({ history, availableSteps = [] }) => {
  const {
    control,
    handleSubmit,
    errors,
    formState: { isValid },
    getValues,
    register,
  } = useForm({ mode: 'onChange' });

  const { actions, state } = useStateMachine({ updateUser });

  const user = useUser();
  const hasUserData = user?.data;

  const onSubmit = () => history.push('/onboarding/two');

  const onChange = () => {
    actions.updateUser(getValues());
  };

  return (
    <form
      className="relative z-10 flex flex-col items-center mx-4"
      onSubmit={handleSubmit(onSubmit)}
      onChange={onChange}
      data-test-id="onboarding-prGoals-form"
    >
      <div className="flex flex-col items-center max-w-lg mt-12 mb-8 w-full">
        <TitleSubtitle
          title="What would you like to achieve by doing PR?"
          subtitle="Select as many as are relevant to you."
        />

        <Controller
          control={control}
          name="prGoals"
          defaultValue={state.user.prGoals}
          render={({ value, onChange: onChangeCards }) => (
            <>
              <InputFieldCheckboxesCards
                options={optionsPrGoals}
                value={value}
                onChange={(val) => {
                  onChangeCards(val);
                  onChange();
                }}
                testId="onboarding-prGoals"
              />

              <SeparatorTextLine text="OR" classNames="mt-0.5" />

              <InputFieldTextArea
                name="prGoalsOther"
                ref={register}
                placeholder="Let us know what you’d like to achieve using MVPR"
                defaultValue={state.user.prGoalsOther}
                data-test-id="onboarding-prGoals-other"
              />
            </>
          )}
        />

        {errors.prGoals && (
          <ErrorMessage>{errors.prGoals.message}</ErrorMessage>
        )}
      </div>

      <div className="mb-8 flex items-center">
        <Button
          size="large"
          submit
          disabled={!isValid || !hasUserData || !availableSteps.includes('two')}
          data-test-id="onboarding-prGoals-submit"
        >
          Next question
        </Button>
      </div>
    </form>
  );
};

StepOne.propTypes = {
  history: PropTypes.object,
  availableSteps: PropTypes.array,
};

export default withRouter(StepOne);
