import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import { AnnotationIcon } from '@heroicons/react/solid';

import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import CopyToClipboardButton from 'components/buttons/CopyToClipboardButton';
import Founder from 'components/company/Founder';

import CompanyQuoteTags from './CompanyQuote/CompanyQuoteTags';

const CompanyQuoteViewModal = ({
  open,
  quotes = [],
  founders = [],
  currentQuoteId = null,
  setCurrentQuoteId,
  onClose,
}) => {
  const quote = quotes.find((f) => f.uid === currentQuoteId);

  if (!quote) {
    return null;
  }

  const currentIndex = quotes.indexOf(quote);
  const hasNext = currentIndex + 1 < quotes.length;
  const hasPrevious = currentIndex > 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <div className="flex items-center justify-center min-h-screen">
        <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
          <div className="absolute top-4 right-4 z-10">
            <CloseButton onClick={onClose} />
          </div>
          <div className="px-6 py-6 pb-0">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
              <AnnotationIcon className="w-6" />
            </div>

            <div className="mt-3 sm:mt-5">
              <CompanyQuoteTags
                tags={quote.tags}
                testId={`company-quote-${quote.title}-tags`}
              />
            </div>

            <h3
              className="text-xl font-medium leading-normal line-clamp-2"
              data-test-id={`company-insight-${quote.title}-title`}
            >
              {quote.title}
            </h3>

            <p
              className="flex-1 mt-2 text-sm text-gray-400 leading-normal"
              data-test-id={`company-insight-${quote.title}-body`}
            >
              {quote.body}
            </p>

            <div className="w-full flex flex-row justify-between items-center mt-4">
              <Founder
                founder={founders.find((f) => f.name === quote.founder?.name)}
                widthClassName="w-3/4 flex-shrink"
                testId={`company-quote-${quote.title}-founder`}
              />
              <CopyToClipboardButton
                copyText={quote.body}
                moduleName="quote"
                eventType="pressPage_view"
                eventProperties={{
                  action: 'quote_view_copyToClipboard',
                  title: quote.title,
                  body: quote.body,
                }}
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center bg-gray-50 mt-5 sm:mt-6 px-6 py-6">
            <Button
              type="secondary"
              onClick={() => setCurrentQuoteId(quotes[currentIndex - 1].uid)}
              disabled={!hasPrevious}
              eventType="pressPage_view"
              eventProperties={{
                action: 'quote_view',
                title: quotes[currentIndex - 1]?.question,
                body: quotes[currentIndex - 1]?.answer,
              }}
            >
              Previous
            </Button>

            <p className="font-normal text-gray-400">
              {currentIndex + 1} of {quotes.length}{' '}
              {quotes.length === 1 ? 'Quote' : 'Quotes'}
            </p>

            <Button
              type="secondary"
              onClick={() => setCurrentQuoteId(quotes[currentIndex + 1].uid)}
              disabled={!hasNext}
              eventType="pressPage_view"
              eventProperties={{
                action: 'quote_view',
                title: quotes[currentIndex + 1]?.question,
                body: quotes[currentIndex + 1]?.answer,
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

CompanyQuoteViewModal.propTypes = {
  open: PropTypes.bool,
  quotes: PropTypes.array,
  founders: PropTypes.array,
  currentQuoteId: PropTypes.string,
  setCurrentQuoteId: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompanyQuoteViewModal;
