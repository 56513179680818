import { useCallback } from 'react';

import { useFunctions } from 'reactfire';

/**
 * Provides access to the `getAnalytics` HTTP Cloud function
 * via a callback that accepts the company slug and number of days
 */
const useGetAnalytics = (companySlug, useGetDetails) => {
  const functions = useFunctions();

  const endpoint = `analytics-getAnalytics${useGetDetails ? 'Details' : ''}`;
  const getAnalytics = functions.httpsCallable(endpoint);

  return useCallback(
    async (numberOfDays) => {
      let analyticsData;
      try {
        analyticsData = await getAnalytics({
          companySlug,
          numberOfDays,
        });
      } catch (e) {
        // TODO-LOG: send error event to logger
      }
      return analyticsData;
    },
    [getAnalytics, companySlug]
  );
};

export default useGetAnalytics;
