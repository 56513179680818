import PropTypes from 'prop-types';
import React from 'react';

import { useForm } from 'react-hook-form';

import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';

const OpportunityResponseModalForm = ({
  setShowResponseForm,
  onSubmit,
  journalist = '',
}) => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  return (
    <form
      onSubmit={(form) => {
        setShowResponseForm(false);
        handleSubmit(onSubmit)(form);
      }}
      data-test-id="opportunity-responseModal-form"
    >
      <div className="p-6 pt-0">
        <h3 className="text-lg font-medium">Respond to {journalist}</h3>
        <InputfieldTextArea
          name="response"
          ref={register({
            required: 'Please type in a message.',
          })}
          placeholder="Please type your response to this journalist here..."
        />
        {errors.response && (
          <ErrorMessage>{errors.response.message}</ErrorMessage>
        )}
      </div>
      <div className="flex w-full justify-between bg-gray-50 p-6 rounded-b-lg">
        <Button type="secondary" onClick={() => setShowResponseForm(false)}>
          Cancel
        </Button>
        <Button
          submit
          disabled={!isValid}
          data-test-id="opportunity-responseModal-form-submit"
        >
          Send response
        </Button>
      </div>
    </form>
  );
};

OpportunityResponseModalForm.propTypes = {
  setShowResponseForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  journalist: PropTypes.string,
};

export default OpportunityResponseModalForm;
