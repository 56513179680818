import PropTypes from 'prop-types';
import React from 'react';

import { AnnotationIcon } from '@heroicons/react/solid';

const OpportunityResponseModalResponse = ({ response }) => {
  if (!response) {
    return null;
  }

  return (
    <div className="mt-4 bg-gray-100 p-4 text-sm rounded-lg border border-gray-300">
      <h4 className="flex items-center space-x-2 text-gray-500 text-xs">
        <AnnotationIcon className="w-4 h-4" />
        <span className="uppercase">Your response</span>
      </h4>
      <p
        className="pt-3 text-gray-700 leading-relaxed"
        data-test-id="opportunity-responseModal-response"
      >
        {response}
      </p>
    </div>
  );
};

OpportunityResponseModalResponse.propTypes = {
  response: PropTypes.string,
};

export default OpportunityResponseModalResponse;
