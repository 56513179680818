import React from 'react';

import { SparklesIcon, TableIcon } from '@heroicons/react/outline';

import EmptyState from 'components/EmptyState';

const SweetheartListPageBeingGenerated = () => (
  <EmptyState
    helmetTitle="Sweetheart List Being Generated"
    testId="sweetheartList-ListBeingGenerated"
    title="We're currently generating your Sweetheart list!"
    subtitle="We'll notify you via email once your list is ready to view."
    Icon={TableIcon}
    IconSecondary={SparklesIcon}
  />
);

export default SweetheartListPageBeingGenerated;
