import { useState } from 'react';

import { useFirestore } from 'reactfire';

// redirects user to a specific price page on Stripe
const useRedirectToStripe = () => {
  const firestore = useFirestore();

  const [isRedirecting, setIsRedirecting] = useState(false);

  const redirectToStripe = async ({ uid, priceId, successUrl, cancelUrl }) => {
    setIsRedirecting(true);
    const docRef = await firestore
      .collection('customers') // TODO: move this to firebasePaths
      .doc(uid)
      .collection('checkout_sessions') // TODO: move this to firebasePaths
      .add({
        price: priceId,
        allow_promotion_codes: true,
        success_url: successUrl,
        cancel_url: cancelUrl,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        // TODO-LOG: send this to logger
        // console.error(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  return { redirectToStripe, isRedirecting };
};

export default useRedirectToStripe;
