import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import IconCheckmark from 'components/icons/IconCheckmark';

// Child element for InputFieldSelect dropdown list with multiple options
const InputFieldDropdownOption = ({
  option = '',
  isSelected = false,
  isActive = false,
}) => (
  <div
    className={classNames(
      'cursor-default select-none relative py-2 px-4 sm:pl-8 sm:pr-4',
      { 'text-white bg-teal-500': isActive, 'text-gray-900': !isActive }
    )}
  >
    <span
      className={classNames('block truncate text-left', {
        'font-semibold': isSelected,
        'font-normal': !isSelected,
      })}
    >
      {option}
    </span>
    {isSelected && <IconCheckmark active={isActive} />}
  </div>
);

InputFieldDropdownOption.propTypes = {
  option: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isSelected: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default InputFieldDropdownOption;
