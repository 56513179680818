import React from 'react';

import EmptyState from 'components/EmptyState';

const NotFoundPage = () => (
  <EmptyState
    helmetTitle="Page not found | mvpr"
    testId="page-notFound"
    title="Oops, sorry!"
    subtitle="Looks like this page doesn't exist."
  />
);

export default NotFoundPage;
