import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';

import Spinner from 'components/Spinner';

// Modal overlay we use when redirecting to Stripe (because this can take some seconds and should block user interactions)
const StripeRedirectModal = ({ isRedirecting = false }) => (
  <Dialog
    open={isRedirecting}
    onClose={() => {}}
    as="div"
    className="fixed inset-0 z-50 overflow-y-auto"
    data-test-id="stripe-redirect-modal"
  >
    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

    <div className="flex items-center justify-center min-h-screen">
      <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
        <div className="flex items-center px-6 py-6 space-x-4">
          <p className="relative w-5 h-5">
            <Spinner />
          </p>
          <div className="flex flex-col justify-start items-start space-y-1">
            <p className="text-base font-medium text-gray-700">
              Redirecting to Stripe...
            </p>
            <p className="text-sm text-gray-500">
              This may take up to 10-15 seconds.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
);

StripeRedirectModal.propTypes = {
  isRedirecting: PropTypes.bool,
};

export default StripeRedirectModal;
