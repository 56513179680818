import PropTypes from 'prop-types';
import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

// Displays the headline for a section on the company's press page
const SectionHeadline = ({
  children = null,
  light = false,
  testId = '',
  showEmptySectionHint = false,
}) => {
  const themeTextColor = {
    'text-gray-400': light,
  };

  return (
    <div className="flex items-center" data-test-id={testId}>
      <h2
        className={classNames(
          'text-sm uppercase font-medium tracking-wide',
          themeTextColor
        )}
      >
        {children}
      </h2>
      {showEmptySectionHint && (
        <InformationCircleIcon
          className={classNames(
            'w-5 h-5 sm:w-4 sm:h-4 ml-2 sm:ml-1',
            themeTextColor
          )}
          data-tip="Empty sections will not show on your published page."
          data-for="tooltip"
        />
      )}
    </div>
  );
};

SectionHeadline.propTypes = {
  children: PropTypes.string.isRequired,
  light: PropTypes.bool,
  testId: PropTypes.string,
  showEmptySectionHint: PropTypes.bool,
};

export default SectionHeadline;
