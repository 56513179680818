import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  StarIcon,
  PaperAirplaneIcon,
  ChartPieIcon,
  HeartIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import { useRemoteConfigString } from 'reactfire';

import useNavigationLinks from 'hooks/useNavigationLinks';

import HomeLogo from '../HomeLogo';
import MobileNavAccountLink from './MobileNavAccountLink';
import MobileNavLink from './MobileNavLink';
import MobileNavToggle from './MobileNavToggle';

/**
 * Navigation overlay for mobile users, gets toggled via toggle icon
 * Highlights the current option as selected
 * Uses the slug to hide/show the analytics option (this should probably come from the context for better decoupling)
 */
const MobileNav = ({ current = '', slug = '' }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [linkPressPage, linkOpportunities, linkAnalytics, linkSweethearts] =
    useNavigationLinks();

  const { data } = useRemoteConfigString('analyticsEnabled');
  const analyticsEnabledCompanies = data
    .split([','])
    .map((company) => company.trim());

  const analyticsEnabled = slug && analyticsEnabledCompanies.includes(slug);

  const toggleMobileMenu = (y) => {
    setIsMobileMenuOpen(y);
    const root = document.getElementsByTagName('html')[0];
    root.classList.toggle('fixed', y);
    root.classList.toggle('overflow-y-scroll', y);
    root.classList.toggle('w-screen', y);
    const iubenda = document.getElementsByClassName('iubenda-tp-btn')[0];
    if (iubenda) {
      if (y) iubenda.setAttribute('style', 'display: none !important');
      if (!y) iubenda.removeAttribute('style');
    }
  };

  return (
    <div
      className={classNames(
        'my-2 px-2 pl-4 sm:hidden flex items-center justify-end border-l-2 border-teal-600 text-white min-h-full',
        { 'z-10': isMobileMenuOpen, 'z-0': !isMobileMenuOpen }
      )}
    >
      <MobileNavToggle
        isMobileMenuOpen={isMobileMenuOpen}
        toggleMobileMenu={toggleMobileMenu}
      />

      <div
        className={classNames(
          'fixed overscroll-none top-0 h-screen min-w-full bg-black',
          {
            'left-0 bg-opacity-70 overflow-hidden': isMobileMenuOpen,
            '-left-full bg-opacity-0': !isMobileMenuOpen,
          }
        )}
      >
        <div
          className={classNames(
            'absolute top-0 min-h-full w-10/12 flex flex-col bg-teal-500 transition-all ease-in-out duration-500',
            { 'left-0': isMobileMenuOpen, '-left-full': !isMobileMenuOpen }
          )}
        >
          <div className="ml-3 mt-3">
            <HomeLogo homeRoute={linkPressPage} white />
          </div>
          <ul className="flex-grow flex flex-col pt-4 text-lg">
            <MobileNavLink
              onClick={() => toggleMobileMenu(false)}
              link={linkPressPage}
              isSelected={current === linkPressPage}
            >
              <StarIcon className="w-9 h-9 pr-4" />
              Press Page
            </MobileNavLink>
            <MobileNavLink
              onClick={() => toggleMobileMenu(false)}
              link={linkOpportunities}
              isSelected={current === linkOpportunities}
            >
              <PaperAirplaneIcon className="w-9 h-9 pr-4" />
              Media Opportunities
            </MobileNavLink>
            {analyticsEnabled && (
              <MobileNavLink
                onClick={() => toggleMobileMenu(false)}
                link={linkAnalytics}
                isSelected={current === linkAnalytics}
              >
                <ChartPieIcon className="w-9 h-9 pr-4" />
                Analytics
              </MobileNavLink>
            )}

            <MobileNavLink
              onClick={() => toggleMobileMenu(false)}
              link={linkSweethearts}
              isSelected={current === linkSweethearts}
            >
              <HeartIcon className="w-9 h-9 pr-4" />
              Sweetheart List
            </MobileNavLink>
            <MobileNavAccountLink />
          </ul>
        </div>
      </div>
    </div>
  );
};

MobileNav.propTypes = {
  current: PropTypes.string,
  slug: PropTypes.string,
};

export default MobileNav;
