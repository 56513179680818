import uniq from 'lodash/uniq';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import {
  CLASSIFIED_OPPORTUNITIES_COLLECTION,
  COMPANIES_SWEETHEARTS_COLLECTION,
} from 'constants/firebasePaths';

const useUniquePublicationsNames = () => {
  const firestore = useFirestore();

  // get opportunities collection
  const opportunitiesCollection = firestore.collection(
    CLASSIFIED_OPPORTUNITIES_COLLECTION
  );
  const opportunities = useFirestoreCollectionData(
    opportunitiesCollection,
    defaultOptions
  );
  // get publications from opportunities data
  const opportunitiesPublicationsNames = opportunities.data
    .map(({ mediaOutlet }) => mediaOutlet || null)
    .filter(Boolean);

  // get sweetheart lists collection
  const companiesSweetheartsCollection = firestore.collection(
    COMPANIES_SWEETHEARTS_COLLECTION
  );
  const companiesSweethearts = useFirestoreCollectionData(
    companiesSweetheartsCollection,
    defaultOptions
  );
  // get publications' names from companies sweethearts lists
  const companiesSweetheartsPublicationsNames = companiesSweethearts.data
    .flatMap((c) => c?.journalists?.map((j) => j.publicationName))
    .filter(Boolean);

  // concat journalists names
  const publicationsNames = opportunitiesPublicationsNames.concat(
    companiesSweetheartsPublicationsNames
  );

  // filter and sort media outlets
  return uniq(publicationsNames).sort();
};

export default useUniquePublicationsNames;
