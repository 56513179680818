import PropTypes from 'prop-types';
import React from 'react';

import findIndex from 'lodash/findIndex';

import { tagsInsights } from 'constants/tags';
import getTagsClassNames from 'utils/color/getTagsClassNames';

const CompanyInsightTag = ({ tag = {}, testId = '' }) => {
  const tagIndex = findIndex(tagsInsights, (t) => t.id === tag.id);

  const [bgColor, textColor] = getTagsClassNames(tagIndex, tag.name?.length);

  return (
    <span
      className={`inline-flex p-2 py-1 text-xs rounded-md ${
        tag.name ? bgColor : 'bg-gray-100'
      } ${tag.name ? textColor : 'text-gray-500'}`}
      data-test-id={testId}
    >
      {tag.name || 'Untagged'}
    </span>
  );
};

CompanyInsightTag.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  testId: PropTypes.string,
};

export default CompanyInsightTag;
