import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import Founder from 'components/company/Founder';

// Child button for the input field for founders
const InputFieldFoundersFounder = ({
  founder = {},
  onChange = noop,
  isSelected = false,
  testId = '',
}) => (
  <button
    className={`flex items-center p-4 border rounded-lg space-x-2 bg-gray-50 focus:outline-none focus:ring ${
      isSelected ? 'opacity-100 border-teal-500' : 'opacity-50 border-gray-100'
    }`}
    onClick={() => onChange(isSelected ? null : founder)}
    type="button"
    data-test-id={`${testId}-${founder?.name}`}
  >
    <Founder founder={founder} />
  </button>
);

InputFieldFoundersFounder.propTypes = {
  founder: PropTypes.shape({
    uid: PropTypes.string,
    image: PropTypes.object,
    name: PropTypes.string,
  }),
  onChange: PropTypes.func,
  isSelected: PropTypes.bool,
  testId: PropTypes.string,
};

export default InputFieldFoundersFounder;
