import PropTypes from 'prop-types';
import React from 'react';

import { EmojiSadIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Helmet from 'react-helmet';

import useUserData from 'contexts/UserContext/hooks/useUserData';

const EmptyState = ({
  helmetTitle = '',
  testId = '',
  title = '',
  subtitle = '',
  Icon = EmojiSadIcon,
  IconSecondary = null,
  instructions = null,
}) => {
  const { uid } = useUserData();
  return (
    <div
      data-test-id={testId}
      className={classNames(
        'bg-gray-50 h-screen flex flex-col space-y-2 justify-center items-center text-gray-400 pt-16',
        {
          'sm:pl-16': uid,
        }
      )}
    >
      <Helmet>
        <title>{helmetTitle} | mvpr</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="text-gray-400 flex my-6">
        <Icon className="w-12 h-12 md:w-20 md:h-20" />
        {IconSecondary && <IconSecondary className="w-4 h-4 md:w-10 md:h-10" />}
      </div>
      <p>{title}</p>
      <p className="mt-2">{subtitle}</p>
      {instructions}
    </div>
  );
};

EmptyState.propTypes = {
  helmetTitle: PropTypes.string.isRequired,
  testId: PropTypes.string,
  Icon: PropTypes.elementType,
  IconSecondary: PropTypes.elementType,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  instructions: PropTypes.object,
};

export default EmptyState;
