import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import ReactPlayer from 'react-player';
import { useAnalytics } from 'reactfire';

import VideoPlayIcon from './VideoPlayIcon';

// Displays a video in the ReactPlayer component with additional children passed as a render prop
// TODO-LOG: this shouldn't log the event, instead it should call a `onClickPreview` function
const Video = ({
  video = {},
  playIconSizeClassName = 'w-20 h-20',
  children = null,
  eventType,
  eventProperties,
  testId = '',
}) => {
  // analytics: track button click
  const analytics = useAnalytics();

  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef();

  return (
    <>
      <div className="aspect-w-16 aspect-h-9" data-test-id={testId}>
        <ReactPlayer
          ref={playerRef}
          light
          controls
          url={video.url}
          width="100%"
          height="100%"
          playIcon={<VideoPlayIcon className={playIconSizeClassName} />}
          playing={isPlaying}
          onClickPreview={() => {
            analytics.logEvent(eventType, eventProperties);
            setIsPlaying(true);
          }}
          onEnded={() => {
            playerRef.current.showPreview();
            setIsPlaying(false);
          }}
        />
      </div>
      {children(isPlaying)}
    </>
  );
};

Video.propTypes = {
  video: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  testId: PropTypes.string,
  playIconSizeClassName: PropTypes.string,
  children: PropTypes.func,
  eventType: PropTypes.string,
  eventProperties: PropTypes.object,
};

export default Video;
