import PropTypes from 'prop-types';
import React from 'react';

import truncate from 'lodash/truncate';

import ellipsize from 'utils/data/ellipsize';

const COLLAPSED_LENGTH = 255;

// Displays an opportunity's query text, can be shortened to 255 chars if collapsed
const OpportunityQuery = ({ query = [], collapsed = false }) => {
  const className = 'mt-3 text-sm leading-relaxed text-gray-400';

  if (collapsed) {
    return (
      <p className={className} data-test-id="opportunity-query">
        {ellipsize(truncate(query.join(), { length: COLLAPSED_LENGTH }))}
      </p>
    );
  }
  return (
    <div className={className}>
      {query.map((paragraph) => (
        <p key={paragraph} className="mt-2">
          {paragraph}
        </p>
      ))}
    </div>
  );
};

OpportunityQuery.propTypes = {
  query: PropTypes.array.isRequired,
  collapsed: PropTypes.bool,
};

export default OpportunityQuery;
