import React from 'react';

// This just displays a set of instructions underneath the autocomplete tag field
const InputFieldTagsAutocompleteInstructions = () => (
  <p className="text-xs text-gray-400">
    Hit{' '}
    <b>
      <code>enter</code>
    </b>{' '}
    to add a tag, use{' '}
    <b>
      <code>backspace</code>
    </b>{' '}
    to delete the last one
  </p>
);

export default InputFieldTagsAutocompleteInstructions;
