import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';
import { useAnalytics } from 'reactfire';

// Versatile button component for most click interactions.
// TODO-ANALYTICS: Extract the `onClickWithAnalytics` function and move to a separate hook
const Button = ({
  children = null,
  className = '',
  type = 'primary',
  size = 'small',
  full = false,
  submit = false,
  disabled = false,
  onClick = noop,
  eventType = '',
  eventProperties = {},
  ...rest
}) => {
  // analytics: track button click
  const analytics = useAnalytics();

  const onClickWithAnalytics = (e) => {
    if (disabled) {
      return;
    }
    if (eventType) {
      analytics.logEvent(eventType, eventProperties);
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
      className={classNames(
        'inline-flex justify-center items-center py-2 px-3 border shadow-sm font-medium text-sm rounded-md whitespace-nowrap focus:outline-none focus:ring',
        {
          'border-transparent text-white bg-teal-500 hover:bg-teal-400 focus:border-teal-700':
            type === 'primary',
          'bg-white hover:bg-gray-50 focus:border-gray-100 text-teal-500':
            type === 'secondary',
          'text-base': size === 'large',
          'w-full': full,
          'cursor-not-allowed': disabled,
          'bg-[#d8f1ed] hover:bg-[#d7f3ef]': disabled && type === 'primary',
          'text-[#d8f1ed] hover:bg-gray-50': disabled && type === 'secondary',
        },
        className
      )}
      onClick={(e) => onClickWithAnalytics(e)}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['large', 'small']),
  full: PropTypes.bool,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  eventType: PropTypes.string,
  eventProperties: PropTypes.object,
};

export default Button;
