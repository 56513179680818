import PropTypes from 'prop-types';
import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { AuthCheck } from 'reactfire';

import { LOGIN_PATH } from 'constants/paths';

// A wrapper for <Route> that redirects to the login
const PrivateRoute = ({ children, path, claimsRequired = null, ...rest }) => (
  <AuthCheck
    requiredClaims={claimsRequired}
    key={path}
    fallback={
      <Redirect
        to={{
          pathname: LOGIN_PATH,
          state: { from: path },
        }}
      />
    }
  >
    <Route key={`Route-${path}`} path={path} {...rest} />
  </AuthCheck>
);

PrivateRoute.propTypes = {
  children: PropTypes.element,
  path: PropTypes.string.isRequired,
  claimsRequired: PropTypes.object,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
};

export default PrivateRoute;
