import { useEffect } from 'react';

import { useFirestore, useFirestoreDocData } from 'reactfire';

import { OPPORTUNITIES_NOTIFICATIONS } from 'constants/firebasePaths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

const OpportunityResponseModalTrackVisit = ({ uid = '' }) => {
  const firestore = useFirestore();

  const ref = firestore.collection(OPPORTUNITIES_NOTIFICATIONS).doc(uid);
  const { data } = useFirestoreDocData(ref);

  const { companyData } = useCompanyData();

  useEffect(() => {
    const companiesVisited = data?.companiesVisited || [];
    const companyUid = companyData.uid;
    if (companiesVisited.includes(companyUid)) {
      return;
    }
    companiesVisited.push(companyUid);

    ref.set(
      {
        companiesVisited,
      },
      { merge: true }
    );
  }, [companyData.uid, data, ref, uid]);

  return null;
};

export default OpportunityResponseModalTrackVisit;
