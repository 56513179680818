import React from 'react';

import { useParams } from 'react-router-dom';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import Analytics from './Analytics';
import AnalyticsAdmin from './AnalyticsAdmin';

const AnalyticsPage = () => {
  const { companyData } = useCompanyData();

  // Use the company slug to inspect companies as admin
  const { id: slug } = useParams('id');

  if (slug) {
    return <AnalyticsAdmin slug={slug} />;
  }

  return <Analytics slug={companyData.slug} />;
};

export default AnalyticsPage;
