import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { AdjustmentsIcon, FilterIcon } from '@heroicons/react/solid';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';

import OpportunitiesFilter from 'components/opportunities/OpportunitiesFilter/OpportunitiesFilter';
import OpportunitiesFilterSortModal from 'components/opportunities/OpportunitiesFilterSortModal';
import OpportunitiesSort from 'components/opportunities/OpportunitiesSort/OpportunitiesSort';

import OpportunitiesNavbarTabs from './OpportunitiesNavbarTabs/OpportunitiesNavbarTabs';

SwiperCore.use([Navigation]);
// eslint-disable-next-line no-unused-vars
const OpportunitiesNavbar = ({
  tabs,
  filterOptions,
  sortOptions,
  activeTab,
  setActiveTab,
  filter,
  setFilter,
  sort,
  sortDir,
  setSort,
  setSortDir,
  viewAllTopics,
  opportunities,
}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  return (
    <>
      <div id="opportunities" className="sticky top-16 z-10">
        <div className="w-full flex pt-2 bg-white shadow-sm text-gray-300 border-b border-gray-200">
          <OpportunitiesNavbarTabs
            tabs={tabs}
            opportunities={opportunities}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          <div className="flex items-center ml-auto mr-4 -mt-2 space-x-3">
            <OpportunitiesFilter
              filterOptions={filterOptions}
              filter={filter}
              setFilter={setFilter}
            />

            <OpportunitiesSort
              sortOptions={sortOptions}
              sort={sort}
              setSort={setSort}
              sortDir={sortDir}
              setSortDir={setSortDir}
            />

            <a
              className="hidden lg:block text-teal-500 bg-white px-3 py-2 border border-gray-200 rounded-md text-sm font-medium hover:bg-gray-50 cursor-pointer"
              type="secondary"
              size="small"
              onClick={viewAllTopics}
              data-test-id="opportunities-navBar-updateTopics"
            >
              Update topics
            </a>

            <a
              className="lg:hidden bg-gray-100 text-gray-500 px-3 py-3 rounded-md text-sm font-medium hover:bg-gray-50 cursor-pointer"
              type="secondary"
              size="small"
              onClick={() => setIsFilterModalOpen(true)}
              data-test-id="opportunities-navBar-filterIcon"
            >
              <FilterIcon className="w-4" />
            </a>

            <a
              className="lg:hidden bg-gray-100 text-gray-500 px-3 py-3 rounded-md text-sm font-medium hover:bg-gray-50 cursor-pointer"
              type="secondary"
              size="small"
              onClick={viewAllTopics}
              data-test-id="opportunities-navBar-updateTopicsIcon"
            >
              <AdjustmentsIcon className="w-4" />
            </a>
          </div>
        </div>
      </div>

      <OpportunitiesFilterSortModal
        open={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        filterOptions={filterOptions}
        filter={filter}
        setFilter={setFilter}
        sortOptions={sortOptions}
        sort={sort}
        setSort={setSort}
        sortDir={sortDir}
        setSortDir={setSortDir}
      />
    </>
  );
};

OpportunitiesNavbar.propTypes = {
  tabs: PropTypes.array.isRequired,
  filterOptions: PropTypes.array.isRequired,
  sortOptions: PropTypes.array.isRequired,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  filter: PropTypes.array.isRequired,
  setFilter: PropTypes.func.isRequired,
  sort: PropTypes.object,
  sortDir: PropTypes.number.isRequired,
  setSort: PropTypes.func.isRequired,
  setSortDir: PropTypes.func.isRequired,
  viewAllTopics: PropTypes.func.isRequired,
  opportunities: PropTypes.object.isRequired,
};

export default OpportunitiesNavbar;
