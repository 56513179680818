import React, { useState } from 'react';

import { OfficeBuildingIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';
import { useFirestore } from 'reactfire';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import Button from 'components/buttons/Button';
import UpdateLogoModal from 'components/company/UpdateLogoModal';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';
import { REGEX_URL_NO_PROTOCOL } from 'constants/regex';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useNotifications from 'modules/notification/useNotifications';
import stripProtocol from 'utils/data/stripProtocol';
import stripTrailingSlash from 'utils/data/stripTrailingSlash';
import createOnChangeIsFutureDate from 'utils/date/createOnChangeIsFutureDate';
import { getDateRange } from 'utils/date/dateRange';
import getCompanyRoute from 'utils/getCompanyRoute';

const CompanyForm = () => {
  const { showSuccess, showError } = useNotifications();
  const { companyData: companyDataRaw } = useCompanyData();

  const companyData = {
    ...companyDataRaw,
    website: stripTrailingSlash(stripProtocol(companyDataRaw.website)),
  };

  const companyId = companyData && companyData.uid;

  const companyRef = useFirestore()
    .collection(COMPANIES_COLLECTION)
    .doc(companyId);

  const [updateLogoModalOpen, setUpdateLogoModalOpen] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, isValid },
    getValues,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    nativeValidation: false,
    defaultValues: companyData,
  });

  const onSubmit = async (data) => {
    try {
      await companyRef.update(data, { merge: true });
      showSuccess('Company settings updated successfully.');
    } catch (err) {
      console.error('Error updating profile', err); // eslint-disable-line no-console
      showError(`Error updating profile: ${err.message}`);
    }
  };

  const updateLogo = async ({ logo }) => {
    companyRef.update({ logo }, { merge: true });
    setUpdateLogoModalOpen(false);
  };

  const onChangeDate = createOnChangeIsFutureDate({
    date: getValues('foundedDate'),
    onError: () =>
      setError('foundedDate', { message: 'Date cannot be in the future' }),
    onSuccess: () => clearErrors(),
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-12 gap-6 mt-6">
          <div className="col-span-12 sm:col-span-12">
            <Label htmlFor="name">Name</Label>
            <div className="w-full sm:w-1/2 pr-3">
              <InputFieldText
                name="name"
                defaultValue={companyData.name}
                placeholder="Name"
                disabled
              />
            </div>

            <p className="text-xs text-gray-500 mt-2">
              The URL of your press page is: <br className="sm:hidden" />
              <a
                className="font-semibold"
                href={getCompanyRoute(companyData.slug)}
              >
                https://mvpr.io/company/{companyData.slug.trim()}
              </a>
            </p>
          </div>

          <fieldset className="col-span-12 sm:col-span-12">
            <Label htmlFor="name">Founded date</Label>
            <div className="flex w-full mt-2 space-x-2">
              <Controller
                name="foundedDate.month"
                control={control}
                defaultValue={companyData.foundedDate.month}
                render={({ onChange, value }) => (
                  <InputFieldDropdown
                    options={getDateRange('months')}
                    onChange={onChangeDate(onChange)}
                    value={value}
                    optionWidthClassName="w-36"
                  />
                )}
              />
              <Controller
                name="foundedDate.year"
                control={control}
                defaultValue={companyData.foundedDate.year}
                render={({ onChange, value }) => (
                  <InputFieldDropdown
                    options={getDateRange('years')}
                    onChange={onChangeDate(onChange)}
                    value={value}
                    optionWidthClassName="w-28"
                  />
                )}
              />
            </div>
            {errors.foundedDate && (
              <ErrorMessage>{errors.foundedDate.message}</ErrorMessage>
            )}
          </fieldset>

          <div className="col-span-12">
            <Label htmlFor="logo">Logo</Label>
            <div className="mt-2 flex items-center">
              <div className="w-20 h-20 bg-white relative text-gray-300">
                {companyData.logo ? (
                  <UniversalImage
                    image={companyData.logo}
                    alt={`Logo of company ${companyData.name}`}
                    widthClass="h-20"
                    objectFitClass="object-contain"
                  />
                ) : (
                  <div className="flex justify-center items-center h-20 text-gray-400 bg-gray-100 rounded-lg">
                    <OfficeBuildingIcon className="w-10" />
                  </div>
                )}
              </div>
              <div className="ml-4 flex">
                <Button
                  type="secondary"
                  onClick={() => setUpdateLogoModalOpen(true)}
                >
                  <span>Change</span>
                  <span className="sr-only"> user photo</span>
                </Button>
              </div>
            </div>
          </div>

          <div className="col-span-12 sm:col-span-6">
            <Label htmlFor="name">Location</Label>
            <InputFieldText
              ref={register({
                required: 'Please type in the location of your company',
              })}
              name="location"
              defaultValue={companyData.location}
              placeholder="Location"
            />
            {errors.location && (
              <ErrorMessage>{errors.location.message}</ErrorMessage>
            )}
          </div>

          <div className="col-span-12 sm:col-span-6">
            <Label htmlFor="name">Website</Label>
            <InputFieldText
              ref={register({
                pattern: {
                  value: REGEX_URL_NO_PROTOCOL,
                  message: 'Please type in the website of your company',
                },
              })}
              prefix="https://"
              name="website"
              defaultValue={companyData.website}
              placeholder="Type your company website here..."
            />

            {errors.website && (
              <ErrorMessage>{errors.website.message}</ErrorMessage>
            )}
          </div>
        </div>

        <div className="mt-4 flex flex-row justify-end">
          <Button type="secondary" submit disabled={isSubmitting || !isValid}>
            Submit
          </Button>
        </div>
      </form>

      {updateLogoModalOpen && (
        <UpdateLogoModal
          uploadFolder={`companies/${companyData.uid}/logo`}
          onSubmit={updateLogo}
          onClose={() => setUpdateLogoModalOpen(false)}
        />
      )}
    </>
  );
};

export default CompanyForm;
