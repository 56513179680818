export const presetImage = {
  storageRef: 'images',
  allowedFormats: [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/webp',
  ],
  allowedFormatsText: 'PNG, JPG, GIF or WEBP up to 10MB',
  allowedFormatsErrorText:
    'Please provide a file in one of the following formats: PNG, JPG, GIF or WEBP',
  maxFilesize: 1024 * 1024 * 10, // 10MB
  maxFilesizeErrorText: 'Please provide an image that is less than 10MB.',
};

export const presetFile = {
  storageRef: 'files',
  allowedFormats: [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/svg+xml',
    'application/pdf',
    'application/zip',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  allowedFormatsText: 'PNG, JPG, GIF, PDF, DOCX or ZIP up to 10MB',
  allowedFormatsErrorText:
    'Please provide a file in one of the following formats: PNG, JPG, GIF, PDF, DOCX or ZIP',
  maxFilesize: 1024 * 1024 * 10, // 10MB
  maxFilesizeErrorText: 'Please provide a file that is less than 10MB.',
};

export const presetPdf = {
  ...presetFile,
  allowedFormats: ['application/pdf'],
  allowedFormatsText: 'PDF up to 10MB',
  allowedFormatsErrorText: 'Please provide a file in PDF format.',
};

export const presetDoc = {
  ...presetPdf,
  allowedFormats: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  allowedFormatsText: 'DOC or DOCX up to 10MB',
  allowedFormatsErrorText: 'Please provide a file in DOC or DOCX format.',
};
