import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { sanitizeUrl } from '@braintree/sanitize-url';
import { PlusIcon } from '@heroicons/react/solid';
import sortBy from 'lodash/sortBy';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import EmptyState from 'components/company/EmptyState';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';

import CompanyVideo from './CompanyVideo';
import CompanyVideoEditModal from './CompanyVideoEditModal/CompanyVideoEditModal';

const CompanyVideos = ({ crud, showOwnerView = false, sectionRef }) => {
  const [status, videosRaw, ops] = crud;

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const closeModal = () => setIsAddModalOpen(false);

  const videos = videosRaw.filter(
    (video) => showOwnerView || (!showOwnerView && !video.featured)
  );
  const videosSorted = sortBy(videos, '!featured');
  const isEmpty = videos.length < 1;
  const isEmptyAndOwnerview = showOwnerView && isEmpty;

  if (status === 'loading') {
    return null;
  }

  // If there are no videos yet, hide this section for journalists
  if (!showOwnerView && isEmpty) {
    return null;
  }

  const onSubmit = (data = {}) => {
    const { title, url, duration, tag } = data;
    const sanitizedUrl = sanitizeUrl(url);
    ops.createDoc({
      title,
      url: sanitizedUrl,
      duration: duration || 0,
      tag: tag || null,
    });
    closeModal();
  };

  return (
    <div data-test-id="company-videos" id="videos" ref={sectionRef}>
      <SectionHeaderWrapper>
        <SectionHeadline
          light
          testId="company-videos-headline"
          showEmptySectionHint={isEmptyAndOwnerview}
        >
          Videos
        </SectionHeadline>
        {showOwnerView && (
          <DotsMenu
            options={[
              {
                label: 'Add video',
                icon: <PlusIcon className="w-4 h-4" />,
                onClick: () => setIsAddModalOpen(true),
              },
            ]}
            theme="dark"
          />
        )}
      </SectionHeaderWrapper>
      <div className="w-full mt-4">
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-8 gap-6">
          {videos.length ? (
            videosSorted.map((video) => (
              <CompanyVideo
                key={video.uid}
                video={video}
                ops={ops}
                showOwnerView={showOwnerView}
              />
            ))
          ) : (
            <EmptyState
              title="Add a video"
              description="Add video content that shares what you and your company is working to achieve."
              onClick={() => setIsAddModalOpen(true)}
              className="col-span-2"
              testId="company-videos-addFirst"
            />
          )}
        </ul>
      </div>

      {showOwnerView && (
        <CompanyVideoEditModal
          open={isAddModalOpen}
          onClose={() => closeModal()}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

CompanyVideos.propTypes = {
  crud: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanyVideos;
