import { useFirestore, useFirestoreDocData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_OPPORTUNITIES_COLLECTION } from 'constants/firebasePaths';

const useCompaniesOpportunities = (uid) => {
  const firestore = useFirestore();
  const doc = firestore.collection(COMPANIES_OPPORTUNITIES_COLLECTION).doc(uid);
  const { data } = useFirestoreDocData(doc, defaultOptions);
  return [data, doc];
};

export default useCompaniesOpportunities;
