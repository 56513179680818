import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { sanitizeUrl } from '@braintree/sanitize-url';
import { PlusIcon } from '@heroicons/react/solid';
import sortBy from 'lodash/sortBy';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import EmptyState from 'components/company/EmptyState';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';

import CompanySnapshot from './CompanySnapshot';
import CompanySnapshotEditModal from './CompanySnapshotEditModal/CompanySnapshotEditModal';

const CompanySnapshots = ({ crud, showOwnerView, sectionRef }) => {
  const [, snapshots, ops] = crud;

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const closeModal = () => setIsAddModalOpen(false);

  const snapshotsSorted = sortBy(snapshots, 'date').reverse();
  const isEmpty = snapshots.length < 1;
  const isEmptyAndOwnerview = showOwnerView && isEmpty;

  const onSubmit = (data = {}) => {
    const { date, emoji, title, url } = data;
    const sanitizedUrl = url ? sanitizeUrl(url) : null;
    ops.createDoc({
      date: createTimestampFromDateObj(date),
      title,
      url: sanitizedUrl || null,
      emoji,
    });
    closeModal();
  };

  // If there are no snapshots yet, hide this section for journalists
  if (!showOwnerView && isEmpty) {
    return null;
  }

  return (
    <div data-test-id="company-snapshots" id="milestones" ref={sectionRef}>
      <SectionHeaderWrapper>
        <SectionHeadline
          light
          testId="company-snapshots-headline"
          showEmptySectionHint={isEmptyAndOwnerview}
        >
          Milestones
        </SectionHeadline>
        {showOwnerView && (
          <DotsMenu
            options={[
              {
                label: 'Add milestone',
                icon: <PlusIcon className="w-4 h-4" />,
                onClick: () => setIsAddModalOpen(true),
              },
            ]}
            theme="dark"
          />
        )}
      </SectionHeaderWrapper>
      <div className="w-full mt-4">
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-5 gap-6">
          {snapshotsSorted.length ? (
            snapshotsSorted.map((snapshot) => (
              <CompanySnapshot
                key={snapshot.uid}
                snapshot={snapshot}
                ops={ops}
                showOwnerView={showOwnerView}
              />
            ))
          ) : (
            <EmptyState
              title="Add a milestone"
              description="Adding milestones shows journalists the story of your company's growth."
              onClick={() => setIsAddModalOpen(true)}
              testId="company-snapshots-addFirst"
            />
          )}
        </ul>
      </div>

      {showOwnerView && (
        <CompanySnapshotEditModal
          open={isAddModalOpen}
          onClose={() => closeModal()}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

CompanySnapshots.propTypes = {
  crud: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanySnapshots;
