import React from 'react';

import { StateMachineProvider, createStore } from 'little-state-machine';
import { FirebaseAppProvider, SuspenseWithPerf } from 'reactfire';

import ErrorBoundary from 'components/ErrorBoundary';
import PreloadFirebase from 'components/PreloadFirebase';
import Spinner from 'components/Spinner';
import IubendaCookieConsentModal from 'components/integrations/IubendaCookieConsentModal/IubendaCookieConsentModal';
import { defaultUser, defaultCompany } from 'constants/defaultData';
import EnvironmentProvider from 'contexts/EnvironmentContext/EnvironmentProvider';
import NotificationsProvider from 'modules/notification/NotificationsProvider';

import Router from './routes/Router';

createStore({
  user: defaultUser,
  company: defaultCompany,
  cookiesEnabled: null,
});

const App = () => (
  <EnvironmentProvider>
    {({ firebaseApp }) => (
      <ErrorBoundary>
        <StateMachineProvider>
          <NotificationsProvider>
            <PreloadFirebase>
              <FirebaseAppProvider
                suspense
                firebaseApp={firebaseApp}
                initPerformance
              >
                <SuspenseWithPerf fallback={<Spinner />} traceId="router-wait">
                  <Router />
                </SuspenseWithPerf>
                <SuspenseWithPerf fallback={<Spinner />} traceId="iubenda-wait">
                  <IubendaCookieConsentModal />
                </SuspenseWithPerf>
              </FirebaseAppProvider>
            </PreloadFirebase>
          </NotificationsProvider>
        </StateMachineProvider>
      </ErrorBoundary>
    )}
  </EnvironmentProvider>
);

export default App;
