import PropTypes from 'prop-types';
import React from 'react';

import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import UniversalImage from 'components/UniversalImage/UniversalImage';
import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import { REGEX_DOMAIN_AUTHORITY } from 'constants/regex';

const UPLOAD_FOLDER = 'publicationImages';

const AdminPublicationsPublicationModal = ({
  publicationName,
  defaultPublication,
  onClose,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const defaultImage = defaultPublication?.image;
  const defaultDomainAuthority = defaultPublication?.domainAuthority;

  return (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-6 py-6">
          <h2 className="text-md text-gray-700">
            Publication <span className="font-semibold">{publicationName}</span>
          </h2>

          <div className="w-full mt-4 text-sm text-gray-800">
            <Label htmlFor="image">Image</Label>
            <div className="mt-2">
              <Controller
                name="image"
                defaultValue={defaultImage}
                control={control}
                render={({ onChange, value }) => (
                  <InputFieldUpload
                    name="image"
                    folder={UPLOAD_FOLDER}
                    onChange={onChange}
                    value={value}
                    setError={setError}
                    renderFile={(image, onChangeImage) => (
                      <div className="relative h-40 w-40 bg-gray-100">
                        <UniversalImage
                          image={image}
                          alt={`Logo of publication ${publicationName}`}
                          heightClass="w-40 h-40"
                          objectFitClass="object-cover"
                        />
                        <div className="absolute -top-2 -right-2">
                          <CloseButton onClick={() => onChangeImage(null)} />
                        </div>
                      </div>
                    )}
                  />
                )}
              />
            </div>

            {errors.image && (
              <ErrorMessage>{errors.image.message}</ErrorMessage>
            )}
          </div>

          {/* Domain authority */}
          <div className="mt-3">
            <Label htmlFor="domainAuthority">Domain authority</Label>
            <InputFieldText
              defaultValue={defaultDomainAuthority}
              ref={register({
                pattern: {
                  value: REGEX_DOMAIN_AUTHORITY,
                  message: 'Please type in a valid domain authority',
                },
              })}
              name="domainAuthority"
              placeholder="A value between 1 and 100"
            />
            {errors.domainAuthority && (
              <ErrorMessage>{errors.domainAuthority.message}</ErrorMessage>
            )}
          </div>
        </div>
        <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
          <Button type="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <div className="ml-3">
            <Button disabled={!isValid} submit>
              Update publication
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

AdminPublicationsPublicationModal.propTypes = {
  publicationName: PropTypes.string.isRequired,
  defaultPublication: PropTypes.shape({
    image: PropTypes.object,
    domainAuthority: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AdminPublicationsPublicationModal;
