import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { TrashIcon } from '@heroicons/react/solid';
import { useAnalytics, useStorage } from 'reactfire';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import Founder from 'components/company/Founder';

import CompanyDownloadsDeleteModal from '../CompanyDownloadsDeleteModal';
import CompanyDownloadsEditModal from '../CompanyDownloadsEditModal';
import CompanyDownloadPreview from './CompanyDownloadPreview';
import CompanyDownloadTags from './CompanyDownloadTags';
import CompanyDownloadWrapper from './CompanyDownloadWrapper';

const CompanyDownload = ({
  download = null,
  founders = [],
  showOwnerView = false,
  companyId,
  ops,
}) => {
  const storage = useStorage();

  const [downloadUrl, setDownloadUrl] = useState('#');
  useEffect(
    () =>
      (async () => {
        const url = await storage.ref(download.file.path).getDownloadURL();
        setDownloadUrl(url);
      })(),
    [download.file.path, storage]
  );

  const analytics = useAnalytics();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onSubmit = (data = {}) => {
    const { title, file, founder, tags } = data;
    ops.updateDoc(download.uid, {
      title,
      file,
      founder: founder?.name ? { name: founder.name } : null,
      users: null,
      tags,
    });
    setIsEditModalOpen(false);
  };

  const onDelete = () => {
    ops.deleteDoc(download.uid);
    setIsDeleteModalOpen(false);
  };

  const downloadFile = async () => {
    await storage
      .ref(download.file.path)
      .getDownloadURL()
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = () => {
          const blob = xhr.response;
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = download.title;
          link.click();
          URL.revokeObjectURL(link.href);
        };
        xhr.open('GET', url);
        xhr.send();
      });
  };

  const onClick = (e) => {
    analytics.logEvent('pressPage_view', {
      action: 'download_open',
      title: download.title,
      url: downloadUrl,
    });
    downloadFile();
    e.preventDefault();
  };

  return (
    <>
      <CompanyDownloadWrapper
        url={downloadUrl}
        showOwnerView={showOwnerView}
        onClick={onClick}
      >
        <CompanyDownloadPreview download={download} />

        {showOwnerView && (
          <div className="absolute top-4 right-4">
            <DotsMenu
              options={[
                {
                  label: `Edit download`,
                  onClick: () => setIsEditModalOpen(true),
                  testId: `company-download-${download.title}-menu-edit`,
                },
                {
                  label: `Delete download`,
                  icon: <TrashIcon className="w-4 h-4" />,
                  onClick: () => setIsDeleteModalOpen(true),
                  testId: `company-download-${download.title}-menu-delete`,
                },
              ]}
              testId={`company-download-${download.title}-menu`}
            />
          </div>
        )}

        <div className="w-full bg-white p-4 rounded-b-lg">
          <CompanyDownloadTags
            tags={download.tags}
            testId={`company-download-${download.title}-tags`}
          />
          <p
            className="mt-1 text-lg font-medium text-gray-700 line-clamp-2"
            data-test-id={`company-download-${download.title}-title`}
          >
            {download.title}
          </p>
          {download?.founder && (
            <div className="mt-3">
              <Founder
                founder={founders.find(
                  (f) => f.name === download?.founder?.name
                )}
                widthClassName="w-3/4 flex-shrink"
                testId={`company-download-${download.title}-founder`}
              />
            </div>
          )}
        </div>
      </CompanyDownloadWrapper>

      {showOwnerView && (
        <CompanyDownloadsEditModal
          open={isEditModalOpen}
          download={download}
          founders={founders}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onSubmit}
          uploadFolder={`companies/${companyId}/articles`}
        />
      )}

      {showOwnerView && isDeleteModalOpen && (
        <CompanyDownloadsDeleteModal
          title={download.title}
          onDelete={onDelete}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}
    </>
  );
};

CompanyDownload.propTypes = {
  download: PropTypes.object.isRequired,
  founders: PropTypes.array,
  ops: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
};

export default CompanyDownload;
