/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import useQuery from 'hooks/useQuery';
import useNotifications from 'modules/notification/useNotifications';

import useLogOpportunityInteraction from '../hooks/useLogOpportunityInteraction';
import EmptyState from './EmptyState';
import OpportunitiesBulkActions from './OpportunitiesBulkActions';
import Opportunity from './Opportunity';
import OpportunityResponseModal from './OpportunityResponseModal/OpportunityResponseModal';

// eslint-disable-next-line react/prop-types
const OpportunitiesList = ({
  opportunities,
  opportunitiesOps,
  currentField,
  selectedTopics = [],
}) => {
  const notifications = useNotifications();
  const logOpportunityInteraction = useLogOpportunityInteraction();
  const currentOpportunities = opportunities[currentField];

  // check if an opportunity query ID has been set
  const opportunityId = useQuery().get('id');
  const opportunityIndexFromId = currentOpportunities.findIndex(
    (o) => o.uid === opportunityId
  );
  const opportunityIdHasIndex = opportunityIndexFromId !== -1;
  const defaultActiveOpportunityIndex = opportunityIdHasIndex
    ? opportunityIndexFromId
    : null;

  // set to default opportunity index if exists, otherwise null
  const [activeOpportunityIndex, setActiveOpportunityIndex] = useState(
    defaultActiveOpportunityIndex
  );
  const [selectedOpportunities, setSelectedOpportunities] = useState([]);

  // if opportunity query ID is set but can't be found, show error
  useEffect(() => {
    if (opportunityId && !opportunityIdHasIndex) {
      notifications.showError(
        'Sorry! It looks like this opportunity has already expired.'
      );
    }
  }, [
    notifications,
    opportunityId,
    opportunityIdHasIndex,
    opportunityIndexFromId,
  ]);

  useEffect(() => {
    setSelectedOpportunities([]);
  }, [currentField]);

  const activeOpportunity = currentOpportunities[activeOpportunityIndex];
  const showActions = !['responded', 'declined'].includes(currentField);

  const onSubmit = (data) => {
    const { response } = data;
    opportunitiesOps.respond(activeOpportunity, response);
    setActiveOpportunityIndex(null);
  };

  return (
    <>
      <div className="sm:px-4 mt-4 lg:mt-6 mb-6">
        {currentOpportunities.length === 0 && (
          <EmptyState currentField={currentField} />
        )}

        {currentOpportunities.map((opportunity, index) => (
          <Opportunity
            key={opportunity.uid}
            opportunity={opportunity}
            opportunitiesOps={opportunitiesOps}
            currentField={currentField}
            selectedTopics={selectedTopics}
            onExpand={() => setActiveOpportunityIndex(index)}
            onSelect={(uid) =>
              setSelectedOpportunities((arr) =>
                selectedOpportunities.includes(uid)
                  ? arr.filter((o) => o !== uid)
                  : [...arr, uid]
              )
            }
            isSelected={selectedOpportunities.includes(opportunity.uid)}
          />
        ))}
      </div>

      {selectedOpportunities.length && showActions ? (
        <OpportunitiesBulkActions
          selectedOpportunities={currentOpportunities.filter((o) =>
            selectedOpportunities.includes(o.uid)
          )}
          setSelectedOpportunities={setSelectedOpportunities}
          opportunitiesOps={opportunitiesOps}
          currentField={currentField}
        />
      ) : null}

      <OpportunityResponseModal
        open={activeOpportunityIndex !== null}
        onClose={() => {
          logOpportunityInteraction('dismiss', activeOpportunity);
          setActiveOpportunityIndex(null);
        }}
        onPrevious={() => setActiveOpportunityIndex((current) => current - 1)}
        onNext={() => setActiveOpportunityIndex((current) => current + 1)}
        onSubmit={onSubmit}
        opportunity={activeOpportunityIndex !== null ? activeOpportunity : null}
        opportunitiesOps={opportunitiesOps}
        currentField={currentField}
        selectedTopics={selectedTopics}
        currentCount={activeOpportunityIndex}
        totalCount={currentOpportunities.length}
      />
    </>
  );
};

OpportunitiesList.propTypes = {
  opportunities: PropTypes.object.isRequired,
  opportunitiesOps: PropTypes.shape({
    decline: PropTypes.func.isRequired,
    undecline: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    unsave: PropTypes.func.isRequired,
    respond: PropTypes.func.isRequired,
  }).isRequired,
  currentField: PropTypes.string,
  selectedTopics: PropTypes.array,
};

export default OpportunitiesList;
