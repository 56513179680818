import React from 'react';

import { useParams } from 'react-router-dom';

import useCompanyRefBySlug from 'hooks/useCompanyRefBySlug';

import CompanyNotFound from './CompanyNotFound';
import CompanyPageContent from './CompanyPageContent';

const CompanyPage = () => {
  const { id } = useParams();
  const companyRef = useCompanyRefBySlug(id);

  return companyRef ? (
    <CompanyPageContent companyRef={companyRef} />
  ) : (
    <CompanyNotFound />
  );
};

export default CompanyPage;
