import PropTypes from 'prop-types';
import React from 'react';

import { LightBulbIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import { sizes, getSizeClassNames } from 'components/Callout/helpers/sizes';

// Displays a callout with some information about something
const Callout = ({ children = null, size = 'xs' }) => {
  const { iconSizeClassName, textSizeClassName } = getSizeClassNames(size);

  return (
    <div className="p-3 space-x-2 rounded-lg flex items-center mt-4 text-gray-500 bg-yellow-100">
      <LightBulbIcon className={classNames('shrink-0', iconSizeClassName)} />

      <div className={classNames('leading-snug', textSizeClassName)}>
        {children}
      </div>
    </div>
  );
};

Callout.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(Object.keys(sizes)),
};

export default Callout;
