import React, { useState } from 'react';

import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { PUBLICATIONS_COLLECTION } from 'constants/firebasePaths';

import AdminWrapper from '../AdminWrapper';
import AdminPublicationsPublication from './AdminPublicationsPublication';
import AdminPublicationsPublicationModal from './AdminPublicationsPublicationModal';
import useUniquePublicationsNames from './hooks/useUniquePublicationsNames';

const AdminPublicationsPage = () => {
  const firestore = useFirestore();

  // get publications collection
  const publicationsCollection = firestore.collection(PUBLICATIONS_COLLECTION);
  const publications = useFirestoreCollectionData(
    publicationsCollection,
    defaultOptions
  );

  const publicationsNames = useUniquePublicationsNames();
  const [currentPublicationName, setCurrentPublicationName] = useState(false);

  const onSavePublication = (data = {}) => {
    setCurrentPublicationName(null);

    const image = data.image || null;
    const domainAuthority = data.domainAuthority || null;

    // check if publication already exists
    const hasPublication = publications.data.find(
      (p) => p.name === currentPublicationName
    );

    // if publication already exists, merge
    if (hasPublication) {
      publicationsCollection
        .doc(hasPublication.uid)
        .set({ image, domainAuthority }, { merge: true });
    }

    // if publication doesn't exist, add
    if (!hasPublication) {
      publicationsCollection.add({
        name: currentPublicationName,
        image,
        domainAuthority,
      });
    }
  };

  return (
    <>
      <AdminWrapper title="Publications" currentIndex={7}>
        <div className="mt-4 p-4 bg-white border border-dashed border-gray-400 rounded-xl text-sm text-gray-400">
          <h3 className="font-semibold uppercase">Instructions</h3>

          <p className="mt-2">
            Below you can find a list of <strong>publications</strong>. The
            ideal image size for <strong>publications</strong> is 256x256 (64x64
            times 4) so it looks really crisp on high-resolution displays.
          </p>
        </div>

        <div className="mt-4 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Publication image
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Domain authority
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {publicationsNames.map((publicationName, index) => (
                      <AdminPublicationsPublication
                        key={publicationName}
                        name={publicationName}
                        publication={publications.data.find(
                          (p) => p.name === publicationName
                        )}
                        className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                        onEdit={() =>
                          setCurrentPublicationName(publicationName)
                        }
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </AdminWrapper>

      {currentPublicationName && (
        <AdminPublicationsPublicationModal
          publication={currentPublicationName}
          defaultPublication={publications.data.find(
            (p) => p.name === currentPublicationName
          )}
          onClose={() => setCurrentPublicationName(false)}
          onSubmit={onSavePublication}
        />
      )}
    </>
  );
};

export default AdminPublicationsPage;
