import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { DEFAULT_ONBOARDING_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

// redirect hook for settings page, redirecting the user to the correct path on load
const useRedirectSettings = () => {
  const { uid, claimsVc } = useUserData();
  const { companyData } = useCompanyData();
  const showSettings = (uid && companyData) || claimsVc;
  const history = useHistory();

  useEffect(() => {
    if (!companyData && !claimsVc) {
      history.push(DEFAULT_ONBOARDING_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showSettings;
};

export default useRedirectSettings;
