import { useMemo } from 'react';

import { uniqBy } from 'lodash';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_SWEETHEARTS_COLLECTION } from 'constants/firebasePaths';
import useCompanies from 'hooks/useCompanies';

const useSweetheartCompanies = () => {
  const firestore = useFirestore();
  const companies = useCompanies();
  const query = firestore.collection(COMPANIES_SWEETHEARTS_COLLECTION);
  const companiesSweethearts = useFirestoreCollectionData(
    query,
    defaultOptions
  )?.data;

  const companiesUnique = uniqBy(companies, 'slug');
  const companiesSweetheartsList = useMemo(() => {
    if (!companiesSweethearts) {
      return null;
    }

    return companiesUnique.map((company) => {
      const companyFlags = companiesSweethearts.find(
        (s) => s.uid === company.uid
      );

      return {
        ...company,
        isEnabled: companyFlags?.isEnabled ?? false,
        isNotified: companyFlags?.isNotified ?? false,
      };
    });
  }, [companiesUnique, companiesSweethearts]);

  return companiesSweetheartsList;
};

export default useSweetheartCompanies;
