import PropTypes from 'prop-types';
import React from 'react';

import { BookmarkIcon, ThumbDownIcon } from '@heroicons/react/solid';
import { flow } from 'lodash';

const OpportunitiesBulkActions = ({
  selectedOpportunities,
  setSelectedOpportunities,
  opportunitiesOps,
  currentField,
}) => {
  const createBulkActionFlow = (action) =>
    flow([
      (e) => e.preventDefault(),
      () => opportunitiesOps[action],
      (bulkAction) => bulkAction(selectedOpportunities),
      () => setSelectedOpportunities([]),
    ]);

  return (
    <div className="hidden lg:flex fixed w-full z-50 bottom-16 justify-center items-center text-sm">
      <div className="bg-teal-500 text-white rounded-xl flex items-center divide-x">
        <p className="px-4 py-2">
          {selectedOpportunities.length} items selected
        </p>

        {currentField === 'upcoming' ? (
          <a
            href="#"
            onClick={createBulkActionFlow('bulkSave')}
            className="px-4 p-2 flex items-center space-x-1 hover:bg-teal-600 cursor-pointer font-medium"
            data-test-id="opportunities-bulkActions-save"
          >
            <BookmarkIcon className="w-4" />
            <span>Save for later</span>
          </a>
        ) : (
          <a
            href="#"
            onClick={createBulkActionFlow('bulkUnsave')}
            className="px-4 p-2 flex items-center space-x-1 hover:bg-teal-600 cursor-pointer font-medium"
            data-test-id="opportunities-bulkActions-unsave"
          >
            <BookmarkIcon className="w-4" />
            <span>Unsave</span>
          </a>
        )}
        <a
          href="#"
          onClick={createBulkActionFlow('bulkDecline')}
          className="px-4 p-2 flex items-center space-x-1 hover:bg-teal-600 cursor-pointer rounded-r-xl font-medium"
          data-test-id="opportunities-bulkActions-decline"
        >
          <ThumbDownIcon className="w-4" />
          <span>Decline</span>
        </a>
      </div>
    </div>
  );
};

OpportunitiesBulkActions.propTypes = {
  selectedOpportunities: PropTypes.array.isRequired,
  setSelectedOpportunities: PropTypes.func.isRequired,
  opportunitiesOps: PropTypes.object.isRequired,
  currentField: PropTypes.string.isRequired,
};

export default OpportunitiesBulkActions;
