import { useCallback, useEffect, useState } from 'react';

import { useFirestore, useFirestoreDocData, useFunctions } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { COMPANIES_SWEETHEARTS_COLLECTION } from 'constants/firebasePaths';
import useNotifications from 'modules/notification/useNotifications';

const useCompanySweetheart = (company = {}) => {
  const [loading, setLoading] = useState(false);
  const { showError } = useNotifications();
  const functions = useFunctions();
  const firestore = useFirestore();
  const updateJournalistsCollection = functions.httpsCallable(
    'sweetheartList-updateJournalistsCollection'
  );
  const companySweetheartRef = firestore.doc(
    `${COMPANIES_SWEETHEARTS_COLLECTION}/${company.uid}`
  );
  const { data } = useFirestoreDocData(companySweetheartRef, defaultOptions);

  const handleUpdateJournalistsCollection = useCallback(async () => {
    const { isEnabled, journalists } = data;
    if (!isEnabled) {
      return;
    }
    if (!journalists || !journalists.length) {
      setLoading(true);
      showError(
        "There's been an error fetching the Sweetheart list. Please get in touch with tom@mvpr.io to resolve this issue."
      );
    }
    try {
      await updateJournalistsCollection(company);
    } catch (error) {
      showError('Error fetching sweetheart list');
    }
    setLoading(false);
  }, [data, showError, updateJournalistsCollection, company]);

  useEffect(() => {
    handleUpdateJournalistsCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [data, loading, handleUpdateJournalistsCollection];
};

export default useCompanySweetheart;
