import PropTypes from 'prop-types';
import React from 'react';

import { Pie } from 'react-chartjs-2';

import getTealShades from 'utils/color/getTealShades';

import AnalyticsContainer from './AnalyticsContainer';
import CHART_OPTIONS from './constants/chartOptions';
import { prepareRowsForPresentation } from './helpers/reportRowsHelpers';

const AnalyticsPie = ({ reportRows, action, title }) => {
  const rows = prepareRowsForPresentation({
    reportRows,
    action,
    showPercentage: true,
    sortByField: ['count', 'name'],
  });

  const labels = rows.map((row) => row.name);

  return (
    <AnalyticsContainer
      title={title}
      loading={!reportRows}
      empty={!rows.length}
    >
      <div className="flex">
        <div className="w-1/2 py-2 px-8">
          <Pie
            options={{
              ...CHART_OPTIONS,
              scales: { x: { display: false } },
            }}
            data={{
              labels,
              datasets: [
                {
                  fill: true,
                  backgroundColor: getTealShades(),
                  borderColor: 'white',
                  data: rows.map((row) => row.count),
                },
              ],
            }}
          />
        </div>
        <div className="flex flex-col w-1/2 items-center justify-center pr-4">
          <div className="space-y-2">
            {labels.map((founder, idx) => (
              <div key={founder} className="flex items-center space-x-2">
                <div className={`bg-teal-${6 - idx}00 w-4 h-4 rounded-full`} />
                <p className="text-xs font-medium">{founder}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AnalyticsContainer>
  );
};

AnalyticsPie.propTypes = {
  reportRows: PropTypes.array,
  action: PropTypes.string,
  title: PropTypes.string,
};

export default AnalyticsPie;
