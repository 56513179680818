import React, { useCallback } from 'react';

import { useFirestore, useFunctions } from 'reactfire';

import { COMPANIES_SWEETHEARTS_COLLECTION } from 'constants/firebasePaths';

import AdminWrapper from '../AdminWrapper';
import AdminSweetheartListCompany from './AdminSweetheartListCompany/AdminSweetheartListCompany';
import useCompaniesSweethearts from './hooks/useCompaniesSweethearts';
import useCompaniesWithSweetheartList from './hooks/useCompaniesWithSweetheartList';

const AdminSweetheartListPage = () => {
  const companiesSweethearts = useCompaniesSweethearts();
  const companiesWithSweetheartList = useCompaniesWithSweetheartList();
  const firestore = useFirestore();
  const updateJournalistsCollection = useFunctions().httpsCallable(
    'sweetheartList-updateJournalistsCollection'
  );

  const toggleSweetheartList = useCallback(
    async ({ uid, name, isEnabled }) => {
      const sweetheartCompanyRef = firestore.doc(
        `${COMPANIES_SWEETHEARTS_COLLECTION}/${uid}`
      );
      await sweetheartCompanyRef.set(
        {
          isEnabled: !isEnabled,
        },
        {
          merge: true,
        }
      );
      if (isEnabled) {
        await updateJournalistsCollection({ name, uid });
      }
    },
    [firestore, updateJournalistsCollection]
  );

  const notifySweetheartList = useCallback(
    async (uid) => {
      const sweetheartCompanyRef = firestore.doc(
        `${COMPANIES_SWEETHEARTS_COLLECTION}/${uid}`
      );
      await sweetheartCompanyRef.set(
        {
          isNotified: true,
        },
        {
          merge: true,
        }
      );
    },
    [firestore]
  );

  return (
    <AdminWrapper title="Sweetheart list" currentIndex={6}>
      <div className="w-full mt-2">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 text-gray-500">
            <thead className="bg-gray-50 text-xs font-medium uppercase">
              <tr className="text-left tracking-wider">
                <th scope="col" className="px-4 py-2">
                  Company
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 text-sm">
              {companiesSweethearts.map((company, index) => (
                <AdminSweetheartListCompany
                  key={`${company.slug}-${company.owner + index}`}
                  notifySweetheartList={() => notifySweetheartList(company.uid)}
                  toggleSweetheartList={() => toggleSweetheartList(company)}
                  hasSweetheartList={companiesWithSweetheartList.includes(
                    company.name
                  )}
                  company={company}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminSweetheartListPage;
