const tealDark = '#14B8A6';

// create gradient for line graph fill
const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');
const gradient = ctx.createLinearGradient(0, 0, 0, 300);
gradient.addColorStop(0, tealDark);
gradient.addColorStop(1, 'white');

// Configuration object for the line graph datasets
const lineGraphConfig = {
  fill: true,
  backgroundColor: gradient,
  borderColor: tealDark,
  cubicInterpolationMode: 'monotone',
  tension: 0.4,
  pointStyle: 'circle',
  pointRadius: 6,
};

export default lineGraphConfig;
