import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import useUserData from 'contexts/UserContext/hooks/useUserData';
import useCompanyRefBySlug from 'hooks/useCompanyRefBySlug';

import Opportunities from './Opportunities';

const OpportunitiesAdmin = ({ slug }) => {
  const user = useUserData();
  const [companyData, setCompanyData] = useState({
    uid: null,
    name: null,
    topics: [],
  });

  const companyRef = useCompanyRefBySlug(slug);

  useEffect(
    () =>
      (async () => {
        if (!companyRef) {
          return false;
        }
        const result = await companyRef.get();
        const uid = result.id;
        const data = result.data();
        const { name, topics } = data;
        if (!companyData.uid && name && topics) {
          return setCompanyData({ uid, name, topics });
        }
        return false;
      })(),
    [companyRef, companyData.uid, user.claimsAdmin]
  );

  if (!user.claimsAdmin) {
    return "You're not authorised to view this page";
  }

  if (!companyData.uid) {
    return 'No company found.';
  }

  return <Opportunities company={companyData} />;
};

OpportunitiesAdmin.propTypes = {
  slug: PropTypes.string,
};

export default OpportunitiesAdmin;
