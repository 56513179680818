import { useEffect, useState } from 'react';

import { useFunctions } from 'reactfire';

const useCompaniesWithSweetheartList = () => {
  const functions = useFunctions();
  const getCompaniesWithSweetheartList = functions.httpsCallable(
    'sweetheartList-getCompaniesWithSweetheartList'
  );
  const [companiesWithSweetheartList, setCompaniesWithSweetheartList] =
    useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { valid, data },
      } = await getCompaniesWithSweetheartList();
      if (valid) {
        setCompaniesWithSweetheartList(data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return companiesWithSweetheartList;
};

export default useCompaniesWithSweetheartList;
