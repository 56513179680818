import { useUser } from 'reactfire';

import useEnvironment from 'contexts/EnvironmentContext/hooks/useEnvironment';
import useNotifications from 'modules/notification/useNotifications';
import sendNotification from 'modules/slack/sendNotification';

import useLogOpportunityInteraction from './useLogOpportunityInteraction';

const useOpportunitiesOps = ({
  opportunities = {},
  doc = {},
  company = {},
}) => {
  const user = useUser();
  const { showSuccess } = useNotifications();
  const logOpportunityInteraction = useLogOpportunityInteraction();

  const { isEmulated, isLocal } = useEnvironment();
  const { declined, saved, responded } = opportunities;

  return {
    // Decline an opportunity
    decline: (opportunity) => {
      const newDeclined = declined ? [...declined] : [];
      if (!newDeclined.find((existing) => existing.uid === opportunity.uid)) {
        newDeclined.push(opportunity);
      }
      logOpportunityInteraction('decline', opportunity);
      showSuccess('Opportunity declined.');
      return doc.set({ declined: newDeclined }, { merge: true });
    },
    bulkDecline: (newOpportunities) => {
      const newDeclined = declined ? [...declined] : [];
      showSuccess(`${newOpportunities.length} opportunities declined.`);
      return doc.set(
        { declined: newDeclined.concat(newOpportunities) },
        { merge: true }
      );
    },
    undecline: (opportunity) => {
      if (!declined) {
        return false;
      }
      if (declined.find((existing) => existing.uid === opportunity.uid)) {
        const newDeclined = declined.filter(
          (existing) => existing.uid !== opportunity.uid
        );
        showSuccess('Opportunity reactivated.');
        return doc.set({ declined: newDeclined }, { merge: true });
      }
      return false;
    },
    save: (opportunity) => {
      const newSaved = saved ? [...saved] : [];
      if (!newSaved.find((existing) => existing.uid === opportunity.uid)) {
        newSaved.push(opportunity);
      }
      logOpportunityInteraction('save', opportunity);
      showSuccess('Opportunity saved for later.');
      return doc.set({ saved: newSaved }, { merge: true });
    },
    bulkSave: (newOpportunities) => {
      const newSaved = saved ? [...saved] : [];
      showSuccess(`${newOpportunities.length} opportunities saved for later.`);
      return doc.set(
        { saved: newSaved.concat(newOpportunities) },
        { merge: true }
      );
    },
    unsave: (opportunity) => {
      if (!saved) {
        return false;
      }
      if (saved.find((existing) => existing.uid === opportunity.uid)) {
        const newSaved = saved.filter(
          (existing) => existing.uid !== opportunity.uid
        );
        showSuccess('Opportunity unsaved.');
        return doc.set({ saved: newSaved }, { merge: true });
      }
      return false;
    },
    bulkUnsave: (newOpportunities) => {
      const newSaved = saved ? [...saved] : [];
      showSuccess(`${newOpportunities.length} opportunities unsaved.`);
      return doc.set(
        {
          saved: newSaved.filter(
            (o) => !newOpportunities.find((n) => n.uid === o.uid)
          ),
        },
        { merge: true }
      );
    },
    respond: async (opportunity, response) => {
      if (!opportunity || !response) {
        return;
      }
      const newResponded = responded ? [...responded] : [];
      if (!newResponded.find((existing) => existing.uid === opportunity.uid)) {
        newResponded.push({ ...opportunity, response });
      }
      doc.set(
        {
          responded: newResponded,
        },
        { merge: true }
      );
      showSuccess(
        'Your response has been set. The opportunity has been moved to the "Potential for coverage" tab.'
      );

      logOpportunityInteraction('send_response', opportunity);

      // if local or emulated, don't send slack notification or log
      if (isLocal || isEmulated) {
        return;
      }

      sendNotification({
        user,
        companyName: company.name,
        companySlug: company.slug,
        opportunity,
        response,
      });
    },
    unrespond: async (opportunity) => {
      if (!opportunity) {
        return;
      }

      if (responded.find((existing) => existing.uid === opportunity.uid)) {
        const newResponded = responded.filter(
          (existing) => existing.uid !== opportunity.uid
        );
        showSuccess('Opportunity unresponded.');
        doc.set({ responded: newResponded }, { merge: true });
      }
    },
  };
};

export default useOpportunitiesOps;
