import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/lodash';
import { Link } from 'react-router-dom';
import parseUrl from 'tld-extract';

import stripProtocol from 'utils/data/stripProtocol';

// Displays a parsed URL without the protocol prefix
const UrlDomain = ({ url = '', testId = '', onClick = noop }) => {
  if (!url) {
    return null;
  }

  let urlParsed = null;
  try {
    urlParsed = url
      ? parseUrl(`https://${stripProtocol(url)}`, {
          allowUnknownTLD: true,
        })
      : '';
  } catch (e) {
    // TODO-LOG: send this to logger
  }

  if (!urlParsed?.domain) {
    return null;
  }

  return (
    <Link
      to={{ pathname: url }}
      target="_blank"
      className="block mt-1 text-sm text-teal-500 hover:underline"
      onClick={onClick}
      data-test-id={testId}
    >
      {urlParsed.domain}
    </Link>
  );
};

UrlDomain.propTypes = {
  url: PropTypes.string,
  testId: PropTypes.string,
  onClick: PropTypes.func,
};

export default UrlDomain;
