import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';
import { Controller, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import slugify from 'slugify';

import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import SeparatorTextLine from 'components/form/SeparatorTextLine';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldTagsDropdown from 'components/form/inputFields/InputFieldTagsDropdown/InputFieldTagsDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextOther from 'components/form/inputFields/InputFieldTextOther/InputFieldTextOther';
import {
  COMPANIES_COLLECTION,
  USERS_COLLECTION,
} from 'constants/firebasePaths';
import { REGEX_URL_NO_PROTOCOL } from 'constants/regex';
import { tagsVcs } from 'constants/tags';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useCompanies from 'hooks/useCompanies';
import filterCustomOptions from 'utils/data/filterCustomOptions';
import createOnChangeIsFutureDate from 'utils/date/createOnChangeIsFutureDate';
import { getDateRange } from 'utils/date/dateRange';
import getCompanyRoute from 'utils/getCompanyRoute';

import TitleSubtitle from '../TitleSubtitle';
import { resetCompanyAndUser, updateCompany, updateUser } from '../actions';
import vcImages from './constants/vcImages';

const StepFour = ({ history }) => {
  const {
    register,
    handleSubmit,
    control,
    errors,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const { uid } = useUserData();
  const hasUserData = uid;

  const { actions, state } = useStateMachine({
    resetCompanyAndUser,
    updateCompany,
    updateUser,
  });

  const firestore = useFirestore();
  const companyRef = firestore.collection(COMPANIES_COLLECTION);
  const userRef = firestore.collection(USERS_COLLECTION).doc(uid);

  const onChangeDate = createOnChangeIsFutureDate({
    date: getValues('foundedDate'),
    onError: () =>
      setError('foundedDate', { message: 'Date cannot be in the future' }),
    onSuccess: () => clearErrors(),
  });

  const { foundedDate, location, name, slug, vcs, vcsOther, website } =
    state.company;
  const customVcs = filterCustomOptions(vcs);

  const companies = useCompanies();

  const onSubmit = async () => {
    if (!uid) {
      // TODO: add error logging notification that there was an error
      // sc-81 - https://app.shortcut.com/mvpr/story/81/logging-error-handling
      return;
    }

    // update anonymous user with values from state
    userRef.set(state.user);

    // merge owner with user's uid into company
    const dataWithOwner = {
      ...state.company,
      owner: uid,
      signupStatus: 0,
    };

    // create company
    let success;
    try {
      success = await companyRef.add(dataWithOwner);
    } catch (e) {
      // TODO-LOG: send this to logger
    }

    // If company has been added, reset local state machine data, as we'll pull data from Firebase from now on
    if (success) {
      actions.resetCompanyAndUser();
      const companyRoute = getCompanyRoute(slug);
      history.push(companyRoute);
    }
  };

  const verifySlug = useCallback(
    (newSlug, newName) => {
      // check if company name or slug exists already
      if (
        companies &&
        companies.some(
          (company) => company.slug === newSlug || company.name === newName
        )
      ) {
        setTimeout(() => {
          setError('name', {
            message: 'Sorry! The company name or slug exists already.',
          });
        }, 0);
      }
    },
    [companies, setError]
  );

  const onFormChange = () => {
    const newValues = getValues();
    const newSlug = slugify(newValues.name, { lower: true, strict: true });
    setValue('slug', newSlug);
    verifySlug(newSlug, newValues.name);
    const { firstName, lastName, ...rest } = getValues();
    actions.updateUser({ firstName, lastName });
    actions.updateCompany(rest);
  };

  // verify name/slug once on page mount
  useEffect(() => {
    const values = getValues();
    verifySlug(values.slug, values.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      className="relative z-10 flex flex-col items-center pb-24"
      onSubmit={handleSubmit(onSubmit)}
      onChange={onFormChange}
      action="#"
      data-test-id="onboarding-company-form"
    >
      <div className="flex flex-col items-center w-10/12 sm:w-8/12 md:w-6/12 lg:w-4/12 mt-12 mb-8">
        <TitleSubtitle
          title="...and finally about you and your company."
          subtitle="We'll only show company information on your press page."
        />

        <div className="w-full mt-3">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            About you
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            We&apos;ll use this to create your account.
          </p>
        </div>

        {/* First Name  */}
        <div className="w-full mt-5">
          <Label htmlFor="firstName">First name*</Label>
          <InputFieldText
            ref={register({ required: 'Please type in your first name' })}
            name="firstName"
            defaultValue={state.user.firstName}
            placeholder="Type your first name..."
            data-test-id="onboarding-user-firstName"
          />

          {errors.firstName && (
            <ErrorMessage testId="onboarding-user-firstName-error">
              {errors.firstName.message}
            </ErrorMessage>
          )}
        </div>

        {/* Last Name  */}
        <div className="w-full mt-5">
          <Label htmlFor="lastName">Last name*</Label>
          <InputFieldText
            ref={register({ required: 'Please type in your last name' })}
            name="lastName"
            defaultValue={state.user.lastName}
            placeholder="Type your last name..."
            data-test-id="onboarding-user-lastName"
          />

          {errors.lastName && (
            <ErrorMessage testId="onboarding-user-lastName-error">
              {errors.lastName.message}
            </ErrorMessage>
          )}
        </div>

        <div className="w-full mt-10">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            About your company
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            We&apos;ll use this to generate your press page.
          </p>
        </div>

        {/* Company Name */}
        <div className="w-full mt-5">
          <Label htmlFor="name">Company name*</Label>
          <InputFieldText
            name="name"
            ref={register({
              required: 'Please type in the name of your company',
            })}
            defaultValue={name}
            placeholder="Type your company name here..."
            data-test-id="onboarding-company-name"
          />
          <p className="text-xs text-gray-500 mt-1">
            Your profile URL will be:{' '}
            <span className="font-semibold">
              https://mvpr.io/company/
              {slug || (
                <i className="text-gray-400">your-onboarding-company-name</i>
              )}
            </span>
          </p>
          <input type="hidden" name="slug" ref={register} defaultValue={slug} />
        </div>

        {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}

        {/* Company Website */}
        <div className="w-full mt-5">
          <Label htmlFor="website">Company website</Label>
          <InputFieldText
            name="website"
            ref={register({
              pattern: {
                value: REGEX_URL_NO_PROTOCOL,
                message: 'Please type in the website of your company',
              },
            })}
            defaultValue={website}
            placeholder="Type your company website here..."
            prefix="https://"
            data-test-id="onboarding-company-url"
          />

          {errors.website && (
            <ErrorMessage>{errors.website.message}</ErrorMessage>
          )}
        </div>

        {/* Founded Date */}
        <fieldset className="w-full mt-5">
          <Label htmlFor="foundedDate">Founded date</Label>
          <div className="flex mt-2">
            <Controller
              name="foundedDate.month"
              control={control}
              defaultValue={foundedDate.month}
              render={({ onChange, value }) => (
                <InputFieldDropdown
                  options={getDateRange('months')}
                  onChange={(val) => {
                    onChangeDate(onChange)(val);
                    onFormChange();
                  }}
                  value={value}
                  optionWidthClassName="w-44"
                  wrapperClassName="mr-2"
                  testId="onboarding-company-month"
                />
              )}
            />
            <Controller
              name="foundedDate.year"
              control={control}
              defaultValue={foundedDate.year}
              render={({ onChange, value }) => (
                <InputFieldDropdown
                  options={getDateRange('years')}
                  onChange={(val) => {
                    onChangeDate(onChange)(val);
                    onFormChange();
                  }}
                  value={value}
                  optionWidthClassName="w-36"
                  wrapperClassName="min-w-min"
                  testId="onboarding-company-year"
                />
              )}
            />
          </div>
          {errors.foundedDate && (
            <ErrorMessage>{errors.foundedDate.message}</ErrorMessage>
          )}
        </fieldset>

        {/* Location */}
        <div className="w-full mt-5">
          <Label htmlFor="location">Location*</Label>
          <InputFieldText
            name="location"
            ref={register({
              required: 'Please type in the location of your company',
            })}
            defaultValue={location}
            placeholder="Type your company location here..."
            data-test-id="onboarding-company-location"
          />

          {errors.location && (
            <ErrorMessage>{errors.location.message}</ErrorMessage>
          )}
        </div>

        {/* VCs */}
        <fieldset className="w-full mt-5">
          <Label htmlFor="vcs">Are you associated with any of these VCs?</Label>
          <div className="mt-2">
            <Controller
              name="vcs"
              control={control}
              defaultValue={vcs}
              render={({ onChange, value }) => (
                <InputFieldTagsDropdown
                  tags={tagsVcs.concat(customVcs)}
                  value={value}
                  optionWidthClassName="w-full"
                  multiplePlaceholder="Select as many as you would like"
                  // eslint-disable-next-line react/no-unstable-nested-components
                  prependButtonFn={(tag) => (
                    <img
                      src={vcImages[tag.slug]}
                      alt="VC logo"
                      className="w-4 h-4 rounded-full mr-2"
                    />
                  )}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  prependTagFn={(tag) => (
                    <img
                      src={vcImages[tag.slug]}
                      alt="VC logo"
                      className="h-8 w-8 rounded-full mr-3"
                    />
                  )}
                  onChange={(val) => {
                    onChange(val);
                    onFormChange();
                  }}
                  testId="onboarding-company-vcs"
                />
              )}
            />
          </div>

          {errors.vcs && <ErrorMessage>{errors.vcs.message}</ErrorMessage>}

          <SeparatorTextLine text="OR" />

          <InputFieldTextOther
            ref={register}
            setValue={setValue}
            onFormChange={onFormChange}
            defaultFieldName="vcs"
            defaultValue={vcsOther}
            watchedValue={vcs}
            options={tagsVcs}
            placeholder="Add other VCs here..."
            extraParams={{ slug: 'custom' }}
            data-test-id="onboarding-company-vcs-other"
          />
        </fieldset>
      </div>
      <div className="mb-8 flex items-center">
        <Button
          size="large"
          submit
          disabled={!isValid || !hasUserData}
          data-test-id="onboarding-company-submit"
          eventType="pressPage_create"
          eventProperties={{ slug: state?.company?.slug }}
        >
          Create press page
        </Button>
      </div>
    </form>
  );
};

StepFour.propTypes = {
  history: PropTypes.object,
};

export default withRouter(StepFour);
