import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';

import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import CopyToClipboardButton from 'components/buttons/CopyToClipboardButton';
import Founder from 'components/company/Founder';

import CompanyInsightAnswer from './CompanyInsight/CompanyInsightAnswer';
import CompanyInsightQuestion from './CompanyInsight/CompanyInsightQuestion';
import CompanyInsightTag from './CompanyInsight/CompanyInsightTag';

const CompanyInsightViewModal = ({
  open,
  insights = [],
  founders = [],
  currentInsightId = null,
  setCurrentInsightId,
  onClose,
}) => {
  const insight = insights.find((f) => f.uid === currentInsightId);

  if (!insight) {
    return null;
  }

  const currentIndex = insights.indexOf(insight);
  const hasNext = currentIndex + 1 < insights.length;
  const hasPrevious = currentIndex > 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <div className="flex items-center justify-center min-h-screen">
        <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
          <div className="absolute top-4 right-4 z-10">
            <CloseButton onClick={onClose} />
          </div>
          <div className="px-6 py-6 pb-0">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
              <QuestionMarkCircleIcon className="w-6" />
            </div>

            <div className="mt-3 sm:mt-5 text-left">
              <CompanyInsightTag tag={insight.tag} />
              <CompanyInsightQuestion question={insight.question} hasAnswer />
            </div>

            <div className="mt-2">
              <CompanyInsightAnswer answer={insight.answer} clamp={false} />
            </div>

            <div className="w-full flex flex-row justify-between items-center mt-4">
              <Founder
                founder={founders.find((f) => f.name === insight.founder?.name)}
                founders={founders}
                widthClassName="w-3/4 flex-shrink"
                testId={`company-insight-${insight.title}-founder`}
              />
              <CopyToClipboardButton
                copyText={insight.answer}
                moduleName="insight"
                eventType="pressPage_view"
                eventProperties={{
                  action: 'snapshot_view_copyToClipboard',
                  title: insight.question,
                  body: insight.answer,
                }}
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center bg-gray-50 mt-5 sm:mt-6 px-6 py-6">
            <Button
              type="secondary"
              onClick={() =>
                setCurrentInsightId(insights[currentIndex - 1].uid)
              }
              disabled={!hasPrevious}
              eventType="pressPage_view"
              eventProperties={{
                action: 'snapshot_view',
                title: insights[currentIndex - 1]?.question,
                body: insights[currentIndex - 1]?.answer,
              }}
            >
              Previous
            </Button>

            <p className="font-normal text-gray-400">
              {currentIndex + 1} of {insights.length}{' '}
              {insights.length === 1 ? 'Insight' : 'Insights'}
            </p>

            <Button
              type="secondary"
              onClick={() =>
                setCurrentInsightId(insights[currentIndex + 1].uid)
              }
              disabled={!hasNext}
              eventType="pressPage_view"
              eventProperties={{
                action: 'snapshot_view',
                title: insights[currentIndex + 1]?.question,
                body: insights[currentIndex + 1]?.answer,
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

CompanyInsightViewModal.propTypes = {
  open: PropTypes.bool,
  insights: PropTypes.array,
  founders: PropTypes.array,
  currentInsightId: PropTypes.string,
  setCurrentInsightId: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompanyInsightViewModal;
