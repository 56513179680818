import React, { useEffect, useState } from 'react';

import firebaseApp from 'firebase/app';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useAuth, useFunctions } from 'reactfire';

import PageWrapper from 'components/PageWrapper';
import Spinner from 'components/Spinner';
import { VC_COMPANIES_PATH } from 'constants/paths';
import useRedirectAuth from 'hooks/useRedirectAuth';
import useNotifications from 'modules/notification/useNotifications';

import SignupVcForm from './SignupVcForm';

const TIMEOUT_BEFORE_REDIRECT = 10000;

const SignupPage = () => {
  const [loading, setLoading] = useState(false);

  const functions = useFunctions();
  const onRegisterVc = functions.httpsCallable('auth-onRegisterVc');

  const auth = useAuth();
  const { showError } = useNotifications();

  const showAuth = useRedirectAuth(true);

  const history = useHistory();

  useEffect(() => {
    if (showAuth) {
      return false;
    }
    const timeout = setTimeout(() => {
      history.push(VC_COMPANIES_PATH);
    }, TIMEOUT_BEFORE_REDIRECT);
    return () => {
      clearTimeout(timeout);
    };
  }, [showAuth, history]);

  if (loading || !showAuth) {
    return <Spinner />;
  }

  const signupWithEmail = async (formValues) => {
    setLoading(true);
    let authResponse = null;

    try {
      authResponse = await auth.createUserWithEmailAndPassword(
        formValues.email,
        formValues.password
      );
    } catch (err) {
      showError(err.message);
      setLoading(false);
      return;
    }

    if (!authResponse.user) {
      return;
    }

    let cloudFnResponse = null;

    // call cloud function to assign custom claim to VC
    try {
      cloudFnResponse = await onRegisterVc({
        uid: authResponse.user.uid,
        token: formValues.token,
      });
    } catch (err) {
      // TODO-LOG: send this to logger
    }

    if (!cloudFnResponse?.data?.valid) {
      showError(
        'Looks like there was a token error. Please contact tom@mvpr.io to resolve the issue.'
      );
      await authResponse.user.delete();
      return;
    }

    const credentials = firebaseApp.auth.EmailAuthProvider.credential(
      formValues.email,
      formValues.password
    );

    let reauthenticateResponse = null;

    try {
      reauthenticateResponse = await authResponse.user.reauthenticateWithCredential(
        credentials
      );
    } catch (err) {
      // TODO-LOG: send this to logger
    }

    // redirect to company index
    const token = await reauthenticateResponse.user.getIdTokenResult();

    if (token.claims.vc) {
      window.location = VC_COMPANIES_PATH;
    }
  };

  return (
    <PageWrapper>
      <Helmet>
        <title>Signup as VC | MVPR</title>
      </Helmet>
      <SignupVcForm onSubmit={signupWithEmail} />
    </PageWrapper>
  );
};

export default SignupPage;
