import PropTypes from 'prop-types';
import React from 'react';

import { CreditCardIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

import StripeRedirectModal from 'components/StripeRedirectModal';
import Button from 'components/buttons/Button';
import { SETTINGS_COMPANY_PATH } from 'constants/paths';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useRedirectToStripePortal from 'hooks/useRedirectToStripePortal';

const Aside = ({ currentSection }) => {
  const { claimsVc } = useUserData();

  const { isRedirecting, redirectToStripePortal } = useRedirectToStripePortal();

  return (
    <>
      <div className="flex items-center col-span-12 mr-0 sm:mr-4 md:mr-12">
        <aside className="bg-white shadow border flex w-screen sm:rounded-md sm:w-max">
          <Link
            to="/settings/user"
            className={`${
              currentSection === 'user'
                ? 'text-teal-500 border-b-2 border-teal-500 pb-1'
                : 'text-gray-400 pb-2'
            } hover:text-teal-500 mr-4 mt-2 ml-4`}
          >
            User
          </Link>

          {!claimsVc && (
            <Link
              to={SETTINGS_COMPANY_PATH}
              className={`${
                currentSection === 'company'
                  ? 'text-teal-500 border-b-2 border-teal-500 pb-1'
                  : 'text-gray-400 pb-2'
              } hover:text-teal-500 mt-2 mr-4`}
            >
              Company
            </Link>
          )}
        </aside>
        <Button
          onClick={redirectToStripePortal}
          className="border-none border-b sm:border border-gray-100 shadow-md sm:shadow rounded-l-none sm:rounded-l-md rounded-r-none sm:rounded-r-md ml-auto py-2.5 space-x-1"
          type="secondary"
        >
          <CreditCardIcon className="w-4" />
          <span>Manage Payments</span>
        </Button>
      </div>

      <StripeRedirectModal isRedirecting={isRedirecting} />
    </>
  );
};

Aside.propTypes = {
  currentSection: PropTypes.string.isRequired,
};

export default Aside;
