import { ADMIN_OPPORTUNITIES_NOTIFICATIONS_PATH as path } from 'constants/paths';

import AdminOpportunitiesNotificationsPage from './AdminOpportunitiesNotificationsPage';

const AdminOpportunitiesNotificationsRoute = {
  path,
  authRequired: true,
  claimsRequired: { admin: true },
  component: AdminOpportunitiesNotificationsPage,
};

export default AdminOpportunitiesNotificationsRoute;
