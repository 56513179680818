import useCrud from 'hooks/useCrud';

const useCompanyCruds = (ref) => {
  // TODO: use constants for these sub-collections
  const cruds = {
    achievements: useCrud(ref.collection('achievements')),
    videos: useCrud(ref.collection('videos')),
    articles: useCrud(ref.collection('articles')),
    insights: useCrud(ref.collection('insights')),
    quotes: useCrud(ref.collection('comments')),
    pressReleases: useCrud(ref.collection('pressReleases')),
    downloads: useCrud(ref.collection('downloads')),
    founders: useCrud(ref.collection('founders')),
  };

  let loading = false;

  // if any of the collections is loading, return null
  Object.keys(cruds).forEach((key) => {
    if (cruds[key].status === 'loading') {
      loading = true;
    }
  });

  return loading ? null : cruds;
};

export default useCompanyCruds;
