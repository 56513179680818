import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';

const NOT_SET = '(not set)';
const mapNotSet = (o) => o.value !== NOT_SET && o.value;

// extracts the report rows where the action parameter (dimensionValues[2]) equals the value in 'field'
const getRowsForAction = (rows, field) =>
  rows ? rows.filter((row) => row.dimensionValues[2].value === field) : [];

// returns the total amount of the first metric ([0], e.g. eventCount) for an array of rows
const getMetricsCount = (rows) =>
  rows.reduce((acc, cur) => acc + parseInt(cur.metricValues[0].value, 10), 0);

// returns an object with the relevant values for an Analytics list view: count, name, title, type
const createMapRow =
  (totalMetricsCount, showPercentage) =>
  ({ metricValues, dimensionValues }) => {
    const metrics = metricValues.map(mapNotSet);
    const dimensions = dimensionValues.map(mapNotSet);
    return {
      count: showPercentage
        ? (100 / totalMetricsCount) * metrics[0]
        : parseInt(metrics[0], 10),
      name: dimensions[2],
      title: dimensions[4] === false ? NOT_SET : dimensions[4],
      type: dimensions[6] !== NOT_SET ? dimensions[6] : null,
    };
  };

// group rows by title and type to be able to merge them in the next step
// since groupBy outputs an object and we only want the value, unwrap using `values`
const groupRows = (rows) =>
  rows ? values(groupBy(rows, (row) => `${row.title}-${row.type}`)) : [];

// each row represents an grouped array of the same item which needs to be accumulated
// thus, for each row we return the reduced `items` and add up the count of all items
const mergeRows = (rows) =>
  rows.map((items) => ({
    ...items.reduce(
      (acc, cur) => ({
        ...(acc || cur),
        count: acc ? acc.count + cur.count : cur.count,
      }),
      undefined
    ),
  }));

// run report rows through a couple of steps to create a simple, presentable array of analytics data
// action is the field that defines which dimension to extract data for,
// showPercentage turns absolute values into percentages
const prepareRowsForPresentation = ({
  reportRows = [],
  action = '',
  showPercentage = false,
  sortByFields = [],
}) => {
  const rows = getRowsForAction(reportRows, action);
  const mapRow = createMapRow(getMetricsCount(rows), showPercentage);

  // pull rows through all of the above helper functions to get the desired result
  const rowsGrouped = groupRows(rows.map(mapRow));
  const rowsGroupedMerged = mergeRows(rowsGrouped);
  const rowsGroupMergedSorted = sortBy(rowsGroupedMerged, sortByFields);
  const rowsGroupedMergedSortedReversed = rowsGroupMergedSorted.reverse();
  const rowsGroupedMergedSortedReversedSliced =
    rowsGroupedMergedSortedReversed.slice(0, 6);
  return rowsGroupedMergedSortedReversedSliced;
};

export { getRowsForAction, prepareRowsForPresentation };
