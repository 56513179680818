import PropTypes from 'prop-types';
import React from 'react';

import extensionsIcons from './config/extensionIcons';

const ExtensionIcon = ({ className = '', type = 'DOC' }) => {
  const { paths, x } = extensionsIcons.find(
    (item) => item.type.toLocaleLowerCase() === type.toLocaleLowerCase()
  );
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-6 w-6 ${className}`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M7,21L17,21C18.097,21 19,20.097 19,19L19,9.414C19,9.149 18.894,8.894 18.707,8.707L13.293,3.293C13.106,3.106 12.851,3 12.586,3L7,3C5.903,3 5,3.903 5,5L5,19C5,20.097 5.903,21 7,21Z"
      />
      <rect
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        fill="currentColor"
        x="4"
        y="11"
        width="12px"
        height="6px"
      />
      <g transform={`translate(${x || 1} 5) scale(0.75)`}>
        {paths.map((letter) => (
          <path key={letter} fill="white" stroke="none" d={letter} />
        ))}
      </g>
    </svg>
  );
};

ExtensionIcon.propTypes = {
  type: PropTypes.oneOf(['DOC', 'JPG', 'PNG', 'GIF', 'PDF']),
  className: PropTypes.string,
};

export default ExtensionIcon;
