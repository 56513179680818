import React, { useState } from 'react';

import { CalendarIcon } from '@heroicons/react/solid';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import OpportunitiesFilter from 'components/opportunities/OpportunitiesFilter/OpportunitiesFilter';
import OpportunitiesSort from 'components/opportunities/OpportunitiesSort/OpportunitiesSort';
import filterOpportunities from 'components/opportunities/helpers/filterOpportunities';
import sortOpportunities from 'components/opportunities/helpers/sortOpportunities';
import defaultOptions from 'constants/defaultOptions';
import {
  CLASSIFIED_OPPORTUNITIES_COLLECTION,
  OPPORTUNITIES_NOTIFICATIONS,
} from 'constants/firebasePaths';
import { filterOptions, sortOptions } from 'constants/opportunitiesOptions';
import useMergeCompaniesIntoOpportunities from 'hooks/useMergeCompaniesIntoOpportunities';

import AdminWrapper from '../AdminWrapper';
import AdminOpportunitiesNotificationsOpportunity from './AdminOpportunitiesNotificationsOpportunity';

const date = new Date();

const sortOptionsNone = sortOptions.slice(2, 3);
const sortOptionsMostRecent = {
  name: 'mostRecent',
  label: 'Most recent',
  Icon: CalendarIcon,
};
const sortOptionsAdmin = sortOptions
  .slice(0, 2)
  .concat(sortOptionsMostRecent, sortOptionsNone);

const AdminOpportunitiesNotificationsPage = () => {
  const firestore = useFirestore();

  // filter, sort
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState(sortOptionsMostRecent);
  const [sortDir, setSortDir] = useState(-1);

  // get classified opportunities
  const opportunitiesCollection = firestore
    .collection(CLASSIFIED_OPPORTUNITIES_COLLECTION)
    .where('deadlineUTC', '>', date);

  const { data } = useFirestoreCollectionData(
    opportunitiesCollection,
    defaultOptions
  );

  // opportunities notifications
  const opportunitiesNotificationsCollection = firestore.collection(
    OPPORTUNITIES_NOTIFICATIONS
  );

  const { data: notificationsData } = useFirestoreCollectionData(
    opportunitiesNotificationsCollection,
    defaultOptions
  );

  let opportunities = useMergeCompaniesIntoOpportunities(data);

  if (filter.length) {
    opportunities = filterOpportunities(opportunities, filter);
  }

  if (sort !== null) {
    opportunities = sortOpportunities(opportunities, { sort, sortDir });
  }

  const toggleCompany = async (opportunityId, companyId) => {
    const notificationRef =
      opportunitiesNotificationsCollection.doc(opportunityId);

    const notification = notificationsData.find((n) => n.uid === opportunityId);

    // create new array
    if (!notification) {
      await notificationRef.set({ companiesToNotify: [companyId] });
    }

    let companiesToNotify = notification.companiesToNotify
      ? [...notification.companiesToNotify]
      : [];

    if (notification?.companiesToNotify?.includes(companyId)) {
      companiesToNotify = companiesToNotify.filter((c) => c !== companyId);
    } else {
      companiesToNotify.push(companyId);
    }

    await notificationRef.update(
      {
        companiesToNotify,
      },
      { merge: true }
    );
  };

  return (
    <AdminWrapper title="Opportunities notifications" currentIndex={5}>
      <p className="mt-2">
        The second column lists the currently assigned topics, the third column
        the core topics. By clicking on an assigned topic, you can remove it. By
        clicking on a core topic, add it to the opportunity .
      </p>

      <div className="flex mt-4 justify-between">
        <div className="flex space-x-2">
          <OpportunitiesFilter
            filterOptions={filterOptions}
            setFilter={setFilter}
            filter={filter}
          />
          <OpportunitiesSort
            sortOptions={sortOptionsAdmin}
            setSort={setSort}
            sort={sort}
            sortDir={sortDir}
            setSortDir={setSortDir}
          />
        </div>
      </div>

      <div className="mt-4">
        {opportunities.map((opportunity) => (
          <AdminOpportunitiesNotificationsOpportunity
            key={opportunity.id}
            opportunity={opportunity}
            onToggleCompany={(companyId) =>
              toggleCompany(opportunity.uid, companyId)
            }
            notificationsData={notificationsData.find(
              (n) => n.uid === opportunity.uid
            )}
          />
        ))}
      </div>
    </AdminWrapper>
  );
};

export default AdminOpportunitiesNotificationsPage;
