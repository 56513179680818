import PropTypes from 'prop-types';
import React from 'react';

import { useIdTokenResult } from 'reactfire';

const Claims = ({ user = {}, children = null }) => {
  const token = useIdTokenResult(user, true);
  const claimsVc = token?.data?.claims?.vc ?? null;
  const claimsAdmin = token?.data?.claims?.admin ?? null;
  return children({ claimsVc, claimsAdmin });
};

const ClaimsWrapper = ({ user = {}, hasAuth = false, children = null }) => {
  if (hasAuth) {
    return <Claims user={user}>{children}</Claims>;
  }
  return children({});
};

ClaimsWrapper.propTypes = {
  user: PropTypes.object,
  hasAuth: PropTypes.bool,
  children: PropTypes.elementType,
};

export default ClaimsWrapper;
