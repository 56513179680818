import React from 'react';

import { capitalize } from 'lodash';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import PageWrapper from 'components/PageWrapper';
import useRedirectSettings from 'hooks/useRedirectSettings';

import CompanySettings from './CompanySettings/CompanySettings';
import SettingsAside from './SettingsAside';
import UserSettings from './UserSettings/UserSettings';

const SettingsPage = () => {
  const { section } = useParams();

  const showSettings = useRedirectSettings();

  if (!showSettings) {
    return null;
  }

  const title = capitalize(section);

  return (
    <PageWrapper>
      <Helmet>
        <title>{`${title} settings | MVPR`}</title>
      </Helmet>
      <SettingsAside currentSection={section} />
      {section === 'user' && <UserSettings />}
      {section === 'company' && <CompanySettings />}
    </PageWrapper>
  );
};

export default SettingsPage;
