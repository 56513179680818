import PropTypes from 'prop-types';
import React from 'react';

import { CalendarIcon, ClockIcon } from '@heroicons/react/solid';
import { capitalize } from 'lodash';
import { useFirestore, useFirestoreDocData } from 'reactfire';

import DomainAuthority from 'components/DomainAuthority/DomainAuthority';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';
import OpportunityMeta from 'components/opportunities/opportunity/OpportunityMeta';
import OpportunityQuery from 'components/opportunities/opportunity/OpportunityQuery';
import getDeadline from 'components/opportunities/opportunity/helpers/getDeadline';
import getTimeLeft from 'components/opportunities/opportunity/helpers/getTimeLeft';
import { CLASSIFIED_OPPORTUNITIES_COLLECTION } from 'constants/firebasePaths';
import { tagsTopics } from 'constants/tags';

import AdminOpportunityTag from './AdminOpportunityTag';

const AdminOpportunity = ({ opportunity = {} }) => {
  const {
    custom,
    date,
    deadlineUTC,
    domainAuthority,
    enquirySummary,
    journalist,
    mediaOutlet,
    topics,
    query,
  } = opportunity;

  const firestore = useFirestore();
  const ref = firestore.doc(
    `${CLASSIFIED_OPPORTUNITIES_COLLECTION}/${opportunity.uid}`
  );
  const { data } = useFirestoreDocData(ref);

  const onTopicAdd = async (topic) => {
    await ref.update({ topics: [...data.topics, topic] });
  };

  const onTopicRemove = async (topic) => {
    await ref.update({
      topics: data.topics.filter((t) => t.id !== topic.id),
    });
  };

  const [deadlineDiff, deadlineUnits] = getDeadline(deadlineUTC);
  const timeLeft = getTimeLeft(deadlineDiff, deadlineUnits, deadlineUTC);

  return (
    <div className="flex flex-row items-stretch mt-2 space-x-2">
      <a
        className="flex flex-none flex-col justify-start relative p-4 pt-2 md:rounded-md md:border border-white max-w-prose bg-white"
        onClick={() => {}}
      >
        <p className="mt-2 inline-flex self-start items-center text-xs space-x-2 text-gray-500 font-semibold">
          <CalendarIcon className="w-4" />
          <span>{date.toDate().toLocaleString()}</span>
        </p>

        <div className="flex justify-between mt-2">
          <h3 className="text-2xl font-semibold">
            {capitalize(enquirySummary)}
          </h3>
        </div>

        <div className="mt-3 flex items-center space-x-2">
          <PublicationImage name={mediaOutlet} custom={custom} />
          <OpportunityMeta text={mediaOutlet} />
          <DomainAuthority domainAuthority={domainAuthority} />
          <div className="w-1" />
          <JournalistImage name={journalist} />
          <OpportunityMeta text={journalist} />
        </div>

        <OpportunityQuery query={query} collapsed />

        <p className="mt-4 inline-flex self-start items-center text-xs py-1 px-2 pr-3 space-x-2 text-gray-400 bg-opacity-40 font-semibold rounded-md bg-gray-200">
          <ClockIcon className="w-4" />
          <span>{timeLeft}</span>
        </p>
      </a>
      <div className="bg-white p-4 w-1/4">
        {topics
          .filter((t) => Number.isInteger(t.id))
          .map((topic) => (
            <AdminOpportunityTag
              key={topic?.id}
              topic={topic}
              onClick={() => onTopicRemove(topic)}
            />
          ))}
      </div>
      <div className="bg-white p-4 w-1/4">
        {tagsTopics
          .filter((t) => t.core && !topics.find((topic) => topic.id === t.id))
          .map((topic) => (
            <AdminOpportunityTag
              key={topic?.id}
              topic={topic}
              onClick={() => onTopicAdd(topic)}
            />
          ))}
      </div>
    </div>
  );
};

AdminOpportunity.propTypes = {
  opportunity: PropTypes.object,
};

export default AdminOpportunity;
