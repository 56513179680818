import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import { tagsTopics } from 'constants/tags';
import getTagsClassNames from 'utils/color/getTagsClassNames';

/**
 * Displays an opportunity's topics
 * Prepends the topic with a blue tag if the opportunity is custom (featured by MVPR)
 */
const OpportunityTopics = ({
  topics = [],
  selectedTopics = [],
  custom = false,
}) => (
  <div>
    {custom && (
      <span className="inline-block mt-2 mr-2 bg-blue-500 text-white px-2 pr-3 py-1 rounded-md text-xs font-semibold">
        ✨ Featured by MVPR
      </span>
    )}

    {topics.map((topic) => {
      const [bgColor, textColor, borderColor] = getTagsClassNames(
        tagsTopics.findIndex((t) => topic.name === t.name),
        topic.name?.length
      );

      const isSelected = !!selectedTopics.find((t) => t.name === topic.name);

      return (
        <span
          key={topic.name}
          className={classNames(
            'inline-block mr-2 mt-2 p-2 py-1 text-xs rounded-md',
            bgColor,
            textColor,
            {
              [`border border-dashed ${borderColor}`]: isSelected,
              'border border-white': !isSelected,
            }
          )}
        >
          {topic.name}
        </span>
      );
    })}
  </div>
);

OpportunityTopics.propTypes = {
  topics: PropTypes.array.isRequired,
  selectedTopics: PropTypes.array,
  custom: PropTypes.bool,
};

export default OpportunityTopics;
