import PropTypes from 'prop-types';
import React from 'react';

import {
  StarIcon,
  PaperAirplaneIcon,
  HeartIcon,
} from '@heroicons/react/outline';
import { ChartPieIcon } from '@heroicons/react/solid';
import { useRemoteConfigString } from 'reactfire';

import useUserData from 'contexts/UserContext/hooks/useUserData';
import useNavigationLinks from 'hooks/useNavigationLinks';

import AsideLink from './AsideLink';

/**
 * Aside navigation with options for logged in users:
 * - press page
 * - opportunities
 * - analytics (only shows the analytics link when enabled via remote config)
 * - sweetheart-list
 */
const Aside = ({ current = '', slug = null }) => {
  const [linkPressPage, linkOpportunities, linkAnalytics, linkSweethearts] =
    useNavigationLinks();

  const user = useUserData();
  const isAdmin = user.claimsAdmin;

  const { data } = useRemoteConfigString('analyticsEnabled');
  const analyticsEnabledCompanies = data
    .split([','])
    .map((company) => company.trim());

  const analyticsEnabled =
    isAdmin || (slug && analyticsEnabledCompanies.includes(slug));

  return (
    <aside
      className="hidden sm:flex absolute mt-16 left-0 min-h-screen"
      aria-label="Sidebar"
    >
      <div className="bg-white pt-2 space-y-1 border-r border-gray-300">
        <AsideLink
          link={linkPressPage}
          isSelected={current === linkPressPage}
          linkName="Press Page"
          testId="aside-pressPage"
        >
          <StarIcon className="h-6 w-6" />
        </AsideLink>

        <AsideLink
          link={linkOpportunities}
          isSelected={current === linkOpportunities}
          linkName="Media Opportunities"
          testId="aside-opportunities"
        >
          <PaperAirplaneIcon className="h-6 w-6 p-px" />
        </AsideLink>

        {linkAnalytics && analyticsEnabled && (
          <AsideLink
            link={linkAnalytics}
            isSelected={current === linkAnalytics}
            linkName="Analytics"
          >
            <ChartPieIcon className="h-6 w-6 p-px" />
          </AsideLink>
        )}
        <AsideLink
          link={linkSweethearts}
          isSelected={current === linkSweethearts}
          linkName="Sweetheart List"
          testId="aside-sweetheart"
        >
          <HeartIcon className="h-6 w-6 p-px" />
        </AsideLink>
      </div>
    </aside>
  );
};

Aside.propTypes = {
  current: PropTypes.string.isRequired,
  slug: PropTypes.string,
};

export default Aside;
