import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/lodash';

import { tagsTopics } from 'constants/tags';
import getTagsClassNames from 'utils/color/getTagsClassNames';

// Displays a topic inside the opportunities topics modal
// Adds a dashed border and the topic colour if selected, otherwise the topic is greyed out
const OpportunitiesTopicsModalTopic = ({
  topic = {},
  isSelected = false,
  onClick = noop,
}) => (
  <button
    key={topic.id}
    className={classNames(
      'inline-block mr-2 mt-2 p-2 py-1 text-xs rounded-md focus:outline-none opacity-70 border',
      isSelected &&
        getTagsClassNames(
          tagsTopics.findIndex((t) => t.name === topic.name),
          topic.name?.length
        ),
      {
        'opacity-100 border-dashed': isSelected,
        'bg-gray-100 border-white': !isSelected,
      }
    )}
    type="button"
    onClick={onClick}
    data-test-id={`opportunities-navBar-topicsModal-tags-${topic.name}`}
  >
    {topic.name}
  </button>
);

OpportunitiesTopicsModalTopic.propTypes = {
  topic: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default OpportunitiesTopicsModalTopic;
