import PropTypes from 'prop-types';
import React from 'react';

import CloseButton from 'components/buttons/CloseButton';

// Renders content in a modal overlay
// TODO-MODAL: this has to be refactored as it should use Headless's Dialog component instead (which is a lot more accessible)
const Modal = ({
  children,
  onClose,
  widthClass = 'sm:max-w-xl',
  testId = '',
}) => (
  <div
    className="fixed z-50 inset-0 overflow-y-auto"
    role="dialog"
    aria-modal="true"
  >
    <div
      className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"
    />
    <div
      className="flex items-top items-center justify-center text-center"
      data-test-id={testId}
    >
      {/* Hack to center modal vertically */}
      <span className="inline-block align-middle h-screen" aria-hidden="true">
        &#8203;
      </span>

      <div
        className={`relative inline-block bg-white rounded-lg text-left shadow-xl transition-all w-full mx-4 ${widthClass}`}
      >
        <div className="absolute top-4 right-4 z-10">
          <CloseButton
            onClick={() => onClose()}
            testId="modal-mediaOpportunity-close"
          />
        </div>
        {children}
      </div>
    </div>
  </div>
);

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  widthClass: PropTypes.string,
  testId: PropTypes.string,
};

export default Modal;
