import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { PlusIcon } from '@heroicons/react/solid';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import EmptyState from 'components/company/EmptyState';
import SectionHeaderWrapper from 'components/company/SectionHeaderWrapper';
import SectionHeadline from 'components/company/SectionHeadline';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';

import CompanyQuote from './CompanyQuote/CompanyQuote';
import CompanyQuoteEditModal from './CompanyQuoteEditModal';
import CompanyQuoteViewModal from './CompanyQuoteViewModal';

const CompanyQuotes = ({
  crud,
  founders,
  showOwnerView = false,
  sectionRef,
}) => {
  const [, quotesRaw, ops] = crud;
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [currentViewQuoteId, setCurrentViewQuoteId] = useState(null);

  // migrate from press page v1 -> v2
  const quotes = quotesRaw
    .map((quote) => ({
      ...quote,
      founder: quote?.users?.[0]
        ? { name: quote?.users?.[0].name }
        : quote.founder,
    }))
    .filter((quote) => showOwnerView || !quote.private);

  const isEmpty = quotes.length < 1;
  const isEmptyAndOwnerview = showOwnerView && isEmpty;

  // If there are no insights yet, hide this section for journalists
  if (!showOwnerView && isEmpty) {
    return null;
  }

  const onSubmit = (comment) => {
    const date = createTimestampFromDateObj(comment.date);
    ops.createDoc({
      title: comment.title,
      body: comment.body,
      tags: comment.tags,
      founder: comment.founder,
      private: comment.private,
      date,
    });
    setIsAddModalOpen(false);
  };

  const onView = (uid) => {
    setCurrentViewQuoteId(uid);
    setIsViewModalOpen(true);
  };

  return (
    <>
      <div data-test-id="company-quote-bank" id="quote-bank" ref={sectionRef}>
        <SectionHeaderWrapper>
          <SectionHeadline
            light
            testId="company-quote-bank-headline"
            showEmptySectionHint={isEmptyAndOwnerview}
          >
            Quote bank
          </SectionHeadline>

          {showOwnerView && (
            <DotsMenu
              options={[
                {
                  label: 'Add quote',
                  icon: <PlusIcon className="w-4 h-4" />,
                  onClick: () => setIsAddModalOpen(true),
                  testId: 'company-quotes-menu-add',
                },
              ]}
              theme="dark"
              testId="company-quotes-menu"
            />
          )}
        </SectionHeaderWrapper>

        <div className="w-full mt-4">
          <ul
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4 gap-6 items-stretch"
            data-test-id="company-quotes-list"
          >
            {quotes.length ? (
              quotes.map((quote) => (
                <CompanyQuote
                  key={quote.title}
                  quote={quote}
                  showOwnerView={showOwnerView}
                  ops={ops}
                  founders={founders}
                  onView={onView}
                />
              ))
            ) : (
              <EmptyState
                title="Add a quote"
                description="This will help journalists understand how your founders and/or team think."
                onClick={() => setIsAddModalOpen(true)}
                testId="company-quotes-addFirst"
              />
            )}
          </ul>
        </div>
      </div>

      {showOwnerView && (
        <CompanyQuoteEditModal
          open={isAddModalOpen}
          founders={founders}
          onClose={() => setIsAddModalOpen(false)}
          onSubmit={onSubmit}
        />
      )}

      <CompanyQuoteViewModal
        open={isViewModalOpen}
        quotes={quotes}
        founders={founders}
        currentQuoteId={currentViewQuoteId}
        setCurrentQuoteId={setCurrentViewQuoteId}
        onClose={() => setIsViewModalOpen(false)}
      />
    </>
  );
};

CompanyQuotes.propTypes = {
  crud: PropTypes.array.isRequired,
  founders: PropTypes.array.isRequired,
  showOwnerView: PropTypes.bool.isRequired,
  sectionRef: PropTypes.object,
};

export default CompanyQuotes;
