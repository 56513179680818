import React from 'react';

const CompaniesListEmptyState = () => (
  <div className="bg-white p-4 text-center m-2 ml-0">
    <h3 className="font-semibold">No companies found</h3>
    <p className="mt-2">Looks like there aren&apos;t any companies.</p>
  </div>
);

export default CompaniesListEmptyState;
