import PropTypes from 'prop-types';
import React from 'react';

import { Menu } from '@headlessui/react';
import { PencilIcon } from '@heroicons/react/outline';

const DotsMenuOption = ({ option }) => (
  <Menu.Item key={option.label}>
    {({ active }) => (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          option.onClick();
        }}
        className={`${
          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
        } flex items-center space-x-2 px-4 py-2 text-sm`}
        data-test-id={option.testId || ''}
      >
        {option.icon || <PencilIcon className="w-4 h-4" />}
        <span>{option.label || ''}</span>
      </a>
    )}
  </Menu.Item>
);

DotsMenuOption.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.node,
    testId: PropTypes.string,
  }),
};

export default DotsMenuOption;
