import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import { TOPICS_MAPPINGS } from 'constants/firebasePaths';
import { tagsTopics } from 'constants/tags';

const useCompanyTopics = (companyTopics) => {
  const firestore = useFirestore();
  const collection = firestore.collection(TOPICS_MAPPINGS);
  const { data: mappings } = useFirestoreCollectionData(
    collection,
    defaultOptions
  );

  const mappedTopics = [];
  companyTopics.forEach((topic) => {
    const match = mappings.find((m) => m.id === topic.id);
    if (match) {
      mappedTopics.push({
        id: match.mapsTo,
        name: topic.name,
      });
    }
  });

  const coreTopics = tagsTopics.filter(
    (t) => !companyTopics.find((topic) => t.id === topic.id)
  );

  return [...coreTopics, ...companyTopics, ...mappedTopics];
};

export default useCompanyTopics;
