import PropTypes from 'prop-types';
import React, { useState } from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';
import { useDropzone } from 'react-dropzone';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import { presetImage } from 'constants/uploadPresets';

import DefaultDropzone from './Dropzone';
import { isLocalFile, isRemoteFile } from './helpers/fileHelpers';
import getFileComponent from './helpers/getFileComponent';
import useOnDrop from './hooks/useOnDrop';

/**
 * Upload component that provides a Dropzone for uploading files.
 * Use local files first, even before file has been uploaded.
 * Accepts a renderFile function to customise the file display (by default: UniversalImage)
 * Passing in a preset allows us to control what kind of file we expect
 */
const InputFieldUpload = ({
  name = '',
  onChange = noop,
  value = null,
  setError = noop,
  renderFile = (file) => <UniversalImage image={file} heightClass="h-40" />,
  Dropzone = DefaultDropzone,
  preset = presetImage,
  folder = '',
  baseClassName = 'w-full flex justify-center rounded-md relative',
  heightClassName = 'h-40',
  emptyClassName = '',
  testId = '',
}) => {
  // local file path in the browser
  const [localFile, setLocalFile] = useState({
    path: null,
    isLocal: true,
    isUploading: false,
  });

  // convenience method for setting errors
  const setErr = (message) => setError(name, { message });

  // upload/drop functionality is in this hook
  const onDrop = useOnDrop({
    onChange,
    setError: setErr,
    setLocalFile,
    preset,
    folder,
  });

  // default dropzone functionality
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const isLocal = isLocalFile(localFile);
  const isRemote = isRemoteFile(value);
  const isPlaceholder = !isLocal && !isRemote;

  // allows removing the existing local file by resetting it
  const onChangeFile = (val) => {
    onChange(val);
    setLocalFile((state) => ({ ...state, path: null }));
  };

  // returns the right file component based on current file props
  const fileComponent = getFileComponent({
    value,
    isLocal,
    localFile,
    renderFile,
    onChangeFile,
  });

  return (
    <div
      {...getRootProps()}
      className={classNames(baseClassName, heightClassName, {
        'border-2 border-gray-300 border-dashed': isPlaceholder,
        [emptyClassName]: isPlaceholder,
      })}
      data-test-id={testId}
    >
      {fileComponent || (
        <Dropzone
          name={name}
          allowedFormatsText={preset.allowedFormatsText}
          getInputProps={getInputProps}
          isDragActive={isDragActive}
        />
      )}
    </div>
  );
};

InputFieldUpload.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.string,
  }),
  setError: PropTypes.func.isRequired,
  renderFile: PropTypes.func,
  Dropzone: PropTypes.elementType,
  preset: PropTypes.shape({
    allowedFormats: PropTypes.array,
    allowedFormatsErrorText: PropTypes.string,
    allowedFormatsText: PropTypes.string,
    maxFilesize: PropTypes.number,
    maxFilesizeErrorText: PropTypes.string,
    storageRef: PropTypes.string,
  }),
  folder: PropTypes.string.isRequired,
  baseClassName: PropTypes.string,
  heightClassName: PropTypes.string,
  emptyClassName: PropTypes.string,
  testId: PropTypes.string,
};

export default InputFieldUpload;
