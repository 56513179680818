import firebase from 'firebase/app';
import { useAuth } from 'reactfire';

import 'firebase/auth';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useNotifications from 'modules/notification/useNotifications';

// sign up a user, converting anonymous users into authenticated ones
const useSignup = () => {
  const auth = useAuth();

  const { showError } = useNotifications();
  const userContext = useUserData();

  return async (formValues, e) => {
    e.preventDefault();
    e.stopPropagation();
    let res = null;

    // if anonymous user, link account
    if (userContext.isAnonymous) {
      try {
        const credentials = firebase.auth.EmailAuthProvider.credential(
          formValues.email,
          formValues.password
        );
        res = await auth.currentUser.linkWithCredential(credentials);
      } catch (err) {
        showError(err.message);
        userContext.setIsAuthenticating(false);
      }
    }

    // if NO anonymous user, create account
    else {
      try {
        res = await auth.createUserWithEmailAndPassword(
          formValues.email,
          formValues.password
        );
      } catch (err) {
        showError(err.message);
        userContext.setIsAuthenticating(false);
      }
    }

    const uid = res?.user?.uid;

    userContext.setIsAuthenticating(false);
    if (!uid) {
      return false;
    }

    return res;
  };
};

export default useSignup;
