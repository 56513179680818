import React from 'react';

import UniversalImage from 'components/UniversalImage/UniversalImage';

import usePhotoUrl from './hooks/usePhotoUrl';

// Displays a user avatar using the user's photo URL
const UserAvatar = () => {
  const photoUrl = usePhotoUrl();
  return (
    <UniversalImage
      widthClass="w-8 rounded-full"
      heightClass="h-8"
      image={{ path: photoUrl }}
      alt="User profile photo thumbnail"
    />
  );
};

export default UserAvatar;
