import PropTypes from 'prop-types';
import React from 'react';

import { BookmarkIcon, PlusIcon, ThumbDownIcon } from '@heroicons/react/solid';

import useEnvironment from 'contexts/EnvironmentContext/hooks/useEnvironment';

import OpportunityAction from './OpportunityAction';

const OpportunityActions = ({
  opportunitiesOps,
  opportunity,
  currentField,
  expired,
  onSelect,
  isSelected,
}) => {
  const showActions = !['responded', 'declined'].includes(currentField);
  const { isLocal, isEmulated } = useEnvironment();

  const revealHoverable = isLocal && isEmulated;
  const baseClasses = 'bg-white hover:bg-gray-50 text-gray-500';
  const hoverClasses = `${baseClasses} ${
    revealHoverable ? '' : 'opacity-0 group-hover:opacity-100'
  }`;

  if (expired) {
    return (
      <div className="hidden lg:flex flex-none flex-col items-end whitespace-nowrap space-y-2 pr-4 w-40" />
    );
  }

  return (
    <div className="hidden lg:flex flex-none flex-col items-end whitespace-nowrap space-y-2 pr-4 w-40">
      {showActions && (
        <>
          <OpportunityAction
            className={
              isSelected ? 'bg-teal-500 hover:bg-teal-500 text-white' : ''
            }
            onClick={onSelect}
            testId="actionButtons-select"
          >
            {isSelected ? (
              <span>Selected</span>
            ) : (
              <>
                <PlusIcon className="w-4" /> <span>Select</span>
              </>
            )}
          </OpportunityAction>
          <OpportunityAction
            className={hoverClasses}
            onClick={() => {
              opportunitiesOps.decline(opportunity);
            }}
            testId="opportunity-actions-decline"
          >
            <ThumbDownIcon className="w-4 text-gray-400" />{' '}
            <span className="font-semibold">Decline</span>
          </OpportunityAction>

          {currentField !== 'saved' && (
            <OpportunityAction
              className={hoverClasses}
              onClick={() => opportunitiesOps.save(opportunity)}
              testId="opportunity-actions-save"
            >
              <BookmarkIcon className="w-4 text-gray-400" />{' '}
              <span className="font-semibold">Save for later</span>
            </OpportunityAction>
          )}

          {currentField === 'saved' && (
            <OpportunityAction
              className={hoverClasses}
              onClick={() => opportunitiesOps.unsave(opportunity)}
              testId="opportunity-actions-unsave"
            >
              <BookmarkIcon className="w-4 text-gray-400" />{' '}
              <span className="font-semibold">Unsave</span>
            </OpportunityAction>
          )}
        </>
      )}

      {currentField === 'declined' && (
        <OpportunityAction
          className={hoverClasses}
          onClick={() => opportunitiesOps.undecline(opportunity)}
          testId="opportunity-actions-reactivate"
        >
          <span className="font-semibold">Reactivate</span>
        </OpportunityAction>
      )}

      {/* This is a hack to remove responded messages in testing */}
      {currentField === 'responded' && revealHoverable && (
        <OpportunityAction
          className={hoverClasses}
          onClick={() => opportunitiesOps.unrespond(opportunity)}
          testId="opportunity-actions-unrespond"
        >
          <span className="font-semibold">Unrespond</span>
        </OpportunityAction>
      )}
    </div>
  );
};

OpportunityActions.propTypes = {
  opportunitiesOps: PropTypes.shape({
    decline: PropTypes.func.isRequired,
    undecline: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    unsave: PropTypes.func.isRequired,
    respond: PropTypes.func.isRequired,
    unrespond: PropTypes.func.isRequired,
  }).isRequired,
  opportunity: PropTypes.object.isRequired,
  currentField: PropTypes.string.isRequired,
  expired: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default OpportunityActions;
