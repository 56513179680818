import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import UniversalImage from 'components/UniversalImage/UniversalImage';
import DefaultUserImage from 'static/User.png';

const AdminJournalistsJournalist = ({
  name = '',
  journalist = {},
  className = '',
  onEdit = noop,
}) => (
  <tr key={name} className={className}>
    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
      {name}
    </td>
    <td className="py-4 whitespace-nowrap text-sm text-gray-500 flex justify-center">
      <div className="w-16 h-16 border rounded-full overflow-hidden relative">
        {journalist?.image ? (
          <UniversalImage
            alt={`${name} logo`}
            widthClass="w-16"
            heightClass="h-16"
            image={journalist.image}
          />
        ) : (
          <div className="w-16 h-16 flex items-center justify-center">
            <img
              src={DefaultUserImage}
              className="w-8 h-8 object-contain opacity-30 rounded-full"
              alt="The default avatar icon"
            />
          </div>
        )}
      </div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hover:text-gray-700  text-center">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onEdit();
        }}
      >
        Edit
      </a>
    </td>
  </tr>
);

AdminJournalistsJournalist.propTypes = {
  name: PropTypes.string,
  journalist: PropTypes.shape({}),
  className: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
};

export default AdminJournalistsJournalist;
