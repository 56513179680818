import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import { NewspaperIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';

import Spinner from 'components/Spinner';
import UniversalImage from 'components/UniversalImage/UniversalImage';
import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import TextCounter from 'components/form/TextCounter';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import { REGEX_URL } from 'constants/regex';
import useOpenGraphApi from 'hooks/useOpenGraphApi';
import createOnChangeIsFutureDate from 'utils/date/createOnChangeIsFutureDate';
import { getDateRange } from 'utils/date/dateRange';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

const CompanyArticlesEditModal = ({
  open,
  article = null,
  uploadFolder,
  onClose,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    control,
    errors,
    watch,
    setValue,
    setError,
    trigger,
    formState: { isValid },
    getValues,
    clearErrors,
  } = useForm({ mode: 'onChange' });

  const defaultDate = article?.date
    ? getDateObjFromTimestamp(article.date)
    : {};
  const defaultValues = article || {};

  const [isLoading, setIsLoading] = React.useState(false);

  const title = watch('title');

  const url = watch('url');
  useOpenGraphApi({
    url,
    defaultUrl: defaultValues.url,
    setIsLoading,
    callback: (error, data) => {
      if (error || !data) {
        setIsLoading(false);
        return;
      }
      setValue('title', data.title);
      setValue('description', data.description);
      setValue('publication', data.site_name);
      setValue('author', data.articleAuthor);

      let date = new Date();
      if (data.articlePublishedTime) {
        date = new Date(data.articlePublishedTime);
      }

      setValue('date.day', date.getDate());
      setValue('date.month', getDateRange('months')[date.getMonth()]);
      setValue('date.year', date.getFullYear());

      if (data.image) {
        setValue('image', { path: data.image });
      }
      trigger();
    },
  });

  const onChangeDate = createOnChangeIsFutureDate({
    date: getValues('date'),
    onError: () =>
      setError('date', { message: 'Date cannot be in the future' }),
    onSuccess: () => clearErrors(),
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
            <div className="absolute top-4 right-4 z-10">
              <CloseButton onClick={onClose} />
            </div>

            <div className="px-6 py-6 pb-0">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                <NewspaperIcon className="w-6" />
              </div>
              <div className="mt-3 text-center">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="dialog-1-title"
                >
                  {article ? 'Edit' : 'Add a'} news article
                </h3>
                {!article && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Recent news articles show that your company is being
                      talked about.
                    </p>
                  </div>
                )}

                <div className="flex mt-5">
                  <Label htmlFor="url">Link to the news article*</Label>
                </div>
                <div className="flex items-center justify-between relative">
                  <InputFieldText
                    defaultValue={defaultValues?.url || null}
                    ref={register({
                      pattern: {
                        value: REGEX_URL,
                        message: 'Please type in a valid URL',
                      },
                    })}
                    name="url"
                    placeholder="https://"
                    data-test-id="company-articles-editModal-urlInput"
                  />
                  {isLoading && (
                    <div className="absolute w-6 h-6 top-4 right-px -mt-px px-6 bg-white">
                      <Spinner />
                    </div>
                  )}
                </div>
                {errors.url && (
                  <ErrorMessage testId="company-articles-editModal-urlInput-error">
                    {errors.url.message}
                  </ErrorMessage>
                )}

                <div className="flex w-full justify-between mt-3">
                  <Label htmlFor="link">Image</Label>
                </div>

                <div className="mt-2">
                  <Controller
                    name="image"
                    control={control}
                    defaultValue={defaultValues?.image || null}
                    render={({ onChange, value }) => (
                      <InputFieldUpload
                        name="image"
                        folder={uploadFolder}
                        onChange={onChange}
                        value={value}
                        setError={setError}
                        renderFile={(image, onChangeImage) => (
                          <div className="relative h-40 w-full bg-gray-100">
                            <UniversalImage
                              image={image}
                              alt="Preview image for article"
                              heightClass="h-40"
                              objectFitClass="object-cover"
                            />
                            <div className="absolute -top-2 -right-2">
                              <CloseButton
                                onClick={() => onChangeImage(null)}
                              />
                            </div>
                          </div>
                        )}
                      />
                    )}
                  />
                </div>
                {errors.image && (
                  <ErrorMessage>{errors.image.message}</ErrorMessage>
                )}

                <div className="flex w-full justify-between items-baseline mt-3">
                  <Label htmlFor="link">Title*</Label>
                  <TextCounter
                    str={title || defaultValues.title}
                    maxLength={140}
                  />
                </div>

                <InputFieldText
                  defaultValue={defaultValues?.title || null}
                  ref={register({
                    required: 'Please put in a title',
                    maxLength: {
                      value: 140,
                      message: 'Please keep the title below 140 characters',
                    },
                  })}
                  name="title"
                  placeholder="Type here the title of the article..."
                  data-test-id="company-articles-editModal-titleInput"
                />
                {errors.title && (
                  <ErrorMessage testId="company-articles-editModal-titleInput-error">
                    {errors.title.message}
                  </ErrorMessage>
                )}

                <fieldset className="mt-3 w-full flex flex-col items-start">
                  <Label htmlFor="date">When did it get published?</Label>
                  <div className="flex mt-2">
                    <Controller
                      name="date.day"
                      defaultValue={defaultDate.day || getDateRange('days')[0]}
                      control={control}
                      render={({ onChange, value }) => (
                        <InputFieldDropdown
                          options={getDateRange('days')}
                          value={value}
                          onChange={onChangeDate(onChange)}
                          optionWidthClassName="w-16 sm:w-20"
                          wrapperClassName="mr-2"
                          testId="company-articles-editModal-day"
                        />
                      )}
                    />

                    <Controller
                      name="date.month"
                      defaultValue={
                        defaultDate.month || getDateRange('months')[0]
                      }
                      control={control}
                      render={({ onChange, value }) => (
                        <InputFieldDropdown
                          options={getDateRange('months')}
                          value={value}
                          onChange={onChangeDate(onChange)}
                          optionWidthClassName="w-36"
                          wrapperClassName="mr-2"
                          testId="company-articles-editModal-month"
                        />
                      )}
                    />

                    <Controller
                      name="date.year"
                      defaultValue={
                        defaultDate.year || getDateRange('years')[0]
                      }
                      control={control}
                      render={({ onChange, value }) => (
                        <InputFieldDropdown
                          options={getDateRange('years')}
                          value={value}
                          onChange={onChangeDate(onChange)}
                          optionWidthClassName="w-20 sm:w-28"
                          testId="company-articles-editModal-year"
                        />
                      )}
                    />
                  </div>
                </fieldset>

                {errors.date && (
                  <ErrorMessage>{errors.date.message}</ErrorMessage>
                )}

                <div className="grid grid-cols-1 gap-0 sm:grid-cols-2 sm:gap-6">
                  <div>
                    <div className="flex w-full justify-between mt-3">
                      <Label htmlFor="link">Author</Label>
                    </div>

                    <InputFieldText
                      defaultValue={defaultValues.author || null}
                      ref={register({
                        maxLength: {
                          value: 50,
                          message: 'Please keep the author below 50 characters',
                        },
                      })}
                      name="author"
                      placeholder="Who wrote the article..."
                      data-test-id="company-articles-editModal-authorInput"
                    />
                    {errors.author && (
                      <ErrorMessage testId="company-articles-editModal-authorInput-error">
                        {errors.author.message}
                      </ErrorMessage>
                    )}
                  </div>

                  <div>
                    <div className="flex mt-3">
                      <Label htmlFor="link">Publication*</Label>
                    </div>

                    <InputFieldText
                      defaultValue={defaultValues.publication || null}
                      ref={register({
                        required: 'Please add a publication',
                        maxLength: {
                          value: 50,
                          message:
                            'Please keep the publication below 50 characters',
                        },
                      })}
                      name="publication"
                      placeholder="Where did it get published..."
                      data-test-id="company-articles-editModal-publicationInput"
                    />
                    {errors.publication && (
                      <ErrorMessage testId="company-articles-editModal-publicationInput-error">
                        {errors.publication.message}
                      </ErrorMessage>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
              <Button
                type="secondary"
                onClick={() => onClose()}
                data-test-id="company-articles-editModal-cancel"
              >
                Cancel
              </Button>
              <div className="ml-3">
                <Button
                  submit
                  disabled={!isValid}
                  eventType="pressPage_update"
                  eventProperties={{ action: 'article_add' }}
                  data-test-id="company-articles-editModal-submit"
                >
                  {article ? 'Update' : 'Add'} article
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

CompanyArticlesEditModal.propTypes = {
  open: PropTypes.bool,
  article: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.object,
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
    author: PropTypes.string,
    publication: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadFolder: PropTypes.string.isRequired,
};

export default CompanyArticlesEditModal;
