import PropTypes from 'prop-types';
import React from 'react';

import { NewspaperIcon } from '@heroicons/react/solid';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';

const CompanyArticleDeleteModal = ({ title, onClose, onDelete }) => (
  <Modal onClose={onClose}>
    <>
      <div className="px-6 py-6 pb-0">
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
          <NewspaperIcon className="w-6" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3
            className="text-lg leading-6 font-medium text-gray-900"
            id="dialog-1-title"
          >
            Delete article
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to delete article: <b>{title}</b>
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md space-x-3">
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={onDelete}
          eventType="pressPage_update"
          eventProperties={{ action: 'article_delete' }}
          data-test-id="company-articles-deleteModal-delete"
        >
          Delete article
        </Button>
      </div>
    </>
  </Modal>
);

CompanyArticleDeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CompanyArticleDeleteModal;
