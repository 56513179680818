import PropTypes from 'prop-types';
import React from 'react';

import { Dialog } from '@headlessui/react';
import { VideoCameraIcon } from '@heroicons/react/solid';
import { Controller, useForm } from 'react-hook-form';

import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import CloseButton from 'components/buttons/CloseButton';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import TextCounter from 'components/form/TextCounter';
import InputFieldTagsList from 'components/form/inputFields/InputFieldTagsList/InputFieldTagsList';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { REGEX_URL } from 'constants/regex';
import { tagsVideo } from 'constants/tags';
import useOpenGraphApi from 'hooks/useOpenGraphApi';

import CompanyVideoEditModalPreview from './CompanyVideoEditModalPreview';

const CompanyVideoEditModal = ({ open, video = null, onClose, onSubmit }) => {
  const {
    control,
    register,
    handleSubmit,
    errors,
    formState: { isValid },
    watch,
    setValue,
    trigger,
  } = useForm({ mode: 'onChange' });

  const title = watch('title');
  const url = watch('url');

  const defaultValues = video || {};

  const [isLoading, setIsLoading] = React.useState(false);
  useOpenGraphApi({
    url,
    defaultUrl: defaultValues.url,
    setIsLoading,
    callback: (error, data) => {
      if (!error && data.title) {
        setValue('title', data.title);
        trigger();
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative bg-white rounded-lg shadow-xl m-6 max-w-xl">
            <div className="absolute top-4 right-4 z-10">
              <CloseButton onClick={onClose} />
            </div>
            <div className="px-6 py-6 pb-0">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                <VideoCameraIcon className="w-6 h-6" />
              </div>
              <div className="mt-3 text-center">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="dialog-1-title"
                >
                  {video ? 'Edit' : 'Add a'} video
                </h3>
                {!video && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Add a video to share your company’s story with journalists
                      - something you’d be happy for the world to see.
                    </p>
                  </div>
                )}
              </div>

              <div className="w-full mt-5 text-left text-sm font-medium">
                <Label htmlFor="url">Video URL*</Label>
              </div>
              <div className="flex items-center justify-between relative">
                <InputFieldText
                  defaultValue={defaultValues.url}
                  ref={register({
                    pattern: {
                      value: REGEX_URL,
                      message: 'Please type in a valid URL',
                    },
                  })}
                  name="url"
                  placeholder="https://www.youtube.com/watch?v={videoId}"
                  data-test-id="company-videos-editModal-urlInput"
                />
                {isLoading && (
                  <div className="absolute w-6 h-6 top-4 right-px -mt-px px-6 bg-white">
                    <Spinner />
                  </div>
                )}
              </div>
              <p className="mt-2 text-xs text-gray-400">
                Only YouTube videos are currently supported. More platforms
                coming soon...
              </p>
              {errors.url && (
                <ErrorMessage testId="company-videos-editModal-urlInput-error">
                  {errors.url.message}
                </ErrorMessage>
              )}

              <div className="flex w-full justify-between items-baseline mt-3">
                <Label htmlFor="title">Video title*</Label>
                <TextCounter
                  str={title || defaultValues.title}
                  maxLength={140}
                />
              </div>
              <InputFieldText
                defaultValue={defaultValues.title}
                ref={register({
                  required: 'Please put in a title',
                  maxLength: {
                    value: 140,
                    message: 'Please keep the title below 140 characters',
                  },
                })}
                name="title"
                placeholder="Title of the video"
                data-test-id="company-videos-editModal-titleInput"
              />
              {errors.title && (
                <ErrorMessage testId="company-videos-editModal-titleInput-error">
                  {errors.title.message}
                </ErrorMessage>
              )}

              <div className="w-full mt-3 text-left text-sm font-medium">
                <Label htmlFor="url">Preview</Label>
              </div>

              <div className="w-full mt-3 h-64 overflow-hidden object-cover">
                <CompanyVideoEditModalPreview
                  url={url}
                  defaultUrl={defaultValues.url}
                  error={errors?.url?.message}
                  onDuration={(duration) => {
                    setValue('duration', parseInt(duration, 10));
                    trigger();
                  }}
                />
              </div>

              <div className="w-full mt-3 text-left text-sm font-medium">
                <Label htmlFor="url">Tags</Label>
              </div>

              <div className="mt-2">
                <Controller
                  name="tag"
                  control={control}
                  defaultValue={defaultValues.tag || tagsVideo[0]}
                  render={({ onChange, value }) => (
                    <InputFieldTagsList
                      tags={tagsVideo}
                      onChange={onChange}
                      value={value}
                      testId="company-videos-editModal-tags"
                    />
                  )}
                />
              </div>

              <input
                ref={register({
                  required: true,
                })}
                id="duration"
                type="hidden"
                name="duration"
              />
            </div>
            <div className="flex w-full justify-end bg-gray-50 mt-5 sm:mt-6 px-6 py-6 rounded-b-md">
              <Button
                type="secondary"
                onClick={() => onClose()}
                data-test-id="company-videos-editModal-cancel"
              >
                Cancel
              </Button>
              <div className="ml-3">
                <Button
                  submit
                  disabled={!isValid}
                  eventType="pressPage_update"
                  eventProperties={{ action: 'video_add' }}
                  data-test-id="company-videos-editModal-submit"
                >
                  {video ? 'Update' : 'Add'} video
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

CompanyVideoEditModal.propTypes = {
  open: PropTypes.bool,
  video: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    tag: PropTypes.object,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyVideoEditModal;
