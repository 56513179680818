import { useEffect, useState } from 'react';

import useGetAnalytics from './useGetAnalytics';

/**
 * get report rows for company with slug and given number of days
 * uses a Cloud function HTTP call to generate the Google Analytics report
 */
const useReportRows = ({ slug = '', numberOfDays = 7, getDetails = false }) => {
  const [reportRows, setReportRows] = useState(null);

  const getAnalyticsData = useGetAnalytics(slug, getDetails);

  useEffect(() => {
    (async () => {
      setReportRows(null);
      const res = await getAnalyticsData(numberOfDays);
      if (res && res.data && res.data.valid) {
        setReportRows(res.data.reportRows);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfDays]);

  return reportRows;
};

export default useReportRows;
