import React from 'react';

/**
 * Footer component with legal links and copyright notice
 */
const Footer = () => (
  <footer className="bg-white py-8 px-8 z-20 relative w-full flex flex-col md:flex-row items-center text-center space-y-4 sm:space-y-0 md:justify-between mx-auto shadow">
    <ul className="flex flex-col sm:flex-row sm:space-x-4 space-y-1 sm:space-y-0">
      <li>
        <a
          className="text-base text-gray-500 hover:text-gray-900"
          rel="noreferrer"
          href="https://www.iubenda.com/privacy-policy/17443214"
          title="Privacy Policy"
          target="_blank"
        >
          Privacy Policy
        </a>
      </li>

      <li>
        <a
          className="text-base text-gray-500 hover:text-gray-900"
          rel="noreferrer"
          href="https://www.iubenda.com/privacy-policy/17443214/cookie-policy"
          title="Cookie Policy"
          target="_blank"
        >
          Cookie Policy
        </a>
      </li>
      <li>
        <a
          className="text-base text-gray-500 hover:text-gray-900"
          rel="noreferrer"
          href="https://www.iubenda.com/terms-and-conditions/17443214"
          title="Terms & Conditions"
          target="_blank"
        >
          Terms &amp; Conditions
        </a>
      </li>
    </ul>
    <p className="text-base text-gray-400 xl:text-center">
      &copy; 2021 MVPR, Inc. All rights reserved.
    </p>
  </footer>
);

export default Footer;
