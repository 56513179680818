import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import getTealBgClassName from 'utils/color/getTealBgClassName';

const InputFieldRadioStackedCardsCard = ({
  inputRef = null,
  name = '',
  option = '',
  index = 0,
  isSelected = false,
  isFirst = false,
  isLast = false,
  testId = '',
}) => (
  <label
    htmlFor={`${name}-${index}`}
    className={classNames(
      'rounded-md',
      {
        'border-t-gray-200': isFirst && !isSelected,
        'border-b-gray-200': isLast && !isSelected,
        [`${getTealBgClassName(index, {
          detailed: true,
          reverse: true,
        })} bg-opacity-20 border-gray-200 hover:bg-opacity-40`]: !isSelected,
        'border-teal-500 bg-teal-50 text-teal-600 z-20 hover:bg-opacity-80':
          isSelected,
      },
      'border py-2.5 px-4 flex items-center cursor-pointer'
    )}
    data-test-id={`${testId}-${option}`}
  >
    <input
      ref={inputRef}
      id={`${name}-${index}`}
      name={name}
      value={option}
      type="radio"
      className={classNames(
        'h-4 w-4 mr-4 cursor-pointer text-teal-400 border-gray-300 focus:ring-teal-400',
        {
          'ring-2 ring-white': isSelected,
        }
      )}
      defaultChecked={isSelected}
    />
    <span className="font-lg">{option}</span>
  </label>
);

InputFieldRadioStackedCardsCard.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  name: PropTypes.string.isRequired,
  option: PropTypes.string.isRequired,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  testId: PropTypes.string,
};

export default InputFieldRadioStackedCardsCard;
