// Get topics that are part of selected topics
const getTopics = (topics = [], selectedTopics = []) =>
  topics
    ? topics
        .filter((topic) =>
          selectedTopics.find((selectedTopic) => selectedTopic.id === topic.id)
        )
        .filter(Boolean)
    : [];

export default getTopics;
