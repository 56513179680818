import padStart from 'lodash/padStart';

const dateRanges = {
  hours: [],
  days: [],
  months: [],
  years: [],
};

// hours
const RANGE_HOURS = [0, 23];
const RANGE_DAYS = [1, 31];
const RANGE_YEARS = [2000, 2021];

let currentHour = RANGE_HOURS[0];
do {
  dateRanges.hours.push(`${padStart(currentHour, 2, 0)}:00`);
  currentHour += 1;
} while (currentHour <= RANGE_HOURS[1]);

// days
let currentDay = RANGE_DAYS[0];
do {
  dateRanges.days.push(currentDay);
  currentDay += 1;
} while (currentDay <= RANGE_DAYS[1]);

// months
dateRanges.months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// years
let currentYear = RANGE_YEARS[0];
do {
  dateRanges.years.push(currentYear);
  currentYear += 1;
} while (currentYear <= RANGE_YEARS[1]);

dateRanges.years.reverse();

// returns the date range for a specific type (hours, days, months, years)
const getDateRange = (type = '') => dateRanges[type] || [];

// returns the default date range (first item) from a date range
const getDateRangeDefault = (type = '') => getDateRange(type)[0] || 0;

export { getDateRange, getDateRangeDefault };
