import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { PlusIcon, TrashIcon } from '@heroicons/react/solid';
import truncate from 'lodash/truncate';

import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import SectionHeadline from 'components/company/SectionHeadline';

import CompanyMetaDescriptionDeleteModal from './CompanyMetaDescriptionDeleteModal';
import CompanyMetaDescriptionEditModal from './CompanyMetaDescriptionEditModal';

const CompanyMetaDescription = ({
  description = null,
  companyRef,
  showOwnerView,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onSubmit = (data) => {
    companyRef.update(data);
    setIsEditModalOpen(false);
  };

  const onDelete = () => {
    companyRef.update({ description: null });
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <div className="relative justify-between items-center mt-5 pt-5 border-t border-gray-200 pr-6">
        <div className="flex justify-between items-center">
          <SectionHeadline>About</SectionHeadline>
          {showOwnerView && (
            <DotsMenu
              options={[
                {
                  label: `${description ? 'Edit' : 'Add'}  description`,
                  onClick: () => setIsEditModalOpen(true),
                  icon: !description && <PlusIcon className="w-4 h-4" />,
                  testId: 'company-meta-description-menu-edit',
                },
              ].concat(
                description
                  ? {
                      label: `Delete description`,
                      onClick: () => setIsDeleteModalOpen(true),
                      icon: <TrashIcon className="w-4 h-4" />,
                      testId: 'company-meta-description-menu-delete',
                    }
                  : []
              )}
              theme="medium"
              testId="company-meta-description-menu"
            />
          )}
        </div>

        <p
          className={`mt-3 text-sm leading-normal ${
            description ? 'text-gray-400' : 'text-gray-300'
          }`}
          data-test-id="company-meta-description"
        >
          {description
            ? truncate(description, { length: 600 })
            : 'No description added yet.'}
        </p>
      </div>

      {showOwnerView && (
        <CompanyMetaDescriptionEditModal
          open={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onSubmit}
          description={description}
        />
      )}

      {isDeleteModalOpen && showOwnerView && (
        <CompanyMetaDescriptionDeleteModal
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

CompanyMetaDescription.propTypes = {
  description: PropTypes.string,
  showOwnerView: PropTypes.bool.isRequired,
  companyRef: PropTypes.object.isRequired,
};

export default CompanyMetaDescription;
